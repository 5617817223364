import React from 'react';
import { RootWrapper } from "../components/Common";
import styled from "styled-components";
import StyledP from "../components/StyledP";
import HeaderWithBack from "../components/HeaderWithBack";
import { EmptyBox } from "./RecentView";
import { useNavigate } from "react-router-dom";

const PaymentFail = () => {
	const navigate = useNavigate();

	return (
		<RootWrapper>
			<HeaderWithBack text="お支払いエラー" />
			<EmptyBox>
				<StyledP>お支払いに失敗しました。 管理者に連絡してください。</StyledP>
				<StyledPTitle onClick={() => navigate("/")}>メイン画面に行く</StyledPTitle>
			</EmptyBox>
		</RootWrapper>
	);
};

export default PaymentFail;

export const StyledPTitle = styled.p`
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 5px;
	cursor: pointer;
`;