import React, { useState } from 'react';
import { ContentWrapper, MarginBottomBox, RootWrapper, buttonStyle, flexCenterCenter, inputStyle, textareaStyle } from '../components/Common';
import HeaderWithBack from '../components/HeaderWithBack';
import b2b from '../assets/yourish_b2b.jpg';
import styled from 'styled-components';
import AskModalOne from '../components/AskModalOne';
import { useNavigate } from "react-router-dom";
import { api_post_header_token } from '../apis/apis';
const ImgBox = styled.div`
  width: 100%;
  overflow: hidden;
  img {
    width: 100%;
  }
`;

const Title = styled.div`
  ${flexCenterCenter}
  font-size: 20px;
  font-weight: 600;
  margin: 15px 0;
`;

const TextDiv = styled.div`
  font-size: 14px;
  font-weight: 500;
`;

const StyledInput = styled.input`
  ${inputStyle}
  margin-top: 5px;
`;

const StyledTextarea = styled.textarea`
  ${textareaStyle}
  margin-top: 5px;
`;

const InputBox = styled.div`
  margin: 10px 0;
`;

const StyledButton = styled.button`
  ${buttonStyle}
  background: var(--color-main);
  width: 100%;
  height: 50px;
  border-radius: 10px;
  margin: 40px 0;
  font-size: 25px;
  font-weight: 600;
  color: #fff;
`;

const B2B = () => {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);

  const [email,setEmail] = useState("");
  const emailChange = (event) => {
    setEmail(event.target.value);
  };
  const [companyName,setCompanyName] = useState("");
  const companyNameChange = (event) => {
    setCompanyName(event.target.value);
  };
  const [yourname,setYourname] = useState("");
  const yournameChange = (event) => {
    setYourname(event.target.value);
  };
  const [phoneNumber,setPhoneNumber] = useState("");
  const phoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };
  const [detail,setDetial] = useState("");
  const detailChange = (event) => {
    setDetial(event.target.value);
  };

  const write = async () => {
    if(email==="" || companyName==="" || yourname==="" || phoneNumber==="" || detail===""){
      setModal2(true);
    }else{
    const params = {
    
        email:email,
        companyName:companyName,
        name:yourname,
        phoneNumber:phoneNumber,
        detail:detail,
      
    }
    const result = await api_post_header_token(`/mail/b2b`, params);
    setModal(true);
  }
    
  }
  const onConfirm = () => {
    navigate(`/`)
    setModal(false);
  };
  const onConfirm2 = () => {
    setModal2(false);
  };

  return (
    <RootWrapper>
      <HeaderWithBack eng="true" text={"B2B"} />
      <ImgBox>
        <img src={b2b} alt='b2b' />
      </ImgBox>
      <Title>ビジネスに関するお問い合わせ</Title>
      <ContentWrapper>
        <InputBox>
          <TextDiv>メールアドレス</TextDiv>
          <StyledInput value={email} onChange={emailChange}/>
        </InputBox>
        <InputBox>
          <TextDiv>会社名</TextDiv>
          <StyledInput placeholder='会社名' value={companyName} onChange={companyNameChange}/>
        </InputBox>
        <InputBox>
          <TextDiv>担当者氏名</TextDiv>
          <StyledInput placeholder='担当者の名前' value={yourname} onChange={yournameChange}/>
        </InputBox>
        <InputBox>
          <TextDiv>担当者連絡先</TextDiv>
          <StyledInput placeholder='担当者連絡先' value={phoneNumber} onChange={phoneNumberChange}/>
        </InputBox>
        <InputBox>
          <TextDiv>お問い合わせ内容</TextDiv>
          <StyledTextarea placeholder='お問い合わせ内容' value={detail} onChange={detailChange}/>
        </InputBox>
        <MarginBottomBox />
        <StyledButton onClick={write}>送信する</StyledButton>
      </ContentWrapper>
      <AskModalOne
        visible={modal}
        title="転送完了"
        confirmText='はい'
        onConfirm={onConfirm}
      />
      <AskModalOne
        visible={modal2}
        title="正しい情報を入力してください"
        confirmText='はい'
        onConfirm={onConfirm2}
      />
    </RootWrapper>
  );
};

export default B2B;