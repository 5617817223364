import React from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";

const StyledDiv = styled.div`
  background-color: #fff;
  width: 100%;
  height: 100%;
`;

// NOTE 누르면 해당 url로 연결
function Layout() {
  return (
    <StyledDiv>
      <Outlet />
    </StyledDiv>
  );
}

export default Layout;
