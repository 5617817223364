import HeaderWithBack from "../../components/HeaderWithBack";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import rank_w from "../../assets/rank_w.png";
import rank_s from "../../assets/rank_s.png";
import rank_g from "../../assets/rank_g.svg";
import rank_d from "../../assets/rank_d.png";
import rank_b from "../../assets/clickhere.png";
import { useLocation,useNavigate } from "react-router-dom";
import listicon from "../../assets/common/listicon.svg";
import reviewicon from "../../assets/common/reviewicon.svg";
import couponicon from "../../assets/common/couponicon.svg";
import arrowright from "../../assets/common/arrow-right.svg";
import NavBasic from "../../components/NavBasic";
import AskLogoutModal from "../../components/AskLogoutModal";
import AskModal from '../../components/AskModal';
import Button from "../../components/Button";
import Cookies from "js-cookie";
import { api_get_header_token,api_get } from "../../apis/apis";
import { flexCenterCenter } from "../../components/Common";
function Mypage() {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [info, setInfo] = useState();
  const [ct, setCt] = useState();
  const location = useLocation();

  const jwtToken = Cookies.get("jwtToken");
  const userInfo = async () => {
    const result = await api_get_header_token(`/user/my-page`);
    setInfo(result.list);
    setCt(result.result);
  
  }

  const animateElements = () => {
    const elements = document.querySelectorAll(".css-animation");
    elements.forEach((ele) => {
      ele.style.opacity = 1;
      ele.style.transform = "translateY(0)";
      ele.style.transitionDuration = "1s";
    });
  }
  useEffect(() => {
    userInfo();
    animateElements();
  }, [location.pathname]);
  

  const onLogoutClick = async () => {
    Cookies.remove("jwtToken");
    
    document.cookie = "jwtToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=yourish.jp;";
    setModal(false);
    navigate('/')
   
  };
  const onCancel = () => {
    setModal(false);
  };
  const onConfirm = async () => {
    Cookies.remove("jwtToken");
    document.cookie = "jwtToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=yourish.jp;";
    setModal(false);
    navigate('/')
  };
  useEffect(() => {
  
  }, [location, modal]);
  const navigateToPage = (url) => {
    navigate(url);
  };

  return (
    <>
      {location.pathname === "/Mypage" ? (
        <>
          <StyledDiv>
            <HeaderWithBack eng="true" text="MY PAGE"></HeaderWithBack>
            <RankIconWrapper>
              <Rank className="left-center css-animation">
                {info && (
                  <>
                    {info.rating === 'white' && (
                      <img src={rank_w} alt="rank_a" />
                    )}
                    {info.rating === 'silver' && (
                      <img src={rank_s} alt="rank_b" />
                    )}
                    {info.rating === 'gold' && (
                      <img src={rank_g} alt="rank_c" />
                    )}
                    {info.rating === 'Diamond' && (
                      <img src={rank_d} alt="rank_d" />
                    )}
                    {info.rating === 'black' && (
                      <img src={rank_b} alt="rank_b" onClick={() => navigateToPage("/collaboration")}/>
                    )}
                    {info.rating === 'influencer' && (
                      <img src={rank_b} alt="rank_b" onClick={() => navigateToPage("/collaboration-influencer")}/>
                    )}
                    <div>
                      <p>{info.nickname}</p>

                      {info.provider === 'yourish' && (
                        <p>{info.email}</p>
                      )}
                      {info.provider === 'google' && (
                        <p>{info.email}</p>
                      )}
                      {info.provider === 'apple' && (
                        <p>Apple</p>
                      )}
                      {info.provider === 'line' && (
                        <p>Line</p>
                      )}

                    </div>
                  </>
                )}
              </Rank>
              <IconWrapper className="css-animation">
                <IconButton to="/mypage/myorderlists">
                  <IconImg><img src={listicon} alt="listicon" /></IconImg>
                  <span>注文履歴</span>
                </IconButton>
                <IconButton to="/mypage/myreviews">
                  <IconImg>
                    <img src={reviewicon} alt="reviewicon" />
                    <div className="num">{ct}</div>
                  </IconImg>
                  <span>レビュー</span>
                </IconButton>
                <IconButton to="/Mypage/mycoupons">
                  <IconImg><img src={couponicon} alt="couponicon" /></IconImg>
                  <span>マイクーポン</span>
                </IconButton>
              </IconWrapper>
            </RankIconWrapper>

            <MenuWrapper>
              <Menu className="css-animation">
                <span onClick={() => navigate("/recentView")}>
                  最近観覧した商品
                  <img src={arrowright} alt="arrow" />
                </span>
              </Menu>
              <Menu className="css-animation">
                <span onClick={() => navigate("/mypost")}>
                  投稿履歴
                  <img src={arrowright} alt="arrow" />
                </span>
              </Menu>
              <Menu className="css-animation">
                <span onClick={() => navigate("/waitpayment")}>
                  支払い待ち
                  <img src={arrowright} alt="arrow" />
                </span>
              </Menu>
              <Menu className="css-animation">
                <span onClick={() => navigate("/inquiry")}>
                  お問い合わせ
                  <img src={arrowright} alt="arrow" />
                </span>
              </Menu>
              <Menu className="css-animation">
                <span onClick={() => navigate("/address-list")}>
                  配送先情報
                  <img src={arrowright} alt="arrow" />
                </span>
              </Menu>
              {/* <li><Button to="/">· 送料クーポン払い戻し</Button></li> */}
              <div className="bottom" />
              <Menu className="css-animation">
                <span onClick={() => navigate("/notice")}>
                  お知らせ
                  <img src={arrowright} alt="arrow" />
                </span>
              </Menu>
              <Menu className="css-animation">
                <span onClick={() => navigate("/faq")}>
                  FAQ
                  <img src={arrowright} alt="arrow" />
                </span>
              </Menu>
              <Menu className="css-animation">
                <span onClick={() => navigate("/personalinfo")}>
                  個人情報
                  <img src={arrowright} alt="arrow" />
                </span>
              </Menu>
              <Menu className="css-animation">
                <span onClick={() => navigate("/termsuse")}
                >サービス利用規約
                  <img src={arrowright} alt="arrow" />
                </span>
              </Menu>
              <Menu className="css-animation">
                <span onClick={() => navigate("/delivery")}>
                  特定商取り引きに関する法律に基づく表記
                  <img src={arrowright} alt="arrow" />
                </span>
              </Menu>

              <div className="bottom" />
              <Menu className="css-animation" onClick={onLogoutClick}>
                <span>ログアウト</span>
              </Menu>
            </MenuWrapper>
            <NavBasic />
            <AskLogoutModal
              visible={modal}
              onConfirm={onConfirm}
              onCancel={onCancel}
            />
          </StyledDiv>
        </>
      ) : (
        <StyledDiv />
      )}
    </>
  );
}

export default Mypage;

const StyledDiv = styled.div`
  position: relative;
  min-height: 100vh;
  height: 1000px;
  p {
    font-size: 14px;
  }
`;

const RankIconWrapper = styled.div`
  background: rgba(255, 123, 154, 0.12);
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
`;

const MenuWrapper = styled.div`
  div:last-child {
    margin-bottom: 100px;
  }
  .bottom {
    border-bottom: 2px solid rgba(217, 217, 217, 0.4);
    margin: 28px 0;
  }
`;

const Menu = styled.div`
  margin: 15px 15px 15px 20px;
  cursor: pointer;
  opacity: 0;
  transform: translateY(1.5rem);
  span {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    color: rgba(102, 102, 102, 1);
  }
`;

const Rank = styled.div`
  width: 100%;
  padding: 30px 40px;
  transform: translateY(1.5rem);
  
  p {
    margin: 10px;
  }
  p:first-of-type {
    font-weight: 700;
  }
  p:last-of-type {
    color: var(--color-gray3);
    @media screen and (max-width: 360px) {
      font-size: 12px;
    }
  }
  img {
    width: 80px;
    margin: 10px 10px 10px 20px;
    @media screen and (max-width: 360px) {
      width: 60px;
      margin: 5px 10px 5px 20px;
    }
  }
`;

const IconWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  opacity: 0;
  transform: translateY(1.5rem);
  background: #fff;
  border-radius: 16px;
  bottom: 23px;
  margin: 20px;
  .num {
    ${flexCenterCenter}
    position: absolute;
    right: -4px;
    top: -8px;
    width: 20px;
    height: 20px;
    background: #fff;
    border: 1px solid rgba(255, 123, 154, 1);
    color: rgba(255, 123, 154, 1);
    font-size: 13px;
    border-radius: 50%;
    text-align: center;
    line-height: 18px;
    @media screen and (max-width: 500px) {
      width: 20px;
      height: 20px;
      font-size: 13px;
    }
  }
`;

const IconImg = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 36px;
  height: 36px;
  img {
    width: 36px;
    height: 36px;
  }
`;

const IconButton = styled(Button)`
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  border-right: 2px solid rgba(255, 123, 154, 0.3);
  margin: 15px 0;
  &:last-child {
    border: none;
  }
  span {
    margin-top: 10px;
    color: rgba(73, 73, 73, 1);
    font-size: 14px;
    font-weight: 400;
  }
`;