import React from 'react';
import styled from 'styled-components';
import Button from './Button';

const WrapBox = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 90%;
  margin: 10px auto;
`;

const FreeShippiingTag = styled(Button)`
  width: 148px;
  height: 30px;
  background: var(--color-gray4);
  border-radius: 20px;
  border: none;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FreeShippiingButton = () => {
  return (
    <WrapBox>
      <FreeShippiingTag to="/buycoupon">送料無料クーポン</FreeShippiingTag>
    </WrapBox>
  );
};

export default FreeShippiingButton;