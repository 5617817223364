import React from "react";
import HeaderWithBack from "../../components/HeaderWithBack";
import { api_get } from "../../apis/apis";
import { useEffect } from "react";
import { useState } from "react";
import { TermsPrivacyDiv } from "../../components/Common";
import { addLineBreaks } from "../../utils/addLineBreaks";

function Terms() {
  const [texts, setTexts] = useState('');

  useEffect(() => {
    fetchTerms();
  }, []);

  const fetchTerms = async () => {
    const response = await api_get('/terms-and-conditions');
    const result = response.termsAndConditions;
    setTexts(result);
  };

  return (
    <>
      <HeaderWithBack text="利用規約" />
      <TermsPrivacyDiv>{addLineBreaks(texts)}</TermsPrivacyDiv>
    </>
  );
}
export default Terms;