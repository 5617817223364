import React from 'react';
import { RootWrapper } from '../components/Common';
import logo from '../assets/logos/logo.png';
import styled from 'styled-components';
import { useState,useEffect } from 'react';
import backicon from "../assets/header/backicon.svg";
import { useNavigate } from 'react-router-dom';
import Cookies from "js-cookie";
import NavBasic from '../components/NavBasic';
import { api_get_header_token } from '../apis/apis';
import NumberFormat from '../components/NumberFormat';
const HeaderDiv = styled.div`
  display: flex;
  align-items: center;
  padding: 25px 20px 15px;
`;

const LogoBox = styled.div`
  margin-left: 20px;
  img {
    width: 140px;
  }
`;

const StyledTable = styled.table`
  width: 100%;
  text-align: center;
  line-height: 50px;
  thead {
    height: 50px;
    font-size: 16px;
    font-weight: 700;
    color: var(--color-sub);
    background: rgba(255, 123, 154, 0.1);
  }
  tbody {
    tr {
      height: 50px;
      font-size: 12px;
      font-weight: 500;
      &:first-child {
        font-size: 14px;
        font-weight: 700;
      }
      &:nth-child(2n) {
        background: var(--color-main-pale);
      }
    }
  }
`;

const CollaborationPage = () => {
  const navigate = useNavigate();
  const [info,setInfo] = useState([]);
  const jwtToken = Cookies.get("jwtToken");
  const getInfo = async () => {
  
    const url = `/private/yourish/coupon-info/total-price`;
    const response = await api_get_header_token(url)
     
    setInfo(response.list);
    
  
  }
  useEffect(() => {
    getInfo();
  }, []);

  return (
    <RootWrapper>
      <HeaderDiv>
        <img src={backicon} alt='backicon'  onClick={() => navigate(-1)}  />
        <LogoBox><img src={logo} alt='logo' /></LogoBox>
      </HeaderDiv>
      {info && (
      <StyledTable>
        <thead>
        <tr>
    <th>Total</th>
    <th>{info.totalSize}</th>
    <th>円{typeof info.totalDisCountPrice === 'number' ? <NumberFormat number={info.totalDisCountPrice} /> : ''}</th>
    <th>円{typeof info.totalPaymentPrice === 'number' ? <NumberFormat number={info.totalPaymentPrice} /> : ''}</th>
    <th>円{typeof info.totalGiveYou === 'number' ? <NumberFormat number={info.totalGiveYou} /> : ''}</th>
  </tr>
        </thead>
        <tbody>
          <tr>
            <td>お支払い日</td>
            <td>販売数量</td>
            <td>割引額</td>
            <td>決済金額</td>
            <td>Give You</td>
          </tr>

          {info.data && info.data.map((list, index) => (
            <tr key={index}>
              <td>{list.date}</td>
              <td>{list.size}</td>
              <td><NumberFormat number={list.discountPrice} />円</td>
              <td><NumberFormat number={list.paymentPrice} />円</td>
              <td><NumberFormat number={list.giveYou} />円</td>
            </tr>
          ))}

        </tbody>
      </StyledTable>
      )}

      <NavBasic/>
    </RootWrapper>
  );
};

export default CollaborationPage;