/* eslint-disable */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import perfumeicon from "../../assets/main/pericon.svg";
import allicon from "../../assets/main/allicon.svg";
import brandicon from "../../assets/main/brandicon.svg";
import skincareicon from "../../assets/main/skincareicon.svg";
import makeupicon from "../../assets/main/makeupicon.svg";
import fashionicon from "../../assets/main/fashionicon.svg";
import timedealicon from "../../assets/main/timedealicon.svg";
import nowuseicon from "../../assets/main/nowuseicon.svg";
import ListMainProduct from "../../components/ListMainProduct";
import HeaderWithLogo from "../../components/HeaderWithLogo";
import TitleMain from "../../components/TitleMain";
import TitleTimedeal from "../../components/TitleTimedeal";
import ListReviews from "../../components/ListReviews";
import Footer from "../../components/Footer";
import NavBasic from "../../components/NavBasic";
import { useNavigate } from "react-router-dom";
import { fetchMainData, isMember } from "../../utils";
import { useQuery } from "react-query";
import Loading from "../Loading";
import ErrorPage from "../ErrorPage";
import Button from "../../components/Button";
import Cookies from "js-cookie";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import { useResetRecoilState } from "recoil";
import { signUpDataAtom } from "../../atom";
import ArrowButton from "../../components/ArrowButton";
import NewProductSlider from "./NewProductSlider";
import apple from "../../assets/apple.png";
import google from "../../assets/play_store.png";
import { fetchTimeDealData } from "../../utils";

const categoryName = [
  {id: 0, name: "ALL", icon: allicon},
  {id: 1, name: "BRAND", icon: brandicon},
  {id: 2, name: "SKINCARE", icon: skincareicon},
  {id: 3, name: "MAKEUP", icon: makeupicon},
  {id: 4, name: "FASHION&LIFE", icon: fashionicon},
  {id: 5, name: "PERFUME&BODY", icon: perfumeicon},
  {id: 6, name: "TIME DEAL", icon: timedealicon},
  {id: 7, name: "NOW USE", icon: nowuseicon},
];

function Home() {
  const navigate = useNavigate();
  const {isLoading, isError, data} = useQuery("mainData", fetchMainData);
  const resetSignUpData = useResetRecoilState(signUpDataAtom);
  const timeDealData = useQuery("timeDeal", fetchTimeDealData);
  const [timedealExpireState , setTimedealExpireState] = useState(true)

  const [isApp , setIsApp] = useState(false);

  const onClick = async (location) => {
    const token = await isMember();
    if (token.status === "fail") {
      Cookies.remove("jwtToken");
      document.cookie = "jwtToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=yourish.jp;";
      navigate(location);
    } else {
      alert("すでに会員です。");
    }
  }

  const handleGoImgLocation = (location, seq) => {
    if(seq === "26"){
      window.location.href = "https://page.line.me/791fpzkr";
    }else{
      navigate(`${location}`);
    }

  }
  const handleGoBrand = (location) => {
    navigate(`/brand/${location}`);
  }

  const handleCategory = (category) => {
    
    if (category === "BRAND") {
      navigate("/brand")
    } else if (category === "TIME DEAL") {
      navigate("/timedeal")
    } else if (category === "NOW USE") {
      navigate("/nowuse")
    } else {
      navigate(`/category/${category}`);
    }
  }

  useEffect(()=>{
    if(timeDealData.data != undefined){
      const timeDealExpire = new Date(timeDealData.data.expire)
      if (timeDealExpire < new Date()){
        setTimedealExpireState(false)
      }
    }
  },[timeDealData])


  useEffect(() => {
    resetSignUpData();
    

    

    // const init = async () => {
    //   await api_get("/expire");
    // };
    // init();

    const isWebView = typeof window.ReactNativeWebView !== 'undefined';
    if (isWebView) {
      setIsApp(true);
    } else {
      setIsApp(false);
    }
  }, []);

  return (
    <StyledDiv className="box">
      {!isApp &&
        <div className="banner download">
          <StyledStoreButton onClick={() => window.open("https://apps.apple.com/kr/app/yourish-k-beauty-life-app/id6450346976")}>
            <img src={apple} alt="apple" />
            <span>App Store</span>
          </StyledStoreButton>
          <StyledStoreButton onClick={() => window.open("https://play.google.com/store/apps/details?id=com.yourish_web_rn&hl=ko")}>
            <img src={google} alt="google" />
            <span>Google Play</span>
          </StyledStoreButton>
        </div>
      }
      <div className="banner banner-top"  onClick={() => onClick(data.small[0].location)}>
        <span>アカウント登録すると、800円割引クーポンプレゼント</span>
      </div>
      <HeaderWithLogo />

      <Swiper
        grabCursor={true}
        className="mySwiper"
        slidesPerView={1}
        autoplay={{ delay: 2000, disableOnInteraction: false }}
        speed={1000}
        loop={true}
        effect="fade"
        modules={[Autoplay, Pagination]}
        pagination={{clickable: true}}
      >
        {data &&
          data.slides.map((i) => (
            <SwiperSlide key={i.seq}>
              <img className="slide-img" src={i.url} alt="banner" onClick={() => handleGoImgLocation(i.location, i.seq)} />
            </SwiperSlide>
          ))}
      </Swiper>

      <section>
        <div className="icon-main">
          <div className="between">
            {categoryName.map((i, index) => (
              <div
                className="category"
                key={i.id}
                style={{ margin: index > 3 ? '15px 0' : '15px 0 0 0' }}
              >
                <img
                  key={i.id}
                  src={i.icon}
                  alt={i.name}
                  onClick={() => handleCategory(i.name)}
                />
              </div>
            ))}
          </div>
        </div>
      </section>
      <div className="wrap">
        {/* 타임딜 진행시 on */}
        {/* <TimeDealView/> */}
        {timedealExpireState &&
                <div className="timedeal">
                <TitleTimedeal
                  title="TIME SALE"
                  color="#000"
                  margin="10px 0 0"
                />
                {isLoading && <Loading />}
                {isError && <ErrorPage />}
                {data && (
                  <ListMainProduct
                    isNowuse={false}
                    isTimedeal={true}
                    data={data.timeDeal}
                  />
                )}
              </div>
        }

  
        {isLoading && <Loading />}
        {isError && <ErrorPage />}
        {data && data.firstCome && data.firstCome.length > 0 ? (
          <div>
            <TitleMain
              title="FIRST COME"
              text="[数量限定] 1点買うと、もう1点プレゼント！"
              viewmore="firstcome"
              margin="0 0 40px"
            >
              <ListMainProduct isNowuse={false} data={data.firstCome} shadow={true} />
            </TitleMain>

          </div>
        ) : (
          <></>
        )}

        <div>
          <TitleMain
            title="人気商品"
            text="お客様に愛され続ける6つの商品"
            viewmore="best"
            margin="0 0 80px"
          >
            {data && <ListMainProduct isNowuse={false} data={data.bestProduct} isBest={true} />}
          </TitleMain>
          {isLoading && <Loading />}
          {isError && <ErrorPage />}
        </div>

        <div className="banner">
          {data && <img src={data.small[2].url} alt="banner" onClick={() => handleGoBrand(data.small[2].location)} className="banner-img" />}
        </div>
        <div>


        {data && data.special.length > 0 ? (
          <div>
            <TitleMain
              title="Special Collaborations"
              text="[数量限定] ユーリッシュ・ポエトリー注目セット賞"
              viewmore="special-collaborations"
              margin="0 0 40px"
            >
          <ListMainProduct isSpecial={true} data={data.special} />
          </TitleMain>

          </div>
          ) : (
          <></>
          )}

        {data && data.influencer.length > 0 &&
          <div>
            <TitleMain
              title="Beauty Insighter"
              margin="0 0 40px"
              viewmore="influencer"
            >
              <ListMainProduct isInfluencer={true} data={data.influencer} />
            </TitleMain>
          </div>
        }

        <div className="banner">
          {data && <img src={data.small[1].url} alt="banner" onClick={() => handleGoBrand(data.small[1].location)} className="banner-img" />}
        </div>


          <TitleMain
            title="NEW ARRIVAL"
            text="今回入荷した新商品"
            viewmore="newproduct"
            margin="0 0 80px"
          >
            {data && <NewProductSlider data={data.newProduct} />}
          </TitleMain>
          {isLoading && <Loading />}
          {isError && <ErrorPage />}
        </div>

        <div>
          {data && data.nowUse.length > 0 &&
            <TitleMain
              title="NOW USE"
              text="期間限定アイテム！"
              viewmore="nowuse"
              margin="0 0 80px"
            >
              {data && <ListMainProduct isNowuse={true} data={data.nowUse} />}
            </TitleMain>
          }
          {isLoading && <Loading />}
          {isError && <ErrorPage />}
        </div>
        <Button to="/watch" className="banner">
          {data &&
            <img src={data.small[3].url} alt="banner" onClick={() => handleGoImgLocation(data.small[3].location)} className="banner-img" />}
        </Button>
        <div>
          <TitleMain
            title="REAL REVIEW"
            text="お客様の声"
            margin="0 0 116px 0"
          >
            {data && <ListReviews data={data.review} />}
          </TitleMain>
        </div>
        <Footer />
      </div>
      <NavBasic />
      <ArrowButton />
    </StyledDiv>
  );
}

export default Home;

const StyledDiv = styled.div`
  width: 100%;
  background: #fff;
  margin-bottom: 0;
  .banner {
    display: flex;
    width: 100%;
    cursor: pointer;
    justify-content: center;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    padding: 10px 0;
    span {
      white-space: nowrap;
      overflow: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
    span::-webkit-scrollbar {
      display: none;
    }
    @media screen and (max-width: 375px) {
      font-size: 12px;
    }
  }
  .banner-top {
    background: #FF7B9A;
  }
  .download {
    position: relative;
    justify-content: flex-end;
  }
  .banner-img {
    width: 100%;
    margin: auto;
  }
  .swiper {
    width: 100%;
    img {
      width: 100%;
    }
  }
  .wrap {
    width: 100%;
  }
  .timedeal {
    background-color: rgba(255, 123, 154, 0.25);
  }
  .swiper-container {
    position: relative;
  }
  .swiper-slide {
    opacity: 0;
    visibility: hidden;
    transition: opacity 1.5s ease-in-out;
  }
  .swiper-slide-active {
    opacity: 1;
    visibility: visible;
    transition: opacity 1.5s ease-in-out;
  }
  .slide-img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  section {
    width: 100%;
    height: fit-content;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .icon-main {
    width: 100%;
    margin: 15px auto;
    padding: 0 10px;
    .between {
      flex-wrap: wrap;
      width: 100%;
      .category {
        width: 25%;
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: baseline;
        cursor: pointer;
        @media screen and (max-width: 500px) {
          max-height: 130px;
        }
        @media screen and (max-width: 375px) {
          max-height: 110px;
        }
      }
      img {
        width: 78%;
      }
    }
  }
`;

const StyledStoreButton = styled.button`
  color: #fff;
  border: none;
  background: #000;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  span {
    padding: 8px 6px;
    font-weight: 500;
  }
  img {
    width: 15px;
    margin-left: 5px;
  }
  :hover {
    background: #D9D9D9;
    cursor: pointer;
  }
  margin-right: 10px;
`;