import React from "react";
import styled from "styled-components";
import HeaderWithBack from "../../components/HeaderWithBack";
import { useQuery } from "react-query";
import { fetchMainData } from "../../utils";
import ListMainProduct from "../../components/ListMainProduct";
import Loading from "../Loading";
import ErrorPage from "../ErrorPage";
import { RootWrapper } from "../../components/Common";
import NavBasic from "../../components/NavBasic";
import ArrowButton from "../../components/ArrowButton";
import CountDown from "../../components/CountDown";
import dayjs from "dayjs";

const Influencer = () => {
  const { isLoading, isError, data } = useQuery("influencer", fetchMainData);
  const expireDate = data?.influencer[0].expireDate;
  const currentDate = dayjs().format("YYYY/MM/DD HH:mm:ss");

  return (
    <RootWrapper>
      <StyledDiv>
        <HeaderWithBack eng="true" text="Beauty Insighter" />
        {currentDate < expireDate && <CountDown expire={expireDate} />}
        {isLoading && <Loading />}
        {isError && <ErrorPage />}
        {data && <ListMainProduct data={data.influencer} isInfluencer={true} />}
      </StyledDiv>
      <NavBasic/>
      <ArrowButton />
    </RootWrapper>
  );
}

export default Influencer;

const StyledDiv = styled.div`
  .text-wrap {
    height: 50px;
    line-height: 2;
    margin-top: 56px;
    margin-bottom: 30px;
  }
`;
