import React from 'react';
import { FullImgDiv, RootWrapper } from '../components/Common';
import HeaderWithBack from '../components/HeaderWithBack';
import aboutus from '../assets/about-us.jpg';
import NavBasic from '../components/NavBasic';

const AboutUs = () => {
  return (
    <RootWrapper>
      <HeaderWithBack eng="true" text={"ABOUT US"} />
      <FullImgDiv>
        <img src={aboutus} alt='aboutus' />
      </FullImgDiv>
      <NavBasic/>
    </RootWrapper>
  );
};

export default AboutUs;