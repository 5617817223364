import React from "react";
import styled from "styled-components";
import BtnRoundExsmall from "./BtnRoundExsmall";
import { useNavigate } from "react-router-dom";
import { ContentWrapper } from "./Common";
import SubStringText from "./SubStringText";
import NumberFormat from "./NumberFormat";

function ListItemReview({data}) {
  const navigate = useNavigate();
  const simple = (seq) => {
    const params = {
      state: seq
    }
    navigate(`/writesimplereview`, params);
  };
  const photo = (seq) => {
    const params = {
      state: seq
    }
    navigate(`/writephotoreview`, params);
  };

  return (
    <ContentWrapper marginBottom>
      <Button>
        レビュー投稿でもらえるポイント合計: {data.result ? <NumberFormat number={data.result} /> : ''}円
      </Button>
      {data.list && data.list.map((list, index) => {
      const orderSeq = list.orderSeq;
      return (
        <StyledDiv key={index}>
          <ReviewBox key={list.orderSeq}>
            <Img>
              <img src={list.productImage} alt="product-Image" />
            </Img>
            <TextBox>
              <div className="itemnum_date">
                <p>{list.orderId}</p>
                <p>{list.orderDate}</p>
              </div>
              <div className="wrap">
                <div className="detail-box">
                  <p>{list.productTitle ? <SubStringText text={list.productTitle} truncatelength={30} /> : null}</p>
                  <p>{list.options ? <SubStringText text={list.options} truncatelength={10} /> : null} / {list.quantity}</p>
                </div>
                <div className="btn-box">
                  <div className="review-btn" onClick={() => list.simpleReview === 'Y' ? null : simple(list.orderSeq)}>
                    <BtnRoundExsmall
                    
                      bgcolor={list.simpleReview === 'Y' ? "#ddd" : "#000"}
                      color="#fff"
                      text="Text"
                      height={"26px"}
                      borderradius="20px"
                      
                    ></BtnRoundExsmall>
                    <p>+30円</p>
                  </div>
                  <div className="review-btn" onClick={() => list.photoReview === 'Y' ? null : photo(list.orderSeq)}>
                    <BtnRoundExsmall
                      
                      bgcolor={list.photoReview === 'Y' ? "#ddd" : "#000"}
                      color="#fff"
                      text="Photo"
                      height={"26px"}
                      borderradius="20px"
                    ></BtnRoundExsmall>
                    <p>+70円</p>
                  </div>
                </div>
              </div>
            </TextBox>
          </ReviewBox>
        </StyledDiv>
      )})}
    </ContentWrapper>
  );
}
export default ListItemReview;

const StyledDiv = styled.div`
  display: block;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #fff;
  border-radius: 20px;
  padding: 10px 15px;
  margin-bottom: 10px;
`;
const ReviewBox = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
const Img = styled.div`
  display: inline-block;
  background: #d9d9d9;
  border-radius: 20px;
  min-width: 80px;
  width: 80px;
  height: 80px;
  img {
    display: block;
    overflow: hidden;
    width: 80px;
    height: 80px;
    border-radius: 20px;
  }
`;
const TextBox = styled.div`
  display: inline-block;
  width: 100%;
  margin: 3px 0 3px 10px;
  .itemnum_date {
    display: flex;
    justify-content: space-between;
    color: #929292;
    font-size: 10px;
    margin-bottom: 7px;
  }
  .wrap {
    display: flex;
    justify-content: space-between;
    height: 55px;
  }
  .detail-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* min-width: 160px; */
    width: 100%;
    p {
      font-size: 12px;
      line-height: 1.5;
      font-weight: bold;
    }
    p:first-child {
      font-weight: 700;
    }
    p:last-child {
      color: #929292;
      font-size: 10px;
    }
  }
  .btn-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .review-btn {
    width: auto;
    text-align: center;
    margin-left: 5px;
    p {
      font-size: 10px;
      color: var(--color-sub);
      margin-top: 5px;
    }
    button {
      white-space: nowrap;
      padding: 5px 0;
      font-size: 10px;
      @media screen and (max-width: 768px) {
        /* width: 72px; */
        height: 24px;
      }
      @media screen and (max-width: 375px) {
        /* width: 55px; */
        height: 25px;
        font-size: 4px;
      }
    }
  }
`;
const Button = styled.button`
  position: absolute;
  top: 10px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0);
  border-radius: 10px;
  font-size: 10px;
  height: 24px;
  color: var(--color-sub);
  border: 1px solid var(--color-sub);
`;