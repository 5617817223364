import { atom } from "recoil";
export const accessTokenAtom = atom({
  key: "accessToken",
  default: localStorage.getItem("accessToken") || "",
});
const initAuthState = {
  email: "",
  nickname: "",
  username: "",
  phoneNumber: "",
  provider: "",
};
export const authState = atom({
  key: "authState",
  default: initAuthState,
});
export const isAuthenticatied = atom({
  key: "isAuthenticatied",
  default: false,
});
export const isLogin = atom({
  key: "isLogin",
  default: false,
});

export const SET_LOADING = 'SET_LOADING';
export const SET_ERROR = 'SET_ERROR';

export const setLoading = isLoading => ({
  type: SET_LOADING,
  payload: isLoading,
});
export const setError = error => ({
  type: SET_ERROR,
  payload: error,
});