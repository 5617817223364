import React, { useEffect } from "react";
import axios from "axios";

// 라인 콜백 테스트
function Callback() {
  useEffect(() => {
    try {
      const url = new URL(window.location.href);
      const code = url.searchParams.get("code");
      const uri = process.env.REACT_APP_LINE_URI + "/line-test";
      const encoded_uri = encodeURI(uri);

      if (code) {
        axios
          .post(
            `https://api.line.me/oauth2/v2.1/token`,
            {
              grant_type: "authorization_code",
              code: code,
              redirect_uri: encoded_uri,
              client_id: process.env.REACT_APP_LINE_CLIENT_ID,
              client_secret: process.env.REACT_APP_LINE_CLIENT_SECRETKEY,
            },
            { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
          )
          .then((res) => {
            const id_token = res.data.id_token.toString();

            const encoded_id_token = encodeURI(id_token);
            const encoded_client_id = encodeURI(
              process.env.REACT_APP_LINE_CLIENT_ID
            );

            axios
              .post(
                "https://api.line.me/oauth2/v2.1/verify",
                {
                  id_token: encoded_id_token,
                  client_id: encoded_client_id,
                },
                {
                  headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                  },
                }
              )
              .then((res) => {
                //TODO 라인한테 받은 유저 이메일을 yourish회원가입 페이지에 같이 주면서 화면이동할 것
                const email = res.data.email;
              })
              .catch((err) => {
                throw err;
              });
          })
          .catch((err) => {
            throw err;
          });
      }
    } catch (err) {throw err;}
  }, []);

  return <div>Callback for TEST</div>;
}

export default Callback;
