import React from 'react';
import styled from 'styled-components';
import HeaderWithBack from '../../components/HeaderWithBack';
import WritePhotoReviewForm from '../../components/WritePhotoReviewForm';

const StyledDiv = styled.div`
  position: relative;
  background-color: #fff;
  min-height: 100vh;
`;

const WriteReview = () => {
  return (
    <StyledDiv>
      <HeaderWithBack text={"レビュー"}></HeaderWithBack>
      <WritePhotoReviewForm />
    </StyledDiv>
  );
};

export default WriteReview;