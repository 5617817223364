import React, { useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { api_get_header_token } from "../apis/apis";
import { useState } from "react";
import Cookies from "js-cookie";
import { ItemListWrap, ItemStyledLi, ItemStyledUl } from './Common';

import ItemListComponent from './ItemListComponent';

const ListMainProduct = ({
  data,
  color,
  isFirstCome,
  isNowuse,
  isTimedeal,
  isInfluencer,
  margintop,
  marginBottom,
  interest,
  shadow,
  isBest,
  isSpecial,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [info, setInfo] = useState({});
  const [wish, setWish] = useState({});

  const onClick = (productSeq, isInfluencer) => {
    navigate(`/item-detail/info/${productSeq}`, { state: { isInfluencer } });
  };

  useEffect(() => {
    setInfo(data);
  }, [data]);

  const toggleWish = async (productSeq) => {
    try {
      const response = await api_get_header_token(`/shop/wish-modify/${productSeq}`);
      if (response.status === "ok") {
        setInfo((prevInfo) => {
          const updatedInfo = prevInfo.map((item) => {
            if (item.productSeq === productSeq) {
              item.wish = response.message === "N" ? "N" : "Y";
            }
            return item;
          });
          return updatedInfo;
        });
        setWish((prevWish) => ({
          ...prevWish,
          [productSeq]: response.message === "N" ? "N" : "Y",
        }));
      } else {
        Cookies.remove("jwtToken");
        document.cookie = "jwtToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=yourish.jp;";
        alert("ログインしてください。");
      }
    } catch (error) {
      window.location.reload();
    }
  };

  const renderMap = location.pathname === "/" ? data.slice(0, 4): data;

  return (
    <ItemListWrap marginBottom={marginBottom}>
      <ItemStyledUl color={color} margintop={margintop} isTimedeal={isTimedeal}>
        {Array.isArray(data) && data.length > 0 ? (
          renderMap.map((list, i) => {
            const startDate = list.startDate;
            const productSeq = list.productSeq;
            const isWish = wish[productSeq] === "Y" || list.wish === "Y";

            return (
              <ItemStyledLi key={list.productSeq}>
                <ItemListComponent
                  onClickToggleWish={() => toggleWish(productSeq)}
                  onClick={() => onClick(productSeq, isInfluencer)}
                  isWish={isWish}
                  src={list.productImageArrays}
                  alt={list.sub}
                  isSoldOut={list.soldOut}
                  isNowuse={isNowuse}
                  isFirstCome={isFirstCome}
                  startDate={startDate}
                  color={color}
                  text={list.brand}
                  truncatelength={10}
                  listAverageScore={list.averageScore}
                  listScoreCount={list.scoreCount}
                  listSub={list.sub}
                  listSubCategory={list.subCategory}
                  listDiscountPercent={list.discountPercent}
                  priceNumber={list.priceYen}
                  discountedPriceNumber={list.discountedPrice}
                  shadow={shadow}
                  isBest={isBest}
                  index={i}
                  isSpecial={list.collaboration === 1 || isSpecial}
                />
              </ItemStyledLi>
            );
          })):(
              <></>
          )}
      </ItemStyledUl>
    </ItemListWrap>
  );
};
export default ListMainProduct;
