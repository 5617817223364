import React, { useState } from "react";
import styled, { css } from "styled-components";
import HeaderWithBack from "../../components/HeaderWithBack";
import ListItemOrder from "../../components/ListItemOrder";
import Cookies from "js-cookie";
import { useQuery } from "react-query";
import Loading from "../Loading";
import ErrorPage from "../ErrorPage";
import { ContentWrapper, MarginBottomBox, RootWrapper, scrollStyle } from "../../components/Common";
import NavBasic from "../../components/NavBasic";
import Button from "../../components/Button";
import { api_get_header_token,api_delete_header_token } from "../../apis/apis";
function Myorderlists() {
  const [selectedBtn, setSelectedBtn] = useState("");

  const jwtToken = Cookies.get("jwtToken");
   let state = "";

  const handleBtnClick = (btn) => {
    setSelectedBtn(btn);
    state = btn;
    refetch();
  };
  const fetchOrderList = async () => {
    const dispose = await api_delete_header_token(`/order/amount-delete`);
    let sort = "orderSeq,desc";
    const response = await api_get_header_token(`/order-lookup/list?state=${state}&sort=${sort}`);
    return response;
  };
  const { isLoading, isError, data, refetch } = useQuery([], () => {
    return fetchOrderList();
  });
  return (
    <RootWrapper gray1Color>
      <HeaderWithBack text={"注文履歴"}></HeaderWithBack>
      <ContentWrapper>
        <ButtonBox>
          <StyledButton className={selectedBtn === '' ? 'active' : ''}
            onClick={()=>handleBtnClick("")}
          >全体</StyledButton>
          <StyledButton className={selectedBtn === 'on_delivery' ? 'active' : ''}
            onClick={()=>handleBtnClick("on_delivery")}
          >配送中</StyledButton>
          <StyledButton className={selectedBtn === 'on_done' ? 'active' : ''}
            onClick={()=>handleBtnClick("on_done")}
          >配送済み</StyledButton>
          <StyledButton className={selectedBtn === 'else' ? 'active' : ''}
            onClick={()=>handleBtnClick("else")}
          >キャンセル・交換・返品</StyledButton>
        </ButtonBox>
      </ContentWrapper>
      {isLoading && <Loading />}
      {isError && <ErrorPage />}
      {data && (
        <ListItemOrder data={data}/>
      )}
      <ContentWrapper>
        <MarginBottomBox />
      </ContentWrapper>
      <NavBasic />
    </RootWrapper>
  );
}
export default Myorderlists;

const ButtonBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  ${scrollStyle}
`;

const StyledButton = styled(Button)`
  white-space: nowrap;
  height: 25px;
  border: 1px solid #000;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 700;
  background: none;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-right: 10px;
  padding: 0 10px;
  &.active {
    background: #000;
    color: #fff;
  }
`;