import React from 'react';
import { ContentWrapper, RootWrapper, TagBox, TagButton, TagWrapper, Tags, TextSub } from '../../components/Common';
import styled, { css } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import deletecircle from '../../assets/common/circle-black-close.png';
import ListMainProduct from '../../components/ListMainProduct';
import { useQuery } from 'react-query';
import { fetchMainData } from '../../utils';
import HeaderwithSearch from '../../components/HeaderwithSearch';
import { useState,useEffect } from 'react';
import Cookies from "js-cookie";
import Loading from '../Loading';
import ErrorPage from '../ErrorPage';
import NavBasic from '../../components/NavBasic';
import { api_get_header_token, api_delete_header_token } from "../../apis/apis";
import SubStringText from '../../components/SubStringText';

const Search = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [tag, setTag] = useState('');
  const [deleteSearch, setDeleteSearch] = useState();
  const [searchResults, setSearchResults] = useState([]);
  const { isLoading, isError, data } = useQuery("mainData", fetchMainData);
  const [popular,setPopular] = useState([]);
  const [suggested,setSuggested] = useState([]);
  const [recent,setRecent] = useState([]);
  const jwtToken = Cookies.get("jwtToken");

  const getInfo = async () => {
    const result = await api_get_header_token(`/search-list-all`);

    setPopular(result.popular);
    setSuggested(result.suggested);
    if(result && result.recent && result.recent.status!=="fail"){
      setRecent(result.recent);
    }
  }

  const handleSearch = () => {
    setSearchTerm('');
  };

  const handleTagSearch = (search) => {
    navigate(`/search-results?word=${search}`);
  };

  const handleSearchDelete = async (recentSearchSeq) => {
    const response = await api_delete_header_token(`/recent-search/delete?recentSearchSeq=${recentSearchSeq}`);
    setDeleteSearch(response);
  };
  
  useEffect(() => {
    getInfo();
  }, []);

  useEffect(() => {
    getInfo();
  }, [deleteSearch]);

  return (
    <RootWrapper>
      <HeaderwithSearch
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        onClick={handleSearch}
      />

      <ContentWrapper>
        <TagBox>
          <div className='text'>おすすめ</div>
          <Tags>
          {suggested.list && suggested.list.map((list, index) => (
            <TagButton key={list.suggestedSearchSeq} onClick={() => handleTagSearch(list.searchWord)}>#{list.searchWord}</TagButton>
          ))}
          </Tags>
        </TagBox>


        <div>
          <AroundBox>
            {/* 회원이면 보임 */}
            <BoxDiv>
              <Title>最近の検索</Title>
              <StyledUl>
              {recent.list && recent.list.length > 0 ? recent.list.map((list, index) => (
                <StyledLi key={list.recentSearchSeq}>
                  <button onClick={() => handleSearchDelete(list.recentSearchSeq)} className='delete'><img src={deletecircle} /></button>
                  <button onClick={() => handleTagSearch(list.searchWord)}>
                    <SubStringText text={list.searchWord} truncatelength={15} />
                  </button>
                </StyledLi>
              )) : (
                <TextSub>会員専用機能</TextSub>
              )}
              </StyledUl>
            </BoxDiv>

            <BoxDiv>
              <Title main>人気検索キーワード</Title>
              <StyledUl>
              {popular.list && popular.list.map((list, index) => (
                <StyledBestLi key={list.recentSearchSeq}>
                  <button onClick={() => handleTagSearch(list.searchWord)}>
                    <SubStringText text={list.searchWord} truncatelength={15} />
                  </button>
                  </StyledBestLi>
              ))}
              </StyledUl>
            </BoxDiv>
          </AroundBox>
          {/*<TextSub className='center'>合計4件の検索結果があります</TextSub>*/}
        </div>
      </ContentWrapper>
      <div>
        {isLoading && <Loading />}
        {isError && <ErrorPage />}
        {data && (
          <ListMainProduct isNowuse={false} data={data.bestProduct} marginBottom={true} />
        )}
      </div>
      <NavBasic />
    </RootWrapper>
  );
};

export default Search;

const AroundBox = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 20px auto;
`;

const BoxDiv = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.div`
  font-size: 16px;
  margin-bottom: 15px;
  ${props => props.main && css`
    font-family: var(--font-jp-bold);
    font-size: 16px;
    color: var(--color-main);
  `}
`;

const StyledUl = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const StyledLi = styled.li`
  position: relative;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  button {
    height: 25px;
    border: none;
    border-radius: 20px;
    background: var(--color-gray1);
    font-size: 12px;
    font-weight: 350;
    color: #000;
    display: flex;
    align-items: center;
    padding: 0 30px 0 10px;
    &.delete {
      position: absolute;
      right: 6px;
      padding: 0;
      background: none;
    }
  }
  span {
    position: absolute;
    display: block;
    width: 10px;
    height: 25px;
    top: 3px;
    left: 10px;
  }
`;

const StyledBestLi = styled.li`
  position: relative;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  button {
    height: 25px;
    border: none;
    border-radius: 20px;
    background: var(--color-gray1);
    font-size: 12px;
    font-weight: 350;
    color: #000;
    display: flex;
    align-items: center;
    padding: 0 10px!important;
  }
`;