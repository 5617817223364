import styled from "styled-components";

const StyledP = styled.p`
  color: ${(color) => color?.color || "#000"};
  font-family: ${(props) => props.bold ? "var(--font-jp-regular)" : "var(--font-jp-light)"};
  font-size: 14px;
  font-weight: ${(props) => props.bold ? "500" : "300"};
  line-height: 1.6;
  text-align: center;
  margin: 22px auto 10px;
`;

export default StyledP;
