import React from "react";
import HeaderWithBack from "../../components/HeaderWithBack";
import ListMainProduct from "../../components/ListMainProduct";
import best6 from "../../assets/banners/best6.png";
import { useQuery } from "react-query";
import { fetchMainData } from "../../utils";
import StyledP from "../../components/StyledP";
import BannerBox from "../../components/BannerBox";
import Loading from "../Loading";
import ErrorPage from "../ErrorPage";
import { RootWrapper } from "../../components/Common";
import NavBasic from "../../components/NavBasic";
function Best() {
  const { isLoading, isError, data } = useQuery("product-best", fetchMainData);
  return (
    <RootWrapper>
      <HeaderWithBack eng="true" text="人気商品"></HeaderWithBack>
      <BannerBox>
        <img src={best6} alt="best6" />
        <StyledP bold>
          YOURISHをご利用の
          <br />
          多くのお客様に愛され続ける
          <br />
          8つの商品
        </StyledP>
      </BannerBox>
      {isLoading && <Loading />}
      {isError && <ErrorPage />}
      {data && (
        <ListMainProduct
          isFirstCome={false}
          isNowuse={false}
          isTimedeal={false}
          data={data.bestProduct}
          marginBottom={true}
        />
      )}
      <NavBasic/>
    </RootWrapper>
  );
}

export default Best;
