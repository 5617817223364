import React from "react";
import coupon from "../../assets/coupon.png";
import styled, { css } from "styled-components";
import { useNavigate } from "react-router-dom";
import BtnBoxBasic from "../../components/BtnBoxBasic";
import { RootWrapper } from "../../components/Common";
import { flexCenterCenter } from "../../components/Common";

const SignUpSuccess = () => {
  const navigate = useNavigate();

  return (
    <RootWrapper>
      <StyledDiv>
        <FlexBox>
          <div>
            <img src={coupon} alt="coupon" />
            <h2>会員登録完了</h2>
            <p>会員登録10%クーポンをプレゼントしました！</p>
            <MaincolorP>マイページ-&gt;保有しているクーポン</MaincolorP>
          </div>
        </FlexBox>
        <BtnBoxBasic
          className="color"
          text="ホームに戻る"
          bgcolor="#85dbd7"
          width="90%"
          fontsize="24px"
          color="#fff"
          onClick={() => navigate("/")}
        ></BtnBoxBasic>
      </StyledDiv>
    </RootWrapper>
  );
};

export default SignUpSuccess;

const StyledDiv = styled.div`
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  button {
    position: absolute;
    width: 90%;
    bottom: 2rem;
  }
`;

const MaincolorP = styled.p`
  color: var(--color-main);
`;

const FlexBox = styled.div`
  ${flexCenterCenter}
  flex-direction: column;
  width: 100%;
  height: 90%;
  h2 {
    font-weight: 700;
    font-size: 24px;
    margin: 1rem 0;
  }
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.6;
    font-family: var(--font-jp-regular);
  }
`;