import React, { useState } from 'react';
import Loading from '../pages/Loading';
import ErrorPage from '../pages/ErrorPage';

const LoadingErrorProvider = ({ children }) => {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  return (
    <div>
      {isLoading && <Loading />}
      {error && <ErrorPage />}
      {children}
    </div>
  );
};

export default LoadingErrorProvider;