import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";
import NotoSansJpLight from "./fonts/NotoSansJP-Light.ttf";
import NotoSansJpRegular from "./fonts/NotoSansJP-Regular.ttf";
import NotoSansJpSemiBold from "./fonts/NotoSansJP-SemiBold.ttf";

const GlobalStyles = createGlobalStyle`
  ${reset}
  @font-face {
    font-family: 'notoSansJpLight';
    src: local('NotoSansJP-Light'),
    url(${NotoSansJpLight}) format('truetype');
    unicode-range: U+3001, U+3002, U+300C-300F, U+3041-3096,
    U+309D, U+309E, U+30A1-30FA, U+30FC,
    U+4E00-9FEA, U+3400-4DB5, U+FF5E, U+F900-FA6D;
  }
  @font-face {
    font-family: 'notoSansJpRegular';
    src: local('NotoSansJP-Regular'),
    url(${NotoSansJpRegular}) format('truetype');
    unicode-range: U+3001, U+3002, U+300C-300F, U+3041-3096,
    U+309D, U+309E, U+30A1-30FA, U+30FC,
    U+4E00-9FEA, U+3400-4DB5, U+FF5E, U+F900-FA6D;
  }
  @font-face {
    font-family: 'notoSansJpSemiBold';
    src: local('NotoSansJP-SemiBold'),
    url(${NotoSansJpSemiBold}) format('truetype');
    unicode-range: U+3001, U+3002, U+300C-300F, U+3041-3096,
    U+309D, U+309E, U+30A1-30FA, U+30FC,
    U+4E00-9FEA, U+3400-4DB5, U+FF5E, U+F900-FA6D;
  }

  :root {
  //  color
    --color-main: #85dbd7;
    --color-main-light: #DFF0EF;
    --color-main-pale: #DCF3F2;
    --color-sub: #FF7B9A;
    --color-sub-pale: #ffdfe2;
    --color-white: #fff;
    --color-black: #000;
    --color-gray1: #f5f5f5;
    --color-gray2: #d9d9d9;
    --color-gray3: #929292;
    --color-gray4: #515151;
  //  font
    --font-english: "Noto Sans JP";
    --font-jp-light: 'NotoSansJpLight', sans-serif;
    --font-jp-regular: 'NotoSansJpRegular', sans-serif;
    --font-jp-bold: 'notoSansJpSemiBold', sans-serif;
  }
  body {
    background-color: var(--color-sub-pale);
    font-family: 'Noto Sans Jp', 'Noto Sans', sans-serif;
  }
  
  * {
    box-sizing: border-box;
    font-family: 'Noto Sans Jp', 'Noto Sans', sans-serif;
  }

  .Web {
    display: flex;
    margin: 0 auto;
    max-width: 915px;
    width: 915px;
    @media screen and (max-width: 935px) {
     width: 100%;
     .QRImg {
      display: none;
     }
    }
    .App{
      max-width: 500px;
      width: 500px;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
      background-color: #fff;
      margin: auto;
      @media screen and (max-width: 935px) {
       width: 100%;
      } 
      .box{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        // min-height: 850px;
        // @media screen and (max-width: 768px) {
        //   min-height: 650px;
        // }
        // @media screen and (max-width: 280px) {
        //   min-height: 500px;
        // }
      }
      .hide {
        display: none;
      }
      .left{
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
      }
      .row{
        margin: auto;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        /* align-items: center; */
      }
      .between{
        display: flex;
        justify-content: space-between;
        align-items: center; 
      }
  
      .evenly{
        display: flex;
        justify-content: space-evenly;
        align-items: center;
      }
      .right {
        display: flex;
        justify-content: flex-end;
      }
      .right-column{
        display: flex;
        flex-direction: column;
      }
      .center {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .left-center {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      .border-bottom {
        border-bottom: 1px solid #000;
        margin-bottom: 10px;
      }
      .p14 {
        font-size: 14px;
      }
      .inline {
        display: inline;
      }
      .color-gray {
        color: #929292;
      }
      input, textarea { 
        -moz-user-select: auto;
        -webkit-user-select: auto;
        -ms-user-select: auto;
        user-select: auto;
        resize: none;
      }
      input:focus {
        outline: none;
      }
      button {
        /* padding: 0; */
        cursor: pointer;
      }
      .sub {
        color: #FF7B9A;
      }
    }
  }
  
`;

export default GlobalStyles;
