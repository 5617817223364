import React from "react";
import styled from "styled-components";
function BtnRoundSmall({
  text,
  bgcolor,
  borderradius,
  width,
  fontsize,
  height,
  color,
}) {
  return (
    <StyledButton
      bgcolor={bgcolor}
      borderradius={borderradius}
      width={width}
      fontsize={fontsize}
      height={height}
      color={color}
    >
      {text}
    </StyledButton>
  );
}
export default BtnRoundSmall;

const StyledButton = styled.button`
  height: ${(height) => height?.height || "18px"};
  min-width: 20px;
  width: ${(width) => width?.width || "40px"};
  background-color: ${(bgcolor) => bgcolor?.bgcolor || "#85DBD7"};
  border-radius: ${(borderradius) => borderradius?.borderradius || "18px"};
  font-size: ${(fontsize) => fontsize?.fontsize || "10px"};
  font-weight: 700;
  border: none;
  color: ${(color) => color?.color || "#fff"};
`;
