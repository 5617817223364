import React from "react";
import styled from "styled-components";
import HeaderWithBack from "../../components/HeaderWithBack";
import ListCoupon from "../../components/ListCoupon";
import { RootWrapper } from "../../components/Common";
const Mycoupons = () => {
  return (
    <RootWrapper maincolor>
      <StyledDiv>
        <HeaderWithBack text={"マイクーポン "}></HeaderWithBack>
        <ListCoupon></ListCoupon>
      </StyledDiv>
    </RootWrapper>
  );
};
export default Mycoupons;

const StyledDiv = styled.div`
  min-height: 100vh;
  background-color: var(--color-main-light);
  padding-bottom: 90px;
`;
