import axios from "axios";
import Cookies from "js-cookie";
const baseUrl = process.env.REACT_APP_BASEURL;


export async function api_get(endpoint , use_params){
  return axios.get(baseUrl + endpoint, {params:use_params})
  .then((response) => response.data)
  .catch((error) => {
    throw error;
  });
}

export async function api_post(endpoint, params){
  return axios.post(baseUrl + endpoint ,params)
  .then((response) => response.data)
  .catch(function (error) {
    throw error
  });
}

export async function api_put(endpoint, params){
  return axios.put(baseUrl + endpoint ,params)
  .then((response) => response.data)
  .catch(function (error) {
      throw error;
  });
}

export async function api_get_header_token(endpoint){
  const jwtToken = Cookies.get("jwtToken");
  const header = {Authorization: `${jwtToken}`}
  
  return axios.get(baseUrl + endpoint, {headers: header})
  .then((response) => response.data)
  .catch((error) => {
    throw error;
  });
}

export async function api_get_header_token_then(endpoint){
  const jwtToken = Cookies.get("jwtToken");
  const header = {Authorization: `${jwtToken}`}
  
  return axios.get(baseUrl + endpoint, {headers: header})
}

export async function api_get_list_header_token(endpoint , use_params){
  const jwtToken = Cookies.get("jwtToken");
  const header = {Authorization: `${jwtToken}`}
  
  return axios.get(baseUrl + endpoint, {params:use_params , headers:header} )
  .then((response) => response.data)
  .catch((error) => {
    throw error;
  });
}

export async function api_post_header_token(endpoint, use_params){
  const jwtToken = Cookies.get("jwtToken");
  const header = {
    Authorization: `${jwtToken}`,
    "Content-Type": "application/json",
  }

  return axios.post(baseUrl + endpoint ,use_params , {headers:header})
  .then((response) => response.data)
  .catch(function (error) {
      throw error;
  });
}

export async function api_post_header_token_then(endpoint, use_params){
  const jwtToken = Cookies.get("jwtToken");
  const header = {
    Authorization: `${jwtToken}`,
    "Content-Type": "application/json",
  }

  return axios.post(baseUrl + endpoint ,use_params , {headers:header});
}

export async function api_put_header_token(endpoint, use_params){
  const jwtToken = Cookies.get("jwtToken");
  const header = {Authorization: `${jwtToken}`}

  return axios.put(baseUrl + endpoint ,use_params , {headers:header})
  .then((response) => response.data)
  .catch(function (error) {
      throw error;
  });
} 

export async function api_delete_header_token(endpoint){
  const jwtToken = Cookies.get("jwtToken");
  const header = {Authorization: `${jwtToken}`}
  
  return axios.delete(baseUrl + endpoint, {headers:header})
  .then((response) => response.data)
  .catch(function (error) {
      throw error;
  });
}

export async function api_delete_header_token_then(endpoint){
  const jwtToken = Cookies.get("jwtToken");
  const header = {Authorization: `${jwtToken}`}

  return axios.delete(baseUrl + endpoint, {headers:header})
}

