import React, { useEffect, useState } from 'react';
import heartactive from "../assets/common/heartactive.svg";
import heartbasic from "../assets/common/heartbasic.svg";
import pinkcirclecheck from "../assets/common/pinkcirclecheck.png";
import graycirclecheck from "../assets/common/graycirclecheck.png";
import styled, { ThemeProvider, css } from 'styled-components';
import { StyledFaStar, flexCenterCenter, itemBoxShadow, itemMediaWithStyle } from './Common';
import SubStringText from './SubStringText';
import NumberFormat from './NumberFormat';
import dayjs from "dayjs";

const ItemListComponent = ({
	trash=0,
	deleteCheck=0,
	onClickTrash,
	onClickToggleWish,
	onClick,
	isWish,
	src,
	alt,
	isSoldOut,
	isNowuse,
	isFirstCome,
	startDate,
	color,
	text,
	listAverageScore,
	listScoreCount,
	listSub,
	listSubCategory,
	listDiscountPercent,
	priceNumber,
	discountedPriceNumber,
	shadow,
	isBest,
	index,
	isSpecial,
}) => {
  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth);

	const nowDate = dayjs().format("YYYY/MM/DD HH:mm:ss");
	const nextDate = dayjs().add(1, "day").format("YYYY/MM/DD")

  useEffect(() => {
    const handleResize = () => {
      setDeviceWidth(window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const theme = {
    deviceWidth: deviceWidth
  };
	return (
		<>
			{trash === 1 ? (
					<HeartImg
						src={
							deleteCheck === 1
								? pinkcirclecheck
								: graycirclecheck
						}
						alt="check"
						onClick={onClickTrash}
				/>
				) : (
				isWish ? (
					<HeartImg
						src={heartactive}
						alt="heart"
						onClick={onClickToggleWish}
						isSpecial={isSpecial}
					/>
				) : (
					<HeartImg
						src={heartbasic}
						alt="heart"
						onClick={onClickToggleWish}
						isSpecial={isSpecial}
					/>
				)
			)}

			<ItemDiv onClick={onClick}>
        <ThemeProvider theme={theme}>
          <ItemImg
            src={src}
            alt={alt}
						shadow={shadow}
						isSpecial={isSpecial}
          />
					{isBest && <span className="best-num">{index+1}</span>}
        </ThemeProvider>
				{/* SOLD OUT */}
				{isSoldOut === "Y" ? (
					<SoldOutDiv>
						<div>SOLD OUT</div>
					</SoldOutDiv>
				) : (
					""
				)}
				{/* Now Use */}
				{isNowuse ?
				<NowUseDiv>
					<div>使用期限 {nextDate}</div>
				</NowUseDiv>
					: ""}
				{/* COMING SOON */}
				{isFirstCome && startDate > nowDate &&
					<FirstComeDiv>
						<div className='first-come'>
							<p>Coming Soon</p>
							<p>{startDate}</p>
						</div>
					</FirstComeDiv>
				}
				<ItemTextBoxDiv>
					<TitleBoxDiv>
						<ItemBrandSpan color={color}>
							<SubStringText text={listSubCategory} />
						</ItemBrandSpan>
						<StyledFaStar />
						<span className='score'>{listAverageScore}</span>
						<span className='num'>({listScoreCount})</span>
					</TitleBoxDiv>
					<ItemSubTextDiv color={color}>{listSub}</ItemSubTextDiv>
					<ItemBrand>
						<span>{text}</span>
					</ItemBrand>
					<PriceBoxDiv color={color}>
						<span>{listDiscountPercent}%</span>
						<span className="price"><NumberFormat number={priceNumber} />円</span>
						<span><NumberFormat number={discountedPriceNumber} />円</span>
					</PriceBoxDiv>
				</ItemTextBoxDiv>
			</ItemDiv>

		</>
	);
};

export default ItemListComponent;

export const HeartImg = styled.img`
  border-radius: 0;
  width: 24px;
  position: absolute;
  right: 7%;
  top: 4%;
	${props => props.isSpecial && css`
		right: 9%;
		top: 6%;
  `}
  cursor: pointer;
`;

export const ItemDiv = styled.div`
  width: 100%;
	.best-num {
		background: #000;
		color: #fff;
		font-size: 16px;
		font-weight: 800;
		border-radius: 15px 0;
		position: absolute;
		top: 0;
		left: 0;
		width: 40px;
		height: 40px;
		line-height: 40px;
		text-align: center;
		@media screen and (max-width: 500px) {
			width: 32px;
			height: 32px;
			line-height: 32px;
			font-size: 14px;
		}
	}
`;

export const ItemImg = styled.img`
	${itemBoxShadow}
	${itemMediaWithStyle}
  border-radius: 15px;
  height: 204px;
	${props => props.isSpecial && css`
		padding: 10px;
  `}
  @media screen and (max-width: 500px) {
    height: ${({ theme }) => `calc(${theme.deviceWidth}px * 0.4)`};
  }
  @media screen and (max-width: 355px) {
    height: ${({ theme }) => `calc(${theme.deviceWidth}px * 0.75)`};
    min-height: 200px;
  }
`;

export const ItemTextBoxDiv = styled.div`
  ${itemMediaWithStyle}
  height: 80px;
`;

export const ItemBrandSpan = styled.span`
  font-weight: 700;
  color: ${props => props.color || '#000'};
  margin: 10px 3px 10px 0;
	white-space: nowrap;
	text-overflow: ellipsis;
	max-width: 180px;
	overflow: hidden;
	
	@media screen and (max-width: 500px) {
		font-size: 13px;
	}
	@media screen and (max-width: 430px) {
		font-size: 13px;
		text-overflow: ellipsis;
		overflow: hidden;
	}
`;

export const ItemSubTextDiv = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: ${props => props.color || 'rgba(81, 81, 81, 1)'};
  margin-bottom: 5px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
  word-wrap: break-word;
  text-overflow: ellipsis;
	overflow: hidden;
	line-height: initial;
	@media screen and (max-width: 500px) {
		font-size: 11px;
	}
`;

export const TitleBoxDiv = styled.div`
  width: 105%;
  display: flex;
  align-items: center;
  overflow: hidden;
  font-size: 16px;
  /* border: 1px solid red; */
  @media screen and (max-width: 400px) {
    font-size: 15px;
  }
  @media screen and (max-width: 375px) {
    font-size: 14px;
  }
  @media screen and (max-width: 355px) {
    font-size: 16px;
  }
  .score,
  .num {
    font-size: 14px;
    font-weight: 700;
    @media screen and (max-width: 430px) {
      font-size: 12px;
    }
    @media screen and (max-width: 400px) {
      font-size: 11px;
    }
    @media screen and (max-width: 355px) {
      font-size: 14px;
    }
  }
  .score {
    color: var(--color-main);
    margin-right: 2px;
  }
  .num {
    color: rgba(192, 192, 192, 1);
    margin-left: 2px;
    font-weight: 500;
  }
`;

export const PriceBoxDiv = styled.div`
  span:first-of-type {
    font-weight: 700;
    font-size: 16px;
    color: var(--color-sub);
    margin-right: 5px;
    @media screen and (max-width: 415px) {
      font-size: 14px;
    }
    @media screen and (max-width: 390px) {
      font-size: 13px;
    }
  }
  .price {
    font-size: 12px;
    font-weight: 500;
    color: var(--color-gray3);
    text-decoration: line-through;
    margin-right: 5px;
    @media screen and (max-width: 415px) {
      font-size: 10px;
    }
  }
  span:last-of-type {
    font-weight: 700;
    font-size: 16px;
    color: ${(props) => props.color || "#000"};
    @media screen and (max-width: 415px) {
      font-size: 14px;
    }
    @media screen and (max-width: 390px) {
      font-size: 13px;
    }
  }
`;

export const SoldOutDiv = styled.div`
  ${itemMediaWithStyle}
  height: calc(100% - 80px);
  border-radius: 15px;
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  div {
    ${flexCenterCenter}
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 55px;
    font-size: 24px;
    font-weight: 700;
    color: #fff;
    background: #000;
  }
`;

export const NowUseDiv = styled.div`
  ${flexCenterCenter}
  ${itemMediaWithStyle}
  height: calc(100% - 80px);
  position: absolute;
  top: 0;
  div {
    ${flexCenterCenter}
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 30px;
    font-size: 12px;
    line-height: 30px;
		color: #fff;
    border-radius: 0 0 15px 15px;
		background: var(--color-main);
		font-family: var(--font-jp-regular);
  }
`;

export const FirstComeDiv = styled.div`
  ${itemMediaWithStyle}
  height: calc(100% - 80px);
  border-radius: 15px;
  position: absolute;
  top: 0;
  background: rgba(255, 255, 255, 0.5);
  .first-come {
    ${flexCenterCenter}
    flex-direction: column;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 50%;
    text-align: center;
    color: #000;
    background: var(--color-main);
    p:first-child {
      font-size: 17px;
      font-weight: 700;
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
    }
    p:last-child {
      display: block;
      width: 90%;
      font-weight: 400;
      font-size: 12px;
      color: #000;
      padding-top: 10px;
      border-top: 1px solid #000;
    }
  }
`;

export const ItemBrand = styled.div`
	font-size: 12px;
	color: rgba(192, 192, 192, 1);
	padding-bottom: 3px;
`;