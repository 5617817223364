import React, { useState,useEffect } from 'react';
import searchicon from "../assets/header/searchicon.svg";
import backicon from "../assets/header/backicon.svg";
import carticonheader from "../assets/header/carticon-header.svg";
import { useNavigate } from 'react-router-dom';
import Button from './Button';
import styled from 'styled-components';
import Cookies from "js-cookie";
import { isMember } from '../utils';
import { flexCenterCenter } from './Common';
import { api_get_header_token_then } from '../apis/apis';

const HeaderwithSearch = ({value,onClick}) => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [dataLength, setDataLength] = useState(null);
  
  useEffect(() => {
    setSearchTerm(value);
  }, [value]);
  
  useEffect(() => {
    fetchProductData();
  }, []);

  const fetchProductData = async () => {
    try {
      const token = await isMember();
      if (token.status === "fail") {
        setDataLength(null);
      } else {
        const response = await api_get_header_token_then(`/user/shop-cart/list`);
        if (response && response.data.list.length) {
          const basketList = response.data.list.length;
          setDataLength(basketList);
        }
      }
    } catch (error) {
      throw error;
    }
  };

  const basket = async () => {
    const token = await isMember();
    if(token.status==="fail"){
      Cookies.remove("jwtToken");
      document.cookie = "jwtToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=yourish.jp;";
      navigate(`/sign-in`)
    }else{
      navigate(`/basket`, { state: "basket_active" })
    }
  }
  
  const handleKeyPress = (event) => {
    
    // if (event.key === 'Enter') {
    //   event.preventDefault();
    //   navigate(`/search-results?word=${searchTerm}`);
    // }

    if (event.key === 'Enter') {
      const searchTerm = event.target.value;
  
      const specialCharRegex = /[^\p{L}\p{N}\s]/gu;
  
      if (/^\s*$/.test(searchTerm) || specialCharRegex.test(searchTerm)) {
        alert("特殊文字を除外してください。");
        event.preventDefault(); 
      } else {
        navigate(`/search-results?word=${searchTerm}`);
      }
    }
  };

  const handleGoBack = () => {
    const currentPath = window.location.pathname;

    if (document.referrer.startsWith("https://yourish.jp")) {
      if (currentPath === "/Mypage/mycoupons") {
        navigate("/Mypage");
      } else {
        navigate(-1);
      }
    } else {
      window.location.href = "https://yourish.jp";
    }
  };

  return (
    <StyledHeader>
      <img src={backicon} alt="backicon" width="20px" onClick={handleGoBack} />
      <div>
        <input
          placeholder="Bellelanco"
          type="text"
          maxLength="35"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyPress={handleKeyPress}
        />
        <button className='hide' onClick={onClick}></button>
        <img src={searchicon} alt="searchicon-header" width="16px" />
      </div>
      <Button className="reletive" onClick={basket}>
        <img
          src={carticonheader}
          alt="carticon-header"
          width="28px"
        />
        {dataLength && dataLength > 0 && (
          <div className='number'>{dataLength}</div>
        )}
      </Button>
    </StyledHeader>
  );
};

export default HeaderwithSearch;

const StyledHeader = styled.header`
  max-width: 500px;
  width: 100%;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center; 
  padding: 13px 15px;
  background-color: #fff;
  z-index: 9;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  img {
    cursor: pointer;
  }
  div {
    width: 100%;
    margin: auto 10px auto;
    position: relative;
    img {
      position: absolute;
      left: 2.5%;
      top: 25%;
    }
    input {
      width: 100%;
      padding-left: 2.4rem;
      height: 32px;
      background: #f5f5f5;
      border-radius: 20px;
      border: none;
      flex-grow: 1;
    }
    input::placeholder {
      color: rgba(197, 197, 197, 1);
    }
  }
  .reletive {
    position: relative;
    display: flex;
  }
  .number {
    ${flexCenterCenter}
    position: absolute;
    bottom: 0;
    left: 6px;
    width: 15px;
    height: 15px;
    background: #FF7B9A;
    border: none;
    border-radius: 50%;
    color: #fff;
    font-size: 10px;
  }
`;