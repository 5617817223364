import React, { useRef, useState  } from 'react';
import HeaderWithBack from '../../components/HeaderWithBack';
import styled, { css } from 'styled-components';
import imgsample from '../../assets/common/img-sample.png';
import Button from '../../components/Button';
import FooterMaxButton from '../../components/FooterMaxButton';
import Cookies from "js-cookie";
import AskModal from '../../components/AskModal';
import AskModalOne from '../../components/AskModalOne';
import { useNavigate } from 'react-router-dom';
import { api_get_header_token, api_post_header_token, api_put_header_token } from '../../apis/apis';
import { async } from 'q';

const StyledDiv = styled.div`
  max-width: 500px;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  position: relative;
`;

const NavBox = styled.div`
  display: flex;
  text-align: center;
  flex-direction: row;
  justify-content: flex-start;
  .active {
    border-bottom: 2px solid var(--color-main);
    font-weight: 700;
  }
`;

const TabButton = styled(Button)`
  width: 100%;
  font-size: 13px;
  font-weight: 400;
  line-height: 3;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
`;

const commonStyle = css`
  width: 100%;
  height: 40px;
  border-radius: 15px;
  border: 1px solid #000;
  font-size: 14px;
  font-weight: 700;
  padding-left: 10px;
`;

const ContentWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 85vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
  select {
    ${commonStyle}
  }
  span {
    font-size: 12px;
    font-weight: 400;
    color: var(--color-sub);
    position: absolute;
    top: 62px;
    left: 20px;
  }
  button {
    &:disabled {
      background: var(--color-gray2);
    }
  }
`;

const StyledInput = styled.input`
  ${commonStyle}
`;

const SearchBox = styled.div`
  display: flex;
`;

const SearchButton = styled.button`
  display: inline-block;
  height: 40px;
  background: var(--color-main);
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  border: none;
  border-radius: 15px;
  width: 80px;
  margin-left: 5px;
`;

const StyledTextarea = styled.textarea`
  width: 100%;
  height: 250px;
  border-radius: 15px;
  border: 1px solid #000;
  font-size: 14px;
  font-weight: 400;
  padding: 15px 10px;
  resize: none;
  margin-top: 10px;
`;

const PhotoBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  label {
    width: 30%;
    height: 110px;
    border: 1px solid #000;
    border-radius: 15px;
    cursor: pointer;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      &.empty {
        width: 50px;
      }
      width: 100%;
    }
  }
  input {
    display: none;
  }
`;

const Inquiry = () => {
  const [activeTab, setActiveTab] = useState("mall");
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <StyledDiv>
      <HeaderWithBack text={"お問い合わせ"} />
      <NavBox>
        <TabButton
          className={activeTab === "mall" ? "active" : ""}
          onClick={() => handleTabClick("mall")}
        >
          ショッピング
        </TabButton>
        <TabButton
          className={activeTab === "productreturn" ? "active" : ""}
          onClick={() => handleTabClick("productreturn")}
        >
          返品
        </TabButton>
      </NavBox>
      {activeTab === "mall" && <Mall />}
      {activeTab === "productreturn" && <ProductReturn />}
    </StyledDiv>
  );
};

const Mall = () => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState("selected");
  const [textareaValue, setTextareaValue] = useState("");
  const [modal, setModal] = useState(false);
  const [checkModal, setCheckModal] = useState(false);

  const jwtToken = Cookies.get("jwtToken");
  
  const onCancel = () => {
    navigate('/Mypage');
  };

  const checkConfirm = () => {
    setCheckModal(false);
  };

  const handleTextareaChange = (event) => {
    setTextareaValue(event.target.value);
  };

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

 const showModal = () => {
    if(selectedOption==="selected" || textareaValue===""){
      setCheckModal(true);
    } else {
      setModal(true);
    }
  };

  const handleSubmit =async () => {
   
   
      const params_submit = {
      
          type : selectedOption,
          contents : textareaValue,
          open : "Y"
      
      }

      const result = await api_post_header_token(`/post/shop-qna-write`, params_submit);
      if(result.status==="ok"){
        navigate("/mypost");
      }else{
        //error
      }
    
   
  }
  
  return (
    <>
      <ContentWrapper>
        <select value={selectedOption} onChange={handleSelectChange} placeholder='ショッピングに関するお問い合わせ'>
          <option value="selected" disabled>ショッピングに関するお問い合わせ</option>
          <option value="orderPayment">注文決済</option>
          <option value="shipping">配送</option>
          <option value="membership">メンバーシップ</option>
          <option value="exchangeReturnRefund">交換・返品・返金</option>
          <option value="etc">その他</option>
        </select>
        <StyledTextarea value={textareaValue}
          onChange={handleTextareaChange}/>
        <FooterMaxButton text={"お問い合わせ作成"} onClick={showModal} />
      </ContentWrapper>
      <AskModal
        visible={modal}
        title="お問い合わせ作成成功!&#13;&#10;確認しますか？"
        confirmText='はい'
        cancelText='いいえ'
        onConfirm={handleSubmit}
        onCancel={onCancel}
      />
      <AskModalOne
        visible={checkModal}
        title="正しい情報を入力してください"
        confirmText='はい'
        onConfirm={checkConfirm}
      />
    </>
  )
}

const ProductReturn = () => {
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState('');
  const [input1, setInput1] = useState('');
  const [input2, setInput2] = useState();
  const [input3, setInput3] = useState('');
  const [files, setFiles] = useState([]);
  const [isSearchBoxReadOnly, setIsSearchBoxReadOnly] = useState(false);
  const [check,setCheck] = useState("N");
  const [modal, setModal] = useState(false);
  const inputRef = useRef(null);
  const isInputValid = inputValue.length === 20;
  const isButtonEnabled = isInputValid && files && input3;
  const jwtToken = Cookies.get("jwtToken");
   
  const onClick = () => {
    setModal(true);
  }

  const onCancel = () => {
    navigate('/Mypage');
  };

  const handleFileChange = (e) => {
    const uploadedFiles = Array.from(e.target.files);
    // Only keep the first three files
    const newFiles = uploadedFiles.slice(0, 3);
    setFiles([...files, ...newFiles]);
    setInput2(e.target.value);
  };

  const deleteFile = (index) => {
    const newFiles = [...files];
    URL.revokeObjectURL(URL.createObjectURL(newFiles[index]));
    newFiles.splice(index, 1);
    setFiles(newFiles);
  }

  const handleInput3Change = (e) => {
    setInput3(e.target.value);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleNumSubmit = (e) => {
    setInput1(e.target.value);
    const spanElement = document.getElementById("non");
    const orderIdCheck = async () => {
      const result = await api_get_header_token(`/order/refund-check?orderId=${inputValue}`);
      if (result.status==="ok"){
        spanElement.textContent = "";
        setIsSearchBoxReadOnly(true);
        setCheck("Y");
      } else {
        spanElement.textContent = "*存在しない注文番号です。";
      }
    }
    orderIdCheck();
  };

  const handleSubmit = () => {

    if (files.length >= 1 && check=="Y") {
      const refundWrite = async () => {
        const params_data = {
         
            orderId : inputValue,
            refundContents : input3
          
        }
        const data = await api_post_header_token(`/order/refund-write`, params_data);
        const seq = data.message;
        if(data.status==="ok"){
          const formData = new FormData();
          for (let i = 0; i < files.length; i++) {
            const file = files[i];
            formData.append('images', file);
          }
         
          const data = await api_put_header_token(`/order/refund-photo-write/${seq}`, formData);
          if(data.status==="ok"){
            navigate('/mypost');
          }
          else if(response.message==="image error"){
            //이미지 오류
          }
        }
        else {
            //api 오류
        }
      };
      refundWrite();
    }
  };

  return (
    <>
      <ContentWrapper>
        <SearchBox>
          <StyledInput type='text' readOnly={isSearchBoxReadOnly} value={inputValue} onChange={handleInputChange} placeholder='注文番号を入力してください' />
          <SearchButton onClick={handleNumSubmit} disabled={!isInputValid}>検索</SearchButton>
        </SearchBox>
        <span id="non"></span>
        <PhotoBox>
          <label>
            {/* <img className='empty' src={imgsample} alt='imgsample' /> */}
            {files[0] ? <img src={URL.createObjectURL(files[0])} onClick={() => deleteFile(0)} alt='thumbnail' /> : <><img onClick={() => inputRef.current.click()} className='empty' src={imgsample} alt='imgsample' /></> }
          </label>
          <label>
            {/* <img className='empty' src={imgsample} alt='imgsample' /> */}
            {files[1] ? <img src={URL.createObjectURL(files[1])} onClick={() => deleteFile(1)} alt='thumbnail' /> : <><img onClick={() => inputRef.current.click()} className='empty' src={imgsample} alt='imgsample' /></> }
          </label>
          <label>
            {/* <img className='empty' src={imgsample} alt='imgsample' /> */}
            {files[2] ? <img src={URL.createObjectURL(files[2])} onClick={() => deleteFile(2)} alt='thumbnail' /> : <><img onClick={() => inputRef.current.click()} className='empty' src={imgsample} alt='imgsample' /></> }
          </label>
          <input
            id='inputTag'
            type='file'
            value={input2}
            accept="image/jpg, image/png, image/jpeg"
            ref={inputRef}
            onChange={handleFileChange}
            multiple={true}
          />
        </PhotoBox>
        <StyledTextarea value={input3} onChange={handleInput3Change} />
        <FooterMaxButton onClick={onClick} disabled={!isButtonEnabled} text={"お問い合わせ"} />
      </ContentWrapper>
      <AskModal
        visible={modal}
        title="お問い合わせ作成成功!&#13;&#10;確認しますか？"
        confirmText='はい'
        cancelText='いいえ'
        onConfirm={handleSubmit}
        onCancel={onCancel}
      />
    </>
  )
}

export default Inquiry;
