import React, { useEffect, useState } from 'react';
import HeaderWithBack from "../components/HeaderWithBack";
import RecentListProduct from "../components/RecentListProduct";
import wishlistdeletebox from "../assets/common/wishlistdeletebox.png";
import styled from "styled-components";
import trash from "../assets/common/trash.svg";
import NavBasic from "../components/NavBasic";
import Cookies from "js-cookie";
import { useQuery } from "react-query";
import Loading from './Loading';
import ErrorPage from './ErrorPage';
import { RootWrapper } from '../components/Common';
import { api_get_header_token } from '../apis/apis';
import { fetchMainData } from '../utils';
import noRecentView from "../assets/no_recent_view.png";

function RecentView() {
  const [isTrashClicked, setIsTrashClicked] = useState(false);
  const { isLoading, isError, data, refetch } = useQuery("mainData", fetchMainData);

  const handleTrashClick = () => {
    setIsTrashClicked(true);
  };
  const [deleteButton, setDeleteButton] = useState(false);
  const handleTrashClick2 = () => {
    setDeleteButton(true);
  };
  const checkButton = () => {
    setDeleteButton(false);
    setIsTrashClicked(false);
    refetch();
  }
  const jwtToken = Cookies.get("jwtToken");

  return (
    <RootWrapper>
      <StyledDiv>
        <HeaderWithBack text={"最近観覧した商品"}></HeaderWithBack>
        <div className="interest between">
          <span></span>
          {isTrashClicked ? (
            <img src={wishlistdeletebox} alt="wishlistdeletebox" onClick={handleTrashClick2} />
          ) : (
            <img src={trash} alt="trash" width="28px" onClick={handleTrashClick} />
          )}
        </div>
        {isLoading && <Loading />}
        {isError && <ErrorPage />}
        {data && (
          <RecentListProduct
            margintop="0px"
            isNowuse={false}
            isFirstCome={false}
            isTimedeal={false}
            recent={true}
            data={data.recent}
            trash={isTrashClicked ? 1 : 0}
            deleteButton={deleteButton ? 1 : 0}
            checkButton={checkButton}
          />
        )}
        {data?.recent.length < 1 &&
          <EmptyBox>
            <img src={noRecentView} alt="no-recent-view" />
          </EmptyBox>
        }
      </StyledDiv>
      <NavBasic />
    </RootWrapper>
  );
}

export default RecentView;

const StyledDiv = styled.div`
  padding-bottom: 90px;
  .interest {
    width: 90%;
    font-weight: 400;
    font-size: 14px;
    align-items: flex-end;
    color: var(--color-gray3);
    margin: 10px auto -5px;
  }
`;
export const EmptyBox = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  p {
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    padding-bottom: 10px;
  }
  span {
    font-size: 15px;
    font-family: var(--font-jp-light);
    white-space: nowrap;
    color: #525252;
  }
`;
