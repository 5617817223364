import React, { useState,useEffect } from "react";
import styled from "styled-components";
import lockicon from "../../assets/common/lockicon.svg";
import QuestionFixed from "../../components/QuestionFixed";
import SubStringText from "../../components/SubStringText";
import { ContentWrapper, DateSpan, DivFlexBetween, flexCenterCenter } from "../../components/Common";
import noQnAImg from "../../assets/no_qna.png";

const ItemQuestion = ({data, proSeq}) => {
  return (
    <>
      <StyleDiv>
      {data && data.length > 0 ? (
        data.map((list, index) => (
          <QuestionBox key={index}>
            <QuestionTitleDiv>
              <DivFlexBetween>
                <div className="title">
                  {list.open === 'N' ? (
                    <img className="lock-icon" src={lockicon} alt="lockicon" width="13px" />
                  ) : <></>}
                  <span className="nickname">{list.questionNickname}</span>
                  {/* <AnswerStateTag>回答完了</AnswerStateTag> */}
                  {list.answerContents === "" ? <></> : <AnswerStateTag>回答完了</AnswerStateTag>}
                </div>
                {/* <DateSpan>{list?.questionSignDate?.substring(0, 10)}</DateSpan> */}
                <DateSpan>{list?.questionSignDate}</DateSpan>
              </DivFlexBetween>
              
              <div className="contents">
                {list.open === 'N' ? (
                  "プライベートな投稿です。"
                ) : ""}
              </div>
            </QuestionTitleDiv>

            {list.open === 'Y' && (
              <AnswerBox>
                <div className="question">{list.questionContents}</div>
                <div className="answer">
                  A. {list.answerContents === "" ? "回答未登録" : list.answerContents + " - "}
                <span className="answer-date"><SubStringText text={list.answerSignDate} truncatelength={15} /></span>
                </div>
              </AnswerBox>
            )}
          </QuestionBox>
        ))
      ) : (
        <EmptyBox>
          <img src={noQnAImg} alt="qna" />
        </EmptyBox>
       )}
      </StyleDiv>
      <QuestionFixed productSeq={proSeq}/>
    </>
  );
}

export default ItemQuestion;


const StyleDiv = styled.div`
  height: 100%;
  background-color: #fff;
  margin-bottom: 6rem;
  max-width: 500px;
  width: 100%;
  position: relative;
`;

const QuestionBox = styled.div`
  margin: 10px auto;
  width: 90%;
  padding: 10px 10px 5px 10px;
  border: 2px solid #85dbd7;
  border-radius: 20px;
`;

const QuestionTitleDiv = styled.div`
  font-family: var(--font-jp-regular);
  .title{
    display: flex;
    align-items: center;
  }
  .lock-icon {
    margin-left: 5px;
  }
  .nickname {
    font-size: 14px;
    color: var(--color-gray3);
    margin-left: 5px;
  }
  .contents {
    font-size: 12px;
    color: var(--color-gray4);
    margin: 10px 5px;
  }
`;

const AnswerBox = styled.div`
  font-family: var(--font-jp-regular);
  .question {
    font-size: 13px;
    color: var(--color-gray4);
    padding-bottom: 10px;
    margin: 0 5px;
    border-bottom: 1.5px solid var(--color-main);
  }
  .answer {
    font-size: 13px;
    color: var(--color-sub);
    margin: 10px 5px;
  }
  .answer-date {
    font-size: 12px;
    margin-left: 10px;
  }
`;

const AnswerStateTag = styled.div`
  ${flexCenterCenter}
  width: 55px;
  height: 18px;
  font-size: 10px;
  color: var(--color-sub);
  border: 1px solid var(--color-sub);
  border-radius: 10px;
  margin-left: 10px;
`;

export const EmptyBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    margin-top: 20px;
  }
`;