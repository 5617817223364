import React, { useState } from 'react';
import {GoogleLogin} from "@react-oauth/google";
import {GoogleOAuthProvider} from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import jwt_decode from 'jwt-decode'
import AskModal from '../components/AskModal';
import Cookies from "js-cookie";
import googlelogo from "../assets/logos/googlelogo.svg";
import AskModalOne from '../components/AskModalOne';
const StyledDiv = styled.div`
  div {
    display: none;
  }
`;

const GoogleButton = () => {
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [info, setInfo] = useState({});
  const navigate = useNavigate();
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const baseUrl = process.env.REACT_APP_BASEURL;

  const onConfirm2 = async () => {
    setModal2(false);
  }
  
  const handleOnConfirm = async () => {
    const response = await fetch(`${baseUrl}/user/sign-up`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email:info.email,
        username:info.name,
        nickname:info.name,
        password:"",
        provider:"google",
        pushMessage:"Y",
        receiveEmail:"Y"
      }),
    });
    const result = await response.json();
    if(result.status==="ok"){
      login(info.email);
    }
    setModal(false);
  };

  const handleOnCancel = () => {
    setModal(false);
  };

  const login = async (email) => {
    const response = await fetch(`${baseUrl}/user/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email:email,
        provider:"google",
      }),
    });
    const result = await response.json();
    if(result.status==="ok"){
      const accessToken = result.access_token;
      if (window.ReactNativeWebView != undefined){
        window.ReactNativeWebView.postMessage(JSON.stringify({"seq":result.seq}))
      }
      Cookies.set('jwtToken', accessToken);
      navigate('/');
    }
  }

  const onClickGooglelogin = () => {
    document.getElementById('button-label').click();
    
  }
  
  return (
    <>
      <StyledDiv>
        <GoogleOAuthProvider clientId={clientId}>
          <GoogleLogin
            onSuccess={(res) => {
              
              let userInfo = jwt_decode(res.credential);
              setInfo(userInfo)
              fetch(`${baseUrl}/user/sign/google?email=${userInfo.email}`, {
                method: 'GET',
              })
              .then(response => response.json())
              .then(data => {
                if(data.status==="ok"){
                  setModal(true);
                }else if(data.message === "exist"){
                  setModal2(true);
                }
                else{
                  login(userInfo.email);
                }
              })
              .catch(error => {
                console.error('Error:', error);
              });
            }}
            onFailure={(err) => {
              throw err;
            }}
          />
        </GoogleOAuthProvider>
      </StyledDiv>

      <button className='login' onClick={()=>onClickGooglelogin()}>
        <img
          src={googlelogo}
          alt="googlelogo"
          width="18px"
          className="icon line"
        />
        Sign in with Google
      </button>
      <AskModalOne
        visible={modal2}
        title="すでに登録されているメールがあります。"
        confirmText='はい'
        onConfirm={onConfirm2}
      />
      <AskModal
        visible={modal}
        title="登録されたメールはありません。会員登録しますか？"
        confirmText='はい'
        cancelText='いいえ'
        onConfirm={handleOnConfirm}
        onCancel={handleOnCancel}
      />
    </>
  );
};

export default GoogleButton;