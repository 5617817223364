import React from "react";
import HeaderWithBack from "../../components/HeaderWithBack";
import ListMainProduct from "../../components/ListMainProduct";
import newarrivalbanner from "../../assets/banners/newarrival.png";
import { useQuery } from "react-query";
import { fetchProductsData } from "../../utils";
import BannerBox from "../../components/BannerBox";
import StyledP from "../../components/StyledP";
import Loading from "../Loading";
import ErrorPage from "../ErrorPage";
import { RootWrapper } from "../../components/Common";
import NavBasic from "../../components/NavBasic";
function NewProduct() {
  const { isLoading, isError, data } = useQuery(
    "product-new",
    fetchProductsData
  );
  return (
    <RootWrapper>
      <HeaderWithBack eng="true" text="NEW ARRIVAL"></HeaderWithBack>
      <BannerBox>
        <img src={newarrivalbanner} alt="newarrivalbanner" />
        <StyledP bold>あなたの肌に明るい日々を与える新商品</StyledP>
      </BannerBox>
      {isLoading && <Loading />}
      {isError && <ErrorPage />}
      {data && (
        <ListMainProduct isNowuse={false} isTimedeal={false} data={data} marginBottom />
      )}
      <NavBasic/>
    </RootWrapper>
  );
}

export default NewProduct;
