import { useState,useEffect } from 'react';
import { ContentWrapper, RootWrapper } from '../../components/Common';
import HeaderWithBack from '../../components/HeaderWithBack';
import styled from 'styled-components';
import Cookies from "js-cookie";
import Button from '../../components/Button';
import { api_get_header_token } from '../../apis/apis';
import NavBasic from '../../components/NavBasic';
import SubStringText from '../../components/SubStringText';
import NumberFormat from '../../components/NumberFormat';
const StyledLi = styled.li`
  position: relative;
  width: 100%;
  height: 148px;
  border: 2px solid var(--color-main);
  border-radius: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 0 20px;
  .thumbnail {
    min-width: 90px;
    width: 90px;
    height: 90px;
    text-align: center;
    background: var(--color-main);
    border: none;
    border-radius: 50%;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .date {
    position: absolute;
    bottom: 10px;
    right: 15px;
    font-size: 12px;
    font-weight: 700;
    color: var(--color-gray3);
  }
`;

const TextBox = styled.div`
  font-size: 14px;
  color: var(--color-gray4);
  p {
    margin: 7px 0 5px 20px;
  }
`;

const WaitPayment = () => {
  const [info,setInfo] = useState([]);
  const jwtToken = Cookies.get("jwtToken");
  
  const beforePayment = async () => {
    const result = await api_get_header_token(`/before-payment-lookup`);

    if(result.status!="fail"){
      setInfo(result.list);
    }else{
      //비회원
    }
  }

  useEffect(() => {
    beforePayment();
  }, []);

  return (
    <RootWrapper>
      <HeaderWithBack text={"支払い待ち"} />
      <ContentWrapper>
        <ul>

          {/* .map */}

          {info && info.length > 0 ? (
            info.map((item, i) => (
            <StyledLi key={i}>
              <div className='thumbnail'>{item.store}</div>
              <TextBox>
                <p><Button to={item.instructionsUrl}>
                  <SubStringText text={item.instructionsUrl} truncatelength={25} />
                </Button></p>
                <p>合計価格 | <NumberFormat number={item.amount} />円</p>
                <p>注文ID | {item.title}</p>
              </TextBox>
              <div className='date'>支払い期限 {item.deadline}</div>
            </StyledLi>
            ))
          ) : (
            <TextBox>
            <p>決済待機商品はありません。</p>
            </TextBox>
            )}

        </ul>
      </ContentWrapper>
      <NavBasic/>
    </RootWrapper>
  );
};

export default WaitPayment;