import React, { useEffect } from "react";
import styled from "styled-components";
import plus from "../../assets/common/plus.png"
import { useState } from "react";
import close from "../../assets/common/close-maincolor.png"
import { DivFlexBetween, StyledFaStar, StyledRate, flexCenterCenter, scrollStyle } from "../../components/Common";
import SubStringText from "../../components/SubStringText";
import Button from "../../components/Button";
import noReviewImg from "../../assets/no_review.png";
import { EmptyBox } from "./ItemQuestion";
import { useLocation } from "react-router-dom";

const ItemReview = ({ data,imgList }) => {

  const location = useLocation();

  const reviewSeq = location.state?.reviewSeq || null;

  const [zoomIn, setZoomIn] = useState(null);
  const [showDetail, setshowDetail] = useState(false);
  const [showPhotos, setshowPhotos] = useState(false);
  const [selectedReviewIndex, setSelectedReviewIndex] = useState({});

  useEffect(() => {
    if(reviewSeq && data.length > 0) {
      handleShowDetail(reviewSeq);
    }
  }, [reviewSeq, data]);

  const handleShowDetail = (postSeq) => {
    setshowPhotos(false);
    const selectedData = data.find((list) => list.postSeq === postSeq);
    setSelectedReviewIndex(selectedData);
    setshowDetail(true);
  };

  const handleClose = () => {
    setshowDetail(false);
    setshowPhotos(false);
  };
  
  const handleCloseZoomIn = () => {
    setZoomIn(false);

  }

  const handleshowPhotos = () => {
    setshowPhotos(true);
  };

  const handleZoomIn = (index) => {
    setZoomIn(index);
  }

  return (
    <>
      <StyleDiv>
        { imgList && imgList?.length > 0 ? 
          <PhotoGridWrapper>
          
            <GridContainerUl>
              {imgList && imgList.slice(0, 6).map((list, index) => (
              <GridItemLi key={index}>
                <img onClick={()=>handleZoomIn(index)} src={list.reviewImages} alt="review-image"></img>
                  
              </GridItemLi>
              ))}
            </GridContainerUl>
          

            <div onClick={handleshowPhotos}>
              <img className="more-view" src={plus} alt="plus" />
            </div>
          </PhotoGridWrapper>
        : (
          <></>
        )}

        
        
        {data && data?.length > 0 ? data.map((list, index) => (
          <ReviewWrapperDiv key={index} onClick={()=>handleShowDetail(list.postSeq)}>
            <DivFlexBetween>
              <RateDiv>
                <StyledRate
                  allowHalf
                  disabled
                  small
                  value={list.score}
                />
                <p>{list.score}</p>
                <span className="nickname">{list.nickname}</span>
              </RateDiv>
              {/* <div className="date">{list.signDate.substring(0, 10)}</div> */}
              <div className="date">{list.signDate}</div>
            </DivFlexBetween>
            <ReviewTextDiv>
              <p><SubStringText text={list.contents} truncatelength={90} /></p>
              {list.reviewState === "photo" && (
                <img src={list.reviewImage[0].reviewImageUrl} alt='sample' />
              )}
              {list.reviewState === "simple" && (
                <></>
              )}
            </ReviewTextDiv>
          </ReviewWrapperDiv>
        ))
      :(
        <EmptyBox>
          <img src={noReviewImg} alt="no-review" />
        </EmptyBox>
      )}

      {(zoomIn === 0 || zoomIn > 0 ) && (
        <ZoomInFullscreen onClick={handleCloseZoomIn}>
          <div>
            <img className="zoom-in" src={imgList[zoomIn]?.reviewImages} alt="review-image" />
          </div>
          <GoReviewBtn onClick={()=>handleShowDetail(imgList[zoomIn]?.postSeq)}>該当する写真のレビューを見に行く </GoReviewBtn>
        </ZoomInFullscreen>
      )}

      {showDetail &&
        <Fullscreen>
          {selectedReviewIndex && (
            <DetailBox>
              <img onClick={handleClose} className='close' src={close} alt='close' />
              <DetailPhotoDiv>
              {selectedReviewIndex && selectedReviewIndex.reviewImage.map((img, idx) => (
                <img onClick={() => handleZoomIn(img.reviewImageUrl)} key={idx} src={img.reviewImageUrl} alt='sample' />
              ))}
              </DetailPhotoDiv>
              <DetailTitleDiv>
                <div className="flexdiv">
                  <RateDiv>
                    <StyledRate
                      allowHalf
                      disabled
                      small
                      value={selectedReviewIndex.score}
                    />
                    <p>{selectedReviewIndex.score}</p>
                  </RateDiv>
                  <span className="user">{selectedReviewIndex?.nickname}</span>
                </div>
                {/* <div className="date">{selectedReviewIndex?.signDate?.substring(0, 10)}</div> */}
                <div className="date">{selectedReviewIndex?.signDate}</div>
              </DetailTitleDiv>
              <div className="contents">
                {selectedReviewIndex?.contents}
              </div>
            </DetailBox>
          )}
          { zoomIn && (
            <ZoomInFullscreen onClick={handleCloseZoomIn}>
              <div>
                <img className="zoom-in" src={zoomIn} alt="review-image" />
              </div>
            </ZoomInFullscreen>
          )}
        </Fullscreen>
      }
      {showPhotos && 
        <Fullscreen>
          <PhotosWrapper>
          {/* <img onClick={handleClosePhotos} className='photo-close' src={close} alt='close' /> */}
          <img onClick={handleClose} className='close' src={close} alt='close' />
            <GridContainerUl>
            {imgList && imgList.map((list, index) => (
              <GridItemLi key={index}>
                <img onClick={() => handleZoomIn(index)} src={list.reviewImages}></img>
              </GridItemLi>
            ))}
            </GridContainerUl>
        
          </PhotosWrapper>
        </Fullscreen>
      }
      </StyleDiv>
    </>
  );
}

export default ItemReview;


 
const StyleDiv = styled.div`
  position: relative;
  background-color: #fff;
  max-width: 500px;
  width: 100%;
  padding-bottom: 100px;
`;

const PhotoGridWrapper = styled.div`
  position: relative;
  width: 90%;
  min-height: 100px;
  margin: 5px auto 30px;
  .more-view {
    width: 70px;
    height: 70px;
    position: absolute;
    bottom: -10px;
    right: -10px;
    cursor: pointer;
  }
`;

const ReviewWrapperDiv = styled.div`
  width: 90%;
  margin: 10px auto;
  background-color: #fff;
  min-height: 100px;
  padding: 15px;
  border: 2px solid #85dbd7;
  border-radius: 20px;
  cursor: pointer;
  .nickname {
    font-size: 12px;
    color: var(--color-gray3);
    margin-left: 5px;
  }
  .date {
    font-size: 12px;
    color: var(--color-gray3);
  }
`;

const ReviewTextDiv = styled.div`
  display: flex;
  justify-content: space-between;
  p {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.6;
    margin: 10px 0;
    color: var(--color-gray3);
  }
  img {
    margin: 15px 0 0 8px;
    min-width:35%;
    max-height: 150px;
    object-fit: cover;
    border-radius: 5px;
  }
`;

const ZoomInFullscreen = styled.div`
${flexCenterCenter}
flex-direction: column;
position: fixed;
top: 0;
/* left: 50; */
max-width: 500px;
width: 100%;
height: 100%;
color: #fff;
background: rgba(0, 0, 0, 0.8);
z-index: 101;
.zoom-in {
  width: 450px;
  border-radius: 5px;
  @media screen and (max-width: 500px) {
  width: 90vw;
}
}
@media screen and (max-width: 500px) {
  max-width: 100%;
  left: 0;
}
`;

const Fullscreen = styled.div`
  position: fixed;
  top: 0;
  left: 50;
  max-width: 500px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  @media screen and (max-width: 500px) {
    max-width: 100%;
    left: 0;
  }
`;

const DetailBox = styled.div`
  ${scrollStyle}
  position: relative;
  width: 90%;
  max-height: 80vh;
  padding: 40px 20px;
  color: var(--color-gray3);
  background: #fff;
  border: 2px solid var(--color-main);
  border-radius: 20px;
  margin: 10px 0;
  .close{
    position: absolute;
    width: 20px;
    height: 20px;
    top: 12px;
    right: 15px;
    cursor: pointer;
  }
  .contents {
    line-height: 1.3;
    font-size: 15px;
  }
`;


const DetailTitleDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
  .flexdiv {
    display: flex;
    align-items: center;
  }
  .user {
    margin-left: 10px;
    font-size: 12px;
  }
  .date {
    font-size: 12px;
    margin-left: 5px;
  }
`;

const DetailPhotoDiv = styled.div`
  display: flex;
  justify-content: center;
  overflow: cover;
  margin: 0;
  img {
    width: 48%;
    min-height: 100px;
    max-height: 250px;
    margin-bottom: 5px;
    border-radius: 5px;
    &:nth-child(2n) {
      margin-left: 5px;
    }
  }
`;

const PhotosWrapper = styled.div`
  ${scrollStyle}
  position: relative;
  width: 90%;
  background: #fff;
  border-radius: 10px;
  height: 85vh;
  padding: 35px 10px;
  z-index: 999;
  .close{
    position: absolute;
    width: 20px;
    height: 20px;
    top: 12px;
    right: 15px;
    cursor: pointer;
  }
`;

const GridContainerUl = styled.ul`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border: none;
  position: relative;
  justify-content: flex-start;
`;

const GridItemLi = styled.li`
  ${flexCenterCenter}
  width: calc(96% / 3);
  height: 125px;
  border: none;
  overflow: hidden;
  margin: 0 5px 5px 0;
  img {
    width: 100%;
    min-height: 100%;
    object-fit: cover;
  }
  &:nth-child(3n) {
    margin-right: 0;
  }
`;

const EmptyTextDiv = styled.div`
  ${flexCenterCenter}
  margin-top: 10px;
`;

const GoReviewBtn = styled(Button)`
  font-family: var(--font-jp-bold);
  font-size: 12px;
  margin-top: 5px;
`;

const RateDiv = styled.div`
  display: flex;
  align-items: center;
  p {
    font-weight: 500;
    margin-left: 3px;
    color: var(--color-main);
  }
`;