import React, { useRef } from 'react';
import styled from 'styled-components';
import { useState } from 'react';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ContentWrapper, MarginBottomBox, StyledRate } from './Common';
import FooterMaxButton from './FooterMaxButton';
import { api_get_header_token, api_post_header_token, api_put_header_token } from '../apis/apis';

const ReviewWrapper = styled.div`
  display: block;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 20px;
  padding: 10px 15px;
  margin: 10px auto;
`;

const ItemBox = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Img = styled.div`
  display: inline-block;
  background: #d9d9d9;
  border-radius: 20px;
  width: 80px;
  height: 80px;
  overflow: hidden;
  img {
    display: block;
    width: 100%;
    border-radius: 20px;
  }
`;

const TextBox = styled.div`
  display: inline-block;
  width: 90%;
  margin: 3px 0 3px 10px;
  @media screen and (max-width: 768px) {
    width: 80%;
  }
  .itemnum_date {
    display: flex;
    justify-content: space-between;
    color: var(--color-gray3);
    font-size: 10px;
    margin-bottom: 7px;
  }
  .wrap {
    display: flex;
    justify-content: space-between;
    height: 55px;
  }
  .detail-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* min-width: 160px; */
    width: 100%;
    p {
      font-size: 12px;
      line-height: 1.5;
      font-weight: 400;
    }
    p:first-child {
      font-weight: 700;
    }
    p:last-child {
      color: var(--color-gray3);;
      font-size: 10px;
    }
  }
`;

const ReviewBox = styled.div`
  width: 100%;
`;

const ScopeBox = styled.div`
  margin: 10px auto;
  p {
    font-size: 16px;
  }
  img {
    margin: 10px 3px;
  }
`;

const Stars = styled.div`
  padding-top: 10px;
`;

const WriteBox = styled.div`
  margin: 10px auto;
  div {
    display: flex;
    align-items: center;
    span {
      font-size: 10px;
      font-weight: 400;
      color: var(--color-sub);
      margin-left: 5px;
    }
  }
  textarea {
    width: 100%;
    height: 10rem;
    background: var(--color-gray2);
    border: none;
    border-radius: 20px;
    font-size: 12px;
    line-height: 1.6;
    padding: 15px;
    resize: none;
  }
  .font {
    font-family: var(--font-jp-regular);
    font-size: 12px;
    line-height: 13px;
  }
`;

const StyledP = styled.p`
  font-size: 20px;
  font-weight: 700;
  margin: 15px 0;
`;

const PhotoBox = styled.div`
  margin: 20px 0;
  label {
    width: 110px;
    height: 130px;
    background: var(--color-gray2);
    border-radius: 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    color: #fff;
    margin-right: 10px;
    overflow: hidden;
    img {
      width: 100%;
    }
  }
  input {
    /* display: none; */
  }
`;

const Photos = styled.div`
  width: 100%;
  display: flex;
`;

const Thumbnail = styled.div`
  width: 100px;
  height: 100px;
  background: var(--color-gray3);
`;


const WriteSimpleReviewForm = () => {
  const {state} = useLocation();
  const [data, setData] = useState({});
  const [files, setFiles] = useState([]);
  const [rating, setRating] = useState(0);
  const inputRef = useRef(null);
  const navigate = useNavigate();

  const productInfo = async () => {
    const url = `/review/product-info/${state}`
    const responseData = await api_get_header_token(url);
    setData(responseData);
  };

  const handleFileChange = (e) => {
    const uploadedFiles = Array.from(e.target.files);
  
    // Only keep the first three files
    const newFiles = uploadedFiles.slice(0, 3);
  
    setFiles([...files, ...newFiles]);
  };


  useEffect(() => {
    productInfo();
  },[]);

  const deleteFile = (index) => {
    const newFiles = [...files];
    URL.revokeObjectURL(URL.createObjectURL(newFiles[index]));
    newFiles.splice(index, 1);
    setFiles(newFiles);
  }

  function changeRating(selectedValue) {
    setRating(selectedValue);
  }

  const writeButton = () => {
    const review = document.querySelector("textarea").value;
    let score = 0;
    score = rating;

    if(review.length < 5 || files.every(file => !file) || score === 0){
      alert("レビューランクとレビューを正しく記入してください。")
      return;
    }

    const fetchReviewList = async () => {
      const params_review = {
        
          score: score,
          contents : review,
          orderSeq : state,
          reviewState : "photo"
       
      }
     
      const data = await api_post_header_token(`/review/write`, params_review);
      const seq = data.message;
      if(data.status==="ok"){
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          formData.append('images', file);
        }
       
        const data = await api_put_header_token(`/review/photo-write/${seq}`, formData);
        if(data.status==="ok"){
          navigate(`/mypage/myreviews`);
        
        }
        else if(data.message==="image error"){
          return
        }

      }
      else if(data.message==="before delivery"){
          
      }
      else if(data.message==="no permission"){
        navigate(`/mypage/myreviews`);
      }
      
    };
    fetchReviewList();
    
  };
  

  return (
    <>
      <ReviewWrapper>
      {data.list && (
          <ItemBox>
          <Img>
            <img src={data.list.productImage}/>
          </Img>
          <TextBox>
            <div className='itemnum_date'>
              <p>{data.list.orderId}</p>
              <p>{data.list.orderDate}</p>
            </div>
            <div className="wrap">
              <div className="detail-box">
                <p>{data.list.productTitle}</p>
                <p>
                  {data.list.options} / {data.list.quantity}
                </p>
              </div>
            </div>
          </TextBox>
        </ItemBox>
        )}
        <ReviewBox>
          <ScopeBox>
            <p>商品の満足度はいかがですか？</p>
            <Stars>
              <StyledRate
                edit="true"
                allowHalf
                onChange={(e)=>changeRating(e)}
              />
            </Stars>
          </ScopeBox>
          <WriteBox>
            <div>
              <StyledP>利用者の声</StyledP><span className="font">3行以上記入してください</span>
            </div>
            <textarea
              required
              placeholder="レビューを書いてください。"
              maxlenth="150"
              minLength="5"
            />
          </WriteBox>
          <PhotoBox>
            <StyledP>PHOTO REVIEW</StyledP>
            <Photos>
            <label>
              {files[0] ? <img src={URL.createObjectURL(files[0])} onClick={() => deleteFile(0)} alt='thumbnail' /> : <><span onClick={() => inputRef.current.click()}> + </span></>}
            </label>
            <label>
              {files[1] ? <img src={URL.createObjectURL(files[1])} onClick={() => deleteFile(1)} alt='thumbnail' /> : <><span onClick={() => inputRef.current.click()}> + </span></> }
            </label>
            <label>
              {files[2] ? <img src={URL.createObjectURL(files[2])} onClick={() => deleteFile(2)} alt='thumbnail' /> : <><span onClick={() => inputRef.current.click()}> + </span></> }
            </label>
            <input
              id='inputTag'
              type='file'
              accept="image/jpg, image/png, image/jpeg"
              ref={inputRef}
              onChange={handleFileChange}
              style={{ display: 'none' }}
              multiple={true}
            />
            </Photos>
          </PhotoBox>
        </ReviewBox>
      </ReviewWrapper>
      <MarginBottomBox />
      <ContentWrapper>
        <FooterMaxButton text={"レビューを書く"} onClick={writeButton} />
      </ContentWrapper>
    </>
  );
};

export default WriteSimpleReviewForm;