import React from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { api_get } from "../../apis/apis";
import Loading from "../Loading";
import YouTube from "react-youtube";
function ItemInfo ({data, seq}) {
  const [videoData,setVideoData] = useState("");

  const fetchProductVideo = async () => {
    const result = await api_get(`/product-video/${seq}`)
    setVideoData(result.message);
  };

  useEffect(() => {
    fetchProductVideo();
  }, []);

  if (data === null) {
    return <Loading />;
  }

  return (
    <StyleDiv>

      {videoData !== "" && (
       <YouTube
          videoId={videoData}
          opts={{
          playerVars: {
            autoplay: 0, //자동재생 여부
            rel: 0, //관련 동영상 표시여부
            modestbranding: 1, // 컨트롤 바 youtube로고 표시여부
            },
          }}
          onEnd={(e) => {
          e.target.stopVideo(0);
          }}
        />
        )}


    {data && data.detailImageArray && data.detailImageArray.map(item => (
      <div key={item}>
        <img src={item} alt="itemdetail" />
      </div>
    ))}
    </StyleDiv>
  )
}
export default ItemInfo;

const StyleDiv = styled.div`
  background-color: darkgray;
  min-width: 328px;
  width: 100%;
  position: relative;
  overflow: hidden;
  padding-bottom: 60px;
  img {
    width: 100%;
  }
  iframe {
    width: 100%;
  }
`;



