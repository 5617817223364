import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import styled from "styled-components";

const CountDown = ({ expire, isTimeDeal }) => {
  const expireProps = dayjs(expire).format('YYYY-MM-DD HH:mm:ss');
  const [timeLeft, setTimeLeft] = useState(0);

  useEffect(() => {
    const calculateTimeLeft = () => {
      const expireDate = dayjs(expireProps);
      const currentDate = dayjs();
      const diff = expireDate.diff(currentDate, 'second');
      setTimeLeft(diff);
    };

    calculateTimeLeft();

    const timer = setInterval(() => {
      calculateTimeLeft();
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [expireProps]);

  const days = Math.floor(timeLeft / 86400).toString().padStart(2, '0');
  const hours = Math.floor((timeLeft % 86400) / 3600).toString().padStart(2, '0');
  const minutes = Math.floor((timeLeft % 3600) / 60).toString().padStart(2, '0');
  const seconds = (timeLeft % 60).toString().padStart(2, '0');

  return (
    <Container>
      <StyledNumBox isTimeDeal={isTimeDeal}>
        <StyledNum>{days}</StyledNum> 日 <StyledNum>{hours}</StyledNum> :{' '}
        <StyledNum>{minutes}</StyledNum> : <StyledNum>{seconds}</StyledNum>
      </StyledNumBox>
    </Container>
  );
};

export default CountDown;

const Container = styled.div`
  padding: 20px 0;
  margin: 0 auto;
  text-align: center;
`;

const StyledNumBox = styled.div`
  span {
    font-size: ${(props) => (props.isTimeDeal ? "24px" : "20px")};
    padding: ${(props) => (props.isTimeDeal ? "12px 10px" : "10px 8px")};
  }
`;

const StyledNum = styled.span`
  display: inline-block;
  background-color: #393939;
  color: #fff;
  border-radius: 5px;
  font-weight: bold;
  margin: 5px;
  text-align: center;
  transition: background-color 0.3s, color 0.3s;
  cursor: pointer;
`;