import React, { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { api_get } from '../apis/apis';
import { isLogin } from "../atoms/atoms";
import { getEncrypt } from '../utils/authUtils';
import styled, { css } from 'styled-components';
import Button from './Button';
import { CheckBoxDiv, FooterButton, flexBetweenCenter, flexCenterCenter, inputStyle } from './Common';
import {useSetRecoilState, useResetRecoilState, useRecoilState} from "recoil";
import { signin } from '../utils';
import { signUpDataAtom } from "../atom";

const SignUpForm = () => {
  const baseUrl = process.env.REACT_APP_BASEURL;
  const setIsLogin = useSetRecoilState(isLogin);
  const navigate = useNavigate();
  const [emck,setEmck] = useState("");
  const [btnstate,setBtnState] = useState(false);
  const [codeBtnstate,setCodeBtnState] = useState(false);
  const jwtCheck = async () => {
    const token = await isMember();
    if(token.status==="fail"){
    }
    else{
      navigate('/');
    }
  }

  const [signupCopyData, setSignupCopyData] = useState({});
  const [signUpData, setSignUpData] = useRecoilState(signUpDataAtom);
  const [signUpDataState, setSignUpDataState] = useState({
    name : false,
    nickname : false,
    email : false,
    code : false,
    password : false,
    password_check : false,
  });

  const [checkComment , setCheckComment] = useState({
    nickname : "",
    email : "",
    code : "",
    password : "",
    password_check:""
  });

  const failComment = {
    nickname : "重複するニックネームがあります",
    email : "メールの形式が正しくありません",
    code : "認証番号が正しくありません。",
    password : "形式が間違っています。 もう一度入力してください。",
    password_check:"上記に入力したパスワードとは異なります。"
  }
  const successComment = {
    nickname : "使用可能なニックネームです。",
    email : "認証番号がメールで送信されました。",
    code : "認証成功",
    password : "",
    password_check:"上記に入力したパスワードと同じです。"
  }

  const [checkState, setCheckState ] = useState({
    checkAll : false,
    check_1 : false,
    check_2 : false,
    check_3 : false,
    check_4 : false,
  })

  function check_select(target){
    if(target === "all" && !checkState.checkAll){
      setCheckState({
        checkAll: true,
        check_1: true,
        check_2: true,
        check_3: true,
        check_4: true
      });
      return
    } else if(target === "all" && checkState.checkAll){
      setCheckState({
        checkAll: false,
        check_1: false,
        check_2: false,
        check_3: false,
        check_4: false
      });
      return
    }
    if (checkState[target]){
      setCheckState({
        ...checkState,
        [target]: !checkState[target],
        checkAll : false
      });
    }else{
      setCheckState({
        ...checkState,
        [target]: !checkState[target]
      });
    }
  }

  async function onClick_api(target, endpoint) {
    var params = {};
    if (target && signUpData[target]) {
      if (target === "code") {
        if (emck === "Y") {
          params = { [target]: signUpData[target], "email": signUpData.email };
          setSignUpDataState((prevState) => ({
            ...prevState,
            code: true,
          }));
        } else {
          setSignUpDataState((prevState) => ({
            ...prevState,
            code: false,
          }));
        }
      } else {
        if (target === "email") {
          const email_params = { [target]: signUpData[target] };
          const email_data = await api_get('/duplication-check/email', email_params);

          if (email_data.status === "fail") {
            alert("すでに登録されているメールです。");
          } else {
            setEmck("Y");
            setCodeBtnState(true);
            setSignUpData((prev) => ({
              ...prev,
              isEmail: true,
            }))
            alert("認証番号が送信されました。");
          }
        }
        params = { [target]: signUpData[target] };
      }
      const data = await api_get(endpoint, params);

      if (data.status === "fail") {
        setCheckComment({
          ...checkComment,
          [target]: failComment[target],
        });
        setSignUpDataState((prevState) => ({
          ...prevState,
          [target]: false,
        }));
      } else {
        if(target === "nickname") {
          setSignUpData((prev) => ({
            ...prev,
            isNickname: true,
          }))
        }
        if(target === "code") {
          setSignUpData((prev) => ({
            ...prev,
            isCode: true,
          }))
        }
        setCheckComment({
          ...checkComment,
          [target]: successComment[target],
        });
        setSignUpDataState((prevState) => ({
          ...prevState,
          [target]: true,
        }));
        setSignUpData((prevSignUpData) => ({ // 여기서 수정
          ...prevSignUpData,
          [target]: signUpData[target],
        }));
      }
    }
  }

  function Checkbox({ children, disabled, checked, target }) {
    return (
      <CheckBoxDiv>
        <input
          type="checkbox"
          disabled={disabled}
          checked={checked}
          onChange={(check) => check_select(target)}
          style={{width:16, height:16}}
        />
      </CheckBoxDiv>
    );
  }

  function SetSignupData(target, text) {
    const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/i;

    setSignupCopyData((prevSignupCopyData) => ({
      ...prevSignupCopyData,
      [target]: text,
    }));

    setSignUpData((prevSignUpData) => {
      const newState = { ...prevSignUpData };
      const prevState = { ...signUpDataState };

      if (target === "password") {
        newState.password = text;
        prevState.password = regex.test(text);
      } else if (target === "password_check") {
        newState.password_check = text;
        prevState.password_check = newState.password === text;
      } else if (target === "email") {
        const emailRegEx = /^[A-Za-z0-9]([-_.]?[A-Za-z0-9])*@[A-Za-z0-9]([-_.]?[A-Za-z0-9])*\.[A-Za-z]{2,3}$/i;
        setCheckComment({
          ...checkComment,
          [target]: emailRegEx.test(text) ? successComment[target] : failComment[target],
        });
        newState.email = text;
        setBtnState(emailRegEx.test(text));
      } else if (target === "name" || target === "nickname") {
        newState[target] = text;
      }
      else if (target === "name" || target === "nickname" || target === "code") {
        newState[target] = text;
      }

      setCheckComment({
        ...checkComment,
        [target]: prevState[target] !== newState[target] ? "" : checkComment[target],
      });

      setSignUpDataState(prevState);

      return newState;
    });
  }

  const signInSuccess = async (email, password) => {
    if (email && password) {
      signin({ email, password, provider: "yourish" })
      .then((response) => {
        setIsLogin(true);
        if(response==="ok"){
          navigate("/sign-up-success");
        }else{
          return
        }
      })
      .catch((error) => console.log(error));
    } else {
      return
    }
  }


  async function sendSignup(){
    const response = await fetch(`${baseUrl}/user/sign-up`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: signUpData.email,
        password: getEncrypt(signUpData.password),
        username: signUpData.name,
        provider: "yourish",
        nickname: signUpData.nickname,
        // receiveEmail: "Y",
        // pushMessage: "Y",
        receiveEmail: checkState.check_3 ? "Y" : "N",
        pushMessage: checkState.check_4 ? "Y" : "N",
      }),
    });
    const result = await response.json();

    if (result.status === "ok") {
      signInSuccess(signUpData.email, signUpData.password);
    } else if(result.status === "fail" && result.message === "exist") {
      alert("すでに登録されているメールです。");
    }
  }
  const isButtonDisabled = () => {
    if (
      signUpData.name === ""
      || !(signUpData.isCode === true)
      || !(signUpData.isNickname === true)
      || !(signUpData.password.length > 0)
      || !(signUpData.password_check.length > 0)
      || !(checkState.check_1 && checkState.check_2)
    ) {
      return true;
    }
  }

  useEffect(() => {
    isButtonDisabled
  }, [signUpData, signUpDataState]);

  return (
    <>
      <InputBox>
        <div className="top_text">氏名</div>
        <StyledInput
          type="text"
          onChange={(e)=>{SetSignupData("name",e.target.value)}}
          value={signUpData.name}
        />
      </InputBox>
      <InputBox>
        <div className="top_text">ニックネーム</div>
        <StyledSpan
          sub={checkComment.nickname === "このニックネームはすでに使われています。"}
        >
          {checkComment.nickname}
        </StyledSpan>
        <InputBtnBox>
          <StyledInput
            withbtn
            type="text"
            autoComplete="username"
            onChange={(e)=>{SetSignupData("nickname",e.target.value)}}
            value={signUpData.nickname}
            confirmbtn
          />
          <StyledButton onClick={(e) => onClick_api("nickname", "/duplication-check/nickname")} >確認</StyledButton>
        </InputBtnBox>
      </InputBox>
      <InputBox>
        <div className="top_text">メールアドレス</div>
        <StyledSpan sub={checkComment.email === "メールの形式が正しくありません"}>{checkComment.email}</StyledSpan>
        <InputBtnBox>
          <StyledInput
            withbtn
            type="text"
            onChange={(e)=>{SetSignupData("email",e.target.value)} }
            value={signUpData.email}
          />
          <StyledButton disabled={!btnstate} onClick={(e) => onClick_api("email", "/mail/signup")} >認証</StyledButton>
        </InputBtnBox>
      </InputBox>
      <InputBox>
        <div className="top_text">認証番号</div>
        <StyledSpan sub={checkComment.code === "認証番号が正しくありません。"}>{checkComment.code}</StyledSpan>
        <InputBtnBox>
          <StyledInput
            placeholder='メールで送信された認証番号を入力します。'
            withbtn
            type="text"
            onChange={(e)=>{SetSignupData("code",e.target.value)}}
            value={signUpData.code}
          />
          <StyledButton disabled={!signUpData.isEmail} onClick={(e) => onClick_api("code", "/certification-email")} >確認</StyledButton>
        </InputBtnBox>
      </InputBox>
      <InputBox>
        <div className="top_text">パスワード</div>
        <StyledSpan right sub={checkComment.password === "形式が間違っています。 もう一度入力してください。"}>{checkComment.password}</StyledSpan>
        {/* <form> */}
        <StyledInput
          type="password"
          autoComplete="new-password"
          placeholder="8～16文字"
          onChange={(e)=>{SetSignupData("password",e.target.value)} }
          value={signUpData.password}
        />
        <StyledPasswordSpan>・ 半角英数字 ・ 記号をそれぞれ2文字以上含ませてください。</StyledPasswordSpan>
        {/* </form> */}
      </InputBox>
      <InputBox>
        <div className="top_text">パスワードの再確認</div>
        <StyledSpan right sub={checkComment.password_check === "上記に入力したパスワードとは異なります。"}>{checkComment.password_check}</StyledSpan>
        {/* <form> */}
        <StyledInput
          type="password"
          autoComplete="off"
          onChange={(e)=>{SetSignupData("password_check",e.target.value)} }
          value={signUpData.password_check}
        />
        {/* </form> */}
      </InputBox>
      <CheckWrapper>
        <CheckBox>
          <div>以下の規約にすべて同意する</div>
          <Checkbox checked={checkState.checkAll} target={"all"}/>
        </CheckBox>
        <CheckBox>
          <div>
            <div className='text'>利用規約</div>
            <StyledLink onClick={() => navigate(`/terms`)}>詳細</StyledLink>
          </div>
          <Checkbox checked={checkState.check_1} target={"check_1"}/>
        </CheckBox>
        <CheckBox>
          <div>
            <div className='text'>個人情報の収集及び利用目的</div>
            <StyledLink onClick={() => navigate(`/privacy`)}>詳細</StyledLink>
          </div>
          <Checkbox checked={checkState.check_2} target={"check_2"}/>
        </CheckBox>
        <div className="push">
          <span>🎁 お得な特典が毎月もらえる！</span>
        </div>
        <CheckBox push>
          <div className='text'>{"メールマガジンを受信"}</div>
          <Checkbox checked={checkState.check_3} target={"check_3"}/>
        </CheckBox>
        <CheckBox>
          <div className='text' >{"プッシュ通知"}</div>
          <Checkbox checked={checkState.check_4} target={"check_4"}/>
        </CheckBox>
      </CheckWrapper>
      <FooterButton
        disabled={isButtonDisabled()}
        onClick={(e)=>sendSignup()}
      >完了</FooterButton>
    </>
  )
}

export default SignUpForm;

const InputBox = styled.div`
  position: relative;
  margin-top: 15px;
  color: rgba(29, 29, 29, 1);
  .top_text {
    font-size: 14px;
    display: inline-block;
  }
`;

const InputBtnBox = styled.div`
  ${flexCenterCenter}
  margin-top: 10px;
`;

const StyledButton = styled(Button)`
  ${flexCenterCenter}
  width: 83px;
  height: 40px;
  margin-left: 18px;
  padding: 0 20px;
  border-radius: 10px;
  background-color: var(--color-main);
  font-size: 14px;
  font-weight: 700;
  color: white;
`;

const StyledSpan = styled.span`
  font-size: 12px;
  color: var(--color-main);
  margin-left: 5px;
  ${props => props.sub && css`
    color: var(--color-sub);
    @media screen and (max-width: 368px) {
      display: block;
      margin: 10px 0 0 0;
    }
  `}
  @media screen and (max-width: 410px) {
    font-size: 10px;
  }
`;

const StyledInput = styled.input`
  ${inputStyle}
  margin-top: 10px;
  font-size: 12px;
  &::placeholder {
    color: rgba(192, 192, 192, 1);
  }
  ${props => props.withbtn && css`
    margin-top: 0;
  `}
  ${props => props.confirmbtn && css`
    width: calc(100% - 101px);
  `}
`;

const CheckWrapper = styled.div`
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  margin: 20px 0 120px;
  .text {
    font-size: 12px;
    font-weight: 400;
    display: inline-block;
  }
  .push {
    color: #fff;
    background: rgba(255, 123, 154, 0.5);
    border-radius: 3px;
    font-size: 12px;
    font-weight: 400;
    padding: 6px 3px;
    display: inline-block;
    margin-top: 10px;
  }
`;

const StyledLink = styled(Button)`
  font-family: var(--font-jp-light);
  font-weight: 600;
  font-size: 12px;
  display: inline-block;
  margin-left: 8px;
  color: var(--color-main);
`;

const CheckBox = styled.div`
  ${flexBetweenCenter}
  margin-top: 10px;
  width: 100%;
  ${props => props.push && css`
    margin-top: 0;
  `}
`;

const StyledPasswordSpan = styled.span`
  font-size: 10px;
  color: rgba(166, 166, 166, 1);
`;