import React from "react";
import HeaderWithBack from "../../components/HeaderWithBack";
import ListMainProduct from "../../components/ListMainProduct";
import { useQuery } from "react-query";
import { fetchMainData } from "../../utils";
import StyledP from "../../components/StyledP";
import Loading from "../Loading";
import ErrorPage from "../ErrorPage";
import { RootWrapper } from "../../components/Common";
import NavBasic from "../../components/NavBasic";

function SpecialCollaborations() {
  const { isLoading, isError, data } = useQuery("special", fetchMainData);

  return (
    <RootWrapper>
      <HeaderWithBack eng="true" text="Special Collaborations" />
      <StyledP bold>[数量限定] ユーリッシュ・ポエトリー注目セット賞</StyledP>
      {isLoading && <Loading />}
      {isError && <ErrorPage />}
      {data && (
        <ListMainProduct
          isNowuse={false}
          isTimedeal={false}
          data={data.special}
          marginBottom={true}
          isSpecial={true}
        />
      )}
      <NavBasic />
    </RootWrapper>
  );
}

export default SpecialCollaborations;
