import React, { useState } from "react";
import styled, { css } from "styled-components";
import HeaderWithBack from "../../components/HeaderWithBack";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import FreeShippiingButton from "../../components/FreeShippiingButton";
import { api_post_header_token_then } from "../../apis/apis";
import NavBasic from "../../components/NavBasic";
const shadowStyle = css`
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.25);
`;

const btnStyle = css`
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  background: #85dbd7;
  border-radius: 10px;
  border: none;
`;
const Addcoupons = () => {
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState("");
  const [result, setResult] = useState("");
  const jwtToken = Cookies.get("jwtToken");
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  const handleRegisterClick = () => {
    const params_reg = {
      certification: inputValue ,
    }
    api_post_header_token_then(`/coupon/write`, params_reg)
      .then((response) => response.data) // Parse the response body as JSON
      .then((data) => {
        if (data.status === "fail") {
          setResult("fail");
        } else if (data.status === "ok") {
          setResult("ok");
        }
      })
      .catch((error) => {
        console.error("Error registering coupon", error);
      });
  };
  return (
    <StyleDiv>
      <HeaderWithBack text={"クーポン登録"}></HeaderWithBack>
      <WrapBox>
        <FreeShippiingButton />
        <AddBox>
          <AddInput>
            <input
              placeholder="クーポン番号を入力してください "
              value={inputValue}
              onChange={handleInputChange}
            />
          </AddInput>
          <AddBtn onClick={handleRegisterClick}>登録</AddBtn>
        </AddBox>
        {result === "ok" && (
          <ResultBox>
            <p>クーポン登録成功</p>
            <button onClick={() => navigate("/Mypage/mycoupons", {replace: true})}>戻る</button>
          </ResultBox>
        )}
        {result === "fail" && (
          <ResultBox>
            <p>クーポン登録に失敗しました</p>
            <p>もう一度入力してください</p>
          </ResultBox>
        )}
      </WrapBox>
      <NavBasic/>
    </StyleDiv>
  );
};

export default Addcoupons;

const StyleDiv = styled.div`
  /* min-height: 850px; */
  min-height: 100vh;
  background-color: var(--color-main-light);
`;

const WrapBox = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
`;

const AddBox = styled.div`
  width: 90%;
  margin: 10px auto;
  display: flex;
  justify-content: space-between;
`;

const AddInput = styled.div`
  width: 100%;
  input {
    width: 100%;
    height: 50px;
    font-size: 16px;
    border: none;
    border-radius: 10px;
    padding-left: 10px;
    ${shadowStyle}
  }
`;

const AddBtn = styled.button`
  width: 70px;
  height: 50px;
  margin-left: 5px;
  display: inline-block;
  ${btnStyle}
  ${shadowStyle}
`;

const ResultBox = styled.div`
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 100px 0;
  p {
    font-family: var(--font-jp-bold);
    font-size: 16px;
    line-height: 1.6;
  }
  p:last-child {
    font-family: var(--font-jp-light);
  }
  button {
    width: 122px;
    height: 40px;
    display: block;
    margin-top: 10px;
    ${btnStyle}
    ${shadowStyle}
  }
`;
