import React, { useEffect,useState } from "react";
import HeaderWithBack from "../components/HeaderWithBack";
import NavBasic from "../components/NavBasic";
import styled from "styled-components";
import YouTube from "react-youtube";
import { ContentWrapper, RootWrapper, flexCenterCenter, scrollStyle } from "../components/Common";
import { useNavigate } from 'react-router-dom';
import { api_get } from "../apis/apis";
import SubStringText from "../components/SubStringText";
import ArrowButton from "../components/ArrowButton";
function YourishAI() {
  const [info,setInfo] = useState({});
  const navigate = useNavigate();

  const list = async (brand) => {
    let now = "all";
    if(brand!=="all"){
      now = brand;
    }
    const response = await api_get(`/ai-sort?brand=${now}`)
    setInfo(response.list);
  };


  const onClick = (productSeq) => {
    navigate(`/item-detail/info/${productSeq}`);
  }

  useEffect(() => {
    list("all");
  },[]);

  return (
    <RootWrapper gray47Color>
      <HeaderWithBack eng="true" text="YOURISH AI"></HeaderWithBack>
      <ContentWrapper marginBottom>
        <TagBox>
          <TagButton onClick={() => list("all")}>#All</TagButton>
          <TagButton onClick={() => list("Bellelanco")}>#BELLERANCO</TagButton>
          <TagButton onClick={() => list("Dermacentric")}>#DERMACENTRIC</TagButton>
          <TagButton onClick={() => list("Dr.Wellmadeone")}>#Dr.Wellmadeone</TagButton>
          <TagButton onClick={() => list("Celltiara")}>#Celltiara</TagButton>
        </TagBox>
        <StyledUl>
        {info && Object.values(info).map((video, index) => (
          <StyledLi key={index}>
            <YouTube
              videoId={video.videoId}
              opts={{
                playerVars: {
                  autoplay: 0, //자동재생 여부
                  rel: 0, //관련 동영상 표시여부
                  modestbranding: 1, // 컨트롤 바 youtube로고 표시여부
                },
              }}
              onEnd={(e) => {
                e.target.stopVideo(0);
              }}
            />
            <ContentBox onClick={() => onClick(video.productSeq)}>
              <StyledButton>商品詳細を<br/>チェック</StyledButton>
              <div>
                <p>[{video.brand}]</p>
                <p><SubStringText text={video.title} truncatelength={27} /></p> 
              </div>
            </ContentBox>
          </StyledLi>
        ))}
         
        </StyledUl>
      </ContentWrapper>
      <NavBasic />
      <ArrowButton />
    </RootWrapper>
  );
}

export default YourishAI;

const TagBox = styled.div`
  ${scrollStyle}
  display: flex;
  margin: 0 0 10px 0;
`;

const TagButton = styled.button`
  height: 30px;
  background: rgba(0, 0, 0, 0);
  border: 1px solid var(--color-main);
  color: var(--color-main);
  font-size: 12px;
  border-radius: 20px;
  padding: 0 10px;
  margin-right: 10px;
`;

const StyledUl = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledLi = styled.li`
  ${flexCenterCenter}
  position: relative;
  margin: 0;
  margin-bottom: 10px;
  div {
    width: 100%;
    iframe {
      border-radius: 15px;
      width: 100%;
    }
  }
`;
const ContentBox = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 70px;
  background-color: #000;
  bottom: 0;
  cursor: pointer;
  p {
    color: #fff;
    font-family: var(--font-english);
    font-size: 16px;
    font-weight: 700;
    margin-left: 20px;
    &:first-child {
      font-size: 12px;
    }
    @media screen and (max-width: 500px) {
      font-size: 14px;
    }
  }
`;

const StyledButton = styled.button`
  ${flexCenterCenter}
  width: 77px;
  height: 52px;
  border: none;
  border-radius: 10px;
  font-family: var(--font-english);
  font-size: 13px;
  font-weight: 700;
  color: #000;
  margin-left: 15px;
  white-space: nowrap;
`;