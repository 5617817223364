import React from "react";
import { Routes, Route } from "react-router-dom";

import PageLayout from "../pages/PageLayout";
import Home from "../pages/main/Home";
import SignUp from "../pages/signup/SignUp";
import SignIn from "../pages/login/SignIn";
import SignInSVIVI from "../pages/login/SigninSVIVI";
import SignInDULCE from "../pages/login/SigninDULCE";
import NotFound from "../pages/NotFound";
import Callback from "../pages/Callback";
import CallbackApple from "../pages/CallbackApple";
import Terms from "../pages/signup/Terms";
import Basket from "../pages/Basket";
import Order from "../pages/Order";
import ItemDetail from "../pages/itemDetail/ItemDetail";
import Mycoupons from "../pages/mypage/Mycoupons";
import Myorderlists from "../pages/mypage/Myorderlists";
import Myreviews from "../pages/mypage/Myreviews";
import Mypage from "../pages/mypage/Mypage";
import ItemInfo from "../pages/itemDetail/ItemInfo";
import ItemReview from "../pages/itemDetail/ItemReview";
import ItemQuestion from "../pages/itemDetail/ItemQuestion";
import Category from "../pages/main/Category";
import Interest from "../pages/Interest";
import RecentView from "../pages/RecentView";
import YourishAI from "../pages/YourishAI";
import FindPassword from "../pages/login/FindPassword";
import FindEmail from "../pages/login/FindEmail";
import TimeDeal from "../pages/main/TimeDeal";
import NewProduct from "../pages/main/NewProduct";
import NowUse from "../pages/main/NowUse";
import Best from "../pages/main/Best";
import Addcoupons from "../pages/mypage/Addcoupons";
import Privacy from "../pages/signup/privacy";
import SignUpSuccess from "../pages/signup/SignUpSuccess";
import FirstCome from "../pages/main/FirstCome";
import BuyCoupon from "../pages/BuyCoupon";
import TermsUse from "../pages/TermsUse";
import WritePhotoReview from "../pages/mypage/WritePhotoReview";
import WriteSimpleReview from "../pages/mypage/WriteSimpleReview";
import Inquiry from "../pages/mypage/Inquiry";
import WaitPayment from "../pages/mypage/WaitPayment";
import PaymentCompleted from "../pages/PaymentCompleted";
import ItemOrderHistory from "../pages/mypage/ItemOrderHistory";
import MyPost from "../pages/mypage/MyPost";
import Faq from "../pages/Faq";
import Delivery from "../pages/delivery";
import Notice from "../pages/Notice";
import Exchange from "../pages/Exchange";
import ExchangeForm from "../components/ExchangeForm";
import AddressList from "../pages/AddressList";
import AddAddress from "../pages/AddAddress";
import Search from "../pages/main/Search";
import SearchResult from "../pages/main/SearchResult";
import DeliveryStatus from "../pages/mypage/DeliveryStatus";
import PersonalInfoPage from "../pages/mypage/PersonalInfoPage";
import ProductInquiry from "../pages/mypage/ProductInquiry";
import CollaborationPage from "../pages/CollaborationPage";
import Loading from "../pages/Loading";
import ErrorPage from "../pages/ErrorPage";
import LoadingErrorProvider from "../components/LoadingErrorProvider";
import QrImg from "../components/QrImg";
import AboutUs from "../pages/AboutUs";
import B2B from "../pages/B2B";
import Apply from "../pages/Apply";
import InternationalShopping from "../pages/InternationalShopping";
import EditMyinfo from "../pages/mypage/EditMyinfo";
import Brand from "../pages/main/Brand";
import EmailInputPage from "../pages/login/EmailInputPage";
import GenuineProduct from "../pages/GenuineProduct";
import YourishMembership from "../pages/YourishMembership";
import { RecoilRoot } from "recoil";
import { Helmet } from "react-helmet-async";
import Influencer from "../pages/main/Influencer";
import CollaborationInfluencerPage from "../pages/CollaborationInfluencerPage";
import PaymentFail from "../pages/PaymentFail";
import SpecialCollaborations from "../pages/main/SpecialCollaborations";

// NOTE url별 랜더링 페이지 설정
function App() {

  return (
    <>
      <RecoilRoot>
        <LoadingErrorProvider>
          <div className="Web">
            <QrImg className="QRImg" />
            <div className="App">
              <Routes>
                <Route path="/" element={<PageLayout />}>
                  <Route index element={<Home />} />
                  <Route path="loading" element={<Loading />} />
                  <Route path="404" element={<ErrorPage />} />
                  <Route path="search" element={<Search />} />
                  <Route path="search-results" element={<SearchResult />} />
                  <Route path="/category/:categoryName/:brandName?" element={<Category />} />
                  <Route path="/brand/:brandName?" element={<Brand />} />
                  <Route path="timedeal" element={<TimeDeal />} />
                  <Route path="firstcome" element={<FirstCome />} />
                  <Route path="best" element={<Best />} />
                  <Route path="newproduct" element={<NewProduct />} />
                  <Route path="nowuse" element={<NowUse />} />
                  <Route path="sign-up" element={<SignUp />} />
                  <Route path="sign-up-success" element={<SignUpSuccess />} />
                  <Route path="sign-in" element={<SignIn />} />
                  <Route path="email-input" element={<EmailInputPage />} />
                  <Route path="sign-in-svivi" element={<SignInSVIVI />} />
                  <Route path="sign-in-dulce" element={<SignInDULCE />} />
                  <Route path="find-email" element={<FindEmail />} />
                  <Route path="find-password" element={<FindPassword />} />
                  <Route path="line-test" element={<Callback />} />
                  <Route path="interest" element={<Interest />} />
                  <Route path="recentView" element={<RecentView />} />
                  <Route path="watch" element={<YourishAI />} />
                  <Route path="Mypage" element={<Mypage />} />
                  <Route path="Mypage/myorderlists" element={<Myorderlists />} />
                  <Route path="Mypage/myreviews" element={<Myreviews />} />
                  <Route path="Mypage/mycoupons" element={<Mycoupons />} />
                  <Route path="Mypage/addcoupons" element={<Addcoupons />} />
                  <Route path="personalinfo" element={<PersonalInfoPage />} />
                  <Route path="edit-myinfo" element={<EditMyinfo />} />
                  <Route path="delivery-status" element={<DeliveryStatus />} />
                  <Route path="writesimplereview" element={<WriteSimpleReview />} />
                  <Route path="writephotoreview" element={<WritePhotoReview />} />
                  <Route path="item-order-history" element={<ItemOrderHistory />} />
                  <Route path="inquiry" element={<Inquiry />} />
                  <Route path="product-inquiry" element={<ProductInquiry />} />
                  <Route path="mypost" element={<MyPost />} />
                  <Route path="waitpayment" element={<WaitPayment />} />
                  <Route path="paymentcompleted" element={<PaymentCompleted />} />
                  <Route path="buycoupon" element={<BuyCoupon />} />
                  <Route path="basket" element={<Basket />} />
                  <Route path="faq" element={<Faq />} />
                  <Route path="exchange" element={<Exchange />} />
                  <Route path="exchange-form" element={<ExchangeForm />} />
                  <Route path="item-detail" element={<ItemDetail />}>
                    <Route path="info/*" index element={<ItemInfo />} />
                    <Route path="review" element={<ItemReview />} />
                    <Route path="question" element={<ItemQuestion />} />
                  </Route>
                  <Route path="order" element={<Order />} />
                  <Route path="address-list" element={<AddressList />} />
                  <Route path="add-address" element={<AddAddress />} />
                  {/* <Route path="edit-address" element={<EditAddress />} /> */}
                  <Route path="about-us" element={<AboutUs />} />
                  <Route path="notice" element={<Notice />} />
                  <Route path="genuine-product" element={<GenuineProduct />} />
                  <Route path="yourish-membership" element={<YourishMembership />} />
                  <Route path="delivery" element={<Delivery />} />
                  <Route path="b2b" element={<B2B />} />
                  <Route path="apply" element={<Apply />} />
                  <Route path="international-shopping" element={<InternationalShopping />} />
                  <Route path="apple-test" element={<CallbackApple />} />
                  <Route path="*" element={<NotFound />} />
                </Route>
                <Route path="termsuse" element={<TermsUse />} />
                <Route path="terms" element={<Terms />} />
                <Route path="privacy" element={<Privacy />} />
                <Route path="collaboration" element={<CollaborationPage />} />
                <Route path="collaboration-influencer" element={<CollaborationInfluencerPage />} />
                <Route path="influencer" element={<Influencer />} />
                <Route path="payment-fail" element={<PaymentFail />} />
                <Route path="special-collaborations" element={<SpecialCollaborations />} />
              </Routes>
            </div>
          </div>
        </LoadingErrorProvider>
      </RecoilRoot>
      <Helmet>
        <title>[YOURISH]&nbsp;Immersive BEAUTY Application</title>
        <meta name="keywords" content="韓国, 韓国コスメ, 韓国ファッション, 韓国香水, 香水, オブベニ, aubeveni,
        beauty, korea, koreanbeauty, koreanperfume, yourish, yourish.jp, yourish.shop, yourishfromkorea,
        bellelanco, kcosmetics, kbeautyaddict, kbeautylover, koreacosmetics, kbeautyshop, BELLELANCO"
        />
      </Helmet>
    </>
  );
}

export default App;
