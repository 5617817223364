import HeaderWithBack from "../../components/HeaderWithBack";
import ListMainProduct from "../../components/ListMainProduct";
import timesalebanner from "../../assets/banners/time-sale-banners.jpg";
import { useQuery } from "react-query";
import {fetchTimeDealData} from "../../utils";
import BannerBox from "../../components/BannerBox";
import StyledP from "../../components/StyledP";
import Loading from "../Loading";
import ErrorPage from "../ErrorPage";
import { RootWrapper } from "../../components/Common";
import NavBasic from "../../components/NavBasic";
import ArrowButton from "../../components/ArrowButton";
import CountDown from "../../components/CountDown";
import { useEffect } from "react";

function TimeDeal() {
  const { isLoading, isError, data } = useQuery("timeDeal", fetchTimeDealData);
  useEffect(()=>{
    console.log("DATA",data)
  },[])

  
  return (
    <RootWrapper>
      <HeaderWithBack eng="true" text="TIME SALE"></HeaderWithBack>
      <BannerBox>
        <img src={timesalebanner} alt="timesalebanner" />
        <StyledP bold>この価格で購入できる残り時間</StyledP>
        <CountDown expire={data?.expire} isTimeDeal />
      </BannerBox>
      {isLoading && <Loading />}
      {isError && <ErrorPage />}
      {data && (
        <ListMainProduct isNowuse={false} isTimedeal={false} data={data.list} marginBottom={true} />
      )}
      <NavBasic/>
      <ArrowButton />
    </RootWrapper>
  );
}
export default TimeDeal;
