import React from 'react';
import Button from './Button';
import qaicon from '../assets/common/qaicon.svg';
import styled, { css } from 'styled-components';
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { isMember } from "../utils";
import { api_get } from '../apis/apis';
const QuestionBox = styled.div`
  position: fixed;
  transform: translateX(430px); 
  bottom: 80px;
  right: 50%;
  z-index: 9;
  @media screen and (max-width: 935px) {
    transform: translateX(0); 
    right: 25%;
  }
  @media screen and (max-width: 860px) {
    transform: translateX(0); 
    right: 20%;
  }
  @media screen and (max-width: 750px) {
    transform: translateX(0); 
    right: 15%;
  }
  @media screen and (max-width: 630px) {
    transform: translateX(0); 
    right: 10%;
  }
  @media screen and (max-width: 550px) {
    transform: translateX(0); 
    right: 10px;
  }
  img {
    width: 70px;
    height: 70px;
  }
  ${props => props.bottom && css`
    bottom: 90px;
   `}
`;

const QuestionFixed = ({ productSeq }) => {

  const navigate = useNavigate();
 
  const check = async ()=>{
  
    const token = await isMember();

     if(token.status==="fail"){
       Cookies.remove("jwtToken");
       document.cookie = "jwtToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=yourish.jp;";
        navigate('/sign-in');
     }
     else{
        navigate(`/product-inquiry?seq=${productSeq}`);
     }

  }

  return (
    <QuestionBox>
      <Button onClick={check}>
        <img src={qaicon} alt="qaicon" />
      </Button>
    </QuestionBox>
  );
  
};

export default QuestionFixed;