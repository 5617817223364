import React, { useState,useEffect } from "react";
import styled from 'styled-components';
import HeaderWithBack from '../components/HeaderWithBack';
import freecoupon from '../assets/free-coupon.png';
import couponprocess from '../assets/coupon-process.png';
import FooterMaxButton from '../components/FooterMaxButton';
import Cookies from "js-cookie";
import AskModalOne from '../components/AskModalOne';
import { PayButton, RootWrapper } from "../components/Common";
import { useNavigate } from 'react-router';
import { api_get_header_token, api_post_header_token } from '../apis/apis';

const  BuyCoupon = () => {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [infoname, setinfoname] = useState([]);
  const jwtToken = Cookies.get("jwtToken");
  const baseUrl = process.env.REACT_APP_BASEURL;
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const komojuKey = process.env.REACT_APP_KOMOJU_PUBLIC;

  const eximbayReturnDelivery = process.env.REACT_APP_EXIMBAY_RETURN_DELIVERY
  const eximbayStatus = process.env.REACT_APP_EXIMBAY_STATUS_DELIVERY
  const eximbayMid = process.env.REACT_APP_EXIMBAY_MID
  const eximbayApi = process.env.REACT_APP_EXIMBAY_API

  const userInfo = async () => {
    const result = await api_get_header_token(`/user/my-page`);
    setinfoname(result.list);
  }
  useEffect(() => {
    userInfo();
  }, []);

  const onConfirm = () => {
    setModal(false);
  };
  const onConfirm2 = () => {
    setModal2(false);
  };

  const handlePay = (token) => {
    
    const coupon = async () => {
      const url = `/REG543E0W244T35RW/payment?tk=${token.id}&amount=800`
      const response = await api_get_header_token(url)
      
      if(response.status==="ok"){

        const id = data.komoju_id;
        const store = data.payment_details.store;
        const deadline = data.payment_deadline;
        const type = data.type;
        const paymentMethodFee = data.payment_method_fee;
        const email = data.payment_details.eamil;
        const amount = data.total;
        const status = data.payment_status;
        const instructionsUrl = data.payment_details.instructions_url;
        const confirmationCode = data.payment_details.confirmation_code;
        const receipt = data.payment_details.receipt;
        const wellnetWalletUrl = data.payment_details.wellnet_wallet_url;
        const brand = data.payment_details.brand;
        const lastFourDigits = data.payment_details.last_four_digits;
       
        const fetchReviewList = async () => {
          const params_coupon = {
            
              id: id,
              store : store,
              deadline : deadline,
              type : type,
              paymentMethodFee : paymentMethodFee,
              email : email,
              amount : amount,
              status : status,
              instructionsUrl : instructionsUrl,
              confirmationCode : confirmationCode,
              receipt : receipt,
              wellnetWalletUrl : wellnetWalletUrl,
              brand : brand,
              lastFourDigits : lastFourDigits,
          
          }
          const result = await api_post_header_token(`/coupon/delivery-write`, params_coupon);
          if(result.status==="ok"){
            navigate('/mypage/mycoupons');
          }
        };
        fetchReviewList();
      }else{
        //여기서 에러처리 해야함.
      }

    };
    coupon();
  };
  

  
  function handleButtonClick(e){
    
    let check = "";
    const couponcheck = async () => {
    
      const url = `/coupon/delivery-check`
      const response = await api_get_header_token(url)
   
      if(response.status!="ok"){
        check ="Y";
        setModal(true);
      } 
    };
   
      couponcheck().then(() => {
        if (check === "Y") {
          return;
        }

        try{
          const handler = Komoju.multipay.configure({
            key: komojuKey,
            token: handlePay,
          });
    
          handler.open({
            amount: 800, // Replace with the total payment amount
            endpoint: "https://komoju.com",
            locale: "ja",
            currency: "JPY",
            title: "配送クーポン",
            description: "交換と払い戻しクーポン",
            methods: ["konbini"],
          })

        }catch(err){
          throw err;
        }
      });
  }

  const payment = async () => {
    let check = "";
    const couponcheck = async () => {
    
      const url = `/coupon/delivery-check`
      const response = await api_get_header_token(url)
   
      if(response.status!="ok"){
        check ="Y";
        setModal(true);
      } 
    };

    couponcheck().then(async () => {
      if (check === "Y") {
        return;
      }
      else{
        
        const params = {
          baseKey : infoname.name + infoname.email
        }
        
        const result = await api_post_header_token(`/coupon/delivery-base-info`, params);

        const payload = {
          "payment": {
            "transaction_type": "PAYMENT",
            "order_id": infoname.name + infoname.email,
            "currency": "JPY",
            "amount": 800,
            "lang": "JP",
            "payment_method":"P000"
          
          },
          "merchant": {
            "mid": eximbayMid,
          },
          "buyer": {
            "name": infoname.name,
            "email": infoname.email
          },
          "settings":{
            "display_type":"R"
          },
          "url": {
            "return_url": eximbayReturnDelivery+`?orderId=${infoname.name + infoname.email}`,
            "status_url": eximbayStatus
          }
        };
  
        const headers = {
          'Authorization': 'Basic '+eximbayApi,
          'Content-Type': 'application/json'
        };
  
      
        try {
          const response = await fetch('https://api.eximbay.com/v1/payments/ready', {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(payload)
          });
  
          if (response.ok) {
            const responseData = await response.json();
            EXIMBAY.request_pay({
              "fgkey" : responseData.fgkey,
              "payment" : {
                  "transaction_type" : "PAYMENT",
                  "order_id" : infoname.name + infoname.email,
                  "currency" : "JPY",
                  "amount" : 800,
                  "lang" : "JP",
                  "payment_method":"P000"
              },
              "merchant" : {
                  "mid" : eximbayMid,
              },
              "buyer" : {
                  "name" : infoname.name,
                  "email" : infoname.email
              },
              "settings":{
                "display_type":"R"
              },
              "url" : {
                "return_url": eximbayReturnDelivery+`?orderId=${infoname.name + infoname.email}`,
                "status_url": eximbayStatus
              }
            });
  
          } else {
            throw new Error('Request failed with status: ' + response.status);
          }
        } catch (error) {
          console.error(error);
        }


      }



    });

  }


  return (
    <RootWrapper>
      <HeaderWithBack text={"送料無料クーポン"}></HeaderWithBack>
      <ContentsBox>
        <img src={couponprocess} alt="couponprocess" />
        <img src={freecoupon} alt="freecoupon" />
        <p>ご安心ください！<br />
          当社の問題によって返品や交換をご希望される場合には<br />
        当社からご確認後、クーポンを返却いたします。</p>
      </ContentsBox>
      <form
        id="pay-form"
        method="post"
        onSubmit={(e) => e.preventDefault()}
        action="https://komoju.com/api/v1/tokens"
        encType="application/x-www-form-urlencoded"
      ><input type="hidden" name="payment_details" /></form>
      {/* <FooterMaxButton onClick={(e)=>{handleButtonClick(e)}} text={"クーポン決済"}/> */}
      <PayButton onClick={(e)=>{handleButtonClick(e)}}>コンビニ決済</PayButton>
      <PayButton subColor onClick={payment}>カード決済</PayButton>

      <AskModalOne
        visible={modal}
        title="クーポンが存在するか、支払い待機中です。"
        confirmText='はい'
        onConfirm={onConfirm}
      />
      <AskModalOne
        visible={modal2}
        title="支払い待機状態であるか、すでに購入済みです。"
        confirmText='はい'
        onConfirm={onConfirm2}
      />
    </RootWrapper>
  );
  
 

};

export default BuyCoupon;


const WrapBox = styled.div`
  position: relative;
  height: 100%;
`;
const ContentsBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    margin-top: 50px;
    min-width: 100px;
    @media screen and (max-width: 768px) {
      width: 90%;
      &:last-of-type {
        width: 60%;
      }
    }
  }
  p {
    font-size: 12px;
    font-weight: 500;
    line-height: 2;
    text-align: center;
    margin: 50px auto;
    @media screen and (max-width: 768px) {
      font-size: 10px;
    }
  }
`;
