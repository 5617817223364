import React from "react";
import styled from "styled-components";

function BtnBoxSmall({
  name,
  text,
  bgcolor,
  width,
  fontsize,
  height,
  onClick,
}) {
  return (
    <StyledButton
      type="button"
      onClick={onClick}
      bgcolor={bgcolor}
      width={width}
      fontsize={fontsize}
      height={height}
      name={name}
    >
      {text}
    </StyledButton>
  );
}
export default BtnBoxSmall;

const StyledButton = styled.button`
  min-width: 20px;
  min-height: 38px;
  width: ${(width) => width?.width || "82px"};
  background-color: ${(bgcolor) => bgcolor?.bgcolor || "#85DBD7"};
  border-radius: 10px;
  border: none;
  height: ${(height) => height?.height || "38px"};
  font-size: ${(fontsize) => fontsize?.fontsize || "14px"};
  font-weight: 700;
  color: ${(color) => color?.color || "#fff"};
`;
