import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router";
import FreeShippiingButton from "./FreeShippiingButton";
import { api_get_header_token_then } from "../apis/apis";
import NavBasic from "./NavBasic";

function ListCoupon({ text, limit, time, width }) {
  const navigate = useNavigate();
  const [coupons, setCoupons] = useState([]);
  
  useEffect(() => {
    api_get_header_token_then(`/coupon/list`)
      .then((response) => {
        const couponData = response.data.list;
       
        const couponElements = couponData.map((coupon,i) => {
          const suffix = coupon.couponType === "price" ? "円" : "%";
          const discount = coupon.discount + " " + suffix;
          return (
            <Coupon key={i} width={width}>
              <div className="between">
                <div className="left">
                  <h3>{discount}</h3>
                  <p>{coupon.couponName}</p>
                  <p>使用期限: {coupon.expireDate}</p>
                </div>
              </div>
            </Coupon>
          );
        });
        setCoupons(couponElements);
      })
      .catch((error) => {
        // handle error
      });
  }, []); // empty dependency array to ensure this effect only runs once

  return (
    <CouponWrap>
      <FreeShippiingButton />
      <AddCouponBtn onClick={() => navigate(`/Mypage/addcoupons`)}>+</AddCouponBtn>
      {coupons} {/* render the generated coupon elements */}
      <NavBasic/>
    </CouponWrap>
  );
}
export default ListCoupon;

const Coupon = styled.div`
  width: ${(width) => width?.width || "90%"};
  margin: 5px auto;
  height: 103px;
  border-radius: 20px;
  background: #ffffff;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
  padding: 20px;
  &:first-child {
    margin-top: 40px;
  }
  &:last-child {
    margin-bottom: 20px;
  }
  .left {
    width: 100%;
  }
  h3 {
    font-size: 20px;
    color: var(--color-sub);
    font-weight: 700;
    font-family: var(--font-jp-bold);
  }
  p {
    font-size: 10px;
    color: var(--color-gray3);
    font-family: var(--font-jp-regular);
  }
  p:first-of-type {
    margin: 8px 0 0;
  }
  p:last-of-type {
    margin: 8px 0 8px;
  }
  .refund {
    min-height: 103px;
    background-color: #fff;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    width: 90%;
    margin: auto;
    padding: 25px;
    p {
      color: var(--color-sub);
      font-weight: 600;
      font-size: 20px;
    }
  }
`;

const CouponWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const AddCouponBtn = styled.button`
  height: 103px;
  background-color: #fff;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  width: 90%;
  margin: 5px auto 8px;
  font-size: 100px;
  border: none;
  color: var(--color-main);
  display: flex;
  justify-content: center;
  align-items: center;
`;
