import React from "react";
import styled from "styled-components";
function BtnBorderExsmall({
  text,
  bgcolor,
  height,
  width,
  fontsize,
  bordercolor,
  color,
  borderradius,
  padding,
  onClick,
}) {
  return (
    <StyledButton
      bgcolor={bgcolor}
      width={width}
      fontsize={fontsize}
      bordercolor={bordercolor}
      borderradius={borderradius}
      color={color}
      height={height}
      padding={padding}
      type="button"
      onClick={onClick}
    >
      {text}
    </StyledButton>
  );
}
export default BtnBorderExsmall;

const StyledButton = styled.button`
  height: ${(height) => height?.height || "21px"};
  min-width: 53px;
  width: ${(width) => width?.width || "53px"};
  background-color: ${(bgcolor) => bgcolor?.bgcolor || "#fff"};
  border-radius: ${(borderradius) => borderradius?.borderradius || "10px"};
  border: 1px solid ${(bordercolor) => bordercolor?.bordercolor || "#515151"};
  font-size: ${(fontsize) => fontsize?.fontsize || "10px"};
  font-weight: 700;
  color: ${(color) => color?.color || "#000"};
  margin: 10px;
  //padding: ${(padding) => padding?.padding || "auto"};
`;
