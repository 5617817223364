import React, { useState,useEffect } from 'react';
import heartactive from "../assets/common/heartactive.svg";
import heartbasic from "../assets/common/heartbasic.svg";
import pinkcirclecheck from "../assets/common/pinkcirclecheck.png";
import graycirclecheck from "../assets/common/graycirclecheck.png";
import AskModal from '../components/AskModal';
import { useNavigate } from 'react-router-dom';
import { api_delete_header_token_then,api_get_header_token } from '../apis/apis';
import { ItemListWrap, ItemStyledLi, ItemStyledUl, itemBoxShadow, itemMediaWithStyle, StyledFaStar } from "./Common";
import { FirstComeDiv, HeartImg, ItemBrandSpan, ItemDiv, ItemSubTextDiv, ItemTextBoxDiv, NowUseDiv, PriceBoxDiv, SoldOutDiv, TitleBoxDiv, ItemBrand } from './ItemListComponent';
import NumberFormat from './NumberFormat';
import styled, { ThemeProvider, css } from 'styled-components';

const RecentListProduct = ({
  data,
  color,
  isFirstCome,
  isNowuse,
  margintop,
  startDate,
  recent,
  trash,
  deleteButton,
  checkButton,
}) => {
  const [modal, setModal] = useState(false);
  const [info, setInfo] = useState({});
  const [wish, setWish] = useState({});
  const navigate = useNavigate();
  const [deleteCheck, setDeleteCheck] = useState(
    data?.reduce((acc, cur) => ({
      ...acc,
      [cur.recentViewSeq]: 0,
    }), {})
  );

  useEffect(() => {
    if (deleteButton === 1) {
      const seqToDelete = Object.keys(deleteCheck).filter(
        (seq) => deleteCheck[seq] === 1
      );
      const seqToDeleteInt = seqToDelete.map((seq) => parseInt(seq));
      if (seqToDeleteInt.length > 0) {
        setModal(true);
      } else {
        checkButton();
      }
    }
    setInfo(data);
  }, [deleteButton, deleteCheck]);

  useEffect(() => {
    setInfo(data);
  }, [data]);
  
  const onCancel = () => {
    setModal(false);
    setDeleteCheck((prev) => ({
      ...prev,
      ...Object.keys(prev).reduce((acc, cur) => ({ ...acc, [cur]: 0 }), {}),
    }));
    checkButton();
  };
  
  const onConfirm = () => {
    deleteStart();
    setModal(false);
  };
  
  const handleTrashClick = (recentViewSeq) => {
    setDeleteCheck((prev) => ({
      ...prev,
      [recentViewSeq]: prev[recentViewSeq] === 1 ? 0 : 1,
    }));
  };
  
  const deleteStart = () => {
    const seqToDelete = Object.keys(deleteCheck).filter(
      (seq) => deleteCheck[seq] === 1
    );
    const seqToDeleteInt = seqToDelete.map((seq) => parseInt(seq));
    const params = seqToDeleteInt.join("&recentViewSeq=");
    api_delete_header_token_then(`/shop/recent-view-delete?recentViewSeq=${params}`)
      .then((response) => {
        setDeleteCheck((prev) => ({
          ...prev,
          ...seqToDeleteInt.reduce((acc, cur) => ({ ...acc, [cur]: 0 }), {}),
        }));
        checkButton();
      })
      .catch((error) => {
        throw error;
      });
  };

  const onClick = (productSeq) => {
    navigate(`/item-detail/info/${productSeq}`);
   
  }

  const toggleWish = async (productSeq) => {
    try {
      const response = await api_get_header_token(`/shop/wish-modify/${productSeq}`);
      if (response.status === "ok") {
        setInfo((prevInfo) => {
          const updatedInfo = prevInfo.map((item) => {
            if (item.productSeq === productSeq) {
              item.wish = response.message === "N" ? "N" : "Y";
            }
            return item;
          });
          return updatedInfo;
        });
        setWish((prevWish) => ({
          ...prevWish,
          [productSeq]: response.message === "N" ? "N" : "Y",
        }));
      } else {
        Cookies.remove("jwtToken");
        document.cookie = "jwtToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=yourish.jp;";
        alert("ログインしてください。");
      }
    } catch (error) {
      window.location.reload();
    }
  };

  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth);

  useEffect(() => {
    const handleResize = () => {
      setDeviceWidth(window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const theme = {
    deviceWidth: deviceWidth
  };

   return (
    <>
    <ItemListWrap>
      <ItemStyledUl color={color} margintop={margintop}>
        {data && data.map((list, index) => {
          const productSeq = list.productSeq;
          const isWish = wish[productSeq] === "Y" || list.wish === "Y";

          return (
            <ItemStyledLi key={list.recentViewSeq}>
            {trash === 1 ? (
              <HeartImg
              src={
                  deleteCheck[list?.recentViewSeq] === 1
                    ? pinkcirclecheck
                    : graycirclecheck
                }
              alt="heartbasic"
              onClick={() => handleTrashClick(list.recentViewSeq)}
              />
            ) : (
              isWish ? (
                <HeartImg
                src={heartactive}
                alt="heart"
                onClick={() => toggleWish(productSeq)}
                isSpecial={list.collaboration}
              />
              ) : (
                <HeartImg className="heart"
                src={heartbasic}
                alt="heart"
                onClick={() => toggleWish(productSeq)}
                isSpecial={list.collaboration}
              />
              )
            )}
            <ItemDiv onClick={() => onClick(productSeq)}>
              <ThemeProvider theme={theme}>
                <ItemImg
                  src={list.productImageArrays}
                  alt={list.title}
                  isSpecial={list.collaboration}
                />
              </ThemeProvider>
              {/* SOLD OUT */}
              {list.soldout === "Y" ? (
                <SoldOutDiv>
                  <div>SOLD OUT</div>
                </SoldOutDiv>
              ) : (
                ""
              )}
              {/* Now Use */}
              {isNowuse ?
                <NowUseDiv>
                  <div>使用期限 {list.expireDate}</div>
                </NowUseDiv> : ""}
              {/* COMING SOON */}
              {isFirstCome ? (
                <FirstComeDiv>
                  <div className='first-come'>
                    <p>Coming Soon</p>
                    <p>{startDate}</p>
                    {/* <TimeCount startDate={startDate}></TimeCount> */}
                  </div>
                </FirstComeDiv>
              ) : (
                ""
              )}
              <ItemTextBoxDiv>
                <TitleBoxDiv>
                  <ItemBrandSpan>{list.subCategory}</ItemBrandSpan>
                  <StyledFaStar />
                  <span className='score'>{list.averageScore}</span>
                  <span className='num'>({list.scoreCount})</span>
                </TitleBoxDiv>
                <ItemSubTextDiv color={color}>{list.sub}</ItemSubTextDiv>
                <ItemBrand>
                  <span>{list.brand}</span>
                </ItemBrand>
                <PriceBoxDiv>
                  <span>{list.discountPercent}%</span>
                  <span className="price">
                  {list.priceYen && <NumberFormat number={list.priceYen} />}円
                  </span>
                  <span>
                  {list.discountedPrice && <NumberFormat number={list.discountedPrice} />}円
                  </span>
                </PriceBoxDiv>
              </ItemTextBoxDiv>
            </ItemDiv>
          </ItemStyledLi>
          )}
        )}
      </ItemStyledUl>
    </ItemListWrap>
    <AskModal
      visible={modal}
      title="選択した商品を削除しますか？"
      confirmText='はい'
      cancelText='いいえ'
      onConfirm={onConfirm}
      onCancel={onCancel}
    />
    </>
  );
};
export default RecentListProduct;

const ItemImg = styled.img`
  ${itemBoxShadow}
  ${itemMediaWithStyle}
  border-radius: 15px;
  height: 204px;
  ${props => props.isSpecial && css`
		padding: 10px;
  `}
  @media screen and (max-width: 500px) {
    height: ${({ theme }) => `calc(${theme.deviceWidth}px * 0.4)`};
  }
  @media screen and (max-width: 355px) {
    height: ${({ theme }) => `calc(${theme.deviceWidth}px * 0.75)`};
    min-height: 200px;
  }
`;