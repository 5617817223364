import React from 'react';
import { ContentWrapper, RootWrapper } from '../components/Common';
import HeaderWithBack from '../components/HeaderWithBack';
import styled from 'styled-components';
import { useState,useEffect } from 'react';
import { api_get  } from '../apis/apis';
import { addLineBreaks } from "../utils/addLineBreaks"; 
import NavBasic from '../components/NavBasic';
const ToggelBox = styled.div`
  width: 100%;
  font-size: 12px;
  border: 2px solid var(--color-main);
  border-radius: 20px;
  margin-bottom: 10px;
`;

const QuestionBox = styled.div`
  height: 40px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 15px;
  cursor: pointer;
  span {
    color: var(--color-main);
  }
  .title {
    display: flex;
    align-items: center;
    font-family: var(--font-jp-bold);
  }
`;

const AnswerBox = styled.div`
  font-weight: 400;
  line-height: 1.4;
  color: var(--color-gray4);
  margin: 15px;
  font-family: var(--font-jp-regular);
`;

const LineDiv = styled.div`
  border-top: 0.5px solid var(--color-main);
`;



const InternationalShopping = () => {
  const [info, setInfo] = useState({});
  const Shopping = async () => {
  
    const url = `/international-shopping`;
    const response = await api_get(url)

    setInfo(response);
   
  }

  useEffect(() => {
    Shopping();
  }, []);


  const [isOpen, setIsOpen] = useState(false);
  const toggleBox = () => {setIsOpen(!isOpen);}
  const [isOpen2, setIsOpen2] = useState(false);
  const toggleBox2 = () => {setIsOpen2(!isOpen2);}
  const [isOpen3, setIsOpen3] = useState(false);
  const toggleBox3 = () => {setIsOpen3(!isOpen3);}
  const [isOpen4, setIsOpen4] = useState(false);
  const toggleBox4 = () => {setIsOpen4(!isOpen4);}
  const [isOpen5, setIsOpen5] = useState(false);
  const toggleBox5 = () => {setIsOpen5(!isOpen5);}
  const [isOpen6, setIsOpen6] = useState(false);
  const toggleBox6 = () => {setIsOpen6(!isOpen6);}
  const [isOpen7, setIsOpen7] = useState(false);
  const toggleBox7 = () => {setIsOpen7(!isOpen7);}
  const [isOpen8, setIsOpen8] = useState(false);
  const toggleBox8 = () => {setIsOpen8(!isOpen8);}
  const [isOpen9, setIsOpen9] = useState(false);
  const toggleBox9 = () => {setIsOpen9(!isOpen9);}
  const [isOpen10, setIsOpen10] = useState(false);
  const toggleBox10 = () => {setIsOpen10(!isOpen10);}
  const [isOpen11, setIsOpen11] = useState(false);
  const toggleBox11 = () => {setIsOpen11(!isOpen11);}
  


  return (
    <RootWrapper>
      <HeaderWithBack eng="true" text={"INTERNATIONAL SHOPPING"} />
      <ContentWrapper>

      {info &&
      <>
        <ToggelBox>
          <QuestionBox onClick={toggleBox}>
            <div className='title'>
            Shipping General
            </div>
            <span>▼</span>
          </QuestionBox>
          {isOpen && 
            <>
              <LineDiv />
              <AnswerBox>
                {addLineBreaks(info.shippingGeneral)}
              </AnswerBox>
            </>
          }
        </ToggelBox>
        <ToggelBox>
          <QuestionBox onClick={toggleBox2}>
            <div className='title'>
            Parcel Tracking
            </div>
            <span>▼</span>
          </QuestionBox>
          {isOpen2 && 
            <>
              <LineDiv />
              <AnswerBox>
                {addLineBreaks(info.parcelTracking)}
              </AnswerBox>
            </>
          }
        </ToggelBox>
        <ToggelBox>
          <QuestionBox onClick={toggleBox3}>
            <div className='title'>
            Order ValueLimit
            </div>
            <span>▼</span>
          </QuestionBox>
          {isOpen3 && 
            <>
              <LineDiv />
              <AnswerBox>
                {addLineBreaks(info.orderValueLimit)}
              </AnswerBox>
            </>
          }
        </ToggelBox>
        <ToggelBox>
          <QuestionBox onClick={toggleBox4}>
            <div className='title'>
            Delivery Failure
            </div>
            <span>▼</span>
          </QuestionBox>
          {isOpen4 && 
            <>
              <LineDiv />
              <AnswerBox>
                {addLineBreaks(info.deliveryFailure)}
              </AnswerBox>
            </>
          }
        </ToggelBox>
        <ToggelBox>
          <QuestionBox onClick={toggleBox5}>
            <div className='title'>
            Customs Tax
            </div>
            <span>▼</span>
          </QuestionBox>
          {isOpen5 && 
            <>
              <LineDiv />
              <AnswerBox>
                {addLineBreaks(info.customsTax)}
              </AnswerBox>
            </>
          }
        </ToggelBox>
        <ToggelBox>
          <QuestionBox onClick={toggleBox7}>
            <div className='title'>
            Hazardous Goods
            </div>
            <span>▼</span>
          </QuestionBox>
          {isOpen7 && 
            <>
              <LineDiv />
              <AnswerBox>
                {addLineBreaks(info.hazardousGoods)}
              </AnswerBox>
            </>
          }
        </ToggelBox>
        <ToggelBox>
          <QuestionBox onClick={toggleBox8}>
            <div className='title'>
            Customs Refusal
            </div>
            <span>▼</span>
          </QuestionBox>
          {isOpen8 && 
            <>
              <LineDiv />
              <AnswerBox>
                {addLineBreaks(info.customsRefusal)}
              </AnswerBox>
            </>
          }
        </ToggelBox>
        <ToggelBox>
          <QuestionBox onClick={toggleBox9}>
            <div className='title'>
            Return And Refund
            </div>
            <span>▼</span>
          </QuestionBox>
          {isOpen9 && 
            <>
              <LineDiv />
              <AnswerBox>
                {addLineBreaks(info.returnAndRefund)}
              </AnswerBox>
            </>
          }
        </ToggelBox>
        <ToggelBox>
          <QuestionBox onClick={toggleBox10}>
            <div className='title'>
            Payment Guide
            </div>
            <span>▼</span>
          </QuestionBox>
          {isOpen10 && 
            <>
              <LineDiv />
              <AnswerBox>
                {addLineBreaks(info.paymentGuide)}
              </AnswerBox>
            </>
          }
        </ToggelBox>
        <ToggelBox>
          <QuestionBox onClick={toggleBox11}>
            <div className='title'>
            Shipping Notice
            </div>
            <span>▼</span>
          </QuestionBox>
          {isOpen11 && 
            <>
              <LineDiv />
              <AnswerBox>
                {addLineBreaks(info.shippingNotice)}
              </AnswerBox>
            </>
          }
        </ToggelBox>
        </>
       }

      </ContentWrapper>
     
    </RootWrapper>
  );
};

export default InternationalShopping;