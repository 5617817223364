import React from 'react';

const SubStringText = (props) => {
	const { text, truncatelength } = props;
	const truncatedText = text && text.length > truncatelength ? text.substring(0, truncatelength) + '..' : text;
	return (
		<>{truncatedText}</>
	);
};

export default SubStringText;