import React from "react";
import styled, { css } from "styled-components";
import backicon from "../assets/header/backicon.svg";
import { useNavigate } from "react-router-dom";
function HeaderWithBack({ text, eng=false }) {
  const navigate = useNavigate();

  const handleGoBack = () => {
    const currentPath = window.location.pathname;

    if (document.referrer.startsWith("https://yourish.jp")) {
      if (currentPath === "/Mypage/mycoupons") {
        navigate("/Mypage");
      } else {
        navigate(-1);
      }
    } else {
      window.location.href = "https://yourish.jp";
    }
  };

  return (
    <StyledHeader>
      <img src={backicon} alt="backicon" onClick={handleGoBack} />
      <Title eng={eng} text={text}>
        {text}
      </Title>
    </StyledHeader>
  );
}
export default HeaderWithBack;

const StyledHeader = styled.header`
  height: 50px;
  background-color: #fff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  img {
    position: absolute;
    left: 20px;
    cursor: pointer;
  }
  
`;

const Title = styled.div`
  font-family: var(--font-jp-bold);
  font-size: 20px;
  width: fit-content;
  ${props => props.eng && css`
    font-family: var(--font-english);
    font-weight: 600;
  `}
`; 
