import React, { useEffect } from "react";
import HeaderWithBack from "../../components/HeaderWithBack";
import SignUpForm from "../../components/SignUpForm";
import { ContentWrapper, RootWrapper } from "../../components/Common";

export default function SignUp() {
  return (
    <RootWrapper>
      <HeaderWithBack text="新規会員登録"></HeaderWithBack>
      <ContentWrapper>
        <SignUpForm/>
      </ContentWrapper>
    </RootWrapper>
  );
}



