import React from "react";
import styled from "styled-components";
function BtnRoundBasic({ text, bgcolor, width, fontsize,onClick }) {
  return (
    <StyledButton bgcolor={bgcolor} width={width} fontsize={fontsize} onClick={onClick}>
      {text}
    </StyledButton>
  );
}
export default BtnRoundBasic;

const StyledButton = styled.button`
  min-width: 331px;
  min-height: 50px;
  width: ${(width) => width?.width || "100%"};
  background-color: ${(bgcolor) => bgcolor?.bgcolor || "#85DBD7"};
  border-radius: 50px;
  font-size: ${(fontsize) => fontsize?.fontsize || "24px"};
  font-weight: 700;
  border: none;
  color: ${(color) => color?.color || "#fff"};
`;
