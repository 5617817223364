import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import cart from "../assets/nav/cart.svg";
import cart_active from "../assets/nav/cart_active.svg";
import home from "../assets/nav/home.svg";
import home_active from "../assets/nav/home_active.svg";
import interest from "../assets/nav/interest.svg";
import interest_active from "../assets/nav/interest_active.svg";
import mypage from "../assets/nav/mypage.svg";
import mypage_active from "../assets/nav/mypage_active.svg";
import video from "../assets/nav/video.svg";
import video_active from "../assets/nav/video_active.svg";
import { isLogin } from "../atoms/atoms";
import { useSetRecoilState } from "recoil";
import Cookies from "js-cookie";
import { api_get } from "../apis/apis";
import { isMember } from "../utils";
const NavBasic = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const jwtToken = Cookies.get("jwtToken");
  
  const handleToMypage = async () => {
    const token = await isMember();

    if(token.status==="fail"){
      Cookies.remove("jwtToken");
      document.cookie = "jwtToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=yourish.jp;";
      navigate('/sign-in');
    }
    else{
      navigate(`/Mypage`, { state: "mypage_active" });
    }

  }

  const handleToShopCart = async () => {
    const token = await isMember();
    if(token.status==="fail"){
      Cookies.remove("jwtToken");
      document.cookie = "jwtToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=yourish.jp;";
      navigate('/sign-in');
    }
    else{
      navigate(`/basket`, { state: "basket_active" })
    }
  }
  const handleToWish = async () => {
    const token = await isMember();
    if(token.status==="fail"){
      Cookies.remove("jwtToken");
      document.cookie = "jwtToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=yourish.jp;";
      navigate('/sign-in');
    }
    else{
      navigate(`/interest`, { state: "interest_active" })
    }
  }
  
  return (
    <StyledNav>
      <IconBox onClick={() => navigate(`/`, { state: "home_active" })}>
        <img
          src={location.state === "home_active"|| location.pathname === "/" ? home_active : home}
          alt="home"
        />
        <span className={location.state === "home_active" || location.pathname === "/" ? "active": null}>ホーム</span>
      </IconBox>
      <IconBox onClick={handleToWish}>
        <img
          src={location.state === "interest_active" ? interest_active : interest}
          alt="interest"
        />
        <span className={location.state === "interest_active" ? "active": null}>お気に入り</span>
      </IconBox>
      <IconBox onClick={() => navigate(`/watch`, { state: "video_active" })}>
        <img
          src={location.state === "video_active" ? video_active : video}
          alt="video"
        />
        <span className={location.state === "video_active" ? "active": null}>ビデオ</span>
      </IconBox>
      <IconBox onClick={handleToShopCart}>
        <img
          src={location.state === "basket_active" ? cart_active : cart}
          alt="cart"
        />
        <span className={location.state === "basket_active" ? "active": null}>カート</span>
      </IconBox>
      <IconBox onClick={handleToMypage}>
        <img
          src={location.state === "mypage_active" ? mypage_active : mypage}
          alt="mypage"
        />
        <span className={location.state === "mypage_active" ? "active": null}>マイページ</span>
      </IconBox>
    </StyledNav>
  );
};
export default NavBasic;

const StyledNav = styled.nav`
  position: fixed;
  bottom: 0;
  max-width: 500px;
  width: 100%;
  height: 75px;
  margin: auto;
  background: #fff;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  box-shadow: 0px -1px 27px 0px rgba(185, 185, 185, 0.2);
  z-index: 99;
  display: flex;
`;

const IconBox = styled.div`
  width: 20%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  img {
    width: 24px;
    height: 24px;
  }
  span {
    font-size: 12px;
    color: #929292;
    margin-top: 3px;
    font-family: var(--font-jp-light);
    white-space: nowrap;
    @media screen and (max-width: 375px) {
      font-size: 10px;
    }
  }
  span.active {
    color: var(--color-sub);
    font-family: var(--font-english);
    font-weight: 700;
  }
`;