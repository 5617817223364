import React from "react";
import styled from "styled-components";
import { StyledPPale, flexBetweenCenter, itemBoxShadow } from "./Common";
import NumberFormat from "./NumberFormat";
import trash from "../assets/common/gray-delete.svg";
import { api_delete_header_token } from "../apis/apis";

function ListItemCheck({ onClick, discountedPrice, description, price, imgUrl, selectoptions, selectquantity, seq, fetchData }) {

  const handleClickDelete = async (e, seq) => {
    e.stopPropagation();

    const result = await api_delete_header_token(`/shop/cart-delete?shopCartSeq=${seq}`);
      if(result.status==="ok"){
        fetchData();
      }
  };

  return (
    <StyledDiv onClick={onClick}>
      <div className="image">
        <img src={imgUrl} alt="img" />
      </div>
      <div className="detail">
        <ItemTitle>{description}</ItemTitle>
        <div>
          <StyledPPale margin>{selectoptions} / {selectquantity}</StyledPPale>
        </div>
      </div>
      <div className="price">
        {price !== discountedPrice &&
          <StyledPrice><NumberFormat number={price} />円</StyledPrice>
        }
        <div className="discount"><NumberFormat number={discountedPrice} />円</div>
      </div>
      <img
        src={trash}
        alt="trash"
        className="trash"
        onClick={(e) => handleClickDelete(e, seq)}
      />
    </StyledDiv>
  );
}

export default ListItemCheck;

const StyledDiv = styled.div`
  ${flexBetweenCenter}
  width: 100%;
  cursor: pointer;
  position: relative;
  .image {
    ${itemBoxShadow}
    background-color: #d9d9d9;
    min-width: 77px;
    width: 77px;
    height: 77px;
    border-radius: 20px;
    margin: 10px 15px;
    overflow: hidden;
    img {
      width: 100%;
    }
  }
  .detail {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 16px;
    color: rgba(97, 97, 97, 1);
  }
  .price {
    display: flex;
    flex-direction: column;
    align-items: center;
    white-space: nowrap;
    margin-top: 10px;
    .discount {
      font-weight: 700;
      font-size: 20px;
      margin-top: 4px;
      @media screen and (max-width: 500px) {
        font-size: 16px;
      }
    }
  }
  .trash {
    position: absolute;
    top: 0;
    right: 0;
    width: 14px;
    height: 14px;
    cursor: pointer;
  }
`;

const StyledPrice = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: rgba(217, 217, 217, 1);
  text-decoration: line-through;

  @media screen and (max-width: 500px) {
    font-size: 12px;
  }
`;

const ItemTitle = styled.span`
  font-size: 12px;
  font-weight: 400;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;