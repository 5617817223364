import React, { useEffect, useState } from "react";
import styled from "styled-components";
import backicon from "../assets/header/backicon.svg";
import ListItemCheck from "../components/ListItemCheck";
import BtnRoundBasic from "../components/BtnRoundBasic";
import NavBasic from "../components/NavBasic";
import { useNavigate } from "react-router-dom";
import {
  ContentWrapper,
  DivFlexBetween,
  RootWrapper,
  flexCenterCenter,
  CheckBoxPink
} from "../components/Common";
import Button from "../components/Button";
import AskModal from '../components/AskModal';
import { api_delete_header_token, api_get_header_token_then } from "../apis/apis";
import SubStringText from "../components/SubStringText";
import NumberFormat from "../components/NumberFormat";

function Basket() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [allChecked, setAllChecked] = useState(true);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    fetchProductData()
  }, []);

  const fetchProductData = async () => {
    await api_get_header_token_then(`/user/shop-cart/list`)
    .then((response) => {
      const basketList = response.data.list;
      const processedData = basketList.map(item => ({
        ...item,
      }));
      setData(processedData);
      
    })
    .catch((error) => {
      throw error;
    });
  };

  const onClick = (productSeq) => {
    const params = {
      state: productSeq,
    };
    navigate(`/item-detail/info/${productSeq}`);
  };

  const handleItemCheck = (shopCartSeq) => {
    const newData = data.map(item => {
      if (item.shopCartSeq === shopCartSeq) {
        return {
          ...item,
          checked: !item.checked,
        };
      }
      return item;
    });
    setData(newData);
    const allChecked = newData.every(item => item.checked); // 모든 아이템이 체크되었는지 확인
    setAllChecked(allChecked); // all-check 상태 업데이트
  };
  const handleAllCheck = () => {
    const checkedStatus = !allChecked;
  
    const newData = data.map(item => ({
      ...item,
      checked: checkedStatus,
    }));
  
    setData(newData);
    setAllChecked(checkedStatus);
  };


  const showDeleteModal = () =>{
    setModal(true);
  }
  
  const onCancel = () => {
    setModal(false);
  };

  const okDelete = async () => {
    const checkedItems = data
    .filter(item => item.checked)
    .map((item, index) => `shopCartSeq=${item.shopCartSeq}`);
    if (checkedItems.length > 0) {
      const result = await api_delete_header_token(`/shop/cart-delete?${checkedItems.join('&')}`);
      if(result.status==="ok"){
        fetchProductData();
      }
    }
    setModal(false);
  };

  // 선택한 상품들의 총 가격 계산
  const getTotalPrice = () => {
    let totalPrice = 0;
    data.forEach(item => {
      if (item.checked) {
        totalPrice += item.price * item.quantity;
      }
    });
    return totalPrice;
  };

  // 선택한 상품들의 총 할인금액
  const getTotalDiscounted = () => {
    let totalPrice = 0;
    data.forEach(item => {
      if (item.checked) {
        totalPrice += (item.price-item.discountedPrice)*item.quantity;
      }
    });
    return totalPrice;
  };

  const shippingFee = ((getTotalPrice() - getTotalDiscounted()) >= 4500 || (getTotalPrice() - getTotalDiscounted()) === 0) ? 0 : 600;

  const goOrder = () => {
    const products = [];
    data.forEach(item => {
      if (item.checked) {
        const product = {
          productSeq: item.productSeq,
          productOptions: item.productOptions,
          quantity: item.quantity,
          price:item.discountedPrice * item.quantity,
          couponSeq:0,
          couponDiscountPrice:0
        };
        products.push(product);
      }
    });
    if (products.length === 0) {
      alert('決済する商品はありません。');
    } else {
      navigate(`/order`,{state : {products}});
    }
  }


  return (
    <>
    <RootWrapper>
      <StyledDiv>
        <StyledHeader>
          {/*<img src={backicon} alt="backicon" onClick={() => navigate(-1)} />*/}
          <Title>ショッピングカート</Title>
          {/*<img src={trash} onClick={showDeleteModal} alt="trash" />*/}
        </StyledHeader>
        <ContentWrapper>
          <CheckBoxPink>
            <div>
              <input
                type="checkbox"
                className="all-check"
                text="すべて選択"
                checked={allChecked}
                onChange={handleAllCheck}
              />
              <span>すべて選択</span>
            </div>
            <div className="delete" onClick={showDeleteModal}>削除</div>
          </CheckBoxPink>
          <StyledItemUl>
            {data && data.length > 0 ? data.map((list, i) => (
              <li key={list.productSeq}>
                <CheckBoxPink>
                  <input
                    type="checkbox"
                    checked={list.checked}
                    onChange={() => handleItemCheck(list.shopCartSeq)}
                  />
                </CheckBoxPink>
                <ListItemCheck
                  imgUrl={list.productImg}
                  brand={list.brand}
                  description={list.title}
                  price={(list.price * list.quantity).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  discountedPrice={(list.discountedPrice * list.quantity).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  selectoptions={<SubStringText text={list.productOptions} truncatelength={7} />}
                  selectquantity={list.quantity}
                  onClick={() => {onClick(list.productSeq)}}
                  seq={list.shopCartSeq}
                  fetchData={fetchProductData}
                />
              </li>
            )) : 
              <AlertTextDiv>カートに入っている商品がありません。</AlertTextDiv>
            }
            <StyledPrice>
              
              {getTotalPrice()-getTotalDiscounted() < 4500 ? <p>4,500円以上で送料無料</p> : <div/>}
              <StyledButton to={`/category/ALL`}>ショッピングを続ける</StyledButton>
            </StyledPrice>
          </StyledItemUl>
        </ContentWrapper>
        <StyledTotalPrice>
          <div className="box">
            <DivFlexBetween className="total-price">
              <h2>お支払い合計</h2>
              <h2><NumberFormat number={getTotalPrice()-getTotalDiscounted()+shippingFee} />円</h2>
            </DivFlexBetween>
            <div className="price">
              <p className="between">商品合計金額 <span><NumberFormat number={getTotalPrice()} />円</span></p>
              <p className="between">割引金額 <span>-<NumberFormat number={getTotalDiscounted()} />円</span></p>
              <p className="between">送料 <span>{shippingFee}円</span></p>
            </div>
            <BtnRoundBasic onClick={goOrder} text="注文する" bgcolor="rgba(255, 123, 154, 1)" />
          </div>
        </StyledTotalPrice>
      </StyledDiv>
      <NavBasic />
      <AskModal
        visible={modal}
        title="選択した商品をショッピングカートから削除しますか？"
        confirmText='はい'
        cancelText='いいえ'
        onConfirm={okDelete}
        onCancel={onCancel}
      />
    </RootWrapper>
  </>
  );
}

export default Basket;

const StyledDiv = styled.div`
  width: 100%;
  background: rgba(255, 243, 246, 1);
  min-height: 100vh;
`;

const StyledHeader = styled.header`
  height: 50px;
  background-color: #fff;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  img {
    display: inline-block;
    position: absolute;
    left: 15px;
  }
  box-shadow: 0 1px 5px 0 rgba(60, 60, 60, 0.08);
`;
const Title = styled.h1`
  display: inline-block;
  font-size: 20px;
  font-weight: bold;
  width: fit-content;
`;
const StyledItemUl = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
  li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    height: 125px;
    background: #fff;
    border-radius: 15px;
    padding: 25px 10px 25px 10px;
  }
`;

const StyledTotalPrice = styled.div`
  width: 100%;
  padding: 20px 20px 75px;
  h2 {
    font-size: 20px;
    margin: 40px 10px 18px;
    font-weight: bold;
  }
  h2:last-child {
    color: var(--color-sub);
  }
  div.total-price {
    border-bottom: 2px solid rgba(194, 194, 194, 0.3);
    width: 95%;
    justify-content: space-between;
  }

  .price {
    width: 90%;
    p {
      margin-bottom: 10px;
      color: rgba(92, 92, 92, 1);
    }
    p:first-child {
      margin-top: 16px;
    }
    p:last-child {
      margin-bottom: 58px;
    }
    span {
      margin-left: 10px;
      color: rgba(146, 146, 146, 1);
    }
  }
`;

const StyledPrice = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 25px auto;
  font-size: 12px;
  span {
    font-weight: bold;
  }

  span:last-child {
    font-size: 16px;
    color: var(--color-sub);
  }
  p {
    margin: 0 auto 6px;
    color: rgba(100, 100, 100, 1);
  }

  p:last-child {
    color: var(--color-sub);
    border: 1px solid var(--color-sub);
    border-radius: 50px;
    min-width: 206px;
    text-align: center;
    width: 90%;
    height: 27px;
    letter-spacing: -0.025em;
    margin: auto;
    padding-left: 10px;
    line-height: 2em;
  }

  div.left {
    flex-direction: row;
  }
`;

const StyledButton = styled(Button)`
  color: var(--color-sub);
  border: 1px solid var(--color-sub);
  border-radius: 40px;
  text-align: center;
  height: 32px;
  display: flex;
  align-items: center;
  letter-spacing: -0.025em;
  margin: auto;
  padding: 10px;
  line-height: 2em;
`;

const AlertTextDiv = styled.div`
  ${flexCenterCenter}
  height: 250px;
  color: var(--color-gray3);
`;