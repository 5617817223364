import { atom } from "recoil";

export const signUpDataAtom = atom({
  key: 'signUpDataAtom',
  default: {
    name: '',
    nickname: '',
    email: '',
    // code: '',
    password: '',
    password_check: '',
    isNickname: false,
    isCode: false,
    isEmail: false,
  },
});