import React from "react";
import styled from "styled-components";
import HeaderWithBack from "../../components/HeaderWithBack";
import ListItemReview from "../../components/ListItemReview";
import { useQuery } from "react-query";
import Loading from "../Loading";
import ErrorPage from "../ErrorPage";
import { RootWrapper } from "../../components/Common";
import NavBasic from "../../components/NavBasic";
import { api_get_header_token } from "../../apis/apis";
function Myreviews() {
  const fetchReviewList = async () => {
    const response = await api_get_header_token(`/user/writeable-review/list`);
    return response;
  };
  const { isLoading, isError, data, refetch } = useQuery([], () => {
    return fetchReviewList();
  });
  return (
    <RootWrapper gray1Color>
      <StyledDiv>
        <HeaderWithBack text={"レビュー"}></HeaderWithBack>
        <div className="wrapper">
          {isLoading && <Loading />}
          {isError && <ErrorPage />}
          {data && (
            <ListItemReview data={data}/>
          )}
        </div>
      </StyledDiv>
      <NavBasic/>
    </RootWrapper>
  );
}
export default Myreviews;

const StyledDiv = styled.div`
  .wrapper {
    width: 100%;
    padding-top: 22px;
    position: relative;
  }
`;