import React from "react";
import HeaderWithBack from "../../components/HeaderWithBack";
import { useState } from "react";
import { useEffect } from "react";
import { api_get } from "../../apis/apis";
import { TermsPrivacyDiv } from "../../components/Common";
import { addLineBreaks } from "../../utils/addLineBreaks";

function Privacy() {
  const [texts, setTexts] = useState('');

  useEffect(() => {
    fetchPrivacy();
  }, []);

  const fetchPrivacy = async () => {
    const response = await api_get('/privacy-policy');
    const result = response.privacyPolicy;
    setTexts(result);
  };
  return (
    <>
      <HeaderWithBack text="個人情報の収集及び利用目的 " />
      <TermsPrivacyDiv>{addLineBreaks(texts)}</TermsPrivacyDiv>
    </>
  );
}
export default Privacy;
