import React from "react";
import styled from "styled-components";
import { ViewMoreButton } from "./Common";
import CountDown from "./CountDown";
import { useQuery } from "react-query";
import { fetchTimeDealData } from "../utils";

const TitleTimedeal = ({ title, color, margin }) => {

  const { data } = useQuery("timeDeal", fetchTimeDealData);

  return (
    <StyledDiv color={color} title="title" text="text">
      <div className="title box">
        <h2 className="border">{title}</h2>
        <CountDown expire={data?.expire} />
        <ViewMoreButton to='/timedeal' margin={margin} isTimeDeal={true}>
          さらに表示
        </ViewMoreButton>
      </div>
    </StyledDiv>
  );
};
export default TitleTimedeal;

const StyledDiv = styled.div`
  width: 100%;
  margin: auto;
  .title {
    h2 {
      margin-top: 40px;
      margin-bottom: 5px;
      font-weight: 700;
      font-size: 30px;
      color: ${(color) => color?.color || "#85DBD7"};
      text-align: center;
    }
    .border {
      border-bottom: #fff 1px solid;
      padding-bottom: 10px;
    }
    p {
      color: ${(color) => color?.color || "#fff"};
      font-size: 20px;
      font-weight: 300;
      text-align: center;
      margin-top: 8px;
      margin-bottom: 10px;
    }
  }
`;