import React, { useState,useEffect } from 'react';
import styled, { css } from 'styled-components';
import {CheckBoxDiv, ContentWrapper, PtextM, StyledLine} from "./Common";
import Button from './Button';
import Cookies from "js-cookie";
import { api_get_header_token } from '../apis/apis';


const AddressForm = ({ onAddressChange, onMountData, marginBottom }) => {
  const jwtToken = Cookies.get("jwtToken");
  const [info,setInfo] = useState({});
  const [defaultAddress,setDefaultAddress] = useState('');

  useEffect(() => {
    setDafault();
  }, []);

  useEffect(() => {
    const data = defaultAddressConfirm();
    onMountData(data);
  }, [info]);
  
  const setDafault = async () => {
    const url = `/address/setdefault-info`;
    const response = await api_get_header_token(url);
    if(response.status!=="fail"){
      setInfo(response.list);
      setDefaultAddress(response.list.resName);
    }
  }
  
  const defaultAddressConfirm = () => {
    // 초기 마운트 시에 수행할 작업을 여기에 작성
    if (defaultAddress === "" || defaultAddress === null ) {
      return false;
    } else {
      return true;
    }
  }
  const [addressValue, setAddressValue] = useState('');
  
  const handleAddressValueChange = (event) => {
    const value = event.target.value;
    setAddressValue(value);

    // Call the callback function provided by the parent component
    onAddressChange(value);
  };
  return (
    <ContentWrapper marginBottom={marginBottom}>
      <BetweenBox>
        <AddressButton to="#" style={{ pointerEvents: 'none' }}>基本</AddressButton>
        <AddressButton to={`/address-list`} white="true">配送先を編集</AddressButton>
      </BetweenBox>
      
      <TitleBox>注文者情報</TitleBox>
      <PtextM>受取人名</PtextM>
      <StyledInput  defaultValue={info?.resName ?? ""} readOnly/>
      <PtextM>連絡先電話番号</PtextM>
      <StyledInput defaultValue={info?.resPhoneNumber ?? ""} readOnly/>
      <PtextM>フリガナ</PtextM>
      <StyledInput defaultValue={info?.furigana ?? ""} readOnly/>
      <StyledLine width />
      <TitleBox>配送先入力</TitleBox>
      <PtextM>配送先名</PtextM>
      <StyledInput defaultValue={info?.addressAlias?? ""} readOnly />
      <PtextM>郵便番号・ご住所</PtextM>
      <AddressBox>
        <BetweenBox>
          <StyledInput placeholder='ex) 000-0000' margin0  defaultValue={info?.zipCode ?? ""} readOnly />
          {/* <button>検索</button> */}
        </BetweenBox>
        <StyledInput margin0 marginTop10px defaultValue={info?.address ?? ""} readOnly/>
      </AddressBox>
      <PtextM>建物名・お部屋番号など</PtextM>
      <StyledInput defaultValue={info?.detailAddress ?? ""} readOnly/>
      <PtextM>配送メモ</PtextM>
      <StyledInput white="true" defaultValue={addressValue} onChange={handleAddressValueChange} placeholder='配送メモを入力してください ！'/>

    </ContentWrapper>
  );
};

export default AddressForm;

const BetweenBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    width: 80px;
    height: 40px;
    border: none;
    border-radius: 10px;
    margin-left: 5px;
    background: #000;
    color: #fff;
  }
`;

const TitleBox = styled.div`
  font-size: 18px;
  font-weight: 700;
  margin: 25px 0 15px;
`;

const AddressButton = styled(Button)`
  height: 30px;
  font-size: 12px;
  font-weight: 400;
  border-radius: 30px;
  background: rgba(81, 81, 81, 1);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: initial;
  padding: 0 10px;
  font-family: var(--font-jp-regular);
  ${props => props.white && css`
    background: #fff;
    border: 1px solid var(--color-gray4);
    color: rgba(81, 81, 81, 1);
  `}
`;

const AddressBox = styled.div`
  margin: 10px 0;
`;

const StyledInput = styled.input`
  width: 100%;
  height: 40px;
  background: rgba(236, 236, 236, 1);
  border: none;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 400;
  padding: 0 15px;
  margin: 6px 0 15px;
  font-family: var(--font-jp-regular);
  color: rgba(184, 184, 184, 1);
  ${props => props.white && css`
    background: #fff;
    border: 1px solid #000;
  `}
  ${props => props.margin0 && css`
    margin: 0;
  `}
  ${props => props.marginTop5px && css`
    margin: 5px 0 0 0;
  `}
  ${props => props.marginTop10px && css`
    margin: 10px 0 15px 0;
  `}
  &::placeholder {
    color: rgba(184, 184, 184, 1);
  }
`;