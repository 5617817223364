import React, { useState,useEffect } from 'react';
import SignInForm from "../../components/SignInForm";
import styled from "styled-components";
import logo from "../../assets/logos/logodulce.png";
import linelogo from "../../assets/logos/linelogo.svg";
import applelogo from "../../assets/logos/applelogo.svg";
import { useNavigate } from "react-router-dom";
import { ContentWrapper, MarginBottomBox, RootWrapper, flexCenterCenter } from "../../components/Common";
import NavBasic from "../../components/NavBasic";
import AskModalOne from '../../components/AskModalOne';
import { isMember } from '../../utils';
import AppleButton from '../../components/AppleButton';
import GoogleButton from '../../components/GoogleButton';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';

function SignInDulce() {
    const navigate = useNavigate();
  const jwtCheck = async () => {
    const token = await isMember();
    if(token.status==="fail"){
    }
    else{
      navigate(`/`);
    }
  }

  const [modal, setModal] = useState(false);
  // 라인 로그인 API 관련
  const uri = process.env.REACT_APP_LINE_URI;
 
  const clientId = process.env.REACT_APP_LINE_CLIENT_ID;
  const baseUrl = process.env.REACT_APP_BASEURL;

  const validtext =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let array = new Uint8Array(40);
  window.crypto.getRandomValues(array);
  array = array.map((x) => validtext.charCodeAt(x % validtext.length));
  const randomState = String.fromCharCode.apply(null, array);

  // 라인로그인
  const onClick = async () => {
    window.location.href = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${clientId}&redirect_uri=${uri}&state=${randomState}&scope=profile%20openid%20email`;
  };

  // 애플로그인(스크립트 사용)
  const handleAppleLogin = () => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
    script.defer = true;
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      window.AppleID.auth.init({
      // window.AppleID.auth.init({
        clientId: 'com.yourish.react',
        scope: 'name email',
        redirectURI: 'https://api.yourish.jp/api/apple-certification',
        state: 'sdf@#12',
        usePopup: true,
        // onSuccess: (response) => console.log('성공!',response),
        // onError: (error) => console.error('실패!',error)
      });
    };

    // 애플 로그인 성공 이벤트 처리
    document.addEventListener('AppleIDSignInOnSuccess', (data) => {
      // 성공적인 응답 처리

      const userInfo = jwtDecode(data);
      
      // 성공 로직
      // 정보 POST 해야됨
      const token = data.detail.authorization.id_token;

      const result = token.json();
      //result json 값으로 로그인 처리 
      Cookies.set('jwtToken', result);

    });

    // 애플 로그인 실패 이벤트 처리
    document.addEventListener('AppleIDSignInOnFailure', (error) => {
      // 에러 처리
      throw error;
      // 실패 로직
    });

    return () => {
      // Clean up the script when the component unmounts
      document.body.removeChild(script);
      document.removeEventListener('AppleIDSignInOnSuccess');
      document.removeEventListener('AppleIDSignInOnFailure');
    };
  }

  const yet = () => {
    setModal(true);
  };
  const onConfirm = () => {
    setModal(false);
  };

  useEffect(() => {
    jwtCheck();
    // handleAppleLogin()
  }, []);

  return (
    <RootWrapper>
      <ContentWrapper>
        <StyledBox className="box">
          <img src={logo} alt="logo" />
          <SignInForm />
          <section>
            <span className="textline">SNS LOGIN</span>
            
            <AppleButton />
            {/* <div id="appleid-signin" data-color="white" data-border="true" data-type="sign in"></div> */}

            {/* <GoogleButton/> */}
            <button className='login' name="line" onClick={onClick}>
              <img
                src={linelogo}
                alt="linelogo"
                width="18px"
                className="icon line"
              />
              Sign in with Line
            </button>
          </section>
        </StyledBox>
        <MarginBottomBox />
      </ContentWrapper>
      <NavBasic/>
      <AskModalOne
        visible={modal}
        title="現在使用できません。&#13;&#10; YOURISH会員登録をお願いします。"
        confirmText='はい'
        onConfirm={onConfirm}
      />
    </RootWrapper>
  );
}

export default SignInDulce;

const StyledBox = styled.div`
  width: 100%;
  margin: 0 auto;
  img {
    margin: 55px;
  }

  section {
    display: flex;
    flex-direction: column;
    width: 80%;

    .textline {
      display: flex;
      align-items: center;
      color: #000;
      font-size: 10px;
      margin-bottom: 10px;
      ::before {
        content: "";
        flex-grow: 1;
        margin-right: 3px;
        background: #000;
        height: 1px;
      }
      ::after {
        content: "";
        flex-grow: 1;
        margin-left: 3px;
        background: #000;
        height: 1px;
      }
    }
    button.login {
      ${flexCenterCenter}
      height: 45px;
      width: 100%;
      border-radius: 5px;
      margin: 10px auto;
      border: 1px solid #000;
      background-color: #fff;
    }
  }

  img.icon {
    margin: 0;
    //background-color: blue;
  }
  .icon.apple {
    margin-left: -15px;
  }
  .icon.line {
    margin-left: -15px;
    margin-right: 5px;
  }

  #appleid-signin {
    width: 100%;
    height: 45px;
    cursor: pointer;
  }
`;

