import React, { useState, useEffect } from "react";
import { ContentWrapper, RootWrapper } from '../components/Common';
import HeaderWithBack from '../components/HeaderWithBack';
import QAToggleBox from '../components/QAToggleBox';
import { api_get } from '../apis/apis';
import NavBasic from "../components/NavBasic";
const Notice = () => {
  const [noticeInfo,setNoticeInfo] = useState();
  const Info = async () => {
    
    const response = await api_get(`/admin-notice/list`)
    setNoticeInfo(response);
  }
  useEffect(() => {
    Info();
  }, []);

  
  return (
    <RootWrapper>
      <HeaderWithBack eng="true" text={"NOTICE"} />
      <ContentWrapper marginBottom>
      {noticeInfo && noticeInfo.map((list, index) => (
        <QAToggleBox
          key={index}
          question={list.title}
          answer={list.contents}
          showIcon={list.important === 'Y'}
          signDate={list.signDate}
          date={list.signDate}
          img={list.img}
          imgUrl={list.imgUrl}
        />
      ))}
      </ContentWrapper>
      <NavBasic/>
    </RootWrapper>
  );
};

export default Notice;