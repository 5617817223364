import React from 'react';
import HeaderWithBack from '../components/HeaderWithBack';
import { ContentWrapper, DivFlexBetween, FooterButton2, ImgBox, MarginBottomBox, PtextM, RootWrapper, flexBetweenCenter } from '../components/Common';
import styled, { css } from 'styled-components';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useState } from 'react';
import { api_get_header_token } from '../apis/apis';
import NavBasic from '../components/NavBasic';
import NumberFormat from '../components/NumberFormat';

const PaymentCompleted = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const orderIdurl = searchParams.get('orderId');
  const navigate = useNavigate();
  const { state } = useLocation();
const [data, setData] = useState({});
  const [ userInfo, setUserInfo ] = useState({});
  const [ paymentInfo, setPaymentInfo ] = useState({});
  const [ paymentMethod, setPaymentMethod ] = useState("");
  const fetchProductData = async () => {
  const state_data = state;
  const result = await api_get_header_token(`/order-lookup/detail?orderId=${orderIdurl}`);
    console.log("ORDER REsult",result)
    setData(result.list);
    setUserInfo(result.list.userAddressInfo);
    setPaymentInfo(result.list.paymentStateInfo);
    setPaymentMethod(result.list.paymentMethod);
  };

  const onClick = () => {
    navigate(`/Mypage/myorderlists`);
  }

  useEffect(() => {
    fetchProductData();
  }, []);

  return (
    <RootWrapper maincolor>
      <HeaderWithBack text={"決済完了"} />
      <ContentWrapper marginBottom>
        <ContextBox between>
          <Title>注文番号</Title>
          {data && (
            <div>
              <Num>{data.orderId}</Num>
            </div>
          )}
        </ContextBox>
        <ContextBox column>
          <StyledUl>
          {data && data.productInfo && data.productInfo.map((product, index) => (
            <li key={product.productTitle}>
              <ImgBox><img src={product.productImage} alt='product' /></ImgBox>
              <ItemBox>
                <ItemTextBox>
                  <StyledP>{product.productTitle}</StyledP>
                  <StyledPPale>{product.productOptions} / {product.quantity}</StyledPPale>
                </ItemTextBox>
                <Price><NumberFormat number={product.price} />円</Price>
              </ItemBox>
            </li>
          ))}
          </StyledUl>
        </ContextBox>
          <ContextBox column>
            <PTitle>配送先情報</PTitle>
            {userInfo && (
            <>
              <StyledBetween>
                <StyledP>受取人名</StyledP>
                <StyledPText>{userInfo.resName}</StyledPText>
              </StyledBetween>
              <StyledBetween>
                <StyledP>連絡先電話番号</StyledP>
                <StyledPText>{userInfo.resPhoneNumber}</StyledPText>
              </StyledBetween>
              <StyledBetween>
                <StyledP>フリガナ</StyledP>
                <StyledPText>{userInfo.furigana}</StyledPText>
              </StyledBetween>
              <StyledBetween>
                <StyledP>配送先名</StyledP>
                <StyledPText>{userInfo.addressAlias}</StyledPText>
              </StyledBetween>
              <StyledBetween>
                <StyledP>郵便番号・ご住所 </StyledP>
                <StyledPText>{userInfo.zipCode} {userInfo.address} {userInfo.detailAddress}</StyledPText>
              </StyledBetween>
            </>
            )}
            <StyledBetween>
              <StyledP>配送メモ</StyledP>
              <StyledPText>{data.deliveryMessage}</StyledPText>
            </StyledBetween>
          </ContextBox>
          <ContextBox column>
            <PTitle>ご注文金額</PTitle>
            <StyledBetween>
              <StyledP>商品合計金額</StyledP>
              <StyledP350><NumberFormat number={data.beforeDiscountPrice} />円</StyledP350>
            </StyledBetween>
            <StyledBetween>
              <StyledP>クーポン利用</StyledP>
              <StyledP350>-<NumberFormat number={data.couponPrice} />円</StyledP350>
            </StyledBetween>
            <StyledBetween>
              <StyledP>送料</StyledP>
              <StyledP350><NumberFormat number={data.orderDeliveryPrice} />円</StyledP350>
            </StyledBetween>
            <StyledBetween>
              <StyledP>決済手数料</StyledP>
              <StyledP350><NumberFormat number={data.paymentMethodFee} />円</StyledP350>
            </StyledBetween>
            <StyledBetween>
              <PTitle>お支払い合計</PTitle>
              <PriceSub><NumberFormat number={data.orderAmount} />円</PriceSub>
            </StyledBetween>
          </ContextBox>
          {/* <ContextBox column>
            <PTitle>お支払い方法</PTitle>
            <StyledBetween>
              <StyledP>コンビニ決済</StyledP>
              <PriceSub><NumberFormat number={data.orderAmount} />円</PriceSub>
            </StyledBetween>
          </ContextBox> */}
          {paymentInfo ? (
            <>
              {paymentMethod === "konbini" ? (
                <>
                  <ContextBox column>
                    <PTitle>メールアドレス</PTitle>
                    <StyledP>{data.email}</StyledP>
                  </ContextBox>
                  <StyledBetween>
                    <ContextBox>
                      <PTitle>コンビニ名</PTitle>
                      <StyledP>{paymentInfo.store}</StyledP>
                    </ContextBox>
                    {paymentInfo.confirmationCode === "" ? 
                      <ContextBox>
                        <PTitle>確認コード</PTitle>
                        <StyledP>_</StyledP>
                      </ContextBox>
                    :  
                      <ContextBox>
                        <PTitle>確認コード</PTitle>
                        <StyledP>{paymentInfo.confirmationCode}</StyledP>
                      </ContextBox>
                    }
                  </StyledBetween>
                  <ContextBox last column>
                    <PTitle>領収書番号</PTitle>
                    <StyledP>{paymentInfo.receipt}</StyledP>
                  </ContextBox>
                </>
              ) : (
                <MarginBottomBox />
              )}
              <div></div>
            </>
          ) : (
            <MarginBottomBox />
          )}
          <FooterButton2 onClick={() => onClick()}>確認</FooterButton2>
      </ContentWrapper>
      <NavBasic/>
    </RootWrapper>
  );
};

export default PaymentCompleted;


const ContextBox = styled.div`
  width: 100%;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.25);
  background: #fff;
  margin-bottom: 15px ;
  ${props => props.column && css`
    display: flex;
    flex-direction: column;
  `}
  ${props => props.between && css`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `}
  ${props => props.last && css`
    margin-bottom: 200px;
  `}
`;

const StyledBetween = styled(DivFlexBetween)`
  margin: 5px 0;
  div {
    &:last-child {
      margin-left: 10px;
    }
  }
`;

const StyledUl = styled.ul`
  li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const ItemTextBox = styled.div`
  margin-left: 10px;
  p {
    margin: 10px 0;
  }
`;

const ItemBox = styled.div`
  width: 100%;
  ${flexBetweenCenter}
`;

const StyledPPale = styled.p`
  font-size: 10px;
  font-weight: 400;
  color: var(--color-gray3);
`;

const StyledP350 = styled.p`
  font-size: 12px;
  font-weight: 350;
`;
const StyledP = styled.p`
  font-size: 12px;
  font-weight: 400;
  font-family: var(--font-jp-regular);
`;
const StyledPText = styled.p`
  font-size: 12px;
  font-weight: 500;
`;
const Num = styled.p`
  font-size: 14px;
  font-weight: 400;
`;
const PTitle = styled.p`
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 10px;
  font-family: var(--font-jp-bold);
`;
const Title = styled.p`
  font-size: 14px;
  font-weight: 700;
`;

const Price = styled.p`
  margin-left: 5px;
  font-size: 16px;
  font-weight: 700;
  white-space: nowrap;
`;

const PriceSub = styled.p`
  font-size: 18px;
  font-weight: 700;
  color: var(--color-sub);
`;
