import React from "react";
import styled from "styled-components";
function BtnBoxBasic({
  text,
  bgcolor,
  width,
  fontsize,
  className,
  disabled,
  onClick,
}) {
  return (
    <StyledButton
      bgcolor={bgcolor}
      width={width}
      fontsize={fontsize}
      className={className}
      disabled={disabled}
      type="button"
      onClick={onClick}
    >
      {text}
    </StyledButton>
  );
}
export default BtnBoxBasic;

const StyledButton = styled.button`
  min-width: 200px;
  min-height: 49px;
  width: ${(width) => width?.width || "500px"};
  background-color: ${(bgcolor) => bgcolor?.bgcolor || "#85DBD7"};
  border-radius: 10px;
  border: none;
  font-size: ${(fontsize) => fontsize?.fontsize || "24px"};
  font-weight: 700;
  color: ${(color) => color?.color || "#fff"};
  &:disabled {
    cursor: default;
    background: var(--color-gray2);
  }
`;
