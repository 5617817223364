import React, { useEffect, useState } from "react";
import styled from "styled-components";
import arrow from "../assets/main/arrow_top.png";

const ArrowButton = () => {

  const isItemDetailPage = window.location.pathname.startsWith("/item-detail/");

  const [isShow, setIsShow] = useState(false);

  const handleClickTop = () => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    })
  };

  useEffect(() => {
    const showButton = () => {
      setIsShow(window.scrollY > 100);
    };

    window.addEventListener("scroll", showButton);
    return () => {
      window.removeEventListener("scroll", showButton);
    }
  }, []);

  return (
    <>
      {isShow &&
        <StyledContainer>
          <Button isItemDetailPage={isItemDetailPage}>
            <Icon onClick={handleClickTop}>
              <img src={arrow} alt="icon" />
            </Icon>
          </Button>
        </StyledContainer>
      }
    </>
  );
};

export default ArrowButton;

const StyledContainer = styled.div`
  width: 100%;
`;

const Button = styled.div`
  position: fixed;
  bottom: ${(props) => (props.isItemDetailPage ? "80px" : "90px")};
  display: flex;
  flex-wrap: nowrap;
  justify-content: right;
  align-items: center;
  z-index: 99;
  width: 100%;
  max-width: 500px;
  cursor: pointer;
`;

const Icon = styled.div`
  margin-right: 20px;
  position: relative;
  display: flex;
  -webkit-box-pack: center;
  -webkit-box-align: center;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  box-sizing: border-box;
  border-radius: 50%;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 10%);
  border: solid 1px rgba(255, 255, 255, 0.95);
  background-color: rgba(255, 255, 255, 0.9);
`;