import React from 'react';
import { ContentWrapper, FooterButton, RootWrapper, flexBetweenCenter, flexCenterCenter, inputStyle } from '../../components/Common';
import HeaderWithBack from '../../components/HeaderWithBack';
import { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Button from '../../components/Button';
import { api_get, api_get_header_token } from '../../apis/apis';
import { getEncrypt } from '../../utils/authUtils';
import Cookies from 'js-cookie';

const EditMyinfo = () => {
  const navigate = useNavigate();
	const [info,setInfo] = useState({});
  const [infoProvider, setInfoProvider] = useState("");
  const [inputChanged, setInputChanged] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const [updateCopyData, setUpdateCopyData] = useState({});
  const jwtToken = Cookies.get("jwtToken");
  const baseUrl = process.env.REACT_APP_BASEURL;

  useEffect(() => {
    userInfo();
  }, []);

  const userInfo = async () => {
    const result = await api_get_header_token(`/user/my-page`);
    setInfo(result.list);
    setInfoProvider(result.list.provider);
  }

  const [editUpdateData, setEditUpdateData] = useState({
    name : info.name,
    nickname : info.nickname,
    password : "",
    password_check : "",
  });
  const [editUpdateDataState, setEditUpdateDataState] = useState({
    name : false,
    nickname : false,
    password : false,
    password_check : false,
  });

  const [checkComment , setCheckComment] = useState({
    nickname : "",
    password : "",
    password_check:""
  })
  const failComment = {
    nickname : "重複するニックネームがあります",
    password : "形式が間違っています。 もう一度入力してください。",
    password_check:"上記に入力したパスワードとは異なります。"
  }
  const successComment = {
    nickname : "使用可能なニックネームです。",
    password : "",
    password_check:"上記に入力したパスワードと同じです。"
  }

  async function onClick_api(target , endpoint){
    var params = {}
    if (target && updateCopyData[target]) {
			params = {[target]:updateCopyData[target]}
      const data = await api_get(endpoint, params);
      if(data.status === "fail"){
        setCheckComment({
          ...checkComment,
          [target] : failComment[target]
        })
        setEditUpdateDataState(prevState => ({
          ...prevState,
          [target]: false
        }));
      }else{
        setCheckComment({
          ...checkComment,
          [target] : successComment[target]
        })
        setEditUpdateDataState(prevState => ({
          ...prevState,
          [target]: true
        }));
        setReadOnly(true);
        const sign = editUpdateData;
        sign[target] = updateCopyData[target]
        setEditUpdateData(sign);
      }
    }
  }
  
  function SetEditUpdateData(target, text){
    setUpdateCopyData(() => ({
      [target]: text,
    }));

    if (target === "password"){
      const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/i;
      if(regex.test(text)){
        setCheckComment({
          ...checkComment,
          [target] : successComment[target]
        });
        const sign = editUpdateData;
        sign.password = text;
        setEditUpdateData(sign);
        setEditUpdateDataState(prevState => ({
          ...prevState,
          password: true
        }));
      }else{
        setCheckComment({
          ...checkComment,
          [target] : failComment[target]
        });
        setEditUpdateDataState(prevState => ({
          ...prevState,
          password: false
        }));
      }
    } else if (target === "password_check"){
      if (editUpdateData.password === text){
        setCheckComment({
          ...checkComment,
          [target] : successComment[target]
        })
        setEditUpdateDataState(prevState => ({
          ...prevState,
          password_check: true
        }));
      } else {
        setCheckComment({
          ...checkComment,
          [target] : failComment[target]
        });
        setEditUpdateDataState(prevState => ({
          ...prevState,
          password_check: false
        }));
      }
    } 
    else if(target === "name"){
      const sign = editUpdateData;
      sign.name = text;
      setEditUpdateData(sign);
    }
    else if(checkComment[target] !== ""){
      if(editUpdateData[target] != text){
        setCheckComment({
          ...checkComment,
          [target] : ""
        })
      }
    } else {
      setInputChanged(true);
    }
  }

  async function handleEditSubmit(){
    if (editUpdateData.name === "") {
      alert("名前を書き留めてください。")
    } else if (inputChanged){
      if (editUpdateDataState.nickname !== true) {
        alert("nickname重複チェックしてください。")
      } else if (editUpdateDataState.nickname === true) {
        if (infoProvider === "yourish") {
          if (editUpdateDataState.password !== true || editUpdateDataState.password_check !== true) {
            alert("パスワードとパスワードチェックを正しく書いてください。")
          } else if (editUpdateDataState.password === true && editUpdateDataState.password_check === true) {
            await fetch(`${baseUrl}/user/info-change`, {
              method: 'POST',
              headers: {
                Authorization: `${jwtToken}`,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                password: getEncrypt(editUpdateData.password),
                nickname: editUpdateData.nickname,
              }),
            }).then((res) => {
              if (res.ok) {
                alert("修正が完了しました。");
                navigate('/personalinfo');
              } else {
                return
              }
            }).catch((error) => {
              throw error;
            })
          } else {
          }
        } else {
          await fetch(`${baseUrl}/user/info-change`, {
            method: 'POST',
            headers: {
              Authorization: `${jwtToken}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              password: "",
              nickname: editUpdateData.nickname,
            }),
          }).then((res) => {
            if (res.ok) {
              alert("修正が完了しました。");
              navigate('/personalinfo');
            } else {
              return
            }
          }).catch((error) => {
            throw error;
          })
        }
      }
    } else if (!inputChanged) {
        if (editUpdateDataState.password !== true || editUpdateDataState.password_check !== true) {
          alert("パスワードとパスワードチェックを正しく書いてください。")
        } else if (editUpdateDataState.password === true && editUpdateDataState.password_check === true) {
          await fetch(`${baseUrl}/user/info-change`, {
            method: 'POST',
            headers: {
              Authorization: `${jwtToken}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              password: getEncrypt(editUpdateData.password),
              nickname: info.nickname,
            }),
          }).then((res) => {
            if (res.ok) {
              alert("修正が完了しました。");
              navigate('/personalinfo');
            } else {
              return
            }
          }).catch((error) => {
            throw error;
          })
        } 
      }
  }

	return (
		<RootWrapper>
			<HeaderWithBack text={"私の情報を編集"} />
			<ContentWrapper>
				<InputBox>
					<div className="top_text">NAME</div>
					<StyledInput
            readOnly
						type="text"
						defaultValue={info?.name ?? ""}
						onChange={(e)=>{SetEditUpdateData("name",e.target.value)} }
					/>
				</InputBox>
				<InputBox>
					<div className="top_text">NICKNAME</div>
					<StyledSpan sub={checkComment.nickname === "重複するニックネームがあります"}>{checkComment.nickname}</StyledSpan>
					<InputBtnBox>
						<StyledInput withbtn
              maxLength="20"
							defaultValue={info ?.nickname ?? ""}
              readOnly={readOnly}
							type="text"
							autoComplete="username"
							onChange={(e)=>{SetEditUpdateData("nickname",e.target.value)} }
						/>
						<StyledButton onClick={(e) => onClick_api("nickname", "/duplication-check/nickname")} >確認</StyledButton>   
					</InputBtnBox>
				</InputBox>
				<InputBox>
					<div className="top_text">E-MAIL</div>
					<StyledInput
						defaultValue={info ?.email ?? ""} 
						type="text"
						readOnly
					/>
				</InputBox>

        { infoProvider === "yourish" ? (
          <>
            <InputBox>
              <div className="top_text">PASSWORD</div>
              <StyledSpan right sub={checkComment.password === "形式が間違っています。 もう一度入力してください。"}>{checkComment.password}</StyledSpan>
              {/* <form> */}
                <StyledInput type="password" autoComplete="new-password" placeholder="最低8文字、16文字前後で数字、特殊文字、英語各2文字以上を使用。"
                onChange={(e)=>{SetEditUpdateData("password",e.target.value)} }/>
              {/* </form> */}
            </InputBox>
            <InputBox>
              <div className="top_text">PASSWORD CHECK</div>
              <StyledSpan right sub={checkComment.password_check === "上記に入力したパスワードとは異なります。"}>{checkComment.password_check}</StyledSpan>
              {/* <form> */}
                <StyledInput type="password" autoComplete="off" 
                onChange={(e)=>{SetEditUpdateData("password_check",e.target.value)} } />
              {/* </form> */}
            </InputBox>
          </>
        ):
          <></>
        }
				<FooterButton
        	onClick={()=>handleEditSubmit()} 
      	>完了</FooterButton>
			</ContentWrapper>
		</RootWrapper>
	);
};

export default EditMyinfo;

const InputBox = styled.div`
  position: relative;
  margin-top: 15px;
  .top_text {
    font-size: 14px;
    display: inline-block;
  }
`;

const InputBtnBox = styled.div`
  ${flexCenterCenter}
  margin-top: 10px;
`;

const StyledButton = styled(Button)`
  ${flexCenterCenter}
  height: 40px;
  margin-left: 5px;
  padding: 0 20px;
  border-radius: 10px;
  background-color: var(--color-main);
  font-size: 14px;
  font-weight: 600;
  color: white;
`;

const StyledSpan = styled.span`
  font-size: 12px;
  color: var(--color-main);
  margin-left: 5px;
  ${props => props.sub && css`
    color: var(--color-sub);
    @media screen and (max-width: 368px) {
      display: block;
      margin: 10px 0 0 0;
    }
  `}
  @media screen and (max-width: 410px) {
    font-size: 10px;
  }
`;

const StyledInput = styled.input`
  ${inputStyle}
  margin-top: 10px;
  ${props => props.withbtn && css`
    margin-top: 0;
  `}
`;
