import React, { useState,useEffect,useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import AddressForm from "../components/AddressForm";
import HeaderWithBack from "../components/HeaderWithBack";
import Cookies from "js-cookie";
import CryptoJS from 'crypto-js';
import {
  CheckBoxDiv,
  ContentWrapper,
  ImgBox,
  MarginBottomBox,
  PayButton,
  PriceSub,
  RootWrapper,
  StyledP350,
  StyledLine
} from "../components/Common";
import Button from "../components/Button";
import Loading from "./Loading";
import SubStringText from "../components/SubStringText";
import NumberFormat from "../components/NumberFormat";
import { api_get_header_token,api_delete_header_token } from "../apis/apis";
import dayjs from "dayjs";


function Order() {
  const navigate = useNavigate();
  const location = useLocation();
  const [info, setInfo] = useState([]);
  const [infoname, setinfoname] = useState([]);
  const [couponToggle, setCouponToggle] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [nowSeq,setNowSeq] = useState(0);
  const [clist,setClist] = useState([]);
  const [addressValue, setAddressValue] = useState('');
  const {products} = location.state;
  const baseUrl = process.env.REACT_APP_BASEURL
  const jwtToken = Cookies.get("jwtToken");
  const [productSeqData, setProductSeqData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [addressData, setAddressData] = useState(false);
  const [inicisOrderId, setInicisOrderId] = useState(null);
  const [inicisHash, setInicisHash] = useState(null);
  

  const komojuKey = process.env.REACT_APP_KOMOJU_PUBLIC;
  
  const eximbayReturn = process.env.REACT_APP_EXIMBAY_RETURN
  const eximbayStatus = process.env.REACT_APP_EXIMBAY_STATUS
  const eximbayMid = process.env.REACT_APP_EXIMBAY_MID
  const eximbayApi = process.env.REACT_APP_EXIMBAY_API

  
  const currentDate = dayjs().format("YYYYMMDDHHmmss");

  useEffect(() => {
    userInfo();
    getInfo();
    handleMountData();
  }, []);

  const userInfo = async () => {
    const dispose = await api_delete_header_token(`/order/amount-delete`);
    const result = await api_get_header_token(`/user/my-page`);
    setinfoname(result.list);
  }

  const handleMountData = (data) => {
    setAddressData(data);
  };

  const formatProductTitle = () => {
    const maxLength = 20;
    const productTitles = info.map((item) => item.productTitle);

    let displayText;
    if(productTitles.length === 1) {
      const title = productTitles[0];
      displayText = title.length > maxLength ? title.slice(0, maxLength) + '...' : title;
    } else {
      const firstTitle = productTitles[0];
      const count = productTitles.length - 1;
      displayText = firstTitle?.length > maxLength ?
        `${firstTitle.slice(0, maxLength)} ... 외 ${count}종` :
        `${firstTitle} 외 ${count}종`;
    }

    return displayText;
  };

  const getInfo = async () => {
    const url = `${baseUrl}/order/product-info`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
          Authorization: `${jwtToken}`,
          "Content-Type": "application/json",
      },
      body: JSON.stringify({products:products}),
    });
    const result = await response.json();
    
    setInfo(result.list);
    if (result.list.length > 0) {
      const productSeqData = {};
      result.list.forEach((item) => {
        const uniqueId = item.num;
        productSeqData[uniqueId] = "N";
      });
      setProductSeqData(productSeqData);
      formatProductTitle();
    }
  }
  
  let totalPrice = 0;
  
  let delivery = 600;
  let couponPrice = 0;
  info && info.map((list, index) => {
    totalPrice += list.orginPrice;
    couponPrice += (list.orginPrice  - list.price);
  });
  

  

  if(totalPrice >= 4500){
    delivery = 0;
  }

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleClick = async () => {
    const url = `${baseUrl}/order/product-quantity-check`;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `${jwtToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({orderArrays:products}),
    });
    const result = await response.json();

    if(result.status==="ok"){
      
      try{
        const handler = Komoju.multipay.configure({
          key: komojuKey,
          token: handlePay,
        });
       
        handler.open({
          amount: totalPrice + delivery - couponPrice, 
          endpoint: "https://komoju.com",
          locale: "ja",
          currency: "JPY",
          title: "Yourish",
          description: "商品購入",
          methods: ["konbini"],
        })
      }catch(err){
        throw err;
       
      }
    
    }
  }

  const handlePay = async (token) => {

    setIsLoading(true); 
    try {

      const url = `${baseUrl}/REG543E0W244T35RW/payment?tk=${token.id}&amount=${totalPrice + delivery - couponPrice}`
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `${jwtToken}`,
        },
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();

      if(data.status==="ok"){

        const id = data.komoju_id;
        const store = data.payment_details.store;
        const deadline = data.payment_deadline;
        const type = data.type;
        const paymentMethodFee = data.payment_method_fee;
        const email = data.payment_details.email;
        const amount = data.total;
        const status = data.payment_status;
        const instructionsUrl = data.payment_details.instructions_url;
        const confirmationCode = data.payment_details.confirmation_code;
        const receipt = data.payment_details.receipt;
        const wellnetWalletUrl = data.payment_details.wellnet_wallet_url;
        const brand = data.payment_details.brand;
        const lastFourDigits = data.payment_details.last_four_digits;

        const orderWrite = async () => {
          const response = await fetch(`${baseUrl}/order/write`, {
            method: "POST",
            headers: {
              Authorization: `${jwtToken}`,
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              deliveryMessage:addressValue,
              komojuId: id,
              store : store,
              deadline : deadline,
              paymentMethod : type,
              lastFourDigits : lastFourDigits,
              paymentMethodFee : paymentMethodFee,
              productTotalPrice: totalPrice,
              deliveryPrice : delivery,
              email : email,
              paymentState : status,
              orderArrays :info,
              instructionsUrl : instructionsUrl,
              confirmationCode : confirmationCode,
              receipt : receipt,
              wellnetWalletUrl : wellnetWalletUrl,
              brand : brand,
            }),
          });
          const result = await response.json();
          if(result.status==="ok"){
            const params = {
              state: result.message
            }
            navigate(`/paymentcompleted?orderId=${result.message}`, params);
          }
        };
        
        await orderWrite();
      } else {
        return;
      }
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false); 
    }
  };

  const handleUseCoupon = async (seq,num) => {
    
    setNowSeq(num);
    const url = `${baseUrl}/coupon/available-order/list?productSeq=${seq}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `${jwtToken}`,
      },
    });
    const result = await response.json();
    setClist(result.list);
    setCouponToggle(!couponToggle);
  }

  const closeToggle = () => {
    setCouponToggle(!couponToggle);
  }

  const noapply = async (data) => {

    let ck = "";
    const checkSeq = info.map((item) => {
      if (item.num === nowSeq) {
       
        if(data.couponSeq === item.couponSeq){
          ck="ok"
        }
      }
      
    });
    if(ck===""){
      return;
    }

    setCouponToggle();
    setInfo((prevInfo) => {
      const updatedInfo = prevInfo.map((item) => {
        if (item.num === nowSeq) {
          return {
            ...item,
            price: item.orginPrice,
            couponSeq :0,
            couponDiscountPrice : 0,
          };
        }
        return item;
      });
      return updatedInfo;
    });
    const url = `${baseUrl}/coupon/using-order/${data.couponSeq}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `${jwtToken}`,
      },
    });
  }

  const apply = async (data) => {
    let ck = "";
    const checkSeq = info.map((item) => {
      if (item.num === nowSeq && item.couponSeq !==0) {
        ck="ck"
      }
    });
    if(ck==="ck"){
      return;
    }

    setCouponToggle();
    const url = `${baseUrl}/coupon/using-order/${data.couponSeq}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `${jwtToken}`,
      },
    });
    const updatedInfo = info.map((item) => {
      if (item.num === nowSeq) {
        let price = 0;
        if(data.couponType==="price"){
          if(data.couponName==="review-point" && data.discount >= 500){
            price =item.price - 500;
          }else{
            price =item.price - data.discount;
          }
        }else{
          price =item.price * ( 1 - (data.discount * 0.01))
        }
        price = Math.floor(price);
        return {
          ...item,
          price: price,
          couponSeq :data.couponSeq,
          couponDiscountPrice :item.price-price
        };
      }
      return item;
    });
    setInfo(updatedInfo);
  }

  const handleAddressChange = (defaultValue) => {
    setAddressValue(defaultValue);
  };

  const payment = async () => {
    const totalOrderPrice = totalPrice + delivery - couponPrice;
    const url = `${baseUrl}/order/eximbay/product-quantity-check`;
    

    const orderProducts = info.map(product => ({
      ...product,
      totalPrice: totalOrderPrice
    }));

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `${jwtToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        deliveryMessage:addressValue,
        deliveryPrice : delivery,
        orderArrays:orderProducts
      }),
    });
    const result = await response.json();

    if(result.status==="ok"){
      
      const payload = {
        "payment": {
          "transaction_type": "PAYMENT",
          "order_id": result.message,
          "currency": "JPY",
          "amount": totalPrice + delivery - couponPrice,
          "lang": "JP",
          "payment_method":"P000"
        
        },
        "merchant": {
          "mid": eximbayMid,
        },
        "buyer": {
          "name": infoname.name,
          "email": infoname.email
        },
        "settings":{
          "display_type":"R"
        },
        "url": {
          "return_url": eximbayReturn+`?orderId=${result.message}`,
          "status_url": eximbayStatus
        }
      };

      const headers = {
        'Authorization': 'Basic '+eximbayApi,
        'Content-Type': 'application/json'
      };

    
      try {
        const response = await fetch('https://api.eximbay.com/v1/payments/ready', {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(payload)
        });

        if (response.ok) {
          const responseData = await response.json();
          EXIMBAY.request_pay({
            "fgkey" : responseData.fgkey,
            "payment" : {
                "transaction_type" : "PAYMENT",
                "order_id" : result.message,
                "currency" : "JPY",
                "amount" : totalPrice + delivery - couponPrice,
                "lang" : "JP",
                "payment_method":"P000"
                // "multi_payment_method":"P101-P102"
            },
            "merchant" : {
                "mid" : eximbayMid,
            },
            "buyer" : {
                "name" : infoname.name,
                "email" : infoname.email
            },
            "settings":{
              "display_type":"R"
            },
            "url" : {
              "return_url": eximbayReturn+`?orderId=${result.message}`,
              "status_url": eximbayStatus
            }
          });

        } else {
          throw new Error('Request failed with status: ' + response.status);
        }
      } catch (error) {
        console.error(error);
      }
    }else{
      alert("エラーが発生しました。もう一度お試しください。")
      navigate('/')
    }
  }
 
  if (isLoading === true) {
    return <Loading />;
  }

  const totalOrderPrice = totalPrice + delivery - couponPrice;

  const generateSHA512Hash = (data) => {
    const hash = CryptoJS.SHA512(data);
    return hash.toString(CryptoJS.enc.Hex);
  }

  const handleSubmit = async (e) => {
    
    const url = `${baseUrl}/order/eximbay/product-quantity-check`;
    const orderProducts = info.map(product => ({
      ...product,
      totalPrice: totalOrderPrice,
    }));
  
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `${jwtToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        deliveryMessage:addressValue,
        deliveryPrice : delivery,
        orderArrays:orderProducts,
      }),
    });
    
    const result = await response.json();
    console.log(result)
    if(result.status==="ok") {

      var timeStamp = currentDate;

      let hash =
       process.env.REACT_APP_INICIS_KEY
        + process.env.REACT_APP_INICIS_MID
        + timeStamp
        + totalOrderPrice
        + result.message;
  
      const hash512 = generateSHA512Hash(hash);
      console.log(hash)
      console.log(hash512)

      document.querySelector('input[name="hashData"]').value = hash512;
      document.querySelector('input[name="timestamp"]').value = timeStamp;
      document.querySelector('input[name="orderId"]').value = result.message;
      document.querySelector('input[name="amount"]').value = totalOrderPrice;
      
    
      const paymethodString = "CARD,CVS";
      const paymethodArray = paymethodString.split(",");
      // , "CVS", "PAYpay", "LINEpay"
      // "linepay": {
        // "productImageUrl": "https://merchantdomain.com/product.png"
      // }
      const paymentDatas = {
				"paymentUI": {
					"language": "JP",
					"logoUrl": "https://d3v0pqqq3eh42w.cloudfront.net/app_logo.png",
					"colorTheme": "orange"
				},
				"payment": {
					"paymethod": ["CARD"],
					"isMobile": "true",
					"card": {
						"payType": ["one"]
					}
				},
				"sbpsPayment": {
					"userId": infoname.name
				}
			}

      document.querySelector('input[name="extraData"]').value = JSON.stringify(paymentDatas);


      // const form = document.getElementById('inicisForm');
      // Array.from(form.elements).forEach(input => console.log(input.name, input.value));

      document.getElementById('inicisForm').submit();
    }
  };

  const submitCheck = (e)=>{
    e.preventDefault();
    const data = new FormData(e.target)
    console.log("LOG",data)
  }
  return (
    <RootWrapper>
      <HeaderWithBack text={"注文・決済"} />
      <ContentWrapper>
        <TitleDiv marginBottom>注文商品数 {info && info.length}点</TitleDiv>
        <ul>
        {info && info.map((list, index) => (
          <StyledLi key={list.productSeq}>
            <ImgBox>
              <img src={list.productImage}/>
            </ImgBox>

            <TextBox>
              <BetweenBox>
                {list.state === 'common' ? (
                  <StyledButton onClick={()=>handleUseCoupon(list.productSeq,list.num)}>割引可能</StyledButton>
                ) : (
                  <></>
                )}
              </BetweenBox>
              <BetweenBox>
                <StyledDiv nomal title line>{list.productTitle}</StyledDiv>
                {list.beforePrice !== list.price &&
                  <StyledDiv gray before><NumberFormat number={list.beforePrice} />円</StyledDiv>
                }
              </BetweenBox>
              <BetweenBox>
                <StyledDiv small title>
                  <SubStringText text={list.productOptions} truncatelength={23}/> / {list.quantity}
                </StyledDiv>
                <StyledDiv large noMargin={list.beforePrice === list.price}><NumberFormat number={list.price} />円</StyledDiv>
              </BetweenBox>
            </TextBox>
          </StyledLi>
          ))}   
        </ul>
      </ContentWrapper>

      { couponToggle && 
        <PurchaseWrap>
          <div className="toggle-bg" onClick={closeToggle}></div>
          <StyledUl>
          {clist && clist.length > 0 ?
            clist.map((list, index) => (
              <li key={list.couponSeq}>
                <div className="text-box">
                <p>{list.couponType === 'price' ? `${list.discount}円` : `${list.discount}%`}</p>
                  <p>{list.couponName}</p>
                  <p>使用期限: {list.expireDate} </p>
                </div>
                {list.nowUsing === 'Y' ? (
                  <CouponButton onClick={()=>apply(list)}>使用する</CouponButton>
                ) : (
                  <CouponButton applied onClick={()=>noapply(list)} >使用中</CouponButton>
                )}
              </li>
            )) : (
              <div className="text">その商品に適用できるクーポンはありません。</div>
            )}
          </StyledUl>
        </PurchaseWrap>
      }

      <StyledLine />
      <ContentWrapper>
        <TitleDiv marginBottom>ご注文金額</TitleDiv>
        <BetweenBox marginBottom>
          <StyledP350>商品合計金額</StyledP350>
          <StyledDiv nomalGray><NumberFormat number={totalPrice} />円</StyledDiv>
        </BetweenBox>
        <BetweenBox marginBottom>
          <StyledP350>送料</StyledP350>
          <StyledDiv nomalGray>+ <NumberFormat number={delivery} />円</StyledDiv>
        </BetweenBox>
        <BetweenBox marginBottom>
          <StyledP350>クーポン利用</StyledP350>
          <StyledDiv nomalGray>- <NumberFormat number={couponPrice} />円</StyledDiv>
        </BetweenBox>
        <BetweenBox>
          <TitleDiv>お支払い合計 </TitleDiv>
          <PriceSub nomalGray><NumberFormat number={totalPrice + delivery - couponPrice} />円</PriceSub>
        </BetweenBox>
      </ContentWrapper>
      <StyledLine />
      <AddressForm onMountData={handleMountData} onAddressChange={handleAddressChange} />
      
      <StyledLine />
      <AgreementBox>
        <CheckBox>
          <input type="checkbox" checked={isChecked} onChange={handleCheckboxChange} />
          <span>注文内容の確認と支払いの同</span>

          <div>
      <form id="inicisForm" action={process.env.REACT_APP_INICIS_URL} method="POST" style={{ display: 'none' }}>
        <input type="hidden" name="cbtType" value={process.env.REACT_APP_INICIS_CBT} />
        <input type="hidden" name="mid" value={process.env.REACT_APP_INICIS_MID} />
        <input type="hidden" name="timestamp" value={currentDate} />
        <input type="hidden" name="returnUrl" value={process.env.REACT_APP_INICIS_API_URL} />
        <input type="hidden" name="buyerName" value={infoname.name} />
        <input type="hidden" name="goodName" value={formatProductTitle()} />
        <input type="hidden" name="amount" value={totalOrderPrice} />
        <input type="hidden" name="orderId" value={inicisOrderId} />
        <input type="hidden" name="userId" value={infoname.nickname} />
        <input type="hidden" name="hashData" value={inicisHash} />
        <input type="hidden" name="extraData" value="CARD" />
        {/* <input type="hidden" name="extraData_payment_paymethod" value="CARD" /> */}
        {/* <input type="hidden" name="extraData_sbpsPayment_userId" value="inicistest" /> */}
      </form>

      {/* <button type="button" onClick={handleSubmit} style={{
        background: 'transparent',s
        border: 'none',
        outline: 'none',
      }}> . </button> */}

    </div>

        </CheckBox>
        <MarginBottomBox />
      </AgreementBox>
      <form
        id="pay-form"
        method="post"
      ><input type="hidden" name="payment_details" /></form>
     
      <PayButton disabled={!isChecked || !addressData} onClick={handleClick}>コンビニ決済</PayButton>
      {/* <PayButton subColor disabled={!isChecked || !addressData} onClick={payment}>カード決済</PayButton> */}
      <PayButton subColor disabled={!isChecked || !addressData} onClick={handleSubmit}>カード決済</PayButton>

    </RootWrapper>

  );
}
export default Order;

const TitleDiv = styled.div`
  font-size: 18px;
  font-weight: 700;
  font-family: var(--font-jp-bold);
  ${props => props.marginBottom && css`
    margin-bottom: 15px;
  `}
`;

const StyledDiv = styled.div`
  font-size: 12px;
  font-weight: 700;
  color: #000;
  ${props => props.gray && css`
    color: rgba(192, 192, 192, 1);
    font-weight: 500;
    text-decoration: line-through;
  `}
  ${props => props.nomal && css`
    font-size: 12px;
    font-weight: 400;
  `}
  ${props => props.nomalGray && css`
    font-size: 14px;
    font-weight: 400;
    color: var(--color-gray4);
  `}
  ${props => props.large && css`
    font-size: 16px;
    font-weight: 600;
    color: rgba(81, 81, 81, 1);
    white-space: nowrap;
  `}
  ${props => props.small && css`
    font-size: 12px;
    font-weight: 400;
    color: rgba(192, 192, 192, 1);
  `}
  ${props => props.title && css`
    max-width: 70%;
    font-size: 12px;
  `}
  ${props => props.line && css`
    line-height: 17px;
  `}
  ${props => props.before && css`
    position: absolute;
    right: 0;
    bottom: 0;
  `}
  ${props => props.noMargin && css`
    position: absolute;
    right: 0;
    top: -10px;
  `}
  span {
    margin-left: 3px;
  }
`;

const StyledButton = styled.button`
  padding: 3px 10px;
  height: 17px;
  line-height: 17px;
  font-size: 10px;
  display: flex;
  align-items: center;
  color: #fff;
  border: none;
  border-radius: 10px;
  background: var(--color-main);
  ${props => props.subColor && css`
    background: var(--color-sub);
  `}
`;

const StyledLi = styled.li`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

const TextBox = styled.div`
  width: 90%;
  margin-left: 12px;
`;

const BetweenBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0;
  position: relative;
  ${props => props.marginBottom && css`
    margin: 0 0 20px 0;
    font-size: 12px;
  `}
`;

const AgreementBox = styled.div`
  width: 100%;
  padding: 20px;
  span {
    margin-left: 10px;
  }
`;

const PurchaseWrap = styled.div`
  max-width: 500px;
  width: 100%;
  bottom: 0;
  background-color: #fff;
  position: fixed;
  box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.5);
  border-radius: 10px 10px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 99;
  .toggle-bg {
    width: 100%;
    height: 1000%;
    background: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: -1000%;
    left: 0;
  }
`;

const StyledUl = styled.ul`
  width: 100%;
  min-height: 60vh;
  padding: 15px;
  position: relative;
  display: flex;
  flex-direction: column;
  li {
    width: 100%;
    height: 100px;
    background: #fff;
    border-radius: 20px;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    margin-bottom: 15px;
  }
  .text-box {
    p {
    font-size: 12px;
    font-weight: 400;
    color: var(--color-gray3);
    margin: 7px 0;
    &:first-child {
      font-size: 20px;
      font-weight: 600;
      color: var(--color-sub);
    }
    }
  }
  .text {
    margin-top: 20px;
    font-size: 14px;
  }
`;

const CouponButton = styled(Button)`
  padding: 0 12px;
  height: 30px;
  background: var(--color-main);
  border-radius: 30px;
  font-family: var(--font-jp-bold);
  font-size: 16px;
  color: #fff;
  ${props => props.applied && css`
    background: var(--color-sub);
  `}
  ${props => props.unapply && css`
    background: var(--color-gray4);
  `}
`;

const CheckBox = styled(CheckBoxDiv)`
  display: flex;
  align-items: center;
  span {
    font-size: 16px;
    font-weight: 400;
    color: rgba(62, 62, 62, 1);
  }
`;