import React from "react";
import styled from "styled-components";

const Fullscreen = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  max-width: 500px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 99;
`;

const AskModalBlock = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 250px;
  background: white;
  padding: 50px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px 20px 0 0;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.125);
  h2 {
    font-size: 24px;
    font-weight: 700;
    margin: 0 auto 10px;
    text-align: center;
    color: var(--color-main);
  }
  p {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 50px;
  }
`;

const StyledButton = styled.button`
  background: var(--color-main);
  border-radius: 50px;
  width: 60%;
  height: 50px;
  font-size: 24px;
  color: #fff;
  font-weight: 700;
  border: none;
`;

const Modal = ({
  visible,
  title,
  text,
  confirmText = "はい",
  onConfirm,
}) => {
  if (!visible) return null;
  return (
    <Fullscreen>
      <AskModalBlock>
        <h2>{title}</h2>
        <p>{text}</p>
        <StyledButton onClick={onConfirm}>{confirmText}</StyledButton>
      </AskModalBlock>
    </Fullscreen>
  );
};

export default Modal;


