import React from 'react';
import { RootWrapper } from '../components/Common';
import HeaderWithBack from '../components/HeaderWithBack';
import styled from 'styled-components';
import NavBasic from '../components/NavBasic';

const YourishMembership = () => {
  return (
    <RootWrapper>
      <HeaderWithBack text={"Yourish Memebership"} />
      <ImgDiv>
        <img src='https://yourish-web-storage.s3.ap-northeast-2.amazonaws.com/product-images/etc/%EB%A9%A4%EB%B2%84%EC%8B%AD.jpg' alt='yourish-membership' />
      </ImgDiv>
      <NavBasic />
    </RootWrapper>
  );
};

export default YourishMembership;

const ImgDiv = styled.div`
	img {
		width: 100%;
	}
	margin-bottom: 75px;
`;