import React from 'react';
import HeaderWithBack from "../components/HeaderWithBack";
import styled from "styled-components";
import NavBasic from "../components/NavBasic";
import { useQuery } from "react-query";
import Loading from './Loading';
import ErrorPage from './ErrorPage';
import { RootWrapper } from '../components/Common';
import ListMainProduct from '../components/ListMainProduct';
import { fetchMainData } from '../utils';
import noInterest from "../assets/no_interest.png";
import { EmptyBox } from "./RecentView";

function Interest() {
  const { isLoading, isError, data } = useQuery("mainData", fetchMainData);
  return (
    <RootWrapper>
      <StyledDiv>
        <HeaderWithBack text={"お気に入り"}></HeaderWithBack>
        <div className="interest between">
          <span>お気に入りの商品 &nbsp;
            { data && data.interest && data.interest.length > 0
              ? `${data.interest.length}`
              : "0"}
          個</span>
        </div>
        {isLoading && <Loading />}
        {isError && <ErrorPage />}
        {data && (
          <ListMainProduct
            isNowuse={false}
            isFirstCome={false}
            isTimedeal={false}
            interest={true}
            data={data.interest}
            listSubCategory={data.subCategory}
          />
        )}
        {data?.interest.length < 1 &&
          <EmptyBox>
            <img src={noInterest} alt="no-interest" />
            <p>お気に入りの商品がありません。</p>
            <span>ハートを押して好きな商品を選んでください。</span>
          </EmptyBox>
        }
      </StyledDiv>
      <NavBasic />
    </RootWrapper>
  );
}

export default Interest;

const StyledDiv = styled.div`
  margin-bottom: 60px;
  .interest {
    width: 90%;
    font-weight: 400;
    font-size: 14px;
    align-items: flex-end;
    color: var(--color-gray3);
    margin: 10px auto -5px;
  }
`;
