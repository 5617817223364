import React, { useState,useEffect } from 'react';
import { RootWrapper } from '../../components/Common';
import HeaderWithBack from '../../components/HeaderWithBack';
import FooterMaxButton from '../../components/FooterMaxButton';
import styled, { css } from 'styled-components';
import publicpost from '../../assets/common/public.png';
import privatepost from '../../assets/common/private.png';
import { useNavigate, useLocation } from 'react-router-dom';
import AskModalOne from '../../components/AskModalOne';
import Cookies from "js-cookie";
import NavBasic from '../../components/NavBasic';
import { isMember } from "../../utils";
import { api_post_header_token } from '../../apis/apis';

const ProductInquiry = () => {

  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const seq = searchParams.get('seq');

  const jwtToken = Cookies.get("jwtToken");

  const tokenCheck = async () => {
    const token = await isMember();
    if(token.status==="fail"){
      Cookies.remove("jwtToken");
      document.cookie = "jwtToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=yourish.jp;";
      navigate('/sign-in');
    }
    
  }
  useEffect(() => {
    tokenCheck();
  },[]);

  const [isPublic, setIsPublic] = useState(true);
  const [inquiryTitle, setInquiryTitle] = useState('');
  const [inquiryContent, setInquiryContent] = useState('');

  const handlePublicChange = () => {
    setIsPublic((prevState) => !prevState);
  };

  const handleTitleChange = (event) => {
    setInquiryTitle(event.target.value);
  };

  const handleContentChange = (event) => {
    setInquiryContent(event.target.value);
  };

  const handleSubmit = async () => {

    if(inquiryContent ===""){
      setModal2(true)
    }
    else{
      let open = ""
      if(!isPublic){
        open = "Y"
      }else{
        open = "N"
      }
      const prams_submit = {
        productSeq:seq,
        contents:inquiryContent,
        open:open
      }
      const result = await api_post_header_token(`/post/product-qna-write`, prams_submit);

      if(result.status==="ok"){
        setModal(true);
      } else{
        //비회원
      }
    
    }
  };

  const onConfirm = () => {
    navigate(`/item-detail/info/${seq}`);
    setModal(false);
  };

  const onConfirm2 = () => {
    setModal2(false);
  };

  return (
    <>
    <RootWrapper>
      <HeaderWithBack text={"商品お問い合わせ"} />
      <ContentWrapper>
        <LockBox>
          <img
            src={ isPublic ? privatepost   : publicpost   }
            alt={ isPublic ? 'privatepost' : 'publicpost'}
            onClick={()=>handlePublicChange()}
          />
        </LockBox>
        <StyledInput
          type='text'
          placeholder='商品お問い合わせ'
          value={"商品お問い合わせ"}
          readOnly
          onChange={handleTitleChange}
        />
        <StyledTextarea
          value={inquiryContent}
          onChange={handleContentChange}
        />
        <FooterMaxButton onClick={handleSubmit} text={"お問い合わせ"} />
      </ContentWrapper>
      <NavBasic/>
      <AskModalOne
        visible={modal}
        title="登録完了。"
        confirmText='はい'
        onConfirm={onConfirm}
      />
      <AskModalOne
        visible={modal2}
        title="内容を入力してください。"
        confirmText='はい'
        onConfirm={onConfirm2}
      />
    </RootWrapper>


    </>
    
  )
};

export default ProductInquiry;

const commonStyle = css`
  width: 100%;
  height: 40px;
  border-radius: 15px;
  border: 1px solid #000;
  font-size: 14px;
  font-weight: 700;
  padding-left: 10px;
`;

const ContentWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 85vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
  select {
    ${commonStyle}
  }
  span {
    font-size: 12px;
    font-weight: 400;
    color: var(--color-sub);
    position: absolute;
    top: 62px;
    left: 20px;
  }
  button {
    &:disabled {
      background: var(--color-gray2);
    }
  }
`;

const StyledInput = styled.input`
  ${commonStyle}
`;

const LockBox = styled.div`
  position: absolute;
  top: -38px;
  right: 20px;
  img {
    width: 100%;
    cursor: pointer;
  }
`;

const StyledTextarea = styled.textarea`
  width: 100%;
  height: 250px;
  border-radius: 15px;
  border: 1px solid #000;
  font-size: 14px;
  font-weight: 400;
  padding: 15px 10px;
  resize: none;
  margin-top: 10px;
`;