import React from 'react';
import HeaderWithBack from '../components/HeaderWithBack';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Button from '../components/Button';
import NavBasic from '../components/NavBasic';

const WrapBox = styled.div`
  /* min-height: 850px; */
  min-height: 100vh;
  p {
    margin: 40px 30px;
    line-height: 2;
    font-size: 12px;
    font-weight: 400;
  }
`;

const LookDetail = styled(Button)`
  color: var(--color-main);
  font-size: 12px;
  font-weight: 400;
  text-decoration: underline;
  margin-left: 10px;
`;

const TermsUse = () => {
  const navigate = useNavigate();

  return (
    <WrapBox>
      <HeaderWithBack text={"サービス利用規約"}></HeaderWithBack>
      <p>利用規約<LookDetail onClick={() => navigate(`/terms`)}>みる</LookDetail><br />
      個人情報の収集及び利用目的<LookDetail onClick={() => navigate(`/privacy`)}>みる</LookDetail></p>
      <NavBasic/>
    </WrapBox>
  );
};

export default TermsUse;