import React, { useState } from "react";
import { useSetRecoilState } from "recoil";
import { isLogin } from "../atoms/atoms";
import { signin } from "../utils";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Button from "./Button";
import CenterModal from "./CenterModal";
import { getEncrypt } from "../utils/authUtils";
import { api_post } from "../apis/apis";
import Cookies from "js-cookie";

function SignInForm() {
  const setIsLogin = useSetRecoilState(isLogin);
  const [modal, setModal] = useState(false);
  const navigate = useNavigate();
  const initInputValue = {
    email: "",
    password: "",
  };
  const [inputValue, setInputValue] = useState(initInputValue);
  const { email, password } = inputValue;

  const onChangeInputValue = (e) => {
    setInputValue({
      ...inputValue,
      [e.target.name]: e.target.value.toString().trim(),
    });
  };

  const onClickButton = async (event) => {
    event.preventDefault();
    // 일반 yourish 로그인 API
    if (email && password) {
      // signin({ email, password, provider: "yourish" })
      //   .then((response) => {
         
      //     setIsLogin(true);

      //     if(response==="ok"){
      //       navigate('/');
      //     } else {
      //       console.log(email, "비번",password);
      //       setModal(true);
      //       // navigate('/sign-in');
      //     }
      //   })
      //   .catch((error) => console.error(error));
      const params = { email: email, password: getEncrypt(password), provider: "yourish" }
      const response = await api_post("/user/login", params)
      
      if(response.status==="ok"){
        const accessToken = response.access_token;
        
        if (window.ReactNativeWebView != undefined){
          window.ReactNativeWebView.postMessage(JSON.stringify({"seq":response.seq}))
        }
        // 앱 자동로그인
        if (window.ReactNativeWebView != undefined) {
          Cookies.set('jwtToken', accessToken, {expires: 180});
        } else {
          Cookies.set('jwtToken', accessToken, {expires: 3});
        }
        setIsLogin(true);
        navigate('/');
      } else {
        setModal(true);
      }
    } else {
      return
    }
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    onClickButton(event);
  };

  const handleLoginKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit(event);
    }
  };

  const handleClose = () => {
    setModal(false);
  }

  return (
    <>
      <StyledInput
        type="text"
        name="email"
        value={inputValue.email}
        onChange={onChangeInputValue}
        placeholder="メールアドレス"
        onKeyPress={handleLoginKeyPress}
      />
      <StyledInput
        type="password"
        name="password"
        value={inputValue.password}
        onChange={onChangeInputValue}
        placeholder="パスワード"
        onKeyPress={handleLoginKeyPress}
      />
      <StyledButton onClick={handleSubmit}>ログイン</StyledButton>
      <StyledDiv>
        <StyledLink to="/find-email">
          メールアドレスを忘れた方
        </StyledLink>
        <span> / </span>
        <StyledLink to="/find-password">
          パスワードを忘れた方
        </StyledLink>
        <span> | </span>
        <StyledLink to="/sign-up">
          新規会員登録
        </StyledLink>
      </StyledDiv>
      <CenterModal
        visible={modal}
        title="メールとパスワード&#13;&#10;をもう一度確認してください。"
        onConfirm={handleClose}
      />
     </>
    
  );
}
export default SignInForm;

const StyledInput = styled.input`
  height: 50px;
  width: 80%;
  border-radius: 10px;
  line-height: 3rem;
  border: none;
  background: #ededed;
  font-size: 20px;
  margin-bottom: 22px;
  padding: 14px;
  ::placeholder {
    color: #929292;
  }
`;


const StyledButton = styled.button`
  height: 50px;
  width: 80%;
  border-radius: 10px;
  background: var(--color-main);
  font-size: 25px;
  color: #fff;
  margin-bottom: 11px;
  border: none;
`;

const StyledDiv = styled.div`
  margin-bottom: 56px;
  width: 80%;
  
  > span {
    font-weight: 400;
    font-size: 13px;
  }
  @media screen and (max-width: 500px) {
    text-align: center;
  }
`;

const StyledLink = styled(Button)`
  height: 21px;
  font-size: 12px;
  font-weight: 4;
  color: #727272;
  text-decoration: none;
  font-family: var(--font-jp-regular);
  white-space: nowrap;
`;