import React, { useState } from "react";
import styled from "styled-components";
import BtnBoxSmall from "./BtnBoxSmall";
import axios from "axios";
import BtnBorderExsmall from "./BtnBorderExsmall";
import BtnBoxBasic from "./BtnBoxBasic";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "./Button";
import AskModalOne from '../components/AskModalOne';
import NavBasic from "./NavBasic";
function InputFindEmail() {
  const [modal, setModal] = useState(false);
  const formik = useFormik({
    initialValues: {
      username: "",
    },
    
    validationSchema: Yup.object({
      username: Yup.string().required("必須です。"),
    }),

    onSubmit: async (values) => {
      const { username } = values;
      const baseUrl = process.env.REACT_APP_BASEURL;
      if (username) {
        const { data } = await axios.get(`${baseUrl}/find-email`, {
          params: { username },
        });
        const message = data.message;
        const status = data.status;

        if (status === "fail") {
          setModal(true);
        } else {
          setList(data.list);
          
        }
      } else {
        
      }
    },
    // 값 변경시마다 validation 체크
    validateOnChange: true,
    // 인풋창 블러시에 validation 체크
    validateOnBlur: true,
  });

  const [list, setList] = useState(null);
  const onClick = async (event) => {
    formik.handleSubmit();
  };

  const onConfirm = () => {
    setModal(false);
  };

  return (
    <>
      <StyledDiv>
        <form onSubmit={formik.handleSubmit}>
          <div className={"left"}>
            <div className="row text">
              <label htmlFor="username">氏名</label>
              {formik.touched.username && formik.errors.username ? (
                <span>{formik.errors.username}</span>
              ) : null}
            </div>
            <div className="row">
              <input
                id="username"
                type="text"
                {...formik.getFieldProps("username")}
              />
              <BtnBoxSmall
                onClick={(e) => onClick(e)}
                text={"検索"}
                height="40px"
              />
            </div>
          </div>
        </form>
      </StyledDiv>
      <StyledLists className="box">
        {list && <div className="border"></div>}
        <ul className="left">
          {list?.map((i) => (
            <li key={i}>{i}</li>
          ))}
          {list && (
            <Button className="button" to="/find-password">
              <BtnBorderExsmall
                text="パスワードを探す"
                bordercolor="var(--color-sub)"
                color="var(--color-sub)"
                width="100%"
                fontsize="14px"
                height="30px"
                borderradius="20px"
              ></BtnBorderExsmall>
            </Button>
          )}
        </ul>
        {list && (
          <Link to="/sign-in">
            <BtnBoxBasic
              className="button login"
              width={"100%"}
              text="ログインに戻る"
            ></BtnBoxBasic>
          </Link>
        )}
        <NavBasic/>
      </StyledLists>
      
      <AskModalOne
        visible={modal}
        title="登録されたメールはありません。"
        confirmText='OK'
        onConfirm={onConfirm}
      />
    </>
  );
}

export default InputFindEmail;

const StyledDiv = styled.div`
  form {
    width: 100%;
    margin: 1rem auto;

    input {
      width: 100%;
      height: 40px;
      background: #d9d9d9;
      border-radius: 10px;
      border: none;
      padding: 0.8rem;
      margin-top: 0.3rem;
    }
    div.row {
      align-items: center;
      width: 100%;
      button {
        margin-top: 0.3rem;
        margin-left: 5px;
      }
    }
  }
  div.text {
    margin-top: 1rem;
    margin-bottom: 0.3rem;
    span {
      margin-left: 0.3rem;
      color: var(--color-sub);
    }
  }
`;

const StyledLists = styled.div`
  ul.left {
    margin-top: 10px;
    align-items: flex-start;
    justify-content: flex-start;
    .button {
      align-self: center;
    }
  }
  .button.login {
    width: 100%;
    align-self: flex-end;
    justify-self: flex-end;
  }

  .border {
    width: 100%;
    border-top: 1px solid var(--color-gray2);
  }
  ul {
    width: 100%;
    li {
      margin: 5px auto 5px;
      background-color: var(--color-gray1);
      border-radius: 20px;
      width: 100%;
      height: 30px;
      padding: 7px 12px 7px;
      font-size: 14px;
    }
  }
`;
