import React, { useRef } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { StyledRate } from "./Common";
import SubStringText from "./SubStringText";

const ListReviews = ({backcolor, color, border, data}) => {
  const navigate = useNavigate();
  const ref = useRef(null);

  const detail = (productSeq, postSeq) => {
    navigate(`/item-detail/info/${productSeq}`, {state: {reviewSeq: postSeq}});
  };

  return (
    <StyledUl backcolor={backcolor} color={color} border={border} ref={ref}>
      {data &&
        data.map((list) => (
          <li className="item" key={list.postSeq}>
            <img className="item-img" src={list.reviewImage[0].reviewImageUrl} alt={list.contents}/>
            <div className="contents">
              <RateDiv>
                <StyledRate
                  allowHalf
                  disabled
                  colorGray
                  xSmall
                  value={list.score}
                  // value={4.5}
                />
              </RateDiv>
              <h3><SubStringText text={list.contents} truncatelength={6}/></h3>
              <p>{list.productOptions}</p>
              <button onClick={() => {
                detail(list.productSeq, list.postSeq)
              }}>VIEW MORE
              </button>
            </div>
          </li>
        ))}
    </StyledUl>
  );
};
export default ListReviews;

const StyledUl = styled.ul`
  display: flex;
  list-style: none;
  overflow-x: scroll;
  max-width: 500px;
  margin-bottom: 80px;
  padding: 50px 0 28px 16px;
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 123, 154, 1) rgba(210, 210, 210, 1);
  ::-webkit-scrollbar-button:start:decrement,
  ::-webkit-scrollbar-button:end:increment {
    display: block;
    width: 16px;
    height: 3px;
    background: white;
  }
  ::-webkit-scrollbar {
    height: 3px;
    -webkit-border-radius: 1ex;
    margin-bottom: 10px;
    background: rgba(210, 210, 210, 1);
  }
  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 1ex;
    background: rgba(255, 123, 154, 1);
  }
  .item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-right: 10px;
    border-radius: 15px;
    //box-shadow: 0 1px 30px 0 rgba(0, 0, 0, 0.11);
  }
  img {
    width: 193px;
    height: 204px;
    border-radius: 15px;
  }
  .contents {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: rgba(89, 89, 89, 0.5);
    border-radius: 15px;
    z-index: 1;
    padding: 10px 15px;
    font-size: 10px;
    button {
      width: 54px;
      height: 38px;
      border: 1px solid #000000;
      border-radius: 10px;
      font-weight: 700;
      background: none;
      position: absolute;
      font-size: 10px;
      right: 10%;
      bottom: 20%;
      color: #000;
    }
    @media screen and (max-width: 768px) {
      width: 160px;
    }
  }
  h3 {
    font-weight: 700;
    color: #000;
    margin: 0 auto 5px;
  }
  p {
    font-weight: 300;
    color: ${(props) => props.color || "#000"};
    margin-bottom: 3px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    width: 75px;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  @media screen and (max-width: 500px) {
    .item,
    img {
      width: 160px;
      height: 160px;
    }
  }
`;

const RateDiv = styled.div`
  display: flex;
  align-items: center;
  p {
    font-weight: 500;
    margin-left: 3px;
  }
`;