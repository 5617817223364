import React, { useEffect } from "react";
import styled, { css } from "styled-components";
import heartbasic from "../../assets/common/heartbasic.svg";
import heartactive from "../../assets/common/heartactive.svg";
import { useLocation, useNavigate } from "react-router-dom";
import circlecheck from "../../assets/common/circle-check.png";
import { useState } from "react";
import Button from "../../components/Button";
import ItemInfo from "./ItemInfo";
import ItemReview from "./ItemReview";
import ItemQuestion from "./ItemQuestion";
import HeaderwithSearch from "../../components/HeaderwithSearch";
import AskModal from '../../components/AskModal';
import Loading from "../Loading";
import { api_get, api_get_header_token,api_post_header_token_then } from "../../apis/apis";
import { isMember } from "../../utils";
import NumberFormat from "../../components/NumberFormat";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from "swiper";
import { StyledRate } from "../../components/Common";
import { useQueries } from "react-query";
import grayDelete from "../../assets/common/gray-delete.svg";
import ArrowButton from "../../components/ArrowButton";
import arrowdown from "../../assets/common/arrow-down.svg";
import arrowup from "../../assets/common/arrow-up.svg";
import minusnum from "../../assets/common/minus-num.svg";
import plusnum from "../../assets/common/plus-num.svg";
import share from "../../assets/common/share.svg";

const ItemDetail = () => {
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [refreshKey, setRefreshKey] = useState(0);
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [seq, setSeq] = useState(0);
  const [imgUrl, setImgUrl] = useState("");
  const [score, setScore] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("detail");
  const [firstCome, setFirstCome] = useState(false);
  const [qntAndOption , setQntAndOption] = useState({"data":[]});
  const [limitedOption, setLimitedOption] = useState({"data":[]});
  const [showOption, setShowOptions] = useState(false);
  const [reviewList,setReviewList] = useState({});
  const [imgList,setImgList] = useState({});
  const [qnaList,setQnaList] = useState({});
  const [isWished, setIsWished] = useState(false);
  const [tag, setTag] = useState(null);
  const location = useLocation()
  let path = location.pathname.split("/");
  let state = path[path.length - 1]

  const fetchProductData = async () => {
    setSeq(state);
    const response = await api_get_header_token(`/sort/product-detail/${state}`)
    setData(response.detail.list);
    setImgUrl(response.detail.list.imageArray);
    setScore(response.detail.list.averageScore);
    setReviewList(response.review.list);
    setQnaList(response.qna.list);
    setTag(response.tag.list);
  };

  const fetchReviewImgList = async () => {
    const response = await api_get(`/review/photo-image-list?productSeq=${state}`);
    setImgList(response.list);
  };

  const queries = useQueries([
    { queryKey: 'productData', queryFn: fetchProductData },
    { queryKey: 'reviewImgList', queryFn: fetchReviewImgList },
  ]);

  const isLoading = queries.some((query) => query.isLoading);
  const isError = queries.some((query) => query.isError);

  const reviewSeq = location.state?.reviewSeq || null;
  const isInfluencer = location.state?.isInfluencer;

  useEffect(() => {
    if (!isLoading && !isError) {
      setIsDataLoading(false);
    }
  }, [isLoading, isError]);

  useEffect(() => {
    const initialWish = data.wish;
    setIsWished(initialWish === "Y");

    if(reviewSeq) {
      setActiveTab("review");
    }
  },[data]);

  function totalPrice(){
    if(limitedOption.data.length > 0) {
      return data.discountedPrice * limitedOption.data.length;
    }

    let total = 0;
    if(isInfluencer && qntAndOption.data.length > 0) {
      const optionTotal = qntAndOption.data.map((item) => item.qnt * item.price);
      total = optionTotal.reduce((acc, sub) => acc + sub, 0);

      return total;
    }

    //
    if(data.influencer && qntAndOption.data.length > 0) {
      const optionTotal = qntAndOption.data.map((item) => item.qnt * item.price);
      total = optionTotal.reduce((acc, sub) => acc + sub, 0);

      return total;
    }

    const discount_price = data.discountedPrice;
    var qnt = 0 
    qntAndOption.data.forEach((element)=>{
      qnt += Number(element.qnt)
    })
    return qnt * discount_price
  }

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const toggleButton = async () => {
    const token = await isMember();
    if(token.status!=="fail"){
      setIsOpen(!isOpen);
    }else{
      setModal2(true);
    }
  };

  const handleShowOptions = () => {
    if(data.soldOut === "Y"){
      // 옵션 안 보여줌
    } else {
      setShowOptions((prevShowOptions) => !prevShowOptions)
    }
  }

  const handleOptionClick = (option) => {
    let selData = {};

    if (data.event === 1) {
      selData = { "option": option, "qnt": 1 };//qnt 2에서 1로 수정(하윤성)
    } else if (data.limitedSale === 1) {
      selData = { "option1": option, "option2": "" };
    } else if (data.influencer === 1) {
      selData = { "option": option.options, "qnt": 1, "price": option.price, "beforePrice": option.beforeDiscount, "discount": option.discountPercent };
    } else {
      selData = { "option": option, "qnt": 1 };
    }

    if (data.limitedSale === 1) {
      const limitedOptionCopy = [...limitedOption.data];
      const lastEl = limitedOptionCopy[limitedOptionCopy.length - 1];

      if (lastEl && lastEl.option2) {
        limitedOptionCopy.push(selData);
      } else if (lastEl) {
        lastEl.option2 = option;
      } else {
        limitedOptionCopy.push(selData);
      }

      setLimitedOption({
        ...limitedOption,
        "data": limitedOptionCopy
      });

      setShowOptions(false);
      return;
    }

    const qntAndOptionCopy = qntAndOption.data;
    if(qntAndOptionCopy.filter(x=> x.option === option).length === 0){
      qntAndOptionCopy.push(selData)
      setQntAndOption({
        ...qntAndOption,
        "data" : qntAndOptionCopy
      })
    }
    setShowOptions(false);
  };

  const handleDeleteOption = (index) => {
    const limitedOptionCopy = [...limitedOption.data];
    limitedOptionCopy.splice(index, 1); // 해당 인덱스의 객체 삭제

    setLimitedOption({
      ...limitedOption,
      "data": limitedOptionCopy
    });
  };

  const handleDeleteQntOption = (index) => {
    const qntAndOptionCopy = [...qntAndOption.data];
    qntAndOptionCopy.splice(index, 1);

    setQntAndOption({
      ...qntAndOption,
      "data": qntAndOptionCopy
    });
  };

  const isOptionEmpty = () => {
    const isLimitedSale = data.limitedSale === 1;

    if (isLimitedSale) {
      const lastOption = limitedOption.data[limitedOption.data.length - 1];
      return limitedOption.data.length === 0 || !lastOption.option2;
    }
    return qntAndOption.data.length === 0;
  };


  function handleQuantityIncrease(option) {
    const qntAndOptionCopy = [...qntAndOption.data];
    const index = qntAndOptionCopy.findIndex((x) => x.option === option);
  
    if (index !== -1) {
      const plus = qntAndOptionCopy[index].qnt + 1;
      qntAndOptionCopy[index] = {
        ...qntAndOptionCopy[index],
        qnt: qntAndOptionCopy[index].qnt > data.quantity ? qntAndOptionCopy[index].qnt : plus,
      };
  
      setQntAndOption({
        ...qntAndOption,
        data: qntAndOptionCopy,
      });
    }
  }
  
  function handleQuantityDecrease(option) {
    const qntAndOptionCopy = [...qntAndOption.data];
    const index = qntAndOptionCopy.findIndex((x) => x.option === option);

    if (index !== -1) {
      if (data.event === 1) {
        const qnt = qntAndOptionCopy[index].qnt;
        qntAndOptionCopy[index] = {
          ...qntAndOptionCopy[index],
          qnt: qnt > 1 ? qntAndOptionCopy[index].qnt - 1 : qntAndOptionCopy[index].qnt,
        };//qnt > 2 에서 1로 수정(하윤성)
        setQntAndOption({
          ...qntAndOption,
          data: qntAndOptionCopy,
        });
      } else {
        const qnt = qntAndOptionCopy[index].qnt;
        qntAndOptionCopy[index] = {
          ...qntAndOptionCopy[index],
          qnt: qnt > 1 ? qntAndOptionCopy[index].qnt - 1 : qntAndOptionCopy[index].qnt,
        };
        setQntAndOption({
          ...qntAndOption,
          data: qntAndOptionCopy,
        });
      }
    }
  }

  const toggleWish = async () => {
    const response = await api_get_header_token(`/shop/wish-modify/${state}`);
    if (response.status === "ok") {
      const newWish = response.message === "N" ? "N" : "Y";
      setIsWished(newWish === "Y");
    }
  };

  const handleAddCart = () => {
    const product_options = [];

    const optionsData = data.limitedSale === 1 ? limitedOption.data : qntAndOption.data;

    optionsData.forEach((element) => {
      const options = `${element.option1}${element.option2 ? `/${element.option2}` : ''}`;

      const option = {
        quantity: data.limitedSale === 1 ? 1 : element.qnt,
        productOptions: data.limitedSale === 1 ? options : element.option,
      };

      product_options.push(option);
    });

    const params_add = {
      productSeq: seq,
      options: product_options,
    };

    api_post_header_token_then("/shop/cart-add", params_add)
    .then((res) => {
      setModal(true);
      setIsOpen(!isOpen);
      setQntAndOption({
        ...qntAndOption,
        "data": []
      });
    });

    setRefreshKey(refreshKey + 1);
  };


  const handleBuyNow = () => {
    const products = [];

    const optionsData = data.limitedSale === 1 ? limitedOption.data : qntAndOption.data;
    const price = data.limitedSale === 1 ? 1 : data.discountedPrice;

    optionsData.forEach((element) => {
      const option = {
        productSeq: data.productSeq,
        quantity: data.limitedSale === 1 ? 1 : element.qnt,
        productOptions: data.limitedSale === 1
          ? `${element.option1}${element.option2 ? `/${element.option2}` : ""}`
          : element.option,
        price: data.discountedPrice * price * (data.limitedSale === 1 ? 1 : element.qnt),
        couponSeq: 0,
        couponDiscountPrice: 0,
      };

      products.push(option);
    });

    navigate(`/order`, { state: { products } });
  };

  const optionMessage = data.limitedSale === 1 ? "2つのオプションを選択します。" : "オプションを選択してください。";

  const onCancel = () => {
    setModal(false);
  };

  const basketgo = () => {
    navigate(`/basket`);
  };

  const con2 = () => {
    setModal2(false);
    navigate(`/sign-in`);
  };

  const can2 = () => {
    setModal2(false);
  };
  
  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <>
    { isDataLoading ? (
      <Loading />
    ) : (
      <>
    <StyledItemDetail>
      <HeaderwithSearch key={refreshKey} />
      { data &&
        <>
          <ItemBanner>
            <div className="banner">
            {imgUrl && imgUrl.length > 1 ? (
              <>
                <Swiper
                  modules={Navigation}
                  spaceBetween={0}
                  slidesPerView={1}
                  pagination={{ clickable: true }}
                  autoplay={{delay:2000}}
                  className="mySwiper"
                  speed={1000}
                >
                  {imgUrl &&
                        imgUrl.map((list, index) => (
                          <SwiperSlide key={index}>
                            <div style={{height:500, display:'flex',alignItems:'center',justifyContent:'center'}}>
                              <img style={{height:'auto', display:'flex', alignSelf:'center'}} className="slide-img" src={list} alt="banner" />
                            </div>
                          </SwiperSlide>
                        ))}

                </Swiper>
              </>
            ) : (
                <img src={imgUrl} alt="img" />
            )}
              {/* <img src={imgUrl} alt="img" /> */}
            </div>
            <ItemTitleBoxDiv>
              <h3 className="title">{data.subCategory}</h3>
              <span className="stars">
                <StyledRate
                  allowHalf
                  disabled
                  value={score}
                />
              </span>
              { firstCome &&
                <span className="first-come">(税込: {data.quantity}個)</span>
              }
            </ItemTitleBoxDiv>
            <div className="description-box">
              <p className="description">{data.sub}</p>
              <p className="brand">{data.brand}</p>
              <div className="event-product">
                {data.limitedSale === 1 && <span>* ご希望の商品オプションを2つ選択してください。</span>}
                {data.event === 1 || data.influencer === 1 ? <span className="event-product">* クーポン適用不可</span> : null}
              </div>
              <span className="discountPercent">{data.discountPercent}%</span>
              <span className="price"><NumberFormat number={data.priceYen} />円</span>
              <span className="discount-price"><NumberFormat number={data.discountedPrice} />円</span>
              <span className="tax">(税込)</span>
              <ItemTagBox>
                <div>
                  {tag && tag.length > 0 &&
                    tag.slice(0, 5).map((item) =>
                      <span key={item.seq}>#&nbsp;{item.tag}</span>
                    )
                  }
                </div>
              </ItemTagBox>
            </div>
          </ItemBanner>
          <ContectsWrapper>
            <div className="delivery-text">
              <p>配送</p>
              <span>4,500円 以上で送料無料</span>
            </div>
            <section>
              <NavBox>
                {isInfluencer ? (
                    <TabButton
                      className={activeTab === "detail" ? "active" : ""}
                      onClick={() => handleTabClick("detail")}
                    >
                      詳細情報
                    </TabButton>
                ) : (
                  <>
                    <TabButton
                      className={activeTab === "detail" ? "active" : ""}
                      onClick={() => handleTabClick("detail")}
                    >
                      詳細情報
                    </TabButton>
                    <TabButton
                      className={activeTab === "review" ? "active" : ""}
                      onClick={() => handleTabClick("review")}
                    >
                      レビュー
                    </TabButton>
                    <TabButton
                      className={activeTab === "question" ? "active" : ""}
                      onClick={() => handleTabClick("question")}
                    >
                      お問い合わせ
                    </TabButton>
                  </>
                )

                }
              </NavBox>
              {activeTab === "detail" && <ItemInfo data={data} seq={seq} />}
              {activeTab === "review" || reviewSeq ? <ItemReview data={reviewList} imgList={imgList}/> : null}
              {activeTab === "question" && <ItemQuestion data={qnaList} proSeq={data.productSeq}/>}
            </section>
          </ContectsWrapper>
          <div className="box">
            <PurchaseWrap isOpen={isOpen}>
              {isOpen && (
                <>
                  <div className="toggle-bg" onClick={toggleButton}></div>
                    <OptionToggle>
                      <HideButton onClick={toggleButton}>
                        <img src={circlecheck} alt="circlecheck" />
                      </HideButton>

                      <OptionWrapper>
                        <OptionTitle onClick={() => handleShowOptions()} border={showOption}>
                          {data.soldOut === "Y" ? (
                            "SOLD OUT"
                          ) : (
                            <>
                              {optionMessage}
                              {showOption ? <img src={arrowup} /> : <img src={arrowdown} />}
                            </>
                          )}
                        </OptionTitle>
                        {showOption && (
                          <OptionSelect>
                            {data.soldOut === "Y" ? (
                              <li>SOLD OUT</li>
                            ) : (
                              data.influencer === 1 ? (
                                data.productOptions.map((option) => (
                                  <li onClick={() => handleOptionClick(option)}
                                      key={option.optionSeq}
                                      value={option.options}
                                  >
                                    <div className="options">
                                      {option.options}
                                      <div>
                                        <span className="before">{option.beforeDiscount?.toLocaleString()}円</span>
                                        <span className="percent">{option.discountPercent}%</span>
                                        <span className="price">{option.price?.toLocaleString()}円</span>
                                      </div>
                                    </div>
                                  </li>
                                ))
                              ) : (
                                data.productOptions.map((option) => (
                                  <li onClick={isInfluencer ? () => handleOptionClick(option) : () => handleOptionClick(option.options)}
                                      key={option.optionSeq}
                                      value={option.options}
                                  >
                                    {option.options}
                                  </li>
                                ))
                              )
                            )}
                          </OptionSelect>
                        )}
                      </OptionWrapper>
                      {data.limitedSale === 1 ? (
                        limitedOption.data.map((element, index) => (
                          <OptionQuantity
                            key={limitedOption.data.findIndex(x => x.option1 === element.option)}
                            oneOption={!element.option2}
                            isNum={false}
                          >
                            <p>{element.option1}&nbsp;&nbsp;{element.option2 && <>/&nbsp;&nbsp;{element.option2}</>}</p>
                            <OptionDelete>
                              <img src={grayDelete} alt="trash" onClick={() => handleDeleteOption(index)} />
                            </OptionDelete>
                          </OptionQuantity>
                        ))
                      ) : (
                        qntAndOption.data.map((element, index) => (
                          <OptionQuantity key={qntAndOption.data.findIndex(x => x.option === element.option)}>
                            <p>{data.title} / {element.option}</p>
                            {data.influencer === 1 &&
                              <div>
                                <span className="before">{element.beforePrice?.toLocaleString()}円</span>
                                <span className="percent">{element.discount}%</span>
                                <span className="price">{element.price?.toLocaleString()}円</span>
                              </div>
                            }
                            <OptionDelete>
                              <img src={grayDelete} alt="trash" onClick={() => handleDeleteQntOption(index)} />
                            </OptionDelete>
                            <OptionQuantityBox>
                              <button onClick={()=>{handleQuantityDecrease(element.option)}}>
                                <img src={minusnum} />
                              </button>
                              <span className="qnt">{element.qnt || ""}</span>
                              <button onClick={()=>{handleQuantityIncrease(element.option)}}>
                                <img src={plusnum} />
                              </button>
                            </OptionQuantityBox>
                          </OptionQuantity>
                        ))
                    )}
                      <>

                        <PriceWrap>
                          <PriceBox>
                            <p>合計</p>
                            <span><NumberFormat number={totalPrice()} />円</span>
                          </PriceBox>
                        </PriceWrap>
                      </>

                    <BuyFooter toggle>
                      { isWished ? (
                        <img src={heartactive} onClick={toggleWish} alt="heartactive" width="39px" />
                      ) : (
                        <img src={heartbasic} onClick={toggleWish} alt="heartbasic" width="39px" />
                      )}
                      <StyledButton
                        disabled={isOptionEmpty()}
                        onClick={handleAddCart}
                        addCart
                      >
                        カート
                      </StyledButton>
                      <StyledButton
                        disabled={isOptionEmpty()}
                        onClick={handleBuyNow}
                        buyNow
                      >
                        すぐ購入
                      </StyledButton>
                    </BuyFooter>
                  </OptionToggle>
                </>
              )}
              <BuyFooter>
                { isWished ? (
                  <>
                    <img src={heartactive} onClick={toggleWish} alt="heartactive" width="32px"/>
                    {/*<img src={share} alt="share" className="share" onClick={handleClickShare} />*/}
                  </>
                ) : (
                  <>
                    <img src={heartbasic} onClick={toggleWish} alt="heartbasic" width="32px" />
                    {/*<img src={share} alt="share" className="share" onClick={handleClickShare} />*/}
                  </>
                )}

                <StyledButton onClick={toggleButton} margin>購入する</StyledButton>
              </BuyFooter>
            </PurchaseWrap>
          </div>
        </>
      }

      <AskModal
        itemdetail={true}
        visible={modal}
        title="カートに商品を追加しました!&#13;&#10;カートに移動しますか？"
        confirmText='はい'
        cancelText='いいえ'
        onConfirm={basketgo}
        onCancel={onCancel}
      />
      <AskModal
        itemdetail={true}
        visible={modal2}
        title="会員専用機能です。ログインしますか？"
        confirmText='はい'
        cancelText='いいえ'
        onConfirm={con2}
        onCancel={can2}
      />
    </StyledItemDetail>
    </>
    )}
      <ArrowButton />
    </>
  );
}

export default ItemDetail;


const StyledItemDetail = styled.div`
  position: relative;
  max-width: 500px;
  width: 100%;
  min-height: 100vh;
  section {
    position: relative;
    width: 100%;
  }
  .active {
    border-top: 2px solid var(--color-main);
    font-weight: 600;
  }
`;

const ItemBanner = styled.div`
  margin-top: 58px;
  width: 100%;
  .mySwiper {
    height: auto;
    img {
      width: 100%;
    }
  }
  div {
    .title,
    .stars,
    .description,
    .discountPercent,
    .price,
    .discount-price {
      padding-left: 10px;
      margin-top: 5px;
      margin-bottom: 5px;
      font-weight: 700;
      font-size: 20px;
    }
    .price {
      text-decoration: line-through;
      font-weight: 400;
      font-size: 16px;
      color: rgba(192, 192, 192, 1);
    }
    .title,
    .stars {
      margin-top: 15px;
    }
    .stars {
      
    }
    .first-come {
      margin: 20px 0 0 5px;
      font-size: 12px;
    }
    .description {
      font-size: 17px;
      font-weight: 400;
      margin-bottom: 10px;
      line-height: 1.2;
    }
    .brand {
      padding-left: 10px;
      margin-top: 5px;
      margin-bottom: 5px;
      font-size: 13px;
      color: var(--color-gray3);
    }
    .event-product {
      display: flex;
      span {
        font-size: 13px;
        color: var(--color-sub);
        margin: 6px;
        white-space: pre-wrap;
      }
      @media screen and (max-width: 768px) {
        span {
          font-size: 10px;
        }
      }
    }
    .discountPercent {
      color: var(--color-sub);
    }
    .tax {
      font-size: 12px;
      font-weight: 400;
      font-family: var(--font-jp-regular);
    }
  }
  .description-box {
    border-bottom: 2px solid rgba(217, 217, 217, 0.5);
    padding-bottom: 15px;
    margin-bottom: 5px;
  }
  .banner {
    width: 100%;
    min-height: 400px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
    }
    @media screen and (max-width: 768px) {
      height: auto;
      min-height: 0;
    }
  }
`;

const ContectsWrapper = styled.div`
  min-width: 328px;
  width: 100%;
  flex-direction: column;
  margin-bottom: 0;
  .delivery-text {
    display: flex;
    flex-direction: row;
    font-weight: 400;
    font-size: 12px;
    margin: 10px 20px 30px 20px;
    color: rgba(112, 112, 112, 1);
    span {
      margin-left: 30px;
      font-family: var(--font-jp-regular);
    }
    p {
      font-family: var(--font-jp-regular);
    }
  }
`;

const ItemTitleBoxDiv = styled.div`
  display: flex;
  align-items: center;
`;

const NavBox = styled.div`
  display: flex;
  text-align: center;
  flex-direction: row;
  justify-content: flex-start;
`;

const TabButton = styled(Button)`
  width: 100%;
  font-size: 14px;
  line-height: 3;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  font-family: var(--font-jp-regular);
  font-weight: 400;
`;

const PurchaseWrap = styled.div`
  max-width: 500px;
  max-height: 500px;
  width: 100%;
  bottom: 0;
  background: #fff;
  box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.25);
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 9999;
  ${props => props.isOpen && css`
    background-color: rgba(0, 0, 0, 0.2);
    box-shadow: none;
  `}
  .toggle-bg {
    width: 100%;
    height: 1000%;
    background: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: -1000%;
    left: 0;
    z-index: 99;
  }
`;

const OptionToggle = styled.div`
  width: 100%;
  min-height: 200px;
  padding: 15px;
  //position: relative;
  background: #fff;
  border-radius: 10px 10px 0 0;
  max-height: 500px;
  overflow-y: scroll;
`;

const HideButton = styled.button`
  width: 50px;
  height: 45px;
  background: var(--color-main);
  border: none;
  border-radius: 10px 10px 0 0;
  position: absolute;
  right: 20px;
  top: -45px;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  img {
    width: 32px;
    height: 32px;
  }
`;

const OptionWrapper = styled.div`
  position: relative;
  cursor: pointer;
`;

const OptionTitle = styled.span`
  width: 100%;
  height: 50px;
  border: 1px solid var(--color-main);
  border-radius: 10px;
  padding-left: 10px;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: rgba(29, 29, 29, 1);
  img {
    margin-right: 20px;
  }

  ${props => props.border && css`
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  `}
`;

const OptionSelect = styled.ul`
  background: #fff;
  width: 100%;
  border: 1px solid var(--color-main);
  border-top: none;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  .soldout {
    color: var(--color-gray3);
  }
  li {
    width: 100%;
    min-height: 50px;
    padding-left: 15px;
    border-bottom: 1px solid var(--color-gray2);
    display: flex;
    align-items: center;
    &:last-child {
      border-bottom: none;
    }
  }
  
  .options {
    display: flex;
    flex-direction: column;
    
    div {
      font-size: 13px;
      margin: 5px 0;
    }
    
    .before {
      text-decoration: line-through;
      color: rgba(192, 192, 192, 1);
      margin-right: 5px;
    }
    
    .percent {
      margin-right: 5px;
      color: var(--color-sub);
      font-weight: 600;
    }
    .price {
      font-weight: 600;
    }
  }
`;

const OptionQuantity = styled.div`
  width: ${(props) => props.oneOption ? "50%" : "100%"};
  background: var(--color-main-pale);
  border-radius: 10px;
  padding: 10px;
  margin: 10px 0;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  position: relative;
  p {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: rgba(87, 87, 87, 1);
    width: calc(100% - 80px);
  }
  div {
    font-size: 13px;
    margin-top: 10px;
  }

  .before {
    text-decoration: line-through;
    color: rgba(192, 192, 192, 1);
    margin-right: 5px;
  }

  .percent {
    margin-right: 5px;
    color: var(--color-sub);
    font-weight: 600;
  }
  .price {
    font-weight: 600;
  }
  @media screen and (max-width: 500px) {
    ${props => props.isNum === false && css`
    width: 100%;
  `}
  }
`;

const OptionQuantityBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  width: 100%;
  margin-top: 4px;
  .qnt {
    line-height: 24px;
    margin: 0 17px;
    font-size: 14px;
    font-weight: 500;
  }
  button {
    border: none;
    font-size: 20px;
    font-weight: 600;
    padding: 11px 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    background: #fff;
    border-radius: 50%;
  }
`;

const OptionDelete = styled.div`
  position: absolute;
  top: 10px;
  right: 13px;
  img {
    cursor: pointer;
  }
`;

const BuyFooter = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px 0;
  img {
    margin-left: 15px;
  }
  span {
    margin-left: 15px;
    margin-right: 15px;
  }
  .share {
    margin: 0 20px;
  }
  ${props =>
  props.toggle &&
  css`
    width: 100%;
    background: #fff;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.16);
    position: absolute;
    bottom: 0;
    left: 0;
  `
  }
`;

const StyledButton = styled(Button)`
  background: var(--color-main);
  border-radius: 50px;
  height: 50px;
  width: 75%;
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  ${props => props.margin && css`
    margin-right: 15px;
  `}
  font-family: var(--font-jp-bold);
  ${props => props.addCart && css`
    width: 35%;
    background: var(--color-sub);
  `}
  ${props => props.buyNow && css`
    width: 35%;
  `}
`;

const PriceWrap = styled.div`
  position: relative;
  margin-top: 100px;

`;

const PriceBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  p {
    font-size: 14px;
    font-weight: 500;
  }
  span {
    font-size: 14px;
    font-weight: 700;
  }
  &:last-child {
    p {
      font-weight: 700;
    }
    span {
      font-size: 16px;
      color: var(--color-sub);
    }
  }
  .sub-text{
    font-size: 10px;
    font-weight: 400;
    color: var(--color-gray3);
  }
`;

const ItemTagBox = styled.div`
  padding: 0 10px;
  margin-top: 13px;
  font-size: 12px;
  font-weight: 600;
  color: rgba(117, 185, 182, 1);
  div {
    display: flex;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  div::-webkit-scrollbar {
    display: none;
  }
  span {
    margin-right: 5px;
    white-space: nowrap;
    background: rgba(133, 219, 215, 0.4);
    border-radius: 11px;
    padding: 5px;
    display: inline-block;
  }
`;