import React from "react";
import HeaderWithBack from "../../components/HeaderWithBack";
import ListMainProduct from "../../components/ListMainProduct";
import { useQuery } from "react-query";
import { fetchMainData } from "../../utils";
import StyledP from "../../components/StyledP";
import Loading from "../Loading";
import ErrorPage from "../ErrorPage";
import { RootWrapper } from "../../components/Common";
import NavBasic from "../../components/NavBasic";
function FirstCome() {
  const { isLoading, isError, data } = useQuery("first-come", fetchMainData);
  return (
    <RootWrapper>
      <HeaderWithBack eng="true" text="FIRST COME"></HeaderWithBack>
      <StyledP bold>[数量限定] 1点買うと、もう1点プレゼント！</StyledP>
      {isLoading && <Loading />}
      {isError && <ErrorPage />}
      {data && (
        <ListMainProduct
          isFirstCome={true}
          isNowuse={false}
          isTimedeal={false}
          data={data.firstCome}
          marginBottom={true}
        />
      )}
      <NavBasic />
    </RootWrapper>
  );
}

export default FirstCome;
