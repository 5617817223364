import styled from "styled-components";
import purchaseConfirmation from "../assets/common/purchaseConfirmation.png";
import { useNavigate } from "react-router";
import React, { useState } from 'react';
import AskModal from '../components/AskModal';
import AskModalOne from '../components/AskModalOne';
import Cookies from "js-cookie";
import { api_get_header_token } from "../apis/apis";
import SubStringText from "./SubStringText";
import { OrderTag } from "./Common";
const ListItemOrder = ({data}) => {

  const jwtToken = Cookies.get("jwtToken");

  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [modal3, setModal3] = useState(false);

  const [orderId, setOrderId] = useState("");
  const navigate = useNavigate();
  const onClick = (orderId) => {
    const params = {
      state: orderId
    }
    navigate('/item-order-history', params);
  }
  const again = (orderId) => {
    setOrderId(orderId);
    setModal(true);
  }
  const onCancel = () => {
    setModal(false);
  };
  const onConfirm1 = () => {
    setModal1(false);
    navigate('/mypage/mycoupons');
  };
  const onCancel1 = () => {
    setModal1(false);
    window.location.reload();
  };
  const onConfirm3 = () => {
    setModal3(false);

  };
  const onConfirm = async () => {
    setModal(false);
  
      const url = `/order/purchaseConfirmation?orderId=${orderId}`;
      const result = await api_get_header_token(url);
     
      if(result.status==="ok"){
        setModal1(true);
      }else{
        setModal3(true);
      }
   
  };
  return (
    <>
      {data.list && data.list.map((list, index) => {
        const orderSeq = list.orderSeq;
        return (
      <div key={index} className="wrapper">
      <StyledDiv>
        <img className="image" src={list.productImage} alt="product-Image"></img>
        <div className="detail" onClick={() => onClick(list.orderId)} key={orderSeq}>
              {list.orderState === 'before_payment' ? (
                <OrderTag>支払い待ち</OrderTag>
              ) : list.orderState === 'on_ready' ? (
                <OrderTag>商品準備中</OrderTag>
              ) : list.orderState === 'on_delivery' ? (
                <OrderTag>発送中</OrderTag>
              ) : list.orderState === 'on_done' ? (
                <OrderTag>発送完了</OrderTag>
              ) : list.orderState === 'orderCancel_ready' ? (
                <OrderTag>キャンセル中</OrderTag>
              ) : list.orderState === 'on_cancel' ? (
                <OrderTag>キャンセル完了</OrderTag>
              ) : list.orderState === 'onExchange_done' ? (
                <OrderTag>交換完了</OrderTag>
              ) : list.orderState === 'onExchange_ready' ? (
                <OrderTag>交換待ち</OrderTag>
              ) : list.orderState === 'onExchange_delivery' ? (
                <OrderTag>交換中</OrderTag>
              ) : list.orderState === 'onExchangeCancel_ready' ? (
                <OrderTag>交換キャンセル待ち</OrderTag>
              ) : list.orderState === 'onExchange_cancel' ? (
                <OrderTag>交換のキャンセル</OrderTag>
              ) : list.orderState === 'onReturn_done' ? (
                <OrderTag>返品完了</OrderTag>
              ) : list.orderState === 'onReturn_ready' ? (
                <OrderTag>返品待ち</OrderTag>
              ) : list.orderState === 'onReturn_delivery' ? (
                <OrderTag>返品中</OrderTag>
              ) : list.orderState === 'onReturnCancel_ready' ? (
                <OrderTag>返品キャンセル待ち</OrderTag>
              ) : list.orderState === 'onReturn_cancel' ? (
                <OrderTag>返品キャンセル</OrderTag>
              ) : (
                ""
              )}
          <p className="text" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {list.productTitle && <SubStringText text={list.productTitle} truncatelength={20} />}
          </p>
          <p>
            {list.productOptions} / {list.quantity}
          </p>
        </div>
        {list.purchaseConfirmation === 'N' && (
          <span onClick={() => again(list.orderId)} ><img src={purchaseConfirmation} alt="purchaseConfirmation"></img></span>
        )}
        <div>
          <span className="date">{list.orderDate}</span>
        </div>
      </StyledDiv>
      </div>
      )})}
      <AskModalOne
        visible={modal3}
        title="お支払い待ち状態です。"
        confirmText='はい'
        onConfirm={onConfirm3}
      />
      <AskModal
        visible={modal}
        title="
        同じ注文番号のすべての商品
        購入確定しますか？"
        confirmText='はい'
        cancelText='いいえ'
        onConfirm={onConfirm}
        onCancel={onCancel}
      />
      <AskModal
        visible={modal1}
        title="
        再購入クーポンが発行されました！
        クーポンボックスに移動しますか？
        "
        confirmText='はい'
        cancelText='いいえ'
        onConfirm={onConfirm1}
        onCancel={onCancel1}
      />
    
    </>
  );
}
export default ListItemOrder;
const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: #ffffff;
  padding: 0 15px;
  position: relative;
  cursor: pointer;
  margin-bottom: 10px;

  .image {
    background-color: #d9d9d9;
    min-width: 80px;
    width: 80px;
    height: 80px;
    border-radius: 20px;
    margin: 10px 15px 10px 0;
  }
  .detail {
    min-width: 160px;
    width: 100%;
    button {
      font-weight: bold;
      margin: 5px 0;
    }
    p {
      padding-bottom: 5px;
    }
    p:last-child {
      color: #929292;
      font-size: 10px;
    }
  }
  span {
    font-size: 16px;
    font-weight: 700;
    margin: 5px 0 5px;
  }

  .date {
    font-size: 10px;
    color: var(--color-gray3);
    position: absolute;
    right: 4%;
    top: 8%;
  }

  .text {
    font-size: 14px;
    font-weight: 600;
    margin: 5px 0;
  }
`;