import React from "react";
import styled from "styled-components";
import HeaderWithBack from "../../components/HeaderWithBack";
import InputFindEmail from "../../components/InputFindEmail";
import { RootWrapper } from "../../components/Common";
function FindEmail() {
  return (
    <RootWrapper>
      <HeaderWithBack text="メールアドレスを探す"></HeaderWithBack>
      <StyledDiv>
        <InputFindEmail text={"NAME"}></InputFindEmail>
      </StyledDiv>
    </RootWrapper>
  );
}
export default FindEmail;

const StyledDiv = styled.div`
  width: 90%;
  margin: 15px auto auto;
`;
