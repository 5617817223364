import React, { useState } from 'react';
import { ContentWrapper, PtextM, RootWrapper } from '../components/Common';
import HeaderWithBack from '../components/HeaderWithBack';
import FooterMaxButton from '../components/FooterMaxButton';
import styled, { css } from 'styled-components';
import { useNavigate } from "react-router-dom";
import AskModalOne from '../components/AskModalOne';
import { api_post_header_token } from '../apis/apis';
const BetweenBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    width: 80px;
    height: 40px;
    border: none;
    border-radius: 10px;
    margin-left: 5px;
    background: #000;
    color: #fff;
  }
`;

const AddressBox = styled.div`
  margin: 10px 0;
`;

const StyledInput = styled.input`
  width: 100%;
  height: 40px;
  background: var(--color-gray2);
  border: none;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 400;
  padding: 0 15px;
  margin: 10px 0;
  ${props => props.white && css`
    background: #fff;
    border: 1px solid #000;
    margin-bottom: 150px;
  `}
  ${props => props.margin0 && css`
    margin: 0;
  `}
  ${props => props.bottom && css`
    margin: 5px 0 0 0;
  `}
`;

const StyledSpan = styled.span`
  margin-left: 5px;
  color: var(--color-sub);
  font-size: 12px;
`;

const AddAddress = () => {
  const [modal, setModal] = useState(false);
  const navigate = useNavigate();
  const [fullAddress, setFullAddress] = useState('');
  const [addressValue, setAddressValue] = useState('');
  const [resultCode, setResultCode] = useState("");

  const [resName,setResName] = useState("");
  const resNameChange = (event) => {
    setResName(event.target.value);
  };
  const [resPhone,setResPhone] = useState("");
  const resPhoneChange = (event) => {
    setResPhone(event.target.value);
  };
  const [furigana,setFurigana] = useState("");
  const furiganaChange = (event) => {
    setFurigana(event.target.value);
  };
  const [designation,setDesignation] = useState("");
  const designationChange = (event) => {
    setDesignation(event.target.value);
  };
  const [detail,setDetail] = useState("");
  const detailChange = (event) => {
    setDetail(event.target.value);
  };

  const address = async () => {
      //453-0809
      const response = await fetch(`https://api.zipaddress.net/?zipcode=${addressValue}`, {
        method: "GET",
      });
      const result = await response.json();
      if(result.code===200){
        setResultCode(result.code);
        setFullAddress(result.data.fullAddress);
      }else{
        setResultCode("again")
      }
    
  }
  const handleInputChange = (event) => {
    setAddressValue(event.target.value);
  };

  const handleAddAddress = async () => {
    if(resName==="" || fullAddress==="" || addressValue==="" || resPhone==="" || 
      furigana==="" || designation==="" || detail===""){
        alert("すべて入力してください。");
        return;
    }
    const params = {
      resName:resName,
      resPhoneNumber:resPhone,
      furigana:furigana,
      zipCode:addressValue,
      address:fullAddress,
      detailAddress:detail,
      addressAlias:designation,
      existAddress:"Y",
      setDefault:"Y"
    }
    const result = await api_post_header_token(`/address/write`, params);
    if(result.status==="ok"){
      navigate(`/address-list`);
    }
  }

  const onConfirm = () => {
    setModal(false);
  };

  return (
    <RootWrapper>
      <HeaderWithBack text={"配送先を追加"} />
      <ContentWrapper>
        <PtextM>受取人名</PtextM>
        <StyledInput value={resName} onChange={resNameChange} />
        <PtextM>連絡先電話番号</PtextM>
        <StyledInput value={resPhone} onChange={resPhoneChange} />
        <PtextM>フリガナ</PtextM>
        <StyledInput value={furigana} onChange={furiganaChange}/>
        <PtextM>配送先名</PtextM>
        <StyledInput value={designation} onChange={designationChange}/>
        <PtextM>郵便番号・ご住所 {resultCode === "again" ? <StyledSpan>もう一度検索してください</StyledSpan> : resultCode === 200 ? '' : ' '}</PtextM>
        <AddressBox>
          <BetweenBox>
            <StyledInput placeholder='ex) 000-0000' margin0 value={addressValue} onChange={handleInputChange} readOnly={resultCode === 200}/>
            <button onClick={address}>検索</button>
          </BetweenBox>
          
          <StyledInput value={fullAddress} readOnly margin0 bottom />
        </AddressBox>
        <PtextM>建物名・お部屋番号など</PtextM>
        <StyledInput value={detail} onChange={detailChange} />
      </ContentWrapper>
      <FooterMaxButton onClick={handleAddAddress} text={"完了"} />
      <AskModalOne
        visible={modal}
        title="正しい情報を入力してください"
        confirmText='はい'
        onConfirm={onConfirm}
      />
    </RootWrapper>
  );
};

export default AddAddress;