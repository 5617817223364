import React from 'react';
import { ContentWrapper, RootWrapper, inputStyle } from '../../components/Common';
import HeaderWithBack from '../../components/HeaderWithBack';
import styled from 'styled-components';
import FooterMaxButton from '../../components/FooterMaxButton';
import { useState } from 'react';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { api_post } from '../../apis/apis';
import { async } from 'q';

const EmailInputPage = () => {
	const navigate = useNavigate();
	const [seqParams, setSeqParams] = useState(null);
	const [emailData, setEmailData] = useState("");
	const [showText, setShowText] = useState(false);
	const [btnDisabled, setBtnDisabled] = useState(true);
	const location = useLocation();

	const onChange = (email) => {
		const emailRegEx = /^[A-Za-z0-9]([-_.]?[A-Za-z0-9])*@[A-Za-z0-9]([-_.]?[A-Za-z0-9])*\.[A-Za-z]{2,3}$/i;
		if(emailRegEx.test(email)){
			setEmailData(email);
			setBtnDisabled(false);
			setShowText(false);
		} else {
			setShowText(true);
		}
	}
	
	const submitEmail = async() => {
		const email = emailData;

		const emailParams = {
			userSeq: seqParams,
			email: email,
		}
		const result = await api_post(`/user/sns-email`, emailParams);
		if(result.status==="ok"){
			navigate('/');
		}
	}

	useEffect(() => {
    if (location.search) {
      const searchParams = new URLSearchParams(location.search);
      const params = searchParams.get("seq");
      setSeqParams(params);
    }
  }, [location.search]);

	return (
		<RootWrapper>
			<HeaderWithBack text={"E-Mail"} />
			<ContentWrapper>
				<div>
					<TextDiv>ID EMAIL</TextDiv>
					{ showText ? 
						<TextSpan>メールの形式が正しくありません</TextSpan>				
					:
						<></>
					}
				</div>
				<StyledInput onChange={(e)=>onChange(e.target.value)} placeholder='Id Emailを書いてください。' />
				<FooterMaxButton disabled={btnDisabled} text={"確認"} onClick={submitEmail} />
			</ContentWrapper>
		</RootWrapper>
	);
};

export default EmailInputPage;

const StyledInput = styled.input`
	${inputStyle}
`;

const TextDiv = styled.div`
	font-size: 14px;
	display: inline-block;
	margin: 10px 5px;
`;

const TextSpan = styled.span`
	font-size: 12px;
	font-weight: 400;
	display: inline-block;
	color: var(--color-sub);
`;