import React from "react";
import styled from "styled-components";
import logo from "../assets/logos/logo.png";
import searchicon from "../assets/header/searchicon.svg";
import carticonheader from "../assets/header/carticon-header.svg";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { isMember } from "../utils";
import { api_get, api_get_header_token_then } from '../apis/apis';
import Button from "./Button";
import { useEffect } from "react";
import { useState } from "react";
import { flexCenterCenter } from "./Common";
function HeaderWithLogo() {
  const jwtToken = Cookies.get("jwtToken");
  const navigate = useNavigate();
  const [dataLength, setDataLength] = useState(null);
    
  useEffect(() => {
  fetchProductData();
  }, []);

  const basket = async () => {
    const token = await isMember();
    if(token.status==="fail"){
      Cookies.remove("jwtToken");
      document.cookie = "jwtToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=yourish.jp;";
      navigate(`/sign-in`)
    }else{
      navigate(`/basket`, { state: "basket_active" })
    }
  }
  const fetchProductData = async () => {
    const token = await isMember();
    if(token.status==="fail"){
      setDataLength(null);
    }else{
      await api_get_header_token_then(`/user/shop-cart/list`)
      .then((response) => {
        if (response && response.data.list.length) {
          const basketList = response.data.list.length;
          setDataLength(basketList);
        }
      })
      .catch((error) => {
        throw error;
      });
    }
  };

  
  return (
    <StyledHeader className="between">
      <img src={logo} alt="logo" width="108px" onClick={() => navigate(`/`)} />
      <div onClick={() => navigate(`/search`)}>
        <input placeholder="Bellelanco" />
        <button className='hide'></button>
        <img src={searchicon} alt="searchicon-header" width="16px" />
      </div>
      <Button className="reletive" onClick={basket}>
        <img
          src={carticonheader}
          alt="carticon-header"
          width="28px"
        />
        {dataLength && dataLength > 0 && (
          <div className='number'>{dataLength}</div>
        )}
      </Button>
    </StyledHeader>
  );
}
export default HeaderWithLogo;

const StyledHeader = styled.header`
  padding: 13px 15px 13px;
  background-color: #fff;
  /* min-width: 428px; */
  width: 100%;

  div {
    width: 100%;
    margin: auto 10px auto;
    position: relative;
    img {
      position: absolute;
      left: 4%;
      top: 25%;
    }
    input {
      //min-width: 242px;
      width: 100%;
      padding-left: 2.2rem;
      height: 32px;
      background: #f5f5f5;
      border-radius: 20px;
      border: none;
      flex-grow: 1;
      line-height: 32px;
    }
    input::placeholder {
      color: rgba(197, 197, 197, 1);
    }
  }.reletive {
    position: relative;
  }
  .number {
    ${flexCenterCenter}
    position: absolute;
    bottom: 0;
    left: 6px;
    width: 15px;
    height: 15px;
    background: #FF7B9A;
    border: none;
    border-radius: 50%;
    color: #fff;
    font-size: 10px;
  }
`;
