import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled  from 'styled-components';
import { StyledFaStar } from "../../components/Common";
import { useNavigate } from "react-router-dom";
import SubStringText from "../../components/SubStringText";
import NumberFormat from "../../components/NumberFormat";
import { ItemTextBoxDiv, TitleBoxDiv, ItemBrandSpan, ItemSubTextDiv, PriceBoxDiv, ItemBrand } from "../../components/Common";
import { ReactComponent as LeftArrowButton } from "../../assets/main/leftarrow.svg";
import { ReactComponent as RightArrowButton } from "../../assets/main/rightarrow.svg";

const NewProductSlider = ({ data }) => {

  const navigate = useNavigate();

  const settings = {
    dots: false,
    infinite: true,
    autoplaySpeed: 2500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    centerMode: true,
    touchMove: true,
    variableWidth: true,
    pauseOnHover: false,
    nextArrow: <RightArrowButton />,
    prevArrow: <LeftArrowButton />
  };

  const handleClickProduct = (productSeq) => {
    navigate(`/item-detail/info/${productSeq}`);
  };

  return (
    <Container>
      <Slider {...settings}>
        {data &&
          data.map((item) => {
            return (
              <div className="item">
                <div className="card">
                  <ItemDiv onClick={() => handleClickProduct(item.productSeq)}>
                    <ItemImg
                      src={item.productImageArrays}
                      alt={item.sub}
                    />
                    <ItemTextBox>
                      <TitleBoxDiv>
                        <ItemBrandSpan>
                          <SubStringText text={item.subCategory} />
                        </ItemBrandSpan>
                        <StyledFaStar />
                        <span className='score'>{item.averageScore}</span>
                        <span className='num'>({item.scoreCount})</span>
                      </TitleBoxDiv>
                      <ItemSubTextDiv>{item.sub}</ItemSubTextDiv>
                      <ItemBrand>
                        <span>{item.brand}</span>
                      </ItemBrand>
                      <PriceBoxDiv>
                        <span>{item.discountPercent}%</span>
                        <span className="price"><NumberFormat number={item.priceYen} />円</span>
                        <span><NumberFormat number={item.discountedPrice} />円</span>
                      </PriceBoxDiv>
                    </ItemTextBox>
                  </ItemDiv>
                </div>
              </div>
            );
          })
        }
      </Slider>
    </Container>
  );
};

export default NewProductSlider;

const Container = styled.div`
  .item {
    transition: transform 0.3s;
    position: relative;
    box-sizing: border-box;
    transform: scaleY(0.8);
    width: 100%;
    background: rgba(245, 245, 245, 1);
    border-radius: 15px;
  }
  .slick-center .item {
    transform: scale(1);
    width: 100%;
    background: #fff;
  }
  .card {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    transform: scaleY(1);
    transition: transform 0.3s;
  }
  .slick-slide {
    width: 307px;
    height: auto;
  }
  .slick-slider {
    overflow: hidden;
  }
  .slick-list {
    margin-left: -20px;
  }
  .slick-current {
    box-shadow: 0 3px 25px 0 rgba(0, 0, 0, 0.12);
    border-radius: 15px;
    margin: 40px 10px;
  }
  .slick-track {
    display: flex;
    align-items: center;
  }
  .slick-arrow {
    z-index: 99;
    width: 40px;
    height: 40px;
  }
  .slick-prev {
    left: 44px;
  }
  .slick-next {
    right: 44px;
  }
  @media screen and (max-width: 500px) {
    .slick-slide {
      width: 240px;
    }
    .slick-prev {
      left: 26px;
    }
    .slick-next {
      right: 26px;
    }
  }
`;

const ItemDiv = styled.div`
  width: 100%;
`;

const ItemImg = styled.img`
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 15px;
	width: 307px;
  height: 307px;
  @media screen and (max-width: 500px) {
    height: 240px;
    width: 240px;
  }
  @media screen and (max-width: 355px) {
    height: ${({ theme }) => `calc(${theme.deviceWidth}px * 0.75)`};
    min-height: 200px;
  }
`;

const ItemTextBox = styled(ItemTextBoxDiv)`
  padding: 10px 8px;
  height: auto;
`;
