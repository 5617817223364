import React from "react";
import { RootWrapper } from "../components/Common";
import logo from "../assets/logos/logo.png";
import styled from "styled-components";
import {useState, useEffect} from "react";
import backicon from "../assets/header/backicon.svg";
import { useNavigate } from "react-router-dom";
import NavBasic from "../components/NavBasic";
import { api_get_header_token } from "../apis/apis";

const HeaderDiv = styled.div`
  display: flex;
  align-items: center;
  padding: 25px 20px 15px;
`;

const LogoBox = styled.div`
  margin-left: 20px;
  img {
    width: 140px;
  }
`;

const StyledTable = styled.table`
  width: 100%;
  text-align: center;
  line-height: 50px;
  margin-bottom: ${(props) => (props.margin ? "30px" : null)};
  thead {
    height: 50px;
    font-size: 16px;
    font-weight: 700;
  }
  tbody {
    color: var(--color-sub);
    background: rgba(255, 123, 154, 0.1);
    tr {
      height: 50px;
      font-size: 14px;
      font-weight: 500;
    }
  }
  .pale {
    background: var(--color-main-pale);
  }
`;

const CollaborationPage = () => {
  const navigate = useNavigate();
  const [info, setInfo] = useState([]);

  const getInfo = async () => {
    const url = '/private/yourish/influencers';
    const response = await api_get_header_token(url);
    setInfo(response.list);
  };

  useEffect(() => {
    getInfo();
  }, []);


  return (
    <RootWrapper>
      <HeaderDiv>
        <img src={backicon} alt="backicon" onClick={() => navigate(-1)} />
        <LogoBox><img src={logo} alt="logo"/></LogoBox>
      </HeaderDiv>
      {info && (
        <>
          <StyledTable margin>
            <thead>
            <tr>
              <th>&nbsp;</th>
              <th>オプション</th>
              <th>販売数量</th>
              <th>販売金額</th>
            </tr>
            </thead>
            <tbody>
            {info.salesGraph?.map((item, index) => {
              return (
                <tr key={index}>
                  <td>&nbsp;</td>
                  <td>{item.productOptions}</td>
                  <td>{item.salesQuantity}</td>
                  <td>{item.salesPrice?.toLocaleString()}円</td>
                </tr>
              );
            })}
            </tbody>
          </StyledTable>

          <StyledTable>
            <thead>
            <tr>
              <th>&nbsp;</th>
              <th>総販売数量</th>
              <th>総販売額</th>
              <th>インフルエンサー利益</th>
            </tr>
            </thead>
            <tbody className="pale">
            <tr>
              <td>Total</td>
              <td>{info.totalQuantity}</td>
              <td>{info.totalPrice?.toLocaleString()}円</td>
              <td>{info.influencerProfit?.toLocaleString()}円</td>
            </tr>
            </tbody>
          </StyledTable>
        </>
      )}
      <NavBasic />
    </RootWrapper>
  );
};

export default CollaborationPage;