import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import App from "./router/App";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot, useRecoilSnapshot } from "recoil";
import { QueryClient, QueryClientProvider } from "react-query";
import reportWebVitals from "./reportWebVitals";
import GlobalStyles from "./CSS/GlobalStyles";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { register } from "swiper/element/bundle";
import ScrollToTop from "./components/ScrollTop";
import { HelmetProvider } from "react-helmet-async";

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;


const script = document.createElement("script");
script.src = "https://multipay.komoju.com";
script.async = true;
document.body.appendChild(script);


const meta = document.createElement('meta');
meta.name = "viewport";
meta.content = "width=device-width, initial-scale=1.0, maximum-scale=3.0, user-scalable=yes, viewport-fit=cover";
document.getElementsByTagName('head')[0].appendChild(meta);

// swiper element register
register();

// NOTE 리액트쿼리 init
const queryClient = new QueryClient();

function DebugObserver() {
  const snapshot = useRecoilSnapshot();
  useEffect(() => {
    // console.debug("The following atoms were modified:");
    for (const node of snapshot.getNodes_UNSTABLE({ isModified: true })) {
      // console.debug(node.key, snapshot.getLoadable(node));
    }
  }, [snapshot]);

  return null;
}

// NOTE 리액트쿼리 + 리코일 + 라우터 적용
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <DebugObserver />
        <GlobalStyles />
        <GoogleOAuthProvider clientId={clientId}>
          <BrowserRouter>
            <ScrollToTop />
            <HelmetProvider>
              <App />
            </HelmetProvider>
          </BrowserRouter>
        </GoogleOAuthProvider>
      </RecoilRoot>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
