import { api_get, api_get_header_token, api_get_list_header_token, api_post } from "../apis/apis";
import { fetchWithAuth } from "./apiUtils";
import { getEncrypt } from "./authUtils";
import Cookies from "js-cookie";

export const signin = async ({ email, password, provider }) => {

  const params = { email, password: getEncrypt(password), provider }
  const response = await api_post("/user/login", params)

  if(response.status==="ok"){
    const accessToken = response.access_token;

    if (window.ReactNativeWebView != undefined){
      window.ReactNativeWebView.postMessage(JSON.stringify({"seq":response.seq}))
    }
    Cookies.set('jwtToken', accessToken);
  }

  return response.status;
};

export async function signOut() {
  localStorage.removeItem("accessToken");
}

export const isMember = async()  => {
  return await api_get_header_token(`/verification-jwt`);
}

const fetchMainData = async () => {
  const { category, small, slide } = await api_get("/banner");
  const { special } = await api_get("/special-collaboration");

  const member = await isMember();
  if(member.status==="fail"){
    const { bestProduct, newProduct, nowUse, review, timeDeal, firstCome, interest, recent, influencer } = await api_get("/main")
    const data = {
      category: category,
      small: small,
      slides: slide,
      bestProduct: bestProduct.list,
      newProduct: newProduct.list,
      nowUse: nowUse.list,
      review: review.list,
      timeDeal: timeDeal.list,
      firstCome: firstCome.list,
      interest: interest.list,
      recent: recent.list,
      influencer: influencer.list,
      special: special.list,
    };
    return data;
  } else {
    const { bestProduct, newProduct, nowUse, review, timeDeal, firstCome, interest, recent, influencer } = await api_get_header_token("/main")
    const data = {
      category: category,
      small: small,
      slides: slide,
      bestProduct: bestProduct.list,
      newProduct: newProduct.list,
      nowUse: nowUse.list,
      review: review.list,
      timeDeal: timeDeal.list,
      firstCome: firstCome.list,
      interest: interest.list,
      recent: recent.list,
      influencer: influencer.list,
      special: special.list,
    };
    return data;

  }

};

const fetchSpecialData = async () => {
  const { category, small, slide } = await api_get("/banner")

  const member = await isMember();
  if(member.status==="fail"){
    const { bestProduct, newProduct, nowUse, review, timeDeal, firstCome, interest, recent, influencer } = await api_get("/main")
    const data = {
      category: category,
      small: small,
      slides: slide,
      bestProduct: bestProduct.list,
      newProduct: newProduct.list,
      nowUse: nowUse.list,
      review: review.list,
      timeDeal: timeDeal.list,
      firstCome: firstCome.list,
      interest: interest.list,
      recent: recent.list,
      influencer: influencer.list,
    };
    return data;
  } else {
    const { bestProduct, newProduct, nowUse, review, timeDeal, firstCome, interest, recent, influencer } = await api_get_header_token("/main")
    const data = {
      category: category,
      small: small,
      slides: slide,
      bestProduct: bestProduct.list,
      newProduct: newProduct.list,
      nowUse: nowUse.list,
      review: review.list,
      timeDeal: timeDeal.list,
      firstCome: firstCome.list,
      interest: interest.list,
      recent: recent.list,
      influencer: influencer.list,
    };
    return data;

  }

};

const fetchProductsData = async () => {
  const member = await isMember();
  if(member.status==="fail"){
    const { list } = await api_get("/sort/product-new")
    return list;
  } else {
    const { list } = await api_get_header_token("/sort/product-new");
    return list;
  }
};

const fetchTimeDealData = async () => {
  const member = await isMember();
  if(member.status==="fail"){
    const { list } = await api_get("/sort/time-deal")
    const expire = await api_get("/expire-controller");
    return { list, expire };
  } else {
    const { list } = await api_get_header_token("/sort/time-deal");
    const expire = await api_get_header_token("/expire-controller");
    return { list, expire };
  }
};

const fetchCategoryData = async (categoryName,sort,desc) => {
  let apiUrl = "";
  let brand = "";
  let mainCategory = "";
  let sortNow = "productSeq,desc";
  if (categoryName === "BRAND") {
    brand = "Bellelanco";
  } else if (categoryName === "PERFUME&BODY") {
    mainCategory = "PERFUME&BODY";
  } else if (categoryName === "FASHION&LIFE") {
    mainCategory = "Fashion&Life";
  } else if (categoryName === "MAKEUP") {
    mainCategory = "makeup";
  } else if (categoryName === "SKINCARE") {
    mainCategory = "skincare";
  } else if (sort ==="brand") {
    brand = categoryName;
  }
  if (desc ==="desc") {
    sortNow = sort;
  }

  if (categoryName === "TIME DEAL") {
    apiUrl = "/sort/time-deal";
  } else if (categoryName === "NOW USE") {
    apiUrl = "/sort/now-use";
  } else {
    apiUrl = "/sort/product-list";
  }

  const params = {
    sort: sortNow,
    mainCategory: mainCategory,
    brand: brand,
  }

  const { list } = await api_get_list_header_token(apiUrl , params)
  return list;
};

const fetchSubCategoryData = async (categoryName, sub) => {
  let apiUrl = `/sub-category/sort/${categoryName.toLowerCase()}`;
  let params = {};

  if (sub) {
    apiUrl = "/sort/product-list";
    params = {
      subCategory: sub,
    };
  }

  const { list } = await api_get_list_header_token(apiUrl, params);
  return list;
};

export {
  fetchWithAuth,
  fetchMainData,
  fetchCategoryData,
  fetchProductsData,
  fetchTimeDealData,
  fetchSubCategoryData,
};