import React from "react";
import styled from "styled-components";
import HeaderWithBack from "../../components/HeaderWithBack";
import { useNavigate, useParams,useLocation } from "react-router-dom";
import ListMainProduct from "../../components/ListMainProduct";
import { useQuery } from "react-query";
import { fetchCategoryData, fetchSubCategoryData } from "../../utils";
import Loading from "../Loading";
import ErrorPage from "../ErrorPage";
import NavBasic from "../../components/NavBasic";
import Button from "../../components/Button";
import circlecheck from "../../assets/common/circle-check.png";
import { flexCenterCenter, scrollStyle } from "../../components/Common";
import { useState,useEffect } from "react";
import ArrowButton from "../../components/ArrowButton";

function Category() {
  const { state } = useLocation();
  const [showCategory, setShowCategory] = useState(false);
  const [showSubCategory, setShowSubCategory] = useState(false);
  const [subCategory, setSubCategory] = useState(null);
  const [nowSubSort, setNowSubSort] = useState("");
  const [nowBrand, setNowBrand] = useState("");
  const [nowSort, setNowSort] = useState("");
  const navigate = useNavigate();
  const { categoryName } = useParams(); // brandName 매개변수 추가

  const { isLoading, isError, data, refetch } = useQuery([], async () => {
    try {
      const subCategoryData = await fetchSubCategoryData(categoryName);
      setSubCategory(subCategoryData);

      if (state === "Dermacentric") {
        return fetchCategoryData("Dermacentric", "brand");
      } else if (nowBrand !== "") {
        return fetchCategoryData(nowBrand, "brand");
      } else if (nowSort !== "") {
        return fetchCategoryData(categoryName, nowSort, "desc");
      } else if(nowSubSort !== "") {
        return fetchSubCategoryData(categoryName, nowSubSort);
      }
      else {
        return fetchCategoryData(categoryName);
      }
    } catch (error) {
      throw error;
    }
  });

  const handleShowCategory = () => {
    setShowCategory(!showCategory);
  }

  const handleShowSubCategory  = () => {
    setShowSubCategory(!showSubCategory);
  }

  const sortBrand = (brand) => {
    navigate(`/category/BRAND/${brand}`); // URL에 brand 매개변수 추가
    setNowBrand(brand);
    setShowCategory(false);
  };

  const sortNormal = (sortNormal) =>{
    setNowSort(sortNormal);
    setShowCategory(false);
    setNowSubSort("");
  }

  const sortSubNormal = (sortNormal) => {
    setNowSubSort(sortNormal);
    setShowSubCategory(false);
    setNowSort("");
  }

  useEffect(() => {
    if (nowBrand !== "" || nowSort !== "" || nowSubSort !== "") {
      refetch();
    }
  }, [nowBrand,nowSort,nowSubSort]);

  return (
    <StyledDiv>
      <HeaderWithBack eng="true" text={categoryName}></HeaderWithBack>
      {categoryName === "ALL" ? (
        <>
          <div className="right">
            <span>並べ替え</span>
            <StyledButton onClick={handleShowCategory}>▼ 分類</StyledButton>
          </div>
          {isLoading && <Loading />}
          {isError && <ErrorPage />}
          {data && (
            <ListMainProduct
              isNowuse={false}
              isFirstCome={false}
              isTimedeal={false}
              data={data}
            />
          )}
        </>
      ) : (
        <>
          <div className="right">
            <StyledButton onClick={handleShowSubCategory}>▼ カテゴリー</StyledButton>
            <StyledButton onClick={handleShowCategory}>▼ 分類</StyledButton>
          </div>
          {isLoading && <Loading />}
          {isError && <ErrorPage />}
          {data && (
            <ListMainProduct
              isFirstCome={false}
              isNowuse={false}
              isTimedeal={false}
              data={data}
            />
          )}
        </>
      )}
      {categoryName === "NOWUSE" ? (
        <>
          <ListMainProduct isNowuse={true} margintop={"5px"}></ListMainProduct>
          <ListMainProduct isNowuse={true} margintop={"5px"}></ListMainProduct>
        </>
      ) : (
        <>
          <ListMainProduct margintop={"5px"} isNowuse={false}></ListMainProduct>
          <ListMainProduct margintop={"5px"} isNowuse={false}></ListMainProduct>
        </>
      )}
      <NavBasic />
      { showCategory &&
        <>
          <Fullscreen onClick={handleShowCategory} />
          <ModalDiv>
            <AllowButton onClick={handleShowCategory}>
              <img src={circlecheck} alt="circlecheck" />
            </AllowButton>

            {categoryName !== null && categoryName === "BRAND" ? (

                <StyledUl>

                  <StyledLi onClick={()=>sortBrand("Dermacentric")}>Dermacentric</StyledLi>
                  <StyledLi onClick={()=>sortBrand("Bellelanco")}>Bellelanco</StyledLi>
                  <StyledLi onClick={()=>sortBrand("Maycoop")}>Maycoop</StyledLi>
                  <StyledLi onClick={()=>sortBrand("Celltiara")}>Celltiara</StyledLi>
                  <StyledLi onClick={()=>sortBrand("Vibewake")}>Vibewake</StyledLi>
                  <StyledLi onClick={()=>sortBrand("Vinnerty")}>Vinnerty</StyledLi>
                  <StyledLi onClick={()=>sortBrand("Near&Dear")}>Near&Dear</StyledLi>
                  <StyledLi onClick={()=>sortBrand("MINISERIES")}>MINISERIES</StyledLi>
                  <StyledLi onClick={()=>sortBrand("iBrea")}>iBrea</StyledLi>
                  <StyledLi onClick={()=>sortBrand("Dr.Wellmadeone")}>Dr.Wellmadeone</StyledLi>
                  <StyledLi onClick={()=>sortBrand("Face More")}>Face More</StyledLi>

                </StyledUl>

              ) :
              <StyledUl>

                <StyledLi onClick={()=>sortNormal("priceYen,desc")}>価格の高い順</StyledLi>
                <StyledLi onClick={()=>sortNormal("priceYen")}>低価格順</StyledLi>
                <StyledLi onClick={()=>sortNormal("salesRate,desc")}>人気順</StyledLi>
                <StyledLi onClick={()=>sortNormal("averageScore,desc")}>評価順</StyledLi>

              </StyledUl>
            }
          </ModalDiv>
        </>
      }

      { showSubCategory &&
        <>
          <Fullscreen onClick={handleShowSubCategory} />
          <ModalDiv>
            <AllowButton onClick={handleShowSubCategory}>
              <img src={circlecheck} alt="circlecheck" />
            </AllowButton>
            <StyledUl>
              { subCategory.map((item, index) => {
                return (
                  <StyledLi key={index} onClick={()=>sortSubNormal(item)}>{item}</StyledLi>
                );
              })
              }
            </StyledUl>
          </ModalDiv>
        </>
      }
      <ArrowButton />
    </StyledDiv>
  );
}

export default Category;
const StyledDiv = styled.div`
  position: relative;
  min-height: 100vh;
  .right {
    width: 96%;
    align-items: center;
    margin-top: 15px;
  }
  .right span {
    padding: 0;
    margin-right: 8px;
  }
`;
const StyledButton = styled.button`
  height: 30px;
  margin-right: 5px;
  font-family: var(--font-jp-light);
  font-size: 12px;
  color: #000;
  padding: 0 10px;
  border: 1px solid #000;
  border-radius: 20px;
  background-color: #fff;
  svg {
    height: 10px;
    width: 10px;
    color: black;
  }
  div:first-of-type {
    div {
      margin: auto;
      padding: 0 1px 0 1px;
      min-height: fit-content;
    }
  }
  div:last-child {
    border: none;
  }

`;

const Fullscreen = styled.div`
  position: absolute;
  top: 50px;
  left: 0;
  max-width: 500px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 9;
`;

const ModalDiv = styled.div`
  position: fixed;
  bottom: 0;
  width: 500px;
  /* min-height: 250px; */
  background: white;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  border-radius: 20px 20px 0 0;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.125);
  z-index: 99;
  @media screen and (max-width: 500px) {
    width: 100%;
  }
`;

const StyledUl = styled.ul`
  ${scrollStyle}
  max-height: 500px;
`;

const StyledLi = styled.li`
  font-size: 16px;
  font-weight: 400;
  height: 65px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--color-gray2);
  cursor: pointer;
  &:last-child {
    border: none;
  }
  span {
    margin-left: 20px;
    color: var(--color-main);
  }
  &.active {
    font-weight: 700;
  }
`;

const StyledBottomButton = styled(Button)`
  width: 100%;
  height: 50px;
  background: var(--color-main);
  color: #fff;
  font-size: 25px;
  font-family: var(--font-jp-bold);
  border-radius: 50px;
  /* margin: 10px 20px; */
`;

const AllowButton = styled(Button)`
  ${flexCenterCenter}
  width: 50px;
  height: 40px;
  position: absolute;
  background: var(--color-main);
  top: -40px;
  right: 28px;
  border-radius: 10px 10px 0 0;
`;