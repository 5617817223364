import React from "react";
import styled from "styled-components";
import HeaderWithBack from "../../components/HeaderWithBack";
import { useNavigate, useParams,useLocation } from "react-router-dom";
import ListMainProduct from "../../components/ListMainProduct";
import { useQuery } from "react-query";
import { fetchCategoryData } from "../../utils";
import Loading from "../Loading";
import ErrorPage from "../ErrorPage";
import NavBasic from "../../components/NavBasic";
import Button from "../../components/Button";
import circlecheck from "../../assets/common/circle-check.png";
import { DivFlexBetween, TagBox, TagButton, Tags, flexBetweenCenter, flexCenterCenter, scrollStyle } from "../../components/Common";
import { useState,useEffect } from "react";
import { api_get } from "../../apis/apis";
import ArrowButton from "../../components/ArrowButton";

const Brand = () => {
  const { state } = useLocation();
  const [showCategory, setShowCategory] = useState(false);
  const [nowBrand, setNowBrand] = useState("Bellelanco");
  const [brandData, setBrandData] = useState([]);
  const navigate = useNavigate();
  const { brandName } = useParams(); // brandName 매개변수 추가

  const { isLoading, isError, data,refetch } = useQuery([], () => {
		if (brandName !== undefined){
			return fetchCategoryData(brandName,"brand");
		} else {
			return fetchCategoryData(nowBrand,"brand");
		}
  });

  const getBrandData = async() => {
    const response = await api_get('/brand-sort');
    setBrandData(response.list);
  }

  const handleShowCategory = () => {
    setShowCategory(!showCategory);
  }

  const sortBrand = (brand) => {
    navigate(`/brand/${brand}`); // URL에 brand 매개변수 추가
    setNowBrand(brand);
    setShowCategory(false);
  };

  useEffect(() => {
    if (nowBrand !== "") {
      refetch();
    }
  }, [nowBrand, brandName]);

  useEffect(()=>{
    getBrandData();
  },[]);

  return (
    <StyledDiv>
      <HeaderWithBack eng="true" text={brandName ? brandName : "Bellelanco"}></HeaderWithBack>
        <>
          <StyledDivBetween>
            <Tags>
              <TagButton onClick={() => sortBrand('Aubeveni')}>#Aubeveni</TagButton>
              <TagButton onClick={() => sortBrand('Bellelanco')}>#Bellelanco</TagButton>
              <TagButton onClick={() => sortBrand('Celltiara')}>#Celltiara</TagButton>
            </Tags>
            <StyledButton onClick={handleShowCategory}>+もっと見る</StyledButton>
          </StyledDivBetween>
          
          {isLoading && <Loading />}
          {isError && <ErrorPage />}
          {data && (
            <ListMainProduct
              isFirstCome={false}
              isNowuse={false}
              isTimedeal={false}
              data={data}
            />
          )}
        </>

      { showCategory &&
        <>
          <Fullscreen onClick={handleShowCategory} />
          <ModalDiv>
            <AllowButton onClick={handleShowCategory}>
              <img src={circlecheck} alt="circlecheck" />
            </AllowButton>


            <StyledUl>
              {brandData && brandData.map((list, index)=> (
                <StyledLi key={index} onClick={()=>sortBrand(list)}>{list}</StyledLi>
              ))}
            </StyledUl>

          </ModalDiv>
        </>
      }
			<NavBasic />
      <ArrowButton />
    </StyledDiv>
  );
};

export default Brand;

const StyledDiv = styled.div`
  position: relative;
  min-height: 100vh;
  margin-bottom: 75px;
  .right {
    width: 96%;
    align-items: center;
    margin-top: 15px;
  }
  .right span {
    padding: 0;
    margin-right: 8px;
  }
`;
const StyledButton = styled.button`
  height: 30px;
  margin-left: 10px;
  font-family: var(--font-jp-light);
  font-size: 12px;
  color: #fff;
  padding: 0 10px;
  border: 1px solid #000;
  border-radius: 20px;
  background-color: #000;
  white-space: nowrap;
  svg {
    height: 10px;
    width: 10px;
    color: black;
  }
  div:first-of-type {
    div {
      margin: auto;
      padding: 0 1px 0 1px;
      min-height: fit-content;
    }
  }
  div:last-child {
    border: none;
  }

`;

const Fullscreen = styled.div`
  position: absolute;
  top: 50px;
  left: 0;
  max-width: 500px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 9;
`;

const ModalDiv = styled.div`
  position: fixed;
  bottom: 0;
  width: 500px;
  /* min-height: 250px; */
  background: white;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  border-radius: 20px 20px 0 0;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.125);
  margin-bottom: 75px;
  z-index: 99;
  @media screen and (max-width: 500px) {
    width: 100%;
  }
`;

const StyledUl = styled.ul`
  ${scrollStyle}
  max-height: 500px;
`;

const StyledLi = styled.li`
  font-size: 16px;
  font-weight: 400;
  height: 65px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--color-gray2);
  cursor: pointer;
  &:last-child {
    border: none;
  }
  span {
    margin-left: 20px;
    color: var(--color-main);
  }
  &.active {
    font-weight: 700;
  }
`;

const StyledBottomButton = styled(Button)`
  width: 100%;
  height: 50px;
  background: var(--color-main);
  color: #fff;
  font-size: 25px;
  font-family: var(--font-bold);
  border-radius: 50px;
  /* margin: 10px 20px; */
`;

const AllowButton = styled(Button)`
  ${flexCenterCenter}
  width: 50px;
  height: 40px;
  position: absolute;
  background: var(--color-main);
  top: -40px;
  right: 28px;
  border-radius: 10px 10px 0 0;
`;

const StyledDivBetween = styled(DivFlexBetween)`
  padding: 20px 15px 0 15px;
`;