import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

const buttonStyle = css`
  margin: 0;
  padding: 0;
  border: none;
  color: inherit;
  background: none;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
`;

const StyledButton = styled.button`
  background: var(--color-main);
  ${buttonStyle}
  ${props => props.subColor && css`
    background: var(--color-sub);
  `}
  &:disabled {
    background: var(--color-gray2);
    cursor: default;
  }
`;

const StyledLink = styled(Link)`
  ${buttonStyle}
  &:focus, &:hover, &:visited, &:link, &:active {
    text-decoration: none;
  }
`;

const Button = props => {
  return props.to ? (
    <StyledLink {...props} />
  ) : (
    <StyledButton {...props} />
  )
};

export default Button;
