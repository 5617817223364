import { ContentWrapper, ImgBox, LargeText, MarginBottomBox, PXsBold, Price, RootWrapper, StyledPPale, Title } from './Common';
import HeaderWithBack from './HeaderWithBack';
import FooterMaxButton from './FooterMaxButton';
import sample from '../assets/dummy/mainitemsample.jpg';
import styled, { css } from 'styled-components';
import AskModal from './AskModal';
import React, { useState, useEffect } from "react";
import Modal from './Modal';
import AddressForm from './AddressForm';
import { useLocation,useNavigate } from 'react-router-dom';
import Cookies from "js-cookie";
import { api_post_header_token,api_get_header_token } from '../apis/apis';
const Wrapper = styled.div`
  width: 100%;
  position: relative;
  padding-bottom: 200px;
`;

const LineDiv = styled.div`
  border-top: 0.5px solid var(--color-gray3);
`;

const ItemBox = styled.div`
  display: flex;
  margin-top: 10px;
`;

const TagDiv = styled.div`
  width: 60px;
  height: 20px;
  font-size: 12px;
  font-weight: 700;
  color: var(--color-gray3);
  border: 1px solid var(--color-gray3);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TextBox = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 15px;
  @media screen and (max-width: 768px) {
    width: 70%;
  }
`;

const BetweenBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TitleBox = styled.div`
  font-size: 18px;
  font-weight: 700;
  margin: 25px 0 15px;
`;

const StyledSelect = styled.select`
  width: 100%;
  height: 40px;
  border: 1px solid #000;
  border-radius: 10px;
  padding-left: 10px ; 
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6;
  margin-bottom: 13px;
  `;

const StyledTextarea = styled.textarea`
  height: 180px;
  font-size: 14px;
  line-height: 1.4;
  border: 1px solid #000;
  border-radius: 10px;
  padding: 10px;
  resize: none;
`;

const SelectBox = styled.select`
  width: 40px;
  height: 20px;
  border: 0.45px solid #000;
  border-radius: 20px;
  font-size: 10px;
  font-weight: 350;
  text-align: center;
  
`;

const StyledOption = styled.option`
  padding: 0;
  .sub-color {
    color: var(--color-sub);
  }
`;



const ExchangeForm = () => {
  
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const orderSeq = searchParams.getAll('orderSeq').map(Number);;

  const navigate = useNavigate();
  const [addressValue, setAddressValue] = useState('');
  const [addressData, setAddressData] = useState(false);
  const [info,setInfo] = useState([]);
  const [checkDefault,setCheckDefault] = useState("N");
  const getInfo = async () => {
    const requestBody = {
      orderSeq: orderSeq,
    };
    const result = await api_post_header_token(`/order/exchange/info`, requestBody);
    setInfo(result.list);

    const url = `/address/setdefault-info`;
    const response = await api_get_header_token(url);
    if(response.status!=="fail"){
      setCheckDefault("Y");
    }
  }

  useEffect(() => {
    getInfo();
    handleMountData();
  }, []);

  const handleAddressChange = (defaultValue) => {
    setAddressValue(defaultValue);
  };
  const handleMountData = (data) => {
    setAddressData(data);
  };

  const [askModal, setAskModal] = useState(false);
  const [askModal2, setAskModal2] = useState(false);
  const [modal, setModal] = useState(false);
  const [selectValue, setSelectValue] = useState("");
  const [textareaValue, setTextareaValue] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);

  const onClick = async () => {
   
    if(checkDefault === "N" || selectValue === "" || textareaValue === ""){
      setAskModal2(true);
    }
    else{
      const requestBody = {
        orderId:info.orderId,
        productInfo:info.exchangeInfo,
        exchangeReason:selectValue,
        exchangeContents:textareaValue,
        deliveryMessage:addressValue
      };
      
      const result = await api_post_header_token(`/order/exchange/write`, requestBody);
      if(result.status==="ok"){
        navigate('/mypost');
      }
    }

  };
  const onCancel = () => {
    setAskModal(false);
  };
  const onConfirm = () => {
    setAskModal(false);
    setModal(true);
  };
  const onConfirm2 = () => {setAskModal2(false);};
  const handleConfirm = () => {setModal(false);};

  const handleSelectChange = (value, index) => {
    const updatedExchangeInfo = [...info.exchangeInfo];
    updatedExchangeInfo[index].quantity = value; 
    setSelectedOptions(updatedExchangeInfo);
  };

  return (
    <>
      <RootWrapper>
        <HeaderWithBack text={"交換"}/>

        <ContentWrapper>
          <LargeText>交換</LargeText>
          {info?.exchangeInfo && info?.exchangeInfo?.map((list, index) => (
          <ItemBox key={index}>
            <ImgBox>
              <img src={list.productImage} alt='product'/>
            </ImgBox>
            <TextBox>
              <BetweenBox>
                <TagDiv>発送完了</TagDiv>
                <PXsBold>{info.orderId}</PXsBold>
              </BetweenBox>
              <BetweenBox>
                <Title>{list.productTitle}</Title>

                <SelectBox value={list.selectedOption || ""} onChange={(event) => handleSelectChange(event.target.value, index)}>
                    {[...Array(list.quantity)].map((_, i) => (
                      <StyledOption key={i} value={list.quantity - i}>
                        {list.quantity - i}
                      </StyledOption>
                    ))}
                </SelectBox>

              </BetweenBox>
              <StyledPPale>{list.productOptions} / {list.quantity}</StyledPPale>
            </TextBox>
          </ItemBox>
          ))}
          <TitleBox>交換理由</TitleBox>
          <StyledSelect value={selectValue} onChange={(event) => setSelectValue(event.target.value)}>
            <option value="" disabled>交換理由</option>
            <option value="商品しよう">商品の不具合</option>
            <option value="単純変心">単純変心</option>
          </StyledSelect>
          <StyledTextarea value={textareaValue} onChange={(event) => setTextareaValue(event.target.value)}
            placeholder='ご希望の交換商品をご記入ください。&#13;&#10;例) Belleranco Cusion 22号, 1ea -> belleranco Cusion 21号, 1ea'
          />
        </ContentWrapper>
        <LineDiv />
        <Wrapper>
          <AddressForm onMountData={handleMountData} onAddressChange={handleAddressChange} />
          <FooterMaxButton bottom={"50px"} onClick={onClick} text={"交換依頼"} />
        </Wrapper>
        <AskModal
          visible={askModal}
          title="交換を進めましょうか？"
          confirmText='はい' //확인
          cancelText='いいえ' //취소
          onConfirm={onConfirm}
          onCancel={onCancel}
        />
        <Modal
          visible={modal}
          title="受付完了!"
          text="交換申請が完了しました"
          confirmText='注文履歴に戻る'
          onConfirm={handleConfirm}
        />
        <Modal
          visible={askModal2}
          text="正しい情報を入力してください。"
          confirmText='はい' //확인
          onConfirm={onConfirm2}
        />
      </RootWrapper>
    </>
  );
};

export default ExchangeForm;