import React from "react";
import AppleSignin from "react-apple-signin-auth";
import applelogo from "../assets/logos/applelogo.svg";
import jwtDecode from "jwt-decode";
import Cookies from "js-cookie";
// const baseURI = process.env.REACT_APP_APPLE_URI;

const login = async (email,sns,seq) => {

  const baseUrl = process.env.REACT_APP_BASEURL;

  const response = await fetch(`${baseUrl}/user/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email:email,
      provider:"apple",
    }),
  });
  const result = await response.json();

  if(result.status==="ok"){
    const accessToken = result.access_token;
    if (window.ReactNativeWebView != undefined){
      window.ReactNativeWebView.postMessage(JSON.stringify({"seq":result.seq}))
    }

    if (window.ReactNativeWebView != undefined) {
      Cookies.set('jwtToken', accessToken, {expires: 180});
    } else {
      Cookies.set('jwtToken', accessToken, {expires: 3});
    }

    if(sns === "sns"){
      window.location.href = `https://yourish.jp/email-input?seq=${seq}`;
    }else{
      window.location.href = 'https://yourish.jp/';
    }
   
  }
}

const signup = async (info) => {
  const baseUrl = process.env.REACT_APP_BASEURL;
  let nick = ""
  try{
    let parts = info.email.split("@");
    nick = parts[0]
  }catch(err){
    nick = info.email
  }

  const response = await fetch(`${baseUrl}/user/sign-up`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email:info.email,
      username:nick,
      nickname:nick,
      password:"",
      provider:"apple",
      pushMessage:"Y",
      receiveEmail:"Y"
    }),
  });
  const result = await response.json();

  if(result.status==="apple"){
    login(info.email,"sns",result.message);
  }
  
};

function decodeApple(response){
  const baseUrl = process.env.REACT_APP_BASEURL;
 
  const identityToken = response.authorization.id_token
  const decode = jwtDecode(identityToken);

  
  fetch(`${baseUrl}/user/sign/apple?email=${decode.email}`, {
    method: 'GET',
  })
  .then(response => response.json())
  .then(data => {
    if(data.status==="ok"){
      signup(decode);
    }
    else{
      login(decode.email);
    }
  })

}

const AppleButton = ({ ...rest }) => (
  <AppleSignin
    authOptions={{
      clientId: "com.yourish.yourish-web",
      scope: "email name",
      redirectURI: "https://yourish.jp/sign-in",
      state: "sdf@#12",
      nonce: "nonce",
      usePopup: true,
    }}
    onSuccess={(response) => decodeApple(response)} // default = undefined
    onError={(error) => console.error(error)} // default = undefined
    skipScript={false} // default = undefined
    render={(props) => (
      <button className="login" {...props}>
        <img
          src={applelogo}
          alt="applelogo"
          width="24px"
          className="icon apple"
        />
        <span>Sign in with Apple</span>
      </button>
    )}
  />
);
export default AppleButton;
