import React from "react";
import styled from "styled-components";

const Fullscreen = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  max-width: 500px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 999;
  @media screen and (max-width: 500px) {
    max-width: 100%;    
  }
`;

const AskModalBlock = styled.div`
  position: absolute;
  bottom: 50%;
	left: 50%;
	transform: translate(-50%);
  width: 80%;
  min-height: 200px;
  background: white;
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.125);
  h2 {
    font-size: 20px;
    font-weight: 700;
    margin: 0 auto 50px;
    text-align: center;
  }
  .buttons {
    color: #fff;
    display: flex;
    justify-content: space-between;
  }
`;

const StyledButton = styled.button`
  background: var(--color-main);
  border-radius: 50px;
  width: 100%;
  height: 50px;
  font-size: 25px;
  color: #fff;
  font-weight: 700;
  border: none;
`;

const CenterModal = ({
  visible,
  title,
  // description,
  confirmText = "はい",
  cancelText = "いいえ",
  onConfirm,
  onCancel,
}) => {
  if (!visible) return null;
  return (
    <Fullscreen>
      <AskModalBlock>
        <h2>{title}</h2>
        <div className="buttons">
          <StyledButton onClick={onConfirm}>{confirmText}</StyledButton>
        </div>
      </AskModalBlock>
    </Fullscreen>
  );
};

export default CenterModal;


