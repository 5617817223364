import React from 'react';
import { RootWrapper } from '../components/Common';
import HeaderWithBack from '../components/HeaderWithBack';
import styled from 'styled-components';
import NavBasic from '../components/NavBasic';

const GenuineProduct = () => {
	return (
		<RootWrapper>
			<HeaderWithBack text={"正規品認証"} />
			<ImgDiv>
				<img src='https://yourish-web-storage.s3.ap-northeast-2.amazonaws.com/product-images/etc/%EC%A0%95%ED%92%88%EC%9D%B8%EC%A6%9D.jpg' alt='' />
			</ImgDiv>
			<NavBasic />
		</RootWrapper>
	);
};

export default GenuineProduct;

const ImgDiv = styled.div`
	img {
		width: 100%;
	}
	margin-bottom: 75px;
`;