import styled, { css } from "styled-components";
import Button from "./Button";
import check_basic from "../assets/common/check-basic.svg";
import checked_basic from "../assets/common/checked-basic.svg";
import checked_pink from "../assets/common/checked-pink.svg";
import { FaStar } from "react-icons/fa";
import { Rate } from "antd";

// 스타일
export const buttonStyle = css`
  margin: 0;
  padding: 0;
  border: none;
  color: inherit;
  background: none;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
`;

export const scrollStyle = css`
  overflow: auto;
  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */
  &::-webkit-scrollbar {
    display: none; /* 크롬, 사파리, 오페라, 엣지 */
  }
`;

export const itemBoxShadow = css`
  box-shadow: ${props => props.shadow ? '0px 1px 41px 0px rgba(0, 0, 0, 0.11)' : '0.5px 0.5px 2px rgba(232, 232, 232, 0.8)'};
`;

export const flexCenterCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const inputStyle = css`
  width: 100%;
  height: 40px;
  border: none;
  background: rgba(217, 217, 217, 0.5);
  border-radius: 10px;
  padding: 0 15px;
  ${props => props.readOnly && css`
    background: var(--color-gray1);
  `}
`;

export const textareaStyle = css`
  width: 100%;
  height: 200px;
  border: none;
  background: var(--color-gray2);
  border-radius: 10px;
  padding: 15px;
  resize: none;
`;

export const flexBetweenCenter = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const checkBoxStyle = css`
  input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    outline: none;
    cursor: pointer;
  }
  input::after {
    content: "";
    background: url(${check_basic}) no-repeat;
    display: inline-block;
    width: 16px;
    height: 16px;
  }
  input[type="checkbox"]:checked::after {
    background: url(${checked_basic}) no-repeat;
    width: 16px;
    height: 16px;
  }
`;

export const checkBoxPink = css`
  input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    outline: none;
    cursor: pointer;
  }
  input::after {
    content: "";
    background: url(${check_basic}) no-repeat;
    display: inline-block;
    width: 16px;
    height: 16px;
  }
  input[type="checkbox"]:checked::after {
    background: url(${checked_pink}) no-repeat;
    width: 16px;
    height: 16px;
  }
`;

export const itemMediaWithStyle = css`
  width: 100%;
  @media screen and (max-width: 355px) {
    //min-width: 200px;
  }
`;

export const ItemBrand = styled.div`
	font-size: 12px;
	color: rgba(192, 192, 192, 1);
	padding-bottom: 3px;
`;


// 컴포넌트
export const RootWrapper = styled.div`
  max-width: 500px;
  width: 500px;
  height: auto;
  min-height: 100vh;
  background-color: #fff;
  position: relative;
  ${props => props.maincolor && css`
        background: var(--color-main-pale);
  `}
  ${props => props.gray1Color && css`
        background: var(--color-gray1);
  `}
  ${props => props.gray47Color && css`
        background: #474747;
  `}
  @media screen and (max-width: 500px) {
    width: 100%;
    max-width: 100%;
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  ${props => props.marginBottom && css`
    margin-bottom: 75px
  `}
  ${props => props.noFlex && css`
    display: block;
  `}
`;

export const DivFlexBetween = styled.div`
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${props =>
  props.isMargin &&
    css`
      margin: 15px 0;
    `
  }
  .flex {
    display: flex;
    align-items: center;
  }
  .cancel-membership {
    color: var(--color-gray3);
    font-size: 12px;
    /* text-decoration: underline; */
  }
`;

export const FooterButton = styled.button`
  background: var(--color-main);
  color: #fff;
  border-radius: 10px;
  border: none;
  font-size: 25px;
  font-weight: 600;
  width: 90%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  &:disabled {
    background: rgba(217, 217, 217, 1);
  }
`;

export const FooterButton2 = styled.button`
  background: var(--color-main);
  color: #fff;
  border-radius: 10px;
  border: none;
  font-size: 25px;
  font-weight: 600;
  width: 90%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
  &:disabled {
    background: rgba(217, 217, 217, 1);
  }
`;


export const StyledRate = styled(Rate)`
  color: var(--color-main);
  margin-left: 0px;
  ${props => props.colorGray && css`
    color: var(--color-gray5);
  `}
  li {
    vertical-align: text-top;
  }
  &.ant-rate .ant-rate-star:not(:last-child) {
    margin-inline-end: 1px;
  }

  svg {
    ${props => props.edit && css`
      font-size: 45px;
    `}
    ${props => props.xSmall && css`
      width: 10px;
      height: 10px;
    `}
    ${props => props.small && css`
      width: 15px;
      height: 15px;
    `}
  }
`; 

export const ItemBox = styled.div`
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 768px) {
    width: 75%;
  }
`;

export const ImgBox = styled.div`
  ${itemBoxShadow}
  min-width: 80px;
  width: 80px;
  height: 80px;
  border-radius: 20px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
  }
`;

export const CheckBoxDiv = styled.div`
  ${checkBoxStyle};
  font-family: var(--font-jp-regular);
`;

export const CheckBoxPink = styled.div`
  ${checkBoxPink};
  font-family: var(--font-jp-regular);
  display: flex;
  align-items: center;
  justify-content: space-between;
  div:first-child {
    display: flex;
    align-items: center;
  }
  span {
    font-size: 16px;
    font-weight: 700;
    margin-left: 7px;
  }
  .delete {
    font-size: 12px;
    font-weight: 500;
    color: #fff;
    background: rgba(255, 123, 154, 1);
    border-radius: 13px;
    padding: 3px 9px;
    line-height: 16px;
    display: flex;
    align-items: center;
  }
`;

export const PXsBold = styled.p`
  font-size: 10px;
  font-weight: 600;
  color: var(--color-gray4);
`;

export const StyledPPale = styled.p`
  font-size: 12px;
  font-weight: 400;
  color: rgba(192, 192, 192, 1);
  ${props => props.margin && css`
    margin-top: 6px;
  `}
`;

export const StyledP350 = styled.p`
  font-family: var(--font-jp-regular);
  font-size: 12px;
  font-weight: 350;
`;
export const StyledP = styled.p`
  font-size: 12px;
  font-weight: 400;
`;
export const StyledPText = styled.p`
  font-size: 12px;
  font-weight: 500;
`;
export const StyledPTitle = styled.p`
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 5px;
  ${props => props.gray3line && css`
    color: var(--color-gray3);
    text-decoration: line-through;
  `}
`;

export const TextSub = styled.p`
  font-size: 14px;
  font-weight: 350;
  color: var(--color-sub);
  font-family: var(--font-jp-regular);
`;

export const PtextM = styled.p`
  font-size: 14px;
  font-weight: 400;
  margin-top: 5px;
  font-family: var(--font-jp-regular);
`;

export const PTitle = styled.p`
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 10px;
`;

export const Title = styled.p`
  font-size: 14px;
  font-weight: 700;
`;

export const Price = styled.p`
  font-size: 16px;
  font-weight: 700;
  ${props => props.gray4 && css`
    color: var(--color-gray4);
  `}
`;

export const PriceSub = styled.p`
  font-family: var(--font-jp-bold);
  font-size: 18px;
  font-weight: 700;
  color: var(--color-sub);
`;

export const LargeText = styled.p`
  font-size: 24px;
  font-weight: 700;
`;

export const TagBox = styled.div`
  display: flex;
  align-items: center;
  margin-top: 50px;
  img {
    width: 60px;
    height: 18px;
  }
  .text {
    font-family: var(--font-jp-bold);
    font-size: 18px;
    white-space: nowrap;
    color: var(--color-sub);
    margin-right: 5px;
  }
`;

export const Tags = styled.div`
  ${scrollStyle}
  display: flex;
  align-items: center;
`;

export const TagButton = styled.button`
  height: 30px;
  padding: 0 5px;
  border: 1px solid var(--color-gray4);
  border-radius: 60px;
  background: #fff;
  color: var(--color-gray4);
  font-size: 14px;
  font-weight: 400;
  margin: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
`;

export const LineDiv = styled.div`
  width: 100%;
  border-bottom: 0.5px solid var(--color-gray3);
`;

export const StyledLine = styled.div`
  width: 100%;
  border-bottom: 2px solid rgba(217, 217, 217, 0.5);
  ${props => props.width && css`
    width: calc(100% + 40px);
    display: flex;
    transform: translateX(-20px);
    margin-top: 10px;
  `};
`;

export const MarginBottomBox = styled.div`
  margin-bottom: 150px;
`;

export const ViewMoreButton = styled(Button)`
  background: #000;
  color: #fff;
  width: 130px;
  height: 30px;
  border-radius: 20px;
  border: none;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${(props) => props?.margin || "0"};
  ${props => props.dark && css`
    background: #000;
    color: #fff;
  `};
  @media screen and (max-width: 500px) {
    margin-bottom: 50px;
    ${props => props.isTimeDeal && css`
      margin-bottom: 0;
  `}
  }
`;

export const FullImgDiv = styled.div`
  width: 100%;
  overflow: hidden;
  img {
    width: 100%;
  }
`;

export const OrderStateDiv = styled.div`

`;

export const FlexCenterBox = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledFaStar = styled(FaStar)`
  color: var(--color-main);
  ${props => props.empty && css`
    color: var(--color-gray2);
  `}
  ${props => props.gray && css`
    color: var(--color-gray5);
  `}
`;

export const OrderTag = styled.div`
  width: fit-content;
  height: 20px;
  font-size: 12px;
  font-weight: 700;
  background: transparent;
  border: 1px solid #000;
  border-radius: 10px;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
`;

export const PayButton = styled.button`
  ${flexCenterCenter}
  background: var(--color-main);
  color: #fff;
  border-radius: 50px;
  border: none;
  font-family: var(--font-jp-bold);
  font-size: 25px;
  width: 90%;
  height: 50px;
  margin: 0 auto 20px;
  &:disabled {
    background: var(--color-gray2);
  }
  ${props => props.subColor && css`
    background: var(--color-sub);
  `}
`;

export const TermsPrivacyDiv = styled.div`
  width: 90%;
  min-height: 100vh;
  margin: 2rem auto;
  padding: 1rem 1rem 2rem 1rem;
  border: 1px solid #000;
  border-radius: 20px;
  line-height: 1.2;
`;

// ListMainProduct & RecenView
export const ItemListWrap = styled.div`
  ${flexCenterCenter}
  margin-bottom: 30px;
  ${props => props.marginBottom && css`
    margin-bottom: 75px;
  `}
`;

export const ItemStyledUl = styled.ul`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 10px;
  flex-wrap: wrap;
  padding: 10px 25px;
  ${props => props.isTimedeal && css`
    margin-bottom: 25px;
  `}
`;

export const ItemStyledLi = styled.li`
  ${flexCenterCenter}
  //width: 47%;
  width: 193px;
  position: relative;
  flex-direction: column;
  margin: 15px 0;
  border-radius: 15px;
  @media screen and (max-width: 500px) {
    width: 47%;
  }
  @media screen and (max-width: 355px) {
    width: 100%;
  }
`;

export const DateSpan = styled.span`
  color: var(--color-gray3);
  font-size: 12px;
  margin-left: 10px;
`;

export const HeartImg = styled.img`
  border-radius: 0;
  width: 24px;
  position: absolute;
  right: 7%;
  top: 4%;
  cursor: pointer;
`;

export const ItemDiv = styled.div`
  width: 100%;
`;

export const ItemTextBoxDiv = styled.div`
  ${itemMediaWithStyle}
  height: 80px;
`;

export const ItemBrandSpan = styled.span`
  font-weight: 700;
  color: ${props => props.color || '#000'};
  margin: 10px 3px 10px 0;
`;

export const ItemSubTextDiv = styled.div`
  font-size: 12px;
  font-weight: 300;
  color: ${props => props.color || '#000'};
  margin-bottom: 5px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
  word-wrap: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const TitleBoxDiv = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  font-size: 16px;
  @media screen and (max-width: 400px) {
    font-size: 15px;
  }
  @media screen and (max-width: 375px) {
    font-size: 14px;
  }
  @media screen and (max-width: 355px) {
    font-size: 16px;
  }
  .score,
  .num {
    font-size: 14px;
    font-weight: 700;
    @media screen and (max-width: 430px) {
      font-size: 12px;
    }
    @media screen and (max-width: 400px) {
      font-size: 11px;
    }
    @media screen and (max-width: 355px) {
      font-size: 14px;
    }
  }
  .score {
    color: var(--color-main);
    margin-right: 2px;
  }
  .num {
    color: var(--color-gray3);
    margin-left: 2px;
    font-weight: 500;
  }
`;

export const PriceBoxDiv = styled.div`
  span:first-of-type {
    font-weight: 700;
    font-size: 16px;
    color: var(--color-sub);
    margin-right: 5px;
    @media screen and (max-width: 415px) {
      font-size: 14px;
    }
    @media screen and (max-width: 390px) {
      font-size: 13px;
    }
  }
  .price {
    font-size: 12px;
    font-weight: 500;
    color: var(--color-gray3);
    text-decoration: line-through;
    margin-right: 5px;
    @media screen and (max-width: 415px) {
      font-size: 10px;
    }
  }
  span:last-of-type {
    font-weight: 700;
    font-size: 16px;
    color: ${(props) => props.color || "#000"};
    @media screen and (max-width: 415px) {
      font-size: 14px;
    }
    @media screen and (max-width: 390px) {
      font-size: 13px;
    }
  }
`;

export const SoldOutDiv = styled.div`
  ${itemMediaWithStyle}
  height: calc(100% - 80px);
  border-radius: 15px;
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  div {
    ${flexCenterCenter}
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 55px;
    font-size: 24px;
    font-weight: 700;
    color: #fff;
    background: #000;
  }
`;

export const NowUseDiv = styled.div`
  ${flexCenterCenter}
  ${itemMediaWithStyle}
  height: calc(100% - 80px);
  position: absolute;
  top: 0;
  div {
    ${flexCenterCenter}
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 30px;
    font-family: var(--font-jp-light);
    font-size: 12px;
    line-height: 30px;
    color: #fff;
    background: red;
    border-radius: 0 0 15px 15px;
    background: rgba(0, 0, 0, 0.5);
  }
`;

export const FirstComeDiv = styled.div`
  ${itemMediaWithStyle}
  height: calc(100% - 80px);
  border-radius: 15px;
  position: absolute;
  top: 0;
  background: rgba(255, 255, 255, 0.5);
  .first-come {
    ${flexCenterCenter}
    flex-direction: column;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 50%;
    text-align: center;
    color: #000;
    background: var(--color-main);
    p:first-child {
      font-size: 17px;
      font-weight: 700;
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
    }
    p:last-child {
      display: block;
      width: 90%;
      font-weight: 400;
      font-size: 12px;
      color: #000;
      padding-top: 10px;
      border-top: 1px solid #000;
    }
  }
`;
