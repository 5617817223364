import CryptoJS from "crypto-js";
export function getToken() {
  return localStorage.getItem("accessToken") || "";
}

export const getEncrypt = (password) => {
  const secretKEY = process.env.REACT_APP_SECRETKEY;
  const IV_LENGTH = 16; // For AES, this is always 16

  let randomIV = Math.floor(Math.random() * 9999999999999999);
  if (randomIV < 999999999999999){
    randomIV += 1000000000000000;
  }

  let ivRandom = CryptoJS.lib.WordArray.random(IV_LENGTH);

  const encrypted = CryptoJS.AES.encrypt(
    randomIV + password,
    CryptoJS.enc.Utf8.parse(secretKEY),
    {
      iv: ivRandom,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC,
    }
  );
  return encrypted.toString();
};
