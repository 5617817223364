import React from 'react';
import loadingimg from '../assets/common/loading.gif';
import styled from 'styled-components';

const LoadingBox = styled.div`
	position: fixed;
	top: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
  	max-width: 500px;
	min-height: 100vh;
	background: var(--color-gray3);
	z-index: 9999;
	img {
		width: 70px;
	}
	p {
		margin-top: 10px;
		color: #fff;
	}
`;

const Loading = () => {
	return (
		<LoadingBox>
			<img src={loadingimg} alt='loading-img' />
			<p>Loading...</p>
		</LoadingBox>
	);
};

export default Loading;