import React from 'react';
import bigsearch from '../../assets/common/big-search.png';
import { ContentWrapper, RootWrapper, TagBox, TagButton, Tags } from '../../components/Common';
import HeaderwithSearch from '../../components/HeaderwithSearch';
import { useNavigate, useLocation } from 'react-router-dom';
import { useState,useEffect } from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import Cookies from "js-cookie";
import ListMainProduct from "../../components/ListMainProduct";
import Loading from '../Loading';
import ErrorPage from '../ErrorPage';
import NavBasic from '../../components/NavBasic';
import { api_get, api_get_header_token } from '../../apis/apis';
const NoResult = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: var(--font-jp-regular);
  img {
    margin: 80px auto 40px;
  }
`;

const SearchResult = () => {
  const jwtToken = Cookies.get("jwtToken");
  
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchWord = searchParams.get('word');
  
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [suggested,setSuggested] =useState([]);
  
  const productInfo = async () => {
    const url = `/sort/product-list?sort=productSeq,desc&search=${searchWord}`;
    const response = await api_get_header_token(url);
    const result = response.list;
    re();
    return result;
  }
  const re  = () => {
    refetch();
  }

  const getInfo = async () => {
    const result = await api_get(`/suggested-search/list`);
    setSuggested(result.list);
  }

  useEffect(() => {
    setSearchTerm(searchWord);
    productInfo();
    getInfo();
  }, [searchWord]);

  useEffect(() => {
    getInfo();
  },[])

  const { isLoading, isError, data, refetch } = useQuery([], () => {
    return productInfo();
  });

  const handleTagSearch = (search) => {
    navigate(`/search-results?word=${search}`);
  };
  
  return (
    <RootWrapper>
      {searchTerm && (
      <HeaderwithSearch
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      )}
      <ContentWrapper>
        <TagBox>
          <div className='text'>おすすめ</div>
          <Tags>
          {suggested && suggested.map((list, index) => (
            <TagButton key={list.suggestedSearchSeq} onClick={() => handleTagSearch(list.searchWord)}>#{list.searchWord}</TagButton>
          ))}
          </Tags>
        </TagBox>
      </ContentWrapper>

      <div>
        {isLoading && <Loading />}
        {isError && <ErrorPage />}

        {data && data.length > 0 ? (
        <ListMainProduct isNowuse={false} data={data} marginBottom />

          ) : (
        <NoResult>
          <img src={bigsearch} alt='bigsearch' />
          <p>検索結果が見つかりませんでした。</p>
        </NoResult>
        )}
      </div>
      <NavBasic />
    </RootWrapper>
  );
};

export default SearchResult;