import React from 'react';
import error from "../assets/common/404.jpg"
import logo from "../assets/logos/logo.png";
import backicon from "../assets/header/backicon.svg";
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const StyledDiv = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 500px;
  min-height: 100vh;
	z-index: 9999;
  img {
    width: 100%;
    min-height: 100vh;
    &:last-child {
      position: fixed;
      left: 50%;
      @media screen and (max-width: 915px ) {
        left: 20%;
      }
      @media screen and (max-width: 500px ) {
        left: 20px
      }
    }
  }
  .backicon {
    position: fixed;
    width: 20px;
    top: -47%;
    left: 15px;
  }
  @media screen and (max-width: 500px ) {
    max-width: 100%;
  }
`;

const ErrorPage = () => {
  const navigate = useNavigate();
  return (
    <StyledDiv>
      <img src={error} alt='error' />
      <img onClick={() => navigate(-1)} className='backicon' src={backicon} alt='backicon' />
    </StyledDiv>
  );
};

export default ErrorPage;