import React from 'react';
import styled from 'styled-components';
import notice from '../assets/common/notice.png';
import { useState } from 'react';

const ToggelBox = styled.div`
  width: 100%;
  font-size: 12px;
  border: 2px solid var(--color-main);
  border-radius: 20px;
  margin-bottom: 10px;
`;

const QuestionBox = styled.div`
  height: 40px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 15px;
  cursor: pointer;
  span {
    color: var(--color-main);
  }
  .title {
    
    .date {
      float: right;
    }
  }
`;

const TitleDiv = styled.div`
  display: flex;
  align-items: center;
  font-family: var(--font-jp-bold);
    .icon{
      margin-right: 5px;
      width: 20px;
      height: 20px;
      img {
        width: 100%;
      }
    }
`;

const DateArrowDiv = styled.div`
  display: flex;
  p {
    font-size: 10px;
    font-weight: 500;
    margin-right: 5px;
    /* color: var(--color-gray4); */
    color: var(--color-main);
  }
`;

const AnswerBox = styled.div`
  font-weight: 400;
  line-height: 1.2;
  color: var(--color-gray4);
  font-family: var(--font-jp-regular);
  margin: 15px;
  img {
    width: 100%;
  }
`;

const LineDiv = styled.div`
  border-top: 0.5px solid var(--color-main);
`;

const QAToggleBox = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleBox = () => {
    setIsOpen(!isOpen);
  }

  return (
    <>
      <ToggelBox>
        <QuestionBox onClick={toggleBox}>
          <TitleDiv>
            {props.showIcon && <span className="icon"><img src={notice} alt="notice" /></span>}
            {props.question}
          </TitleDiv>
          <DateArrowDiv>
            <p>{props.signDate}</p>
            {isOpen ? <span>▲</span> : <span>▼</span>}
          </DateArrowDiv>
        </QuestionBox>
        {isOpen &&
          <>
            <LineDiv />
            <AnswerBox style={{ whiteSpace: "pre-line" }}>
              {props.img === "Y" ? 
                <img src={props.imgUrl} alt='img' />
              :
                <>
                  {props.answer}
                </>
              }
            </AnswerBox>
          </>
        }
      </ToggelBox>
    </>
  );
};

export default QAToggleBox;