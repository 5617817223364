import React, { useState } from 'react';
import { ContentWrapper, DivFlexBetween, RootWrapper, flexCenterCenter } from '../../components/Common';
import HeaderWithBack from '../../components/HeaderWithBack';
import styled from 'styled-components';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import edit_pencil from "../../assets/common/edit_pencil.png";
import rank_w from "../../assets/rank_w.png";
import rank_s from "../../assets/rank_s.png";
import rank_g from "../../assets/rank_g.svg";
import rank_d from "../../assets/rank_d.png";
import rank_b from "../../assets/rank_b.png";
import Button from '../../components/Button';
import { useNavigate } from "react-router-dom";
import NavBasic from '../../components/NavBasic';
import { api_get_header_token, api_put_header_token } from "../../apis/apis";
import PasswordModal from '../../components/PasswordModal';
import { getEncrypt } from '../../utils/authUtils';

const PersonalInfoPage = () => {
  const navigate = useNavigate();
  const [info, setInfo] = useState();
  const [pushNotice, setPushNotice] = useState(false);
  const [receiveEmail, setReceiveEmail] = useState(false);
  const [inputValue, setInputValue] = useState(true);
  const [modal, setModal] = useState(false);
  const jwtToken = Cookies.get("jwtToken");
  const baseUrl = process.env.REACT_APP_BASEURL;

  useEffect(() => {
    userInfo();
  }, []);

  const userInfo = async () => {
    const result = await api_get_header_token(`/user/my-page`);
    setInfo(result.list);
    setPushNotice(result.list.pushMessage === "Y");
    setReceiveEmail(result.list.receiveEmail === "Y");
  }

  const handleShowModal = () => {
    setModal(true);
  };

  const handleInfoDelSubmit = async () => {
    const response = await fetch(`${baseUrl}/user/info-delete`, {
      method: 'Delete',
      headers: {
        Authorization: `${jwtToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        password: getEncrypt(inputValue)
      }),
    });
    const result = await response.json();
    if(result.status === "ok"){
      Cookies.remove("jwtToken");
      document.cookie = "jwtToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=yourish.jp;";
      alert("会員脱退が完了しました。");
      navigate('/');
    } else {
      alert("パスワードが一致しません。");
    }
  };

  const handleChangeToggle = async (target, isToggle, setState) => {
    setState(!isToggle);

    const isTargetToggle = target === "push" ? "pushMessage" : "receiveEmail";
    await api_put_header_token(`/user/info/${isTargetToggle}/${isToggle === true ? "N" : "Y"}`);
  };

  const handleCancle = () => {
    setModal(false);
  };

  const handleEdit = () => {
    navigate('/edit-myinfo')
  }

  const onChange = (e) => {
    setInputValue(e.target.value);
  }


  return (
    <>
    <RootWrapper maincolor>
      <HeaderWithBack text={"個人情報"} />
      <ContentWrapper noFlex="true">
        <EditBtn onClick={handleEdit}>
          <img src={edit_pencil} alt='edit_pencil'/>
          <p>修正</p>
        </EditBtn>
        <Rank>
          {info && (
            <>
            {info.rating === 'white' && (
              <img src={rank_w} alt="rank_a" />
            )}
            {info.rating === 'silver' && (
              <img src={rank_s} alt="rank_b" />
            )}
            {info.rating === 'gold' && (
              <img src={rank_g} alt="rank_c" />
            )}
            {info.rating === 'Diamond' && (
              <img src={rank_d} alt="rank_d" />
            )}
            {info.rating === 'black' && (
              <img src={rank_b} alt="rank_b" />
            )}
            {info.rating === 'influencer' && (
              <img src={rank_b} alt="rank_b" />
            )}
            <TextBox>
              <p>{info.name}</p>
              <p>{info.nickname}</p>
              <p>{info.email}</p>
            </TextBox>
            </>
          )} 
        </Rank>

        <ContentBox>
          <DivFlexBetween>
            <div className='flex'>
              <p>プッシュ通知</p>
              <StyledLabel>
                <ToggleInput
                  role="switch"
                  type="checkbox"
                  checked={pushNotice}
                  onChange={() => handleChangeToggle("push", pushNotice, setPushNotice)}
                />
              </StyledLabel >
            </div>
            <Button onClick={handleShowModal} className="cancel-membership">会員脱退</Button>
          </DivFlexBetween>
        </ContentBox>
        <ContentBox>
          <DivFlexBetween>
            <div className='flex'>
              <p>メールマガジンを受信</p>
              <StyledLabel>
                <ToggleInput
                  role="switch"
                  type="checkbox"
                  checked={receiveEmail}
                  onChange={() => handleChangeToggle("email", receiveEmail, setReceiveEmail)}
                />
              </StyledLabel >
            </div>
          </DivFlexBetween>
        </ContentBox>
      </ContentWrapper>
      <NavBasic/>
      <PasswordModal
        visible={modal}
        title="パスワードを入力してください。"
        onConfirm={handleInfoDelSubmit}
        onCancel={handleCancle}
        onChange={(e) => onChange(e)}
      />
      </RootWrapper>
    </>
  );
};

export default PersonalInfoPage;

const Rank = styled.div`
  width: 100%;
  height: 108px;
  background: #fff;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  display: flex;
  align-items: center;
  img {
    width: 80px;
    border-radius: 50%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
    margin: 10px 10px 10px 20px;
    @media screen and (max-width: 360px) {
      width: 60px;
      margin: 5px 10px 5px 20px;
    }
  }
`;

const TextBox = styled.div`
  font-size: 14px;
  font-weight: 700;
  margin-left: 10px;
  p {
    &:last-child {
      margin-top: 5px;
      color: var(--color-gray3);
    }
    @media screen and (max-width: 360px) {
      font-size: 12px;
    }
  }
`;

const ContentBox = styled.div`
  margin: 20px 0;
`;

const StyledLabel = styled.label`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  margin-left: 15px;
`;

const ToggleInput = styled.input`
  appearance: none;
  position: relative;
  width: 42px;
  height: 22px;
  border: none;
  border-radius: 20px;
  background: var(--color-gray2);
  &::before {
    content: "";
    position: absolute;
    left: 0;
    width: 22px;
    height: 22px;
    border: none;
    border-radius: 50%;
    transform: scale(0.8);
    background-color: #fff;
    transition: left 250ms linear;
  }
  &:checked::before {
    background-color: white;
    left: 20px;
  }
  &:checked {
    background: var(--color-sub);
  }
`;

const EditBtn = styled(Button)`
  ${flexCenterCenter}
  width: 74px;
  height: 34px;
  background: var(--color-gray4);
  border-radius: 20px;
  color: #fff;
  margin-bottom: 16px;
  float: right;
  p {
    margin-left: 5px;
    font-size: 12px;
    font-weight: 700;
  }
`;