import React from 'react';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { DivFlexBetween, ImgBox, OrderTag, RootWrapper, StyledPPale, StyledPTitle } from '../../components/Common';
import HeaderWithBack from '../../components/HeaderWithBack';
import { ContentWrapper } from '../../components/Common';
import styled from 'styled-components';
import { useEffect } from 'react';
import AskModal from '../../components/AskModal';
import AskModalOne from '../../components/AskModalOne';
import { api_get_header_token } from '../../apis/apis';
import NumberFormat from '../../components/NumberFormat';
const ItemOrderHistory = () => {
  const { state } = useLocation();
  const [data, setData] = useState({});
  const [productState, setProductState] = useState({});
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);

   
  const fetchProductData = async () => {
    const result = await api_get_header_token(`/order-lookup/detail?orderId=${state}`);
    setData(result.list);
    setProductState(result?.list?.productInfo);
  };

  useEffect(() => {
    fetchProductData();
  }, []);


  const navigate = useNavigate();

  const exChangeClick = async () => {
    //여기서 쿠폰 검사하기
    const result = await api_get_header_token(`/coupon/delivery-check`);
    if(result.message==="shipping coupon already exist"){
      navigate(`/exchange?orderId=${data.orderId}`);
    }else{
      navigate('/buycoupon');
    }

  }

  const delivery = () => {
    navigate(`/delivery-status?orderId=${data.orderId}`);
  }
  const orderCancel = () => {
    setModal(true);
  }
  const con = async() => {
    setModal(false);
    const result = await api_get_header_token(`/order/cancel?orderId=${data.orderId}`);
    
    if(result.status==="ok"){
      setModal2(true);
    }
  }
  const can = () => {
    setModal(false);
  }
  const onConfirm2 = () => {
    navigate(`/mypage/myorderlists`);
  }

  return (
    <RootWrapper>
      <HeaderWithBack text={"注文履歴"} />
      {data && data.productInfo && (
      <ContentWrapper>
        {data && productState && productState.map((product, index) => (
          <ItemBox key={index}>
            <ImgBox>
              <img src={product.productImage} alt='product' />
            </ImgBox>
            <TextBox>
              <DivFlexBetween>
                
                {product.orderState === 'before_payment' ? (
                  <OrderTag>決済待ち</OrderTag>
                ) : product.orderState === 'on_ready' ? (
                  <OrderTag>商品準備中</OrderTag>
                ) : product.orderState === 'on_delivery' ? (
                  <OrderTag>発送中</OrderTag>
                ) : product.orderState === 'on_done' ? (
                  <OrderTag>発送完了</OrderTag>
                ) : product.orderState === 'orderCancel_ready' ? (
                  <OrderTag>キャンセル中</OrderTag>
                ) : product.orderState === 'on_cancel' ? (
                  <OrderTag>キャンセル完了</OrderTag>
                ) : product.orderState === 'onExchange_done' ? (
                  <OrderTag>交換完了</OrderTag>
                ) : product.orderState === 'onExchange_ready' ? (
                  <OrderTag>交換待ち</OrderTag>
                ) : product.orderState === 'onExchange_delivery' ? (
                  <OrderTag>交換中</OrderTag>
                ) : product.orderState === 'onExchangeCancel_ready' ? (
                  <OrderTag>交換キャンセル待ち</OrderTag>
                ) : product.orderState === 'onExchange_cancel' ? (
                  <OrderTag>交換のキャンセル</OrderTag>
                ) : product.orderState === 'onReturn_done' ? (
                  <OrderTag>返品完了</OrderTag>
                ) : product.orderState === 'onReturn_ready' ? (
                  <OrderTag>返品待ち</OrderTag>
                ) : product.orderState === 'onReturn_delivery' ? (
                  <OrderTag>返品中</OrderTag>
                ) : product.orderState === 'onReturnCancel_ready' ? (
                  <OrderTag>返品キャンセル待ち</OrderTag>
                ) : product.orderState === 'onReturn_cancel' ? (
                  <OrderTag>返品キャンセル</OrderTag>
                ) : (
                  ""
                )}
                
                <StyledPPale>{data.orderDate}</StyledPPale>
              </DivFlexBetween>
              <TextButtonBox>
                <ItemTitleBox>
                  <StyledPTitle>{product.productTitle}</StyledPTitle>
                  <Price>
                    <NumberFormat number={product.price} />円&nbsp;
                    <span>{product.productOptions} / {product.quantity}</span>
                  </Price>
                </ItemTitleBox>
              </TextButtonBox>
            </TextBox>
          </ItemBox>
        ))}
        
        <OrderNum>NO. {data.orderId}</OrderNum>
        <TextBlock>
          <div>配送先情報</div>
          <div className='text'>お受取人名 <span>{data.userAddressInfo.resName}</span></div>
          <div className='text'>連絡先電話番号 <span>{data.userAddressInfo.resPhoneNumber}</span></div>
          <div className='text'>フリガナ <span>{data.userAddressInfo.furigana}</span></div>
          <div className='text'>配送先名 <span>{data.userAddressInfo.addressAlias}</span></div>
          <div className='text'>郵便番号・ご住所  <span>{data.userAddressInfo.zipCode} {data.userAddressInfo.address} {data.userAddressInfo.detailAddress}</span></div>
          <div className='text'>配送メモ <span>{data.deliveryMessage}</span></div>
        </TextBlock>
        <LineDiv />
        <TextBlock>
          <div>ご注文金額</div>
          <div className='text'>商品合計金額 <p>{data.beforeDiscountPrice ? <NumberFormat number={data.beforeDiscountPrice} /> : "0"}円</p></div>
          <div className='text'>送料 <p>{data.orderDeliveryPrice ? <NumberFormat number={data.orderDeliveryPrice} /> : "0"}円</p></div>
          <div className='text'>クーポン利用 <p>-{data.couponPrice ? <NumberFormat number={(data.couponPrice)} /> : "0"}円</p></div>
          <div className='text'>決済手数料 <p>{data.paymentMethodFee ? <NumberFormat number={data.paymentMethodFee} /> : "0"}円</p></div>
          <StyledTitle>お支払い合計<p>{data.orderAmount ? <NumberFormat number={data.orderAmount} />  : "0"}円</p></StyledTitle>
        </TextBlock>
        <LineDiv />
        {data.orderState}
        <BottomBox>お支払い方法 <span>{data.paymentMethod === "konbini" ? "コンビニ" : "クレジットカード"}</span></BottomBox>
        
        {/* { data.purchaseConfirmation === "Y" ? (
          <></>
        ) :  */}
        {data.result === "delivery" ? (
          <ButtonBox>
            <BuyButton onClick={delivery}>配送状況</BuyButton>
          </ButtonBox>
        ) : (data.result === "authorized" || data.result === "on_ready") && data.purchaseConfirmation != "Y"? (
          <ButtonBox>
            <BuyButton onClick={orderCancel}>注文のキャンセル</BuyButton>
          </ButtonBox>
        ) : data.result === "authorized" && productState.orderState === 'orderCancel_ready' ? (
          <ButtonBox>
            <BuyButton disabled>キャンセル中</BuyButton>
          </ButtonBox>
        ) : (data.result === "on_done" && data.purchaseConfirmation != "Y") ? (
          <ButtonBox>
            <BuyButton onClick={exChangeClick}>交換する</BuyButton>
          </ButtonBox>
        ): ("") }
      </ContentWrapper>
      )}
      
      <AskModal
        visible={modal}
        title="該当商品にご注文をキャンセルします。"
        confirmText='はい'
        cancelText='いいえ'
        onConfirm={con}
        onCancel={can}
      />
      <AskModalOne
        visible={modal2}
        title="注文キャンセルを受け取りました。"
        confirmText='はい'
        onConfirm={onConfirm2}
      />
    </RootWrapper>
  );
};

export default ItemOrderHistory;

const TextButtonBox = styled.div`
  display: flex;
`;

const ItemTitleBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const DoneButtonBox = styled.div`
  width: 73%;
  margin-left: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  button {
    border: 1px solid #000;
    border-radius: 20px;
    background: #000;
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    padding: 3px 10px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  p {
    font-size: 12px;
    font-weight: 500;
  }
  @media screen and (max-width: 768px) {
    width: 35%;
  }
  @media screen and (max-width: 360px) {
    width: 45%;
  }
`;

const TagButton = styled.button`
  border: 1px solid #000;
  border-radius: 20px;
  background: #fff;
  font-size: 12px;
  font-weight: 700;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ItemBox = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 15px;
`;

const TextBox = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 5px 0;
  margin-left: 15px;
  @media screen and (max-width: 768px) {
    width: 75%;
  }
`;

const OrderNum = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: var(--color-gray4);
  margin: 20px 0;
`;

const TextBlock = styled.div`
  margin: 10px 0;
  line-height: 1.6;
  div {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 10px;
    font-family: var(--font-jp-regular);
  }
  .text {
    font-size: 14px;
    font-weight: 500;
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      color: var(--color-gray3);
    }
    p {
      font-weight: 350;
      margin: 0;
      color: var(--color-gray4);
    }
  }
`;

const Price = styled.p`
  font-size: 10px;
  font-weight: 700;
  span:last-child {
    font-size: 10px;
    font-weight: 400;
    color: var(--color-gray3);
  }
`;

const BottomBox = styled.p`
  font-size: 14px; 
  font-weight: 700;
  margin: 20px 0 200px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    color: var(--color-gray4);
  }
`;

const StyledTitle = styled.div`
  font-size: 14px; 
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    color: var(--color-gray4);
  }
  p {
    font-size: 18px;
    font-weight: 700;
    color: var(--color-sub);
    margin: 0;
  }
`;

const LineDiv = styled.div`
  border-bottom: 0.5px solid var(--color-gray3);
  width: 100%;
  height: 1px;
`;

const ButtonBox = styled.div`
  position: fixed;
  bottom: 0;
  transform: translateX(-20px);
  max-width: 500px;
  width: 100%;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  border-radius: 10px 10px 0 0;
  box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.25);

`;

const BuyButton = styled.button`
  background: var(--color-main);
  border-radius: 50px;
  border: none;
  height: 50px;
  width: 100%;
  font-family: var(--font-jp-bold);
  font-size: 20px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
  &:last-child {
    margin-left: 15px;
  }
  &:disabled {
    background: var(--color-gray3);
  }
  @media screen and (max-width: 768px) {
    font-size: 18px;
  }

`;

