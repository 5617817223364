import React from 'react';
import qr from "../assets/web_qr_button.png";
import phone from "../assets/web_qr.gif";
import styled from 'styled-components';
import apple from "../assets/apple.png";
import google from "../assets/play_store.png";

const QrImg = () => {

  return (
    <FixedBox>
      <FixedWrapper>
        <QRWrapper backgroundImage={qr}>
          <img className="phone" src={phone} alt="phone" />
          <StyledAppleButton onClick={() => window.open("https://apps.apple.com/kr/app/yourish-k-beauty-life-app/id6450346976")}>
            <img src={apple} alt="apple" />
            <span>App Store</span>
          </StyledAppleButton>
          <StyledGoogleButton onClick={() => window.open("https://play.google.com/store/apps/details?id=com.yourish_web_rn&hl=ko")}>
            <img src={google} alt="google" />
            <span>Google Play</span>
          </StyledGoogleButton>
        </QRWrapper>
      </FixedWrapper>
    </FixedBox>
  );
};

export default QrImg;

const FixedBox = styled.div`
  position: relative;
  width: 415px;
  .qr, .phone {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 30px;
    @media screen and (max-width: 935px) {
      display: none;
    }
  }
  .qr {
    width: 400px;
    height: 580px;
  }
  .phone {
    width: 170px;
    height: 326px;
    transform: translateY(-63%) translateX(67%);
  }
  @media screen and (max-width: 935px) {
    display: none;
  }
`;

const QRWrapper = styled.div`
  background: url(${props => props.backgroundImage}) center/cover;
  width: 400px;
  height: 580px;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 30px;
  @media screen and (max-width: 935px) {
    display: none;
  }
`;

const FixedWrapper = styled.div`
  position: relative;
`;

const StyledAppleButton = styled.button`
  color: #fff;
  border: none;
  background: #000;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 53px;
  top: 425px;
  width: 120px;
  span {
    padding: 8px 6px;
    font-weight: 500;
    flex-grow: 1;
  }
  img {
    width: 15px;
    margin-left: 5px;
  }
  :hover {
    background: #D9D9D9;
    cursor: pointer;
  }
`;

const StyledGoogleButton = styled(StyledAppleButton)`
  left: 223px;
`;