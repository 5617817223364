import React from 'react';
import { ContentWrapper, RootWrapper, StyledP, Title } from '../components/Common';
import HeaderWithBack from '../components/HeaderWithBack';
import FooterMaxButton from '../components/FooterMaxButton';
import styled, { css } from 'styled-components';
import AskModal from '../components/AskModal';
import { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from "js-cookie";
import { api_delete_header_token, api_get_header_token } from '../apis/apis';
const StyledUl = styled.ul`
  width: 100%;
`;

const StyledLi = styled.li`
  position: relative;
  width: 100%;
  height: 110px;
  background: #fff;
  color: var(--color-gray3);
  border: none;
  border-radius: 20px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.25);
  padding: 15px 13px;
  margin-bottom: 10px;
  .flex {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .address-name {
      color: var(--color-gray4);
      font-size: 16px;
      font-weight: 600;
    }
  }
`;

const ButtonBox = styled.div`
  position: absolute;
  top: 15px;
  right: 10px;
  display: flex;
  align-items: center;
  color: var(--color-gray4);
  span {
    font-size: 10px;
    font-weight: 600;
  }
`;

const TagButton = styled.button`
  width: 52px;
  height: 22px;
  background: var(--color-main);
  border: none;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  ${props => props.white && css`
    color: var(--color-main);
    border: 1px solid var(--color-main);
    background: #fff;
  `}
`;

const StyledButton = styled.button`
  font-size: 12px;
  font-weight: 600;
  color: var(--color-gray4);
  border: none;
  background: none;
  padding: 5px 0;
  margin-right: 10px;
`;

const TextBox = styled.div`
  font-size: 13px;
  font-weight: 400;
  color: var(--color-gray4);
  line-height: 1.6;
  margin: 30px auto 100px;
  text-align: center;
`;

const AddressList = () => {
  const navigate = useNavigate();
  const [addModal, setAddModal] = useState(false);
  const [delModal, setDelModal] = useState(false);
  const [rowMoreThan,setRowMoreThan] = useState("Y");
  const [deleteSeq,setDeleteSeq] = useState();

  const onAddClick = async (seq) => {
    await api_get_header_token(`/address/default-change?addressSeq=${seq}`);
    getInfo();
    // setAddModal(true);
  };
  const onAddCancel = () => {
    setAddModal(false);
  };
  const onAddConfirm = () => {
    setAddModal(false);
    // 주소 추가 실행 함수
  };

  const onDelClick = (seq) => {
    setDeleteSeq(seq);
    setDelModal(true);
    
  };
  const onDelCancel = () => {
    setDeleteSeq();
    setDelModal(false);
  };
  const onDelConfirm = async() => {
    const result = await api_delete_header_token(`/address/delete?deleteSeq=${deleteSeq}`);
    if(result.status==="ok"){
      getInfo();
      setDelModal(false);
    }else{
      return
    }
  };

  const handleAddAddress = () => {
    navigate(`/add-address`);
  }

  const checkRow = async () => {
    const result = await api_get_header_token(`/address-row-check`);
    if(result.status==="ok"){
      handleAddAddress();
    }else{
      setRowMoreThan("N");
    }
  }

  // const handleEditAddress = (seq) => {
  //   navigate(`/edit-address?addressSeq=${seq}`);
  // }

  const [info,setInfo] = useState([]);
  const getInfo = async () => {
    const result = await api_get_header_token(`/address/list`);
    setInfo(result.list);
  }
  useEffect(() => {
    getInfo();
  }, []);

  return (
    <RootWrapper maincolor>
      <HeaderWithBack text={"配送先一覧"} />
      <ContentWrapper>
        <StyledUl>
        {info && info.length > 0 ? (info.map((list, index) => (
          <StyledLi key={list.addressSeq}>
            <ButtonBox>
            {list.setDefault === 'Y' ? (
            <TagButton onClick={()=> onAddClick(list.addressSeq)}>基本</TagButton>
          ) : (
            <TagButton onClick={()=> onAddClick(list.addressSeq)} white="true">基本</TagButton>
          )}

              <StyledButton onClick={()=>onDelClick(list.addressSeq)}>削除</StyledButton>
              {/* <span>｜</span> */}
              {/* <StyledButton onClick={()=>handleEditAddress(list.addressSeq)}>修正</StyledButton> */}
            </ButtonBox>
            <div className='flex'>
              <div className='address-name'>{list.addressAlias}</div>
              <Title>{list.resName}｜{list.resPhoneNumber}</Title>
              <StyledP><span>{list.zipCode}</span></StyledP>
              <StyledP>{list.address} {list.detailAddress}</StyledP>
            </div>
          </StyledLi>
        ))) : (
         <TextBox>
          配送先を登録してください！
         </TextBox>
        )}
        </StyledUl>

        {rowMoreThan==="N" && 
        <TextBox>
          配送先は5つまでしか作れません<br />
          追加する場合は、既存のお届け先を削除してください！
        </TextBox>
        }

      </ContentWrapper>
      
      <FooterMaxButton onClick={checkRow} text={"配送先追加"} />
      
      <AskModal
        visible={addModal}
        main={"職場"}
        title={"をデフォルトの配送先に設定しますか？"}
        confirmText='はい'
        onConfirm={onAddConfirm}
        cancelText='いいえ'
        onCancel={onAddCancel}
      />
      <AskModal
        visible={delModal}
        main={"ホーム"}
        title={"削除しますか？"}
        confirmText='はい'
        onConfirm={onDelConfirm}
        cancelText='いいえ'
        onCancel={onDelCancel}
      />
    </RootWrapper>
  );
};

export default AddressList;