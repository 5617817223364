import React from 'react';
import styled, { css } from "styled-components";

const ButtonBox = styled.button`
  background: var(--color-main);
  color: #fff;
  border-radius: 50px;
  border: none;
  font-family: var(--font-jp-bold);
  font-size: 25px;
  width: 90%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: ${(props) => props.bottom || "2rem"};
  left: 50%;
  transform: translateX(-50%);
  &:disabled {
    background: var(--color-gray2);
  }
`;


const FooterMaxButton = ({ bottom, onClick, text, disabled, props }) => {
  return (
    <ButtonBox bottom={bottom} {...props} onClick={onClick} disabled={disabled}>{text}</ButtonBox>

  );
};

export default FooterMaxButton;
