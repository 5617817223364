import React, { useState, useEffect } from "react";
import { ContentWrapper, RootWrapper } from '../components/Common';
import HeaderWithBack from '../components/HeaderWithBack';
import QAToggleBox from '../components/QAToggleBox';
import { api_get } from '../apis/apis';
import NavBasic from "../components/NavBasic";
import { addLineBreaks } from "../utils/addLineBreaks";
const Delivery = () => {
  const [noticeInfo,setNoticeInfo] = useState();
  const Info = async () => {
    
    const response = await api_get(`/specific-law`)
    
    setNoticeInfo(response);
   
  }
  useEffect(() => {
    Info();
  }, []);

  return (
    <RootWrapper>
      <HeaderWithBack eng="true" text={"DELIVERY"} />
      <ContentWrapper marginBottom>
      {noticeInfo && (
        <QAToggleBox
        question={"特定商取り引きに関する法律に基づく表記"}
        answer={addLineBreaks(noticeInfo.specificLaw)}
      />
      )}
      </ContentWrapper>
      <NavBasic/>
    </RootWrapper>
  );
};

export default Delivery;