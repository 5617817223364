import React from 'react';
import HeaderWithBack from '../../components/HeaderWithBack';
import styled, { css } from 'styled-components';
import Button from '../../components/Button';
import { useState } from 'react';
import trash from '../../assets/common/gray-trash.png';
import close from '../../assets/common/close-maincolor.png';
import AskModal from '../../components/AskModal';
import { useEffect } from 'react';
import Cookies from "js-cookie";
import NavBasic from '../../components/NavBasic';
import { MarginBottomBox, RootWrapper, StyledRate, scrollStyle } from '../../components/Common';
import Loading from '../Loading';
import { api_delete_header_token, api_get_header_token } from '../../apis/apis';
import SubStringText from '../../components/SubStringText';
const StyledDiv = styled.div`
  max-width: 500px;
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  position: relative;
`;

const NavBox = styled.div`
  display: flex;
  text-align: center;
  flex-direction: row;
  justify-content: flex-start;
  .active {
    border-bottom: 2px solid var(--color-main);
    font-weight: 700;
  }
`;

const TabButton = styled(Button)`
  width: 100%;
  font-size: 13px;
  font-weight: 400;
  line-height: 3;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
`;

const Fullscreen = styled.div`
  position: fixed;
  top: 0;
  width: 500px;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  @media screen and (max-width: 500px) {
    width: 100%;
  }
`;

const DetailBox = styled.div`
  position: relative;
  max-height: 90vh;
  width: 90%;
  padding: 40px 20px;
  background: #fff;
  border: 1px solid var(--color-main);
  border-radius: 20px;
  ${scrollStyle}
  .close{
    position: absolute;
    width: 20px;
    height: 20px;
    top: 12px;
    right: 15px;
  }
  div {
    margin-bottom: 15px;
    .detail-title {
      margin-top: 0;
      font-size: 14px;
      font-weight: 700;
      line-height: 1.2;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  .photos {
    display: flex;
    justify-content: center;
    img {
      border-radius: 10px;
      width: 48%;
      &:last-child {
        margin-left: 15px;
      }
    }
  }
`;

const TrachBox = styled.div`
  width: 28px;
  height: 28px;
  position: absolute;
  right: 10px;
  top: 10px;  
  cursor: pointer;
  img {
    width: 100%;
  }
`;

const ItemBox = styled.div`
  width: 100%;
  display: flex;
`;

const TitleDiv = styled.div`
  width: 100%;
  border: 1px solid var(--color-main);
  border-radius: 20px;
  padding: 15px 20px;
  font-size: 14px;
  font-weight: 700;
  color: var(--color-gray4);
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    margin-bottom: 0;
  }
  span {
    font-size: 12px;
    font-weight: 350;
  }
`;

const TextDiv = styled.div`
  width: 100%;
  border: 1px solid var(--color-main);
  border-radius: 20px;
  padding: 15px 20px;
  font-size: 14px;
  font-weight: 400;
  color: var(--color-gray3);
  p {
    border-top: 1px solid var(--color-gray3);
    padding: 15px 0;
    color: var(--color-sub);
  }
`;

const ImgBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0;
  div {
    border: 1px solid var(--color-main);
    border-radius: 20px;
    margin: 0;
    margin-right: 10px;
    overflow: hidden;
    &:last-child {
      margin: 0;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
`;

const MyPost = () => {
  const [activeTab, setActiveTab] = useState("review");
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <>
    <StyledDiv>
      <HeaderWithBack text={"投稿履歴"} />
      <NavBox>
        <TabButton
          className={activeTab === "review" ? "active" : ""}
          onClick={() => handleTabClick("review")}
        >
          レビュー
        </TabButton>
        <TabButton
          className={activeTab === "contact" ? "active" : ""}
          onClick={() => handleTabClick("contact")}
        >
          お問い合わせ
        </TabButton>
        <TabButton
          className={activeTab === "exchange" ? "active" : ""}
          onClick={() => handleTabClick("exchange")}
        >
          交換・返品
        </TabButton>
      </NavBox>
      {activeTab === "review" && <Review />}
      {activeTab === "contact" && <Contact />}
      {activeTab === "exchange" && <Exchange />}
    </StyledDiv>
    <NavBasic/>
    </>
  );
};
// -------------------------------------------------------------------
const Wrapper = styled.div`
  position: relative;
  padding: 10px 20px;
  `;

const StyleUl = styled.ul`
  width: 100%;
  margin-top: 5px;
`;

const StyledLi = styled.li`
  position: relative;
  border-radius: 15px;
  border: 1px solid var(--color-main);
  padding: 15px 10px;
  width: 100%;
  margin-bottom: 10px;
`;

const PhotoReview = styled.div`
  min-width: 100px;
  width: 100px;
  height: 100px;
  border-radius: 10px;
  overflow: hidden;
  margin: 5px 15px 0 0;
  background: var(--color-gray2);
  img {
    width: 100%;
    height: 100%;
  }
`;

const FlexDiv = styled.div`
  display: flex;
`;

const ContentBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  .title {
    width: 100%;
    margin: 0 10px 5px 0;
    padding-right: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 700;
    color: var(--color-gray4);
    line-height: 1.2;
  }
  .text {
    font-size: 12px;
    font-weight: 400;
    color: var(--color-gray3);
    margin-top: 5px;
  }
`;

// const OptionBox = styled.div`
//   width: 100%;
//   margin-bottom: 5px;
// `;

const OptionTitle = styled.div`
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
`;

const AnswerTag = styled.div`
  width: 60px;
  height: 20px;
  font-size: 12px;
  font-weight: 400;
  background: #fff;
  border: 1px solid var(--color-sub);
  border-radius: 10px;
  color: var(--color-sub);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  ${props =>
    props.done &&
    css`
      border-color: var(--color-gray3);
      color: var(--color-gray3);
    `
  }
`;

const StarDateBox = styled.div`
  display: flex;
  align-items: center;
  div {
    margin: 0;
  }
  .date {
    margin-left: 10px;
    font-size: 12px;
    font-weight: 400;
    color: var(--color-gray3);
    vertical-align: top;
  }
`;
// ------------------------------------------------------------------------------
const Review = () => {
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [reviewList,setReviewList] = useState();
  const [modal, setModal] = useState(false);
  const [showDetail, setshowDetail] = useState(false);
  const [selectedReviewIndex, setSelectedReviewIndex] = useState(null);
  const [deleteSeq,setDeleteSeq] = useState("");
  const jwtToken = Cookies.get("jwtToken");

  const ReviewInfo = async () => {
    const result = await api_get_header_token(`/user/review/list`);
    setReviewList(result.list);
    setIsDataLoading(false);
  }

  const onClick = (postSeq) => {
    setDeleteSeq(postSeq);
    setModal(true);
  };
  const onCancel = () => {
    setDeleteSeq("");
    setModal(false);
  };
  const onConfirm = async () => {
    const result = await api_delete_header_token(`/review/delete/${deleteSeq}`);
    if(result.status==="ok"){
      ReviewInfo();
      setModal(false);
    }else{
      setModal(false);
    }
    // 확인 시 실행되는 함수
  };

  const handleshowDetail = (index) => {
    setshowDetail(true);
    setSelectedReviewIndex(index);
  };

  const handleCloseDetail = () => {
    setshowDetail(false);
  };

  useEffect(() => {
    ReviewInfo();
  }, []);

  return (
    <>
    { isDataLoading ? (
      <Loading />
    ) : (
      <>
        <Wrapper>
          <StyleUl >
          {reviewList && reviewList.map((list, index) => (
            <StyledLi key={index}>
              <TrachBox>
                <img onClick={()=>onClick(list.postSeq)} src={trash} alt='trash' />
              </TrachBox>
              <ItemBox onClick={()=>handleshowDetail(index)}>
              
                  {list.reviewState === "simple" && (
                    ""
                  )}
                <ContentBox>
                  {/* <div className='title'><SubStringText text={list.productTitle} truncatelength={35} /></div> */}
                  <div className='title'>{list.productTitle}</div>
                  
                  <FlexDiv>
                    {list.reviewState === "photo" && list.reviewImage && list.reviewImage[0] && list.reviewImage[0].reviewImageUrl && (
                      <PhotoReview>
                        <img src={list.reviewImage[0].reviewImageUrl} alt='sample' />
                      </PhotoReview>
                    )}
                    <div>
                      <StarDateBox>
                        <StyledRate
                          allowHalf
                          disabled
                          small
                          value={list.score}
                        />
                        <span className='date'>{list.signDate}</span>
                      </StarDateBox>
                      <div className='text'><SubStringText text={list.contents} truncatelength={85} /></div>
                    </div>
                  </FlexDiv>
                </ContentBox>
              </ItemBox>
            </StyledLi>
          ))}
          </StyleUl>
          <MarginBottomBox />
        </Wrapper>
        <AskModal
          visible={modal}
          title="選択したアイテムを削除しますか?"
          confirmText='はい' //확인
          cancelText='いいえ' //취소
          onConfirm={onConfirm}
          onCancel={onCancel}
        />
        {showDetail && selectedReviewIndex !== null && (
          <Fullscreen>
            <DetailBox>
              <img onClick={handleCloseDetail} className='close' src={close} alt='close' />
              {/* {reviewList[selectedReviewIndex].reviewImage && reviewList[selectedReviewIndex].reviewImage[0] && (
                <img src={reviewList[selectedReviewIndex].reviewImage[0].reviewImageUrl} alt='sample' />
              )} */}
              <div className='photos'>
                {reviewList[selectedReviewIndex].reviewImage.map((img, idx) => (
                  <img key={idx} src={img.reviewImageUrl} alt='sample' />
                ))}
              </div>
              <div>
                <div className='detail-title'>{reviewList[selectedReviewIndex].productTitle}</div>
                <StarDateBox>
                  <StyledRate
                    allowHalf
                    disabled
                    small
                    value={reviewList[selectedReviewIndex].score}
                  />
                  <span className='date'>{reviewList[selectedReviewIndex].signDate}</span>
                </StarDateBox>
              </div>
              <div>
                {reviewList[selectedReviewIndex].contents}
              </div>
            </DetailBox>
          </Fullscreen>
        )}
      </>
      )}
    </>
  );
}
// -----------------------------------------------------------------------------
const Contact = () => {
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [qnaList,setQnaList] = useState();
  const [showDetail, setshowDetail] = useState(false);
  const [modal, setModal] = useState(false);
  const [selectedQnaIndex, setSelectedQnaIndex] = useState(null);
  const [deleteSeq,setDeleteSeq] = useState("");
  const jwtToken = Cookies.get("jwtToken");

  const QnaInfo = async () => {
    const result = await api_get_header_token(`/user/post-qna/list`);
    setQnaList(result.list);
    setIsDataLoading(false);
  }

  const onClick = (postSeq) => {
    setDeleteSeq(postSeq);
    setModal(true);
  };

  const onCancel = () => {
    setDeleteSeq("");
    setModal(false);
  };

  const onConfirm = async () => {
    const result = await api_delete_header_token(`/post/delete/${deleteSeq}`);
    if(result.status==="ok"){
      QnaInfo();
      setModal(false);
    }else{
      setModal(false);
    }
  };
  
  const handleshowDetail = (index) => {
    setshowDetail(true);
    setSelectedQnaIndex(index);
  };

  const handleCloseDetail = () => {
    setshowDetail(false);
  };

  useEffect(() => {
    QnaInfo();
  }, []);

  return (
    <>
    { isDataLoading ? (
      <Loading />
    ) : (
      <RootWrapper>
        <Wrapper>
          <StyleUl>
            {qnaList && qnaList.map((list, index) => (
            <StyledLi key={index}>
              {/* <TrachBox>
                <img onClick={()=>onClick(list.postSeq)} src={trash} alt='trash' />
              </TrachBox> */}
              <ItemBox onClick={()=>handleshowDetail(index)}>
                {list.type === "productQuestion" && (
                  <PhotoReview>
                    <img src={list.productImage} alt='sample' />
                  </PhotoReview>
                )}
                {list.type != "productQuestion" && (
                  ""
                )}
                <ContentBox>
                  <div className='title'>
                    {list.type === 'productQuestion' && <div>製品質問</div>}
                    {list.type === 'orderPayment' && <div>注文決済の質問</div>}
                    {list.type === 'shipping' && <div>輸送の質問</div>}
                    {list.type === 'membership' && <div>メンバーシップの質問</div>}
                    {list.type === 'exchangeReturnRefund' && <div>交換返品払い戻しの質問</div>}
                    {list.type === 'etc' && <div>これ以外の質問</div>}
                
                    {list.answer === 'Y' ? (
                      <AnswerTag>回答完了</AnswerTag>
                    ) : (
                      <AnswerTag done>未回答</AnswerTag>
                    )}
                  </div>
                  <div className='text'>{list.questionContents}</div>
                </ContentBox>
              </ItemBox>
            </StyledLi>
          ))}
          </StyleUl>
          <MarginBottomBox />
        </Wrapper>
        <AskModal
          visible={modal}
          title="選択した商品を削除しますか？"
          confirmText='はい' //확인
          cancelText='いいえ' //취소
          onConfirm={onConfirm}
          onCancel={onCancel}
        />
        {showDetail && selectedQnaIndex !== null && (
          <Fullscreen>
            <DetailBox>
              <img onClick={handleCloseDetail} className='close' src={close} alt='close' />
              <TitleDiv>
                {qnaList[selectedQnaIndex].type === 'productQuestion' && <div>製品質問</div>}
                {qnaList[selectedQnaIndex].type === 'orderPayment' && <div>注文決済の質問</div>}
                {qnaList[selectedQnaIndex].type === 'shipping' && <div>輸送の質問</div>}
                {qnaList[selectedQnaIndex].type === 'membership' && <div>メンバーシップの質問</div>}
                {qnaList[selectedQnaIndex].type === 'exchangeReturnRefund' && <div>交換返品払い戻しの質問</div>}
                {qnaList[selectedQnaIndex].type === 'etc' && <div>これ以外の質問</div>}
                <span>{qnaList[selectedQnaIndex].questionSignDate}</span>
              </TitleDiv>
              <TextDiv>
                <div>
                  Q.{qnaList[selectedQnaIndex].questionContents}
                </div>
                  {qnaList[selectedQnaIndex].answer === 'Y' ? (
                    <p>
                      A.{qnaList[selectedQnaIndex].answerContents}
                    </p>
                  ) : (
                    <></>
                  )}
              </TextDiv>
            </DetailBox>
          </Fullscreen>
        )}
      </RootWrapper>
    )}
    </>
  )
}
// -------------------------------------------------------------------------
const Exchange = () => {
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [exList,setExList] = useState();
  const [modal, setModal] = useState(false);
  const [showDetail, setshowDetail] = useState(false);
  const [selectedQnaIndex, setSelectedQnaIndex] = useState(null);
  const [deleteSeq,setDeleteSeq] = useState("");

  const ExInfo = async () => {
    const result = await api_get_header_token(`/post/refund-exchange`);
    setExList(result.list);
    setIsDataLoading(false);
  }
  
  const onClick = (combinedExchangeSeq) => {
    setDeleteSeq(combinedExchangeSeq);
    setModal(true);
  };

  const onCancel = () => {
    setDeleteSeq("");
    setModal(false);
  };

  const onConfirm = async () => {
   
  };

  const handleshowDetail = (index) => {
    setshowDetail(true);
    setSelectedQnaIndex(index);
  };

  const handleCloseDetail = () => {
    setshowDetail(false);
  };

  useEffect(() => {
    ExInfo();
  }, []);

  return (
    <>
    { isDataLoading ? (
      <Loading />
    ) : (
      <>
        <Wrapper>
          <StyleUl>
            {exList && exList.map((list, index) => (
            <StyledLi>
              <ItemBox onClick={()=>handleshowDetail(index)}>
                <PhotoReview>
                  {list.type === "refund" && (
                    <img src={list.url[0].refundImageUrl} alt='sample' />
                  )}
                  {list.type === "exchange" && (
                    <img src={list.productImg} alt='sample' />
                  )}
                </PhotoReview>
                <ContentBox>
                  <div className='title'>
                    {list.type === 'refund' && <div>返品</div>}
                    {list.type === 'exchange' && <div>交換</div>}
                    {list.adminState === 'Y' ? (
                      <AnswerTag>回答完了</AnswerTag>
                    ) : (
                      <AnswerTag done>未回答</AnswerTag>
                    )}
                  </div>
                  <div className='text'>{list.contents}</div>
                </ContentBox>
              </ItemBox>
            </StyledLi>
            ))}
          </StyleUl>
          <MarginBottomBox />
        </Wrapper>
        <AskModal
          visible={modal}
          title="選択した商品を削除しますか？"
          confirmText='はい' //확인
          cancelText='いいえ' //취소
          onConfirm={onConfirm}
          onCancel={onCancel}
        />
        {showDetail && selectedQnaIndex !== null && (
          <Fullscreen>
          <DetailBox>
            <img onClick={handleCloseDetail} className='close' src={close} alt='close' />
            <TitleDiv>
            {exList[selectedQnaIndex].type === 'exchange' && <>{exList[selectedQnaIndex].reason}</>}
            {exList[selectedQnaIndex].type === 'refund' && <>返品</>}
            <span>{exList[selectedQnaIndex].signDate}</span></TitleDiv>
            <ImgBox>
            {exList[selectedQnaIndex].type === 'exchange' && <div><img src={exList[selectedQnaIndex].productImg} alt='sample' /></div>}
            {exList[selectedQnaIndex].type === 'refund' && 
          
              exList[selectedQnaIndex].url.map((item, index) => (
              <div><img src={item.refundImageUrl} key={index}/></div>
              ))}
              
            </ImgBox>
            <TextDiv>
                <div>
                  Q.{exList[selectedQnaIndex].contents}
                </div>
                  {exList[selectedQnaIndex].adminState === 'Y' ? (
                    <p>
                      A.{exList[selectedQnaIndex].adminContents}
                    </p>
                  ) : (
                    <></>
                  )}
              </TextDiv>
          </DetailBox>
        </Fullscreen>
        )}
      </>
    )}
    </>
  )
}

export default MyPost;
