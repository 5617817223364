import React from "react";
import AskModal from "./AskModal";

const AskLogoutModal = ({ visible, onConfirm, onCancel }) => {
  return (
    <AskModal
      visible={visible}
      title="ログアウトしますか？"
      onConfirm={onConfirm}
      onCancel={onCancel}
    />
  );
};

export default AskLogoutModal;
