import React from 'react';
import { ContentWrapper, DivFlexBetween, RootWrapper, StyledP, Title } from '../../components/Common';
import HeaderWithBack from '../../components/HeaderWithBack';
import preparation from '../../assets/delivery/preparation.png';
import arrival from '../../assets/delivery/arrival.png';
import shipping from '../../assets/delivery/shipping.png';
import completed from '../../assets/delivery/delivery-completed.png';
import styled from 'styled-components';
import NavBasic from '../../components/NavBasic';
import { useEffect } from 'react';
import { api_get_list_header_token } from '../../apis/apis';
import { useState } from 'react';

const DeliveryStatus = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const orderId = urlParams.get('orderId');
  const [statusData, setStatusData] = useState(null);
  const [lastIndex, setLastIndex] = useState(0);
  const [statusMessage, setStatusMessage] = useState("");

  const renderStatus = async () => {
    const params = {
      "orderId": orderId,
    }
    await api_get_list_header_token('/order-lookup/delivery-tracking', params)
    .then((res) => {
      setStatusData(res);
    })
  };

  const renderStatusImg = () => {
    const status = lastIndex;

    if (status > 11) {
      return <img src={completed} alt='completed' />;
    } else if (status > 9) {
      return <img src={shipping} alt='shipping' />;
    } else if (status > 1) {
      return <img src={arrival} alt='arrival' />;
    } else if (status > 0) {
      return <img src={preparation} alt='preparation' />;
    }
  }

  useEffect(() => {
    renderStatus();
  }, []);

  useEffect(() => {
    const last = statusData?.list?.length - 1;
    const lastValue = statusData?.list[last]?.Status;
    setLastIndex(last);
    setStatusMessage(lastValue);
  }, [statusData]);

  return (
    <RootWrapper>
      <HeaderWithBack text={"配送状況"} />
      <ContentWrapper marginBottom>
        <BoxDiv>
          <Title>現在、お客様の商品は<span className='sub'>
            {statusData && statusMessage}  
          </span>しました</Title>
          <div className='img-box'>{renderStatusImg()}</div>
        </BoxDiv>
        <DivFlexBetween isMargin>
          <StyledP>宅配業者</StyledP>
          <StyledP>{statusData?.courier}</StyledP>
        </DivFlexBetween>
        <StyledP>請求書番号</StyledP>
        { statusData &&
          <StyledTable>
            <thead>
              <tr>
                <th>配送進行状況</th>
                <th>日付</th>
              </tr>
            </thead>
            <tbody>
              {statusData.list?.map((item, index)=>
                <tr key={index}>
                  <td>{item.Status}</td>
                  <td>{item.Datetime}</td>
                </tr>
              )}
            </tbody>
          </StyledTable>
        }
      </ContentWrapper>
      <NavBasic/>
    </RootWrapper>
  );
};

export default DeliveryStatus;

const BoxDiv = styled.div`
  margin: 60px auto;
  p {
    text-align: center;
  }
  .img-box{
    margin-top: 50px;
  }
`;

const StyledTable = styled.table`
  background: var(--color-gray1);
  width: 100%;
  text-align: center;
  line-height: 35px;
  margin: 10px 0;
  thead {
    font-size: 12px;
    font-weight: 500;
    color: #000;
    background: var(--color-main-pale);
  }
  tbody {
    tr {
      font-size: 12px;
      &:nth-child(2n) {
        background: var(--color-gray2);
      }
    }
  }
`;