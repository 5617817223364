import React, { useState } from "react";
import styled from "styled-components";
import HeaderWithBack from "../../components/HeaderWithBack";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import BtnBoxSmall from "../../components/BtnBoxSmall";
import axios from "axios";
import { RootWrapper } from "../../components/Common";
import AskModalOne from '../../components/AskModalOne';
import NavBasic from "../../components/NavBasic";
function FindPassword() {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true); // state variable to track button disablement
  const formik = useFormik({
    initialValues: {
      username: "",
      email: "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("必須です。"),
      email: Yup.string()
        .email("存在しないメールです。")
        .required("存在しないメールです。"),
    }),
    onSubmit: async (values) => {
      const { username, email } = values;

      const baseUrl = process.env.REACT_APP_BASEURL;
      const { data } = await axios.get(`${baseUrl}/find-password`, {
        params: { username, email },
      });
      const message = data.message;
      const status = data.status;

      if (status === "fail") {
        if (message === "wrong info") {
          setModal2(true);
        } else if (message === "mail sending error") {
          setModal2(true);
        }
      } else {
        setModal(true);
      }
    },
    // 값 변경시마다 validation 체크
    validateOnChange: true,
    // 인풋창 블러시에 validation 체크
    validateOnBlur: true,
  });
  const onClick = (e) => {
    formik.handleSubmit();
    setIsSubmitDisabled(false);
  };

  const onConfirm = () => {
    navigate('/sign-in');
    setModal(false);
  };
  const onConfirm2 = () => {
    setModal2(false);
  };

  return (
    <>
    <RootWrapper>

      <StyledDiv>
        <HeaderWithBack text="パスワードを探す"></HeaderWithBack>
        <form onSubmit={formik.handleSubmit}>
          <div className={"left"}>
            <TextBox>
              <label htmlFor="username">氏名</label>
              {formik.touched.username && formik.errors.username ? (
                <StyledSpan>{formik.errors.username}</StyledSpan>
              ) : null}
            </TextBox>
            <input
              id="username"
              type="text"
              {...formik.getFieldProps("username")}
            />
          </div>
          <div className={"left"}>
            <TextBox>
              <label htmlFor="email">メールアドレス</label>
              {formik.touched.email && formik.errors.email ? (
                <StyledSpan>{formik.errors.email}</StyledSpan>
              ) : null}
            </TextBox>
            <div className={"row"}>
              <input id="email" type="email" {...formik.getFieldProps("email")} />
              <BtnBoxSmall
                onClick={(e) => onClick(e)}
                text={"転送"}
              ></BtnBoxSmall>
            </div>
          </div>
        </form>
      </StyledDiv>
      <NavBasic/>
      <AskModalOne
        visible={modal}
        title="入力したメールにパスワードが送信されました。"
        confirmText='OK'
        onConfirm={onConfirm}
      />
      <AskModalOne
        visible={modal2}
        title="正しい情報を入力してください。"
        confirmText='OK'
        onConfirm={onConfirm2}
      />
    </RootWrapper>
    </>
  );
}
export default FindPassword;

const StyledDiv = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  button:last-child {
    margin-top: 2rem;
  }
  form {
    width: 90%;
    margin: 1rem auto;
    input {
      width: 100%;
      height: 40px;
      background: #d9d9d9;
      border-radius: 10px;
      border: none;
      padding: 0.8rem;
      margin-top: 0.3rem;
    }
    div.row {
      align-items: center;
      width: 100%;
      button {
        margin-top: 0.3rem;
        margin-left: 5px;
      }
    }
  }
  .btn {
    position: absolute;
    width: 90%;
    bottom: 5%;
  }
`;

const StyledSpan = styled.span`
  font-size: 12px;
  color: var(--color-sub);
  margin-left: 5px;
`;

const TextBox = styled.div`
  width: 100%;
  margin: 15px 0 0;
  align-items: center;
`;