import React from 'react';
import { inputStyle } from './Common';
import styled from 'styled-components';

const PasswordModal = ({
	visible,
  title,
  main,
  onConfirm,
	onCancel,
	confirmText='確認',
	cancelText='キャンセル',
	onChange,
}) => {
	if (!visible) return null;
	return (
		<Fullscreen>
			<AskModalBlock>
        <h2><span>{main}</span>{title}</h2>
				<StyledInput type='password' onChange={onChange} />
        <div className="buttons">
          <StyledButton onClick={onConfirm}>{confirmText}</StyledButton>
          <StyledButton onClick={onCancel}>{cancelText}</StyledButton>
        </div>
      </AskModalBlock>
			
		</Fullscreen>
	);
};

export default PasswordModal;

const Fullscreen = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 9999;
  ${props => props.itemdetail && css`
    top: -100px;
  `}
`;
const AskModalBlock = styled.div`
  position: fixed;
  bottom: 0;
  width: 500px;
  height: 250px;
  background: white;
  padding: 40px 20px;
  border-radius: 20px 20px 0 0;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.125);
  h2 {
    font-size: 20px;
    font-weight: 700;
    margin: 0 auto 30px;
    text-align: center;
    line-height: 1.2;
    span {
      color: var(--color-main);
    }
  }
  .buttons {
    color: #fff;
    display: flex;
    justify-content: space-between;
  }
  @media screen and (max-width: 500px) {
    width: 100%;    
  }
`;

const StyledButton = styled.button`
  background: var(--color-main);
  border-radius: 50px;
  width: 100%;
  height: 50px;
  font-size: 25px;
  color: #fff;
  font-weight: 700;
  border: none;
  & + & {
    margin-left: 10px;
    background: var(--color-gray4);
  }
`;

const StyledInput = styled.input`
	${inputStyle}
	margin-bottom: 30px;
`;