import React, { useState, useEffect } from "react";
import { ContentWrapper, ImgBox, RootWrapper, StyledPPale } from '../components/Common';
import HeaderWithBack from '../components/HeaderWithBack';
import FooterMaxButton from '../components/FooterMaxButton';
import styled, { css } from 'styled-components';
import { useNavigate,useParams,useLocation  } from 'react-router-dom';
import Cookies from "js-cookie";
import pinkcirclecheck from "../assets/common/pinkcirclecheck.png";
import graycirclecheck from "../assets/common/graycirclecheck.png";
import AskModal from '../components/AskModal';
import { api_get_header_token } from '../apis/apis';
const StyledUl = styled.ul`
  width: 100%;
  margin-top: 5px;
  /* background: blue; */
`;

const StyledLi = styled.li`
  display: flex;
  align-items: center;
  padding: 10px 15px;
  border: 1px solid #000;
  border-radius: 20px;
  margin-top: 5px;
`;

const TextBox = styled.div`
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;
  .text {
    p {
      font-size: 12px;
      font-weight: 400;
      margin: 10px 0;
      &:first-child {
        font-weight: 700;
      }
      &:last-child {
        color: var(--color-gray3);
        font-size: 10px;
      }
    }
  }
  @media screen and (max-width: 768px) {
    width: 70%;
  }
`;

const SelectBox = styled.select`
  width: 40px;
  height: 20px;
  border: 0.45px solid #000;
  border-radius: 20px;
  font-size: 10px;
  font-weight: 350;
  text-align: center;
  
`;

const StyledOption = styled.option`
  padding: 0;
  .sub-color {
    color: var(--color-sub);
  }
`;

const Exchange = () => {
  const [modal, setModal] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const orderId = searchParams.get('orderId');

  const jwtToken = Cookies.get("jwtToken");
  
  const [info,setInfo] = useState([]);
  const navigate = useNavigate();
  const onClick = () => {
    setModal(true);
  } 

  const getInfo = async () => {
    const url = `/order/exchange/select?orderId=${orderId}`;
    const response = await api_get_header_token(url)
     
    setInfo(response.list);
  
  }
  useEffect(() => {
    getInfo();
  }, []);

  const handleTrashClick = (orderSeq) => {
    setDeleteCheck((prev) => ({
      ...prev,
      [orderSeq]: prev[orderSeq] === 1 ? 0 : 1,
    }));
  };

  const onCancel = () => {
    setModal(false);
  };
  
  const onConfirm = () => {
    deleteStart();
    setModal(false);
  };

  const [deleteCheck, setDeleteCheck] = useState(
    info.reduce((acc, cur) => ({ ...acc, [cur.orderSeq]: 0 }), {})
  );

  const deleteStart = () => {
    const seqToDelete = Object.keys(deleteCheck).filter(
      (seq) => deleteCheck[seq] === 1
    );
    const seqToDeleteInt = seqToDelete.map((seq) => parseInt(seq));
    const params = seqToDeleteInt.join("&orderSeq=");
   
    navigate(`/exchange-form?orderSeq=${params}`);
  };
  const [isClickEnabled, setIsClickEnabled] = useState(false);
  useEffect(() => {
    const hasPinkImages = Object.values(deleteCheck).some((value) => value === 1);
    setIsClickEnabled(hasPinkImages || info.length === 0);
  }, [deleteCheck, info]);
  
  return (
    <>
    <RootWrapper>
      <HeaderWithBack text={"交換"} />
      <ContentWrapper>

        <StyledUl>
          {info && info.map((list, index) => (
          <StyledLi key={index}>
            <ImgBox><img src={list.productImage} alt='product' /></ImgBox>
            <TextBox>
              <div className='text'>
                <p>{list.productTitle}</p>
                <p>{list.productOptions} / {list.quantity}</p>
              </div>
              <img
                src={
                    deleteCheck[list.orderSeq] === 1
                      ? pinkcirclecheck
                      : graycirclecheck
                  }
                alt="heartbasic"
                onClick={() => handleTrashClick(list.orderSeq)}
              />
            </TextBox>
          </StyledLi>
          ))}
        </StyledUl>
        
        {Object.values(deleteCheck).length > 0 ? (
            <FooterMaxButton
              onClick={onClick}
              text={"選択完了"}
              disabled={!isClickEnabled}
            />
          ) : (
            <FooterMaxButton disabled text={"選択完了"} />
          )}

      </ContentWrapper>
    <AskModal
      visible={modal}
      title="選択した商品の交換リクエストを行いますか？"
      confirmText='はい'
      cancelText='いいえ'
      onConfirm={onConfirm}
      onCancel={onCancel}
    />
    </RootWrapper>
    </>
  );
};

export default Exchange;