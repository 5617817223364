import styled from "styled-components";

const BannerBox = styled.div`
  margin-bottom: 50px;
  img {
    width: 100%;
    margin-bottom: 50px;
  }
`;

export default BannerBox;
