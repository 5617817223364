import React from 'react';
import styled from 'styled-components';
import { useState } from 'react';
import { useEffect } from 'react';
import Button from './Button';
import { useLocation, useNavigate } from 'react-router-dom';
import { api_get_header_token, api_post_header_token } from '../apis/apis';
import { ContentWrapper, MarginBottomBox, StyledRate } from './Common';
import FooterMaxButton from './FooterMaxButton';

const WriteSimpleReviewForm = () => {
  const {state} = useLocation();
  const [data, setData] = useState([null]);
  const [rating, setRating] = useState(0);
  const navigate = useNavigate();

  const productInfo = async () => {
    const responseData = await api_get_header_token(`/review/product-info/${state}`);
    setData(responseData);
  };

  useEffect(() => {
    productInfo();
  },[]);

  function changeRating(selectedValue) {
    setRating(selectedValue);
  }
  
  const writeButton = () => {
    const review = document.querySelector("textarea").value;
    let score = 0;
    score = rating;
    
    if(review.length < 5 || score === 0){
      alert("スコアとレビューを正しく入力してください。")
      return;
    }

    const fetchReviewList = async () => {
      const params = {
      
          score: score,
          contents : review,
          orderSeq : state,
          reviewState : "simple"
       
      }
      const data = await api_post_header_token(`/review/write`, params);
      if(data.status==="ok"){
        navigate(`/mypage/myreviews`);
      }
      else if(response.message==="before delivery"){
          
      }
      else if(response.message==="no permission"){

      }
      
    };
    fetchReviewList();
    
  };


  return (
    <>
      <ReviewWrapper>
        {data.list && (
          <ItemBox>
          <Img>
            <img src={data.list.productImage}/>
          </Img>
          <TextBox>
            <div className='itemnum_date'>
              <p>{data.list.orderId}</p>
              <p>{data.list.orderDate}</p>
            </div>
            <div className="wrap">
              <div className="detail-box">
                <p>{data.list.productTitle}</p>
                <p>
                  {data.list.options} / {data.list.quantity}
                </p>
              </div>
            </div>
          </TextBox>
        </ItemBox>
        )}
        
        <ReviewForm>
          <ScopeBox>
            <p>商品の満足度はいかがですか？</p>
            <Stars>
              <StyledRate
                edit="true"
                allowHalf
                onChange={(e)=>changeRating(e)}
              />
            </Stars>
          </ScopeBox>
          <WriteBox>
            <div>
              <p>利用者の声</p><span>3行以上記入してください</span>
            </div>
            <textarea
              required
              placeholder="レビューを書いてください。"
              maxlenth="150"
              minLength="5"
            />
          </WriteBox>
        </ReviewForm>
      </ReviewWrapper>
      <MarginBottomBox />
      <ContentWrapper>
        <FooterMaxButton text={"レビューを書く"} onClick={writeButton} />
      </ContentWrapper>
    </>
  );
};

export default WriteSimpleReviewForm;


const ReviewWrapper = styled.div`
  display: block;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 20px;
  padding: 10px 15px;
  margin: 10px auto;
`;

const ItemBox = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Img = styled.div`
  display: inline-block;
  background: #d9d9d9;
  border-radius: 20px;
  width: 80px;
  height: 80px;
  overflow: hidden;
  img {
    display: block;
    width: 100%;
    border-radius: 20px;
  }
`;

const TextBox = styled.div`
  display: inline-block;
  width: 90%;
  margin: 3px 0 3px 10px;
  @media screen and (max-width: 768px) {
    width: 80%;
  }
  .itemnum_date {
    display: flex;
    justify-content: space-between;
    color: var(--color-gray3);
    font-size: 10px;
    margin-bottom: 7px;
  }
  .wrap {
    display: flex;
    justify-content: space-between;
    height: 55px;
  }
  .detail-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* min-width: 160px; */
    width: 100%;
    p {
      font-size: 12px;
      line-height: 1.5;
      font-weight: 400;
    }
    p:first-child {
      font-weight: 700;
    }
    p:last-child {
      color: var(--color-gray3);;
      font-size: 10px;
    }
  }
`;

const ReviewForm = styled.form`
  width: 100%;
  .doutone {
    width: 200px;
    height: 200px;
  }
`;

const ScopeBox = styled.div`
  margin: 10px auto;
  p {
    font-size: 16px;
  }
  img {
    margin: 10px 3px;
  }
`;

const Stars = styled.div`
  padding-top: 10px;
`;

const WriteBox = styled.div`
  margin: 10px auto;
  div {
    display: flex;
    align-items: center;
    margin: 15px auto;
    p {
      font-size: 20px;
      font-weight: 700;
    }
    span {
      font-size: 10px;
      font-weight: 400;
      color: var(--color-sub);
      margin-left: 5px;
    }
  }
  textarea {
    width: 100%;
    height: 25rem;
    background: var(--color-gray2);
    border: none;
    border-radius: 20px;
    font-size: 12px;
    line-height: 1.6;
    padding: 15px;
    resize: none;
  }
`;

const StyledButton = styled(Button)`
  background: var(--color-main);
  color: #fff;
  border-radius: 50px;
  border: none;
  font-size: 25px;
  font-weight: 600;
  width: 90%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
`;