import React from "react";
import styled from "styled-components";
import customercentericon from "../assets/main/customercentericon.svg";
import aboutus from "../assets/main/aboutus.png";
import notice from "../assets/main/notice.png";
import internationalshopping from "../assets/main/internationalshopping.png";
import B2B from "../assets/main/b2b.png";
import B2BOrder from "../assets/main/B2BOrder.png";
import FAQ from "../assets/main/FAQ.png";

import { useNavigate } from "react-router-dom";

import Button from "./Button";

function Footer() {
  const navigate = useNavigate();
  return (
    <StyledFooter>
      <div className="box">
        <img src={customercentericon} alt="customercentericon" />
        <p>カスタマーセンター</p>
        <p className="num">03-6412-9071</p>
        <p>Mon~Fri AM 10:00~PM17:00</p>
        <p>Lunch PM 12:00~PM 13:00</p>
        <p>SAT SUN HOLIDAY OFF</p>
        <div className="button between">

          <StyledButton to='/about-us'>
            <img src={aboutus} alt="aboutus" />
          </StyledButton>
          <StyledButton to='/notice'>
            <img src={notice} alt="notice" />
          </StyledButton>
          <StyledButton to='/faq'>
            <img src={FAQ} alt="FAQ" />
          </StyledButton>
          <StyledButton to='/b2b'>
            <img src={B2BOrder} alt="B2B" />
          </StyledButton>
          <StyledButton to='/international-shopping'>
            <img src={internationalshopping} alt="internationalshopping" />
          </StyledButton>

        </div>

        <div style={{ padding: '20px' }}>
          <StyledButton to='/apply'>
            <StyledApplyButton>ビジネスに関するお問い合わせ</StyledApplyButton>
          </StyledButton>
        </div>
        
      
        <div className="info">
          <div className="">
          <StyledButton to='/delivery'><p>特定商取り引きに関する法律に基づく表記</p></StyledButton>
            {/* <p>特定商取り引きに関する法律に基づく表記</p> */}
            <p>COMPANY : MONASVENTANA Co.,ltd</p>
            <p>ADDRESS : 3F,72,Sungin-ro, Seongbuk-gu, Seoul, Korea</p>
            <p>MALL-ORDER LICENSE : 2017-Seoul Seacho-1442</p>
          </div>
          <div className="">
            <p>BUSINESS LICENSE : 3818600655</p>
            <p>TEL:03-6412-9071 / FAX: 05070936164</p>
            <p>CONTACT US: yourishinfo@gmail.com</p>
            <p>COPYRIGHTⓒMONASVENTANA ALL RIGHT RESERVED</p>
          </div>
        </div>
      </div>
    </StyledFooter>
  );
}

export default Footer;

const StyledFooter = styled.footer`
  margin: 0 auto 60px;
  position: relative;
  width: 100%;
  //height: 574px;
  background-color: #fff;
  p {
    font-size: 10px;
  }
  div.evenly {
    height: 40px;
    margin: auto;
    width: 100%;
    align-items: center;
    word-break: break-all;
  }
  .evenly {
    font-size: 8px;
  }
  div.box {
    padding: 20px 0 25px;
    background-color: #000;
    color: #fff;
    p {
      margin: 5px;
      font-weight: 400;
    }
    p:nth-of-type(1) {
      font-size: 14px;
      margin-top: 20px;
    }
    p.num {
      margin-top: 10px;
      margin-bottom: 10px;
      font-weight: 600;
      font-size: 18px;
    }
    div.info {
      width: 90%;
      border-top: 0.5px solid #fff;
      text-align: center;
      font-size: 8px;
      p {
        font-size: 10px;
        margin-top: 4px;
        font-weight: 400;
        word-break: break-all;
      }
      p:first-of-type {
        margin-top: 20px;
      }
    }
  }
`;


const StyledButton = styled(Button)`
  width: 25%;
  margin: 30px 3px;
  img {
    width: 100%;
    min-width: 10px;
  }
`;

const StyledApplyButton = styled.button`
  color: #fff;
  background: #37C3BD;
  font-weight: 500;
  font-size: 16px;
  border: 1px solid #fff;
  border-radius: 10px;
  padding: 5px 20px;
`;