import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { ViewMoreButton } from "./Common";
const TitleMain = ({ title, text, color, viewmore, children, margin }) => {
  const navigate = useNavigate();
  return (
    <>
      <StyledDiv color={color} title="title" text="text" viewmore="viewmore">
        <div className="title box">
          <h2>{title}</h2>
          <p>{text}</p>
        </div>
        {children && children}
        <div className="title box">
          {viewmore &&
            <ViewMoreButton dark onClick={() => navigate(`/${viewmore}`)} margin={margin}>
              さらに表示
            </ViewMoreButton>
          }
        </div>
      </StyledDiv>
    </>

  );
};
export default TitleMain;

const StyledDiv = styled.div`
  width: 100%;
  margin: auto;
  .title {
    h2 {
      margin-top: 30px;
      font-weight: 700;
      font-size: 30px;
      color: ${(color) => color?.color || "#000"};
      text-align: center;
    }
    p {
      color: ${(color) => color?.color || "#000"};
      font-size: 15px;
      font-weight: 350;
      text-align: center;
      margin: 15px 0;
      font-family: var(--font-jp-regular);
    }
  }
`;