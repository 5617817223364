import React from "react";
import styled from "styled-components";
import HeaderWithBack from "../../components/HeaderWithBack";
import { useQuery } from "react-query";
import { fetchMainData } from "../../utils";
import ListMainProduct from "../../components/ListMainProduct";
import StyledP from "../../components/StyledP";
import Loading from "../Loading";
import ErrorPage from "../ErrorPage";
import { RootWrapper } from "../../components/Common";
import NavBasic from "../../components/NavBasic";
import ArrowButton from "../../components/ArrowButton";
function NowUse() {
  const { isLoading, isError, data } = useQuery("now-use", fetchMainData);
  return (
    <RootWrapper>
      <StyledDiv>
        <HeaderWithBack eng="true" text="NOW USE"></HeaderWithBack>
        <StyledP bold>使用期限間近の商品を格安価格で!</StyledP>
        {isLoading && <Loading />}
        {isError && <ErrorPage />}
        {data && <ListMainProduct isNowuse={true} data={data.nowUse} />}
      </StyledDiv>
      <NavBasic/>
      <ArrowButton />
    </RootWrapper>
  );
}

export default NowUse;

const StyledDiv = styled.div`
  .text-wrap {
    height: 50px;
    line-height: 2;
    margin-top: 56px;
    margin-bottom: 30px;
  }
`;
