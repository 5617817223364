import React from 'react';
import { ContentWrapper, RootWrapper } from '../components/Common';
import HeaderWithBack from '../components/HeaderWithBack';
import QAToggleBox from '../components/QAToggleBox';
import NavBasic from '../components/NavBasic';
import { useEffect } from 'react';
import { api_get } from '../apis/apis';
import { useState } from 'react';

const Faq = () => {
  const [faqData, setFaqData] = useState([]);
  useEffect(()=>{
    api_get('/faq/list').then((res)=> {
      setFaqData(res);
    }).catch((error)=>{
      throw error;
    })
  },[]);
  return (
    <RootWrapper>
      <HeaderWithBack eng="true" text={"FAQ"} />
      <ContentWrapper>
        {faqData && faqData.map((list,index) => (
          <QAToggleBox
            key={index}
            question={list.title}
            answer={list.contents}
            signDate={list.signDate}
            showArrow={true}
          />
        ))}
        
      </ContentWrapper>
      <NavBasic/>
    </RootWrapper>
  );
};

export default Faq;